import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import MoreVert from "@mui/icons-material/MoreVert";
import { FcFeedback, FcBusinessContact, FcAbout } from "react-icons/fc";
import { Link } from "react-router-dom";

export default function PositionedMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="positioned-demo-button"
        aria-controls={open ? "positioned-demo-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        color="neutral"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="positioned-demo-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby="positioned-demo-button"
        placement="bottom-end"
      >
        <MenuItem onClick={handleClose}>
          <Link to="/Aboutus" style={{ textDecoration: "none", display: "block" }}>
            <ListItemDecorator>
              <FcAbout />
            </ListItemDecorator>
            About
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/Feedback" style={{ textDecoration: "none", display: "block" }}>
            <ListItemDecorator>
              <FcFeedback />
            </ListItemDecorator>
            FeedBack
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/Resources" style={{ textDecoration: "none", display: "block" }}>
            <ListItemDecorator>
              <FcFeedback />
            </ListItemDecorator>
            Resources
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/Contact" style={{ textDecoration: "none", display: "block" }}>
            <ListItemDecorator>
              <FcBusinessContact />
            </ListItemDecorator>
            Contact Us
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
