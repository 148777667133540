import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsSunburst from "highcharts/modules/sunburst";
import HCExport from "highcharts/modules/exporting";
import { data1 } from "./RDAtableData";
import "./RdaTable.css";

HCExport(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
// var data = [
//   {
//     id: "0.0",
//     parent: "",
//     name: "RDA",
//   },

//   // Infants
//   {
//     id: "1.1",
//     parent: "0.0",
//     name: "Protein",
//     value: 1,
//   },

//   {
//     id: "1.111",
//     parent: "1.1",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.1111",
//     parent: "1.111",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 1,
//   },

//   {
//     id: "1.1112",
//     parent: "1.111",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.121",
//     parent: "1.1",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.1211",
//     parent: "1.121",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1212",
//     parent: "1.121",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1213",
//     parent: "1.121",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 1,
//   },

//   // Boys

//   {
//     id: "1.131",
//     parent: "1.1",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.1311",
//     parent: "1.131",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1312",
//     parent: "1.131",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1313",
//     parent: "1.131",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 1,
//   },

//    // Girls

//    {
//     id: "1.141",
//     parent: "1.1",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.1411",
//     parent: "1.141",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1412",
//     parent: "1.141",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1413",
//     parent: "1.141",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 1,
//   },

//   //Men
//   {
//     id: "1.151",
//     parent: "1.1",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.1511",
//     parent: "1.151",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1512",
//     parent: "1.151",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1513",
//     parent: "1.151",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 1,
//   },

//   //Women

//   {
//     id: "1.161",
//     parent: "1.1",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.1611",
//     parent: "1.161",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1612",
//     parent: "1.161",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1613",
//     parent: "1.161",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1614",
//     parent: "1.161",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 1,
//   },

//   {
//     id: "1.1615",
//     parent: "1.161",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1616",
//     parent: "1.161",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 1,
//   },
//    // Elderly

//    {
//     id: "1.171",
//     parent: "1.1",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.1711",
//     parent: "1.171",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 1,
//   },

//   {
//     id: "1.1712",
//     parent: "1.171",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 1,
//   },

//  {
//     id: "1.2",
//     parent: "0.0",
//     name: "Dietary Fiber",
//     value: 1,
//   },
//   {
//     id: "1.211",
//     parent: "1.2",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.2111",
//     parent: "1.211",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.2112",
//     parent: "1.211",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.221",
//     parent: "1.2",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.2211",
//     parent: "1.221",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.2212",
//     parent: "1.221",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.2213",
//     parent: "1.221",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.231",
//     parent: "1.2",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.2311",
//     parent: "1.231",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.2312",
//     parent: "1.231",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.2313",
//     parent: "1.231",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.241",
//     parent: "1.2",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.2411",
//     parent: "1.241",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.2412",
//     parent: "1.241",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.2413",
//     parent: "1.241",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.251",
//     parent: "1.2",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.2511",
//     parent: "1.251",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.2512",
//     parent: "1.251",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.2513",
//     parent: "1.251",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.261",
//     parent: "1.2",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.2611",
//     parent: "1.261",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.2612",
//     parent: "1.261",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.2613",
//     parent: "1.261",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.2614",
//     parent: "1.261",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.2615",
//     parent: "1.261",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.2616",
//     parent: "1.261",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.271",
//     parent: "1.2",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.2711",
//     parent: "1.271",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.2712",
//     parent: "1.271",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.3",
//     parent: "0.0",
//     name: "Calcium",
//     value: 1,
//   },
//   {
//     id: "1.311",
//     parent: "1.3",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.3111",
//     parent: "1.311",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.3112",
//     parent: "1.311",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.321",
//     parent: "1.3",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.3211",
//     parent: "1.321",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.3212",
//     parent: "1.321",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.3213",
//     parent: "1.321",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.331",
//     parent: "1.3",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.3311",
//     parent: "1.331",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.3312",
//     parent: "1.331",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.3313",
//     parent: "1.331",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.341",
//     parent: "1.3",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.3411",
//     parent: "1.341",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.3412",
//     parent: "1.341",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.3413",
//     parent: "1.341",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.351",
//     parent: "1.3",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.3511",
//     parent: "1.351",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.3512",
//     parent: "1.351",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.3513",
//     parent: "1.351",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.361",
//     parent: "1.3",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.3611",
//     parent: "1.361",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.3612",
//     parent: "1.361",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.3613",
//     parent: "1.361",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.3614",
//     parent: "1.361",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.3615",
//     parent: "1.361",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.3616",
//     parent: "1.361",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.371",
//     parent: "1.3",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.3711",
//     parent: "1.371",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.3712",
//     parent: "1.371",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.4",
//     parent: "0.0",
//     name: "Magnesium",
//     value: 1,
//   },
//   {
//     id: "1.411",
//     parent: "1.4",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.4111",
//     parent: "1.411",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.4112",
//     parent: "1.411",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.421",
//     parent: "1.4",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.4211",
//     parent: "1.421",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.4212",
//     parent: "1.421",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.4213",
//     parent: "1.421",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.431",
//     parent: "1.4",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.4311",
//     parent: "1.431",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.4312",
//     parent: "1.431",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.4313",
//     parent: "1.431",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.441",
//     parent: "1.4",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.4411",
//     parent: "1.441",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.4412",
//     parent: "1.441",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.4413",
//     parent: "1.441",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.451",
//     parent: "1.4",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.4511",
//     parent: "1.451",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.4512",
//     parent: "1.451",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.4513",
//     parent: "1.451",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.461",
//     parent: "1.4",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.4611",
//     parent: "1.461",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.4612",
//     parent: "1.461",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.4613",
//     parent: "1.461",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.4614",
//     parent: "1.461",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.4615",
//     parent: "1.461",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.4616",
//     parent: "1.461",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.471",
//     parent: "1.4",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.4711",
//     parent: "1.471",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.4712",
//     parent: "1.471",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.5",
//     parent: "0.0",
//     name: "Iron",
//     value: 1,
//   },
//   {
//     id: "1.511",
//     parent: "1.5",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.5111",
//     parent: "1.511",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.5112",
//     parent: "1.511",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.521",
//     parent: "1.5",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.5211",
//     parent: "1.521",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.5212",
//     parent: "1.521",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.5213",
//     parent: "1.521",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.531",
//     parent: "1.5",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.5311",
//     parent: "1.531",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.5312",
//     parent: "1.531",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.5313",
//     parent: "1.531",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.541",
//     parent: "1.5",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.5411",
//     parent: "1.541",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.5412",
//     parent: "1.541",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.5413",
//     parent: "1.541",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.551",
//     parent: "1.5",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.5511",
//     parent: "1.551",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.5512",
//     parent: "1.551",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.5513",
//     parent: "1.551",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.561",
//     parent: "1.5",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.5611",
//     parent: "1.561",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.5612",
//     parent: "1.561",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.5613",
//     parent: "1.561",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.5614",
//     parent: "1.561",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.5615",
//     parent: "1.561",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.5616",
//     parent: "1.561",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.571",
//     parent: "1.5",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.5711",
//     parent: "1.571",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.5712",
//     parent: "1.571",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.6",
//     parent: "0.0",
//     name: "Zinc",
//     value: 1,
//   },
//   {
//     id: "1.611",
//     parent: "1.6",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.6111",
//     parent: "1.611",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.6112",
//     parent: "1.611",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.621",
//     parent: "1.6",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.6211",
//     parent: "1.621",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.6212",
//     parent: "1.621",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.6213",
//     parent: "1.621",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.631",
//     parent: "1.6",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.6311",
//     parent: "1.631",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.6312",
//     parent: "1.631",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.6313",
//     parent: "1.631",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.641",
//     parent: "1.6",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.6411",
//     parent: "1.641",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.6412",
//     parent: "1.641",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.6413",
//     parent: "1.641",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.651",
//     parent: "1.6",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.6511",
//     parent: "1.651",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.6512",
//     parent: "1.651",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.6513",
//     parent: "1.651",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.661",
//     parent: "1.6",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.6611",
//     parent: "1.661",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.6612",
//     parent: "1.661",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.6613",
//     parent: "1.661",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.6614",
//     parent: "1.661",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.6615",
//     parent: "1.661",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.6616",
//     parent: "1.661",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.671",
//     parent: "1.6",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.6711",
//     parent: "1.671",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.6712",
//     parent: "1.671",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.7",
//     parent: "0.0",
//     name: "Iodine",
//     value: 1,
//   },
//   {
//     id: "1.711",
//     parent: "1.7",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.7111",
//     parent: "1.711",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.7112",
//     parent: "1.711",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.721",
//     parent: "1.7",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.7211",
//     parent: "1.721",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.7212",
//     parent: "1.721",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.7213",
//     parent: "1.721",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.731",
//     parent: "1.7",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.7311",
//     parent: "1.731",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.7312",
//     parent: "1.731",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.7313",
//     parent: "1.731",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.741",
//     parent: "1.7",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.7411",
//     parent: "1.741",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.7412",
//     parent: "1.741",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.7413",
//     parent: "1.741",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.751",
//     parent: "1.7",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.7511",
//     parent: "1.751",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.7512",
//     parent: "1.751",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.7513",
//     parent: "1.751",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.761",
//     parent: "1.7",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.7611",
//     parent: "1.761",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.7612",
//     parent: "1.761",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.7613",
//     parent: "1.761",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.7614",
//     parent: "1.761",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.7615",
//     parent: "1.761",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.7616",
//     parent: "1.761",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.771",
//     parent: "1.7",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.7711",
//     parent: "1.771",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.7712",
//     parent: "1.771",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.8",
//     parent: "0.0",
//     name: "Thiamine",
//     value: 1,
//   },
//   {
//     id: "1.811",
//     parent: "1.8",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.8111",
//     parent: "1.811",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.8112",
//     parent: "1.811",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.821",
//     parent: "1.8",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.8211",
//     parent: "1.821",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.8212",
//     parent: "1.821",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.8213",
//     parent: "1.821",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.831",
//     parent: "1.8",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.8311",
//     parent: "1.831",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.8312",
//     parent: "1.831",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.8313",
//     parent: "1.831",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.841",
//     parent: "1.8",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.8411",
//     parent: "1.841",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.8412",
//     parent: "1.841",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.8413",
//     parent: "1.841",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.851",
//     parent: "1.8",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.8511",
//     parent: "1.851",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.8512",
//     parent: "1.851",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.8513",
//     parent: "1.851",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.861",
//     parent: "1.8",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.8611",
//     parent: "1.861",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.8612",
//     parent: "1.861",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.8613",
//     parent: "1.861",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.8614",
//     parent: "1.861",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.8615",
//     parent: "1.861",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.8616",
//     parent: "1.861",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.871",
//     parent: "1.8",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.8711",
//     parent: "1.871",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.8712",
//     parent: "1.871",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.9",
//     parent: "0.0",
//     name: "Riboflavin",
//     value: 1,
//   },
//   {
//     id: "1.911",
//     parent: "1.9",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.9111",
//     parent: "1.911",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.9112",
//     parent: "1.911",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.921",
//     parent: "1.9",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.9211",
//     parent: "1.921",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.9212",
//     parent: "1.921",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.9213",
//     parent: "1.921",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.931",
//     parent: "1.9",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.9311",
//     parent: "1.931",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.9312",
//     parent: "1.931",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.9313",
//     parent: "1.931",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.941",
//     parent: "1.9",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.9411",
//     parent: "1.941",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.9412",
//     parent: "1.941",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.9413",
//     parent: "1.941",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.951",
//     parent: "1.9",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.9511",
//     parent: "1.951",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.9512",
//     parent: "1.951",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.9513",
//     parent: "1.951",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.961",
//     parent: "1.9",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.9611",
//     parent: "1.961",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.9612",
//     parent: "1.961",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.9613",
//     parent: "1.961",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.9614",
//     parent: "1.961",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 69(3rd trimester)",
//     value: 69,
//   },

//   {
//     id: "1.9615",
//     parent: "1.961",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.9616",
//     parent: "1.961",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.971",
//     parent: "1.9",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.9711",
//     parent: "1.971",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.9712",
//     parent: "1.971",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.10",
//     parent: "0.0",
//     name: "Niacin",
//     value: 1,
//   },
//   {
//     id: "1.1011",
//     parent: "1.10",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.10111",
//     parent: "1.1011",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.10112",
//     parent: "1.1011",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.1021",
//     parent: "1.10",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.10211",
//     parent: "1.1021",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.10212",
//     parent: "1.1021",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.10213",
//     parent: "1.1021",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.1031",
//     parent: "1.10",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.10311",
//     parent: "1.1031",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.10312",
//     parent: "1.1031",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.10313",
//     parent: "1.1031",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.1041",
//     parent: "1.10",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.10411",
//     parent: "1.1041",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.10412",
//     parent: "1.1041",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.10413",
//     parent: "1.1041",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.1051",
//     parent: "1.10",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.10511",
//     parent: "1.1051",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.10512",
//     parent: "1.1051",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.10513",
//     parent: "1.1051",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.1061",
//     parent: "1.10",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.10611",
//     parent: "1.1061",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.10612",
//     parent: "1.1061",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.10613",
//     parent: "1.1061",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.10614",
//     parent: "1.1061",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.10615",
//     parent: "1.1061",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.10616",
//     parent: "1.1061",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.1071",
//     parent: "1.10",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.10711",
//     parent: "1.1071",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.10712",
//     parent: "1.1071",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.11",
//     parent: "0.0",
//     name: "Vit-B6",
//     value: 1,
//   },
//   {
//     id: "1.1111",
//     parent: "1.11",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.11111",
//     parent: "1.1111",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.11112",
//     parent: "1.1111",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.1121",
//     parent: "1.11",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.11211",
//     parent: "1.1121",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.11212",
//     parent: "1.1121",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.11213",
//     parent: "1.1121",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.1131",
//     parent: "1.11",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.11311",
//     parent: "1.1131",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.11312",
//     parent: "1.1131",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.11313",
//     parent: "1.1131",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.1141",
//     parent: "1.11",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.11411",
//     parent: "1.1141",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.11412",
//     parent: "1.1141",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.11413",
//     parent: "1.1141",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.1151",
//     parent: "1.11",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.11511",
//     parent: "1.1151",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.11512",
//     parent: "1.1151",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.11513",
//     parent: "1.1151",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.1161",
//     parent: "1.11",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.11611",
//     parent: "1.1161",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.11612",
//     parent: "1.1161",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.11613",
//     parent: "1.1161",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.11614",
//     parent: "1.1161",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.11615",
//     parent: "1.1161",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.11616",
//     parent: "1.1161",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.1171",
//     parent: "1.11",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.11711",
//     parent: "1.1171",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.11712",
//     parent: "1.1171",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.12",
//     parent: "0.0",
//     name: "Folate",
//     value: 1,
//   },
//   {
//     id: "1.1211",
//     parent: "1.12",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.12111",
//     parent: "1.1211",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.12112",
//     parent: "1.1211",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.1221",
//     parent: "1.12",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.12211",
//     parent: "1.1221",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.12212",
//     parent: "1.1221",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.12213",
//     parent: "1.1221",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.1231",
//     parent: "1.12",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.12311",
//     parent: "1.1231",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.12312",
//     parent: "1.1231",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.12313",
//     parent: "1.1231",
//     name: "16-112 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.1241",
//     parent: "1.12",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.12411",
//     parent: "1.1241",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.12412",
//     parent: "1.1241",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.12413",
//     parent: "1.1241",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.1251",
//     parent: "1.12",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.12511",
//     parent: "1.1251",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.12512",
//     parent: "1.1251",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.12513",
//     parent: "1.1251",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.1261",
//     parent: "1.12",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.12611",
//     parent: "1.1261",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.12612",
//     parent: "1.1261",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.12613",
//     parent: "1.1261",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.12614",
//     parent: "1.1261",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.12615",
//     parent: "1.1261",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.12616",
//     parent: "1.1261",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.1271",
//     parent: "1.12",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.12711",
//     parent: "1.1271",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.12712",
//     parent: "1.1271",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.13",
//     parent: "0.0",
//     name: "Vit-B12",
//     value: 1,
//   },
//   {
//     id: "1.1311",
//     parent: "1.13",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.13111",
//     parent: "1.1311",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.13112",
//     parent: "1.1311",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.1321",
//     parent: "1.13",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.13211",
//     parent: "1.1321",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.13212",
//     parent: "1.1321",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.13213",
//     parent: "1.1321",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.1331",
//     parent: "1.13",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.13311",
//     parent: "1.1331",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.13312",
//     parent: "1.1331",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.13313",
//     parent: "1.1331",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.1341",
//     parent: "1.13",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.13411",
//     parent: "1.1341",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.13412",
//     parent: "1.1341",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.13413",
//     parent: "1.1341",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.1351",
//     parent: "1.13",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.13511",
//     parent: "1.1351",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.13512",
//     parent: "1.1351",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.13513",
//     parent: "1.1351",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.1361",
//     parent: "1.13",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.13611",
//     parent: "1.1361",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.13612",
//     parent: "1.1361",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.13613",
//     parent: "1.1361",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.13614",
//     parent: "1.1361",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.13615",
//     parent: "1.1361",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.13616",
//     parent: "1.1361",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.1371",
//     parent: "1.13",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.13711",
//     parent: "1.1371",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.13712",
//     parent: "1.1371",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.14",
//     parent: "0.0",
//     name: "Vit-C",
//     value: 1,
//   },
//   {
//     id: "1.1411",
//     parent: "1.14",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.14111",
//     parent: "1.1411",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.14112",
//     parent: "1.1411",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.1421",
//     parent: "1.14",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.14211",
//     parent: "1.1421",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.14212",
//     parent: "1.1421",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.14213",
//     parent: "1.1421",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.1431",
//     parent: "1.14",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.14311",
//     parent: "1.1431",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.14312",
//     parent: "1.1431",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.14313",
//     parent: "1.1431",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.1441",
//     parent: "1.14",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.14411",
//     parent: "1.1441",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.14412",
//     parent: "1.1441",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.14413",
//     parent: "1.1441",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.1451",
//     parent: "1.14",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.14511",
//     parent: "1.1451",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.14512",
//     parent: "1.1451",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.14513",
//     parent: "1.1451",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.1461",
//     parent: "1.14",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.14611",
//     parent: "1.1461",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.14612",
//     parent: "1.1461",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.14613",
//     parent: "1.1461",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.14614",
//     parent: "1.1461",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.14615",
//     parent: "1.1461",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.14616",
//     parent: "1.1461",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.1471",
//     parent: "1.14",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.14711",
//     parent: "1.1471",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.14712",
//     parent: "1.1471",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.15",
//     parent: "0.0",
//     name: "Vit-A",
//     value: 1,
//   },

//   {
//     id: "1.1511",
//     parent: "1.15",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.15111",
//     parent: "1.1511",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.15112",
//     parent: "1.1511",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.1521",
//     parent: "1.15",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.15211",
//     parent: "1.1521",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.15212",
//     parent: "1.1521",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.15213",
//     parent: "1.1521",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.1531",
//     parent: "1.15",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.15311",
//     parent: "1.1531",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.15312",
//     parent: "1.1531",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.15313",
//     parent: "1.1531",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.1541",
//     parent: "1.15",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.15411",
//     parent: "1.1541",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.15412",
//     parent: "1.1541",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.15413",
//     parent: "1.1541",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.1551",
//     parent: "1.15",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.15511",
//     parent: "1.1551",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.15512",
//     parent: "1.1551",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.15513",
//     parent: "1.1551",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.1561",
//     parent: "1.15",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.15611",
//     parent: "1.1561",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.15612",
//     parent: "1.1561",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.15613",
//     parent: "1.1561",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.15614",
//     parent: "1.1561",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.15615",
//     parent: "1.1561",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.15616",
//     parent: "1.1561",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.1571",
//     parent: "1.15",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.15711",
//     parent: "1.1571",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.15712",
//     parent: "1.1571",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.16",
//     parent: "0.0",
//     name: "Vit-D",
//     value: 1,
//   },
//   {
//     id: "1.1611",
//     parent: "1.16",
//     name: "Infants",
//     value: 2 / 22,
//   },

//   {
//     id: "1.16111",
//     parent: "1.1611",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 8.0,
//   },

//   {
//     id: "1.16112",
//     parent: "1.1611",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 10.5,
//   },

//   {
//     id: "1.1621",
//     parent: "1.16",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.16211",
//     parent: "1.1621",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 12.5,
//   },

//   {
//     id: "1.16212",
//     parent: "1.1621",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 16.0,
//   },

//   {
//     id: "1.16213",
//     parent: "1.1621",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 23.0,
//   },

//   // Boys

//   {
//     id: "1.1631",
//     parent: "1.16",
//     name: "Boys",
//     value: 3 / 22,
//   },

//   {
//     id: "1.16311",
//     parent: "1.1631",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 32.0,
//   },

//   {
//     id: "1.16312",
//     parent: "1.1631",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 45.0,
//   },

//   {
//     id: "1.16313",
//     parent: "1.1631",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 55.0,
//   },

//    // Girls

//    {
//     id: "1.1641",
//     parent: "1.16",
//     name: "Girls",
//     value: 3 / 22,
//   },

//    {
//     id: "1.16411",
//     parent: "1.1641",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 33,
//   },

//   {
//     id: "1.16412",
//     parent: "1.1641",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 43.0,
//   },

//   {
//     id: "1.16413",
//     parent: "1.1641",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   //Men
//   {
//     id: "1.1651",
//     parent: "1.16",
//     name: "Men",
//     value: 3 / 22,
//   },

//   {
//     id: "1.16511",
//     parent: "1.1651",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.16512",
//     parent: "1.1651",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.16513",
//     parent: "1.1651",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   //Women

//   {
//     id: "1.1661",
//     parent: "1.16",
//     name: "Women",
//     value: 6 / 22,
//   },

//   {
//     id: "1.16611",
//     parent: "1.1661",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.16612",
//     parent: "1.1661",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.16613",
//     parent: "1.1661",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 46.0,
//   },

//   {
//     id: "1.16614",
//     parent: "1.1661",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 68,
//   },

//   {
//     id: "1.16615",
//     parent: "1.1661",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 63.0,
//   },

//   {
//     id: "1.16616",
//     parent: "1.1661",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 59.0,
//   },
//    // Elderly

//    {
//     id: "1.1671",
//     parent: "1.16",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.16711",
//     parent: "1.1671",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 54.0,
//   },

//   {
//     id: "1.16712",
//     parent: "1.1671",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 54.0,
//   },

// ];

// const data1 = [
//   {
//     id: "0.0",
//     parent: "",
//     name: "RDA",
//   },
//   {
//     id: "1.1",
//     parent: "0.0",
//     name: "Infants",
//     value: 2 / 22,
//   },
//   {
//     id: "1.2",
//     parent: "0.0",
//     name: "Children",
//     value: 3 / 22,
//   },
//   {
//     id: "1.3",
//     parent: "0.0",
//     name: "Boys",
//     value: 3 / 22,
//   },
//   {
//     id: "1.4",
//     parent: "0.0",
//     name: "Girls",
//     value: 3 / 22,
//   },
//   {
//     id: "1.5",
//     parent: "0.0",
//     name: "Men",
//     value: 3 / 22,
//   },
//   {
//     id: "1.6",
//     parent: "0.0",
//     name: "Women",
//     value: 6 / 22,
//   },
//   {
//     id: "1.7",
//     parent: "0.0",
//     name: "Elderly",
//     value: 2 / 22,
//   },

//   {
//     id: "1.11",
//     parent: "1.1",
//     name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//     value: 17.0,
//   },
//   {
//     id: '1.111',
//     parent: '1.11',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.112',
//     parent: '1.11',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.113',
//     parent: '1.11',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.114',
//     parent: '1.11',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.115',
//     parent: '1.11',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.116',
//     parent: '1.11',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.117',
//     parent: '1.11',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.118',
//     parent: '1.11',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.119',
//     parent: '1.11',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.1110',
//     parent: '1.11',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.1111',
//     parent: '1.11',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.1112',
//     parent: '1.11',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.1113',
//     parent: '1.11',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.1114',
//     parent: '1.11',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.1115',
//     parent: '1.11',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.1116',
//     parent: '1.11',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.1117',
//     parent: '1.11',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.12",
//     parent: "1.1",
//     name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.121',
//     parent: '1.12',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.122',
//     parent: '1.12',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.123',
//     parent: '1.12',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.124',
//     parent: '1.12',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.125',
//     parent: '1.12',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.126',
//     parent: '1.12',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.127',
//     parent: '1.12',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.128',
//     parent: '1.12',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.129',
//     parent: '1.12',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.1210',
//     parent: '1.12',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.1211',
//     parent: '1.12',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.1212',
//     parent: '1.12',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.1213',
//     parent: '1.12',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.1214',
//     parent: '1.12',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.1215',
//     parent: '1.12',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.1216',
//     parent: '1.12',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.1217',
//     parent: '1.12',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   // Children

//   {
//     id: "1.21",
//     parent: "1.2",
//     name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.211',
//     parent: '1.21',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.212',
//     parent: '1.21',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.213',
//     parent: '1.21',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.214',
//     parent: '1.21',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.215',
//     parent: '1.21',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.216',
//     parent: '1.21',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.217',
//     parent: '1.21',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.218',
//     parent: '1.21',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.219',
//     parent: '1.21',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.2110',
//     parent: '1.21',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.2111',
//     parent: '1.21',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.2112',
//     parent: '1.21',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.2113',
//     parent: '1.21',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.2114',
//     parent: '1.21',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.2115',
//     parent: '1.21',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.2116',
//     parent: '1.21',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.2117',
//     parent: '1.21',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.22",
//     parent: "1.2",
//     name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.221',
//     parent: '1.22',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.222',
//     parent: '1.22',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.223',
//     parent: '1.22',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.224',
//     parent: '1.22',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.225',
//     parent: '1.22',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.226',
//     parent: '1.22',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.227',
//     parent: '1.22',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.228',
//     parent: '1.22',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.229',
//     parent: '1.22',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.2210',
//     parent: '1.22',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.2211',
//     parent: '1.22',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.2212',
//     parent: '1.22',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.2213',
//     parent: '1.22',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.2214',
//     parent: '1.22',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.2215',
//     parent: '1.22',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.2216',
//     parent: '1.22',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.2217',
//     parent: '1.22',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.23",
//     parent: "1.2",
//     name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.231',
//     parent: '1.23',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.232',
//     parent: '1.23',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.233',
//     parent: '1.23',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.234',
//     parent: '1.23',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.235',
//     parent: '1.23',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.236',
//     parent: '1.23',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.237',
//     parent: '1.23',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.238',
//     parent: '1.23',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.239',
//     parent: '1.23',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.2310',
//     parent: '1.23',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.2311',
//     parent: '1.23',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.2312',
//     parent: '1.23',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.2313',
//     parent: '1.23',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.2314',
//     parent: '1.23',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.2315',
//     parent: '1.23',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.2316',
//     parent: '1.23',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.2317',
//     parent: '1.23',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   // Boys

//   {
//     id: "1.31",
//     parent: "1.3",
//     name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.311',
//     parent: '1.31',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.312',
//     parent: '1.31',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.313',
//     parent: '1.31',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.314',
//     parent: '1.31',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.315',
//     parent: '1.31',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.316',
//     parent: '1.31',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.317',
//     parent: '1.31',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.318',
//     parent: '1.31',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.319',
//     parent: '1.31',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.3110',
//     parent: '1.31',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.3111',
//     parent: '1.31',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.3112',
//     parent: '1.31',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.3113',
//     parent: '1.31',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.3114',
//     parent: '1.31',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.3115',
//     parent: '1.31',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.3116',
//     parent: '1.31',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.3117',
//     parent: '1.31',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.32",
//     parent: "1.3",
//     name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.321',
//     parent: '1.32',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.322',
//     parent: '1.32',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.323',
//     parent: '1.32',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.324',
//     parent: '1.32',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.325',
//     parent: '1.32',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.326',
//     parent: '1.32',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.327',
//     parent: '1.32',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.328',
//     parent: '1.32',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.329',
//     parent: '1.32',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.3210',
//     parent: '1.32',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.3211',
//     parent: '1.32',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.3212',
//     parent: '1.32',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.3213',
//     parent: '1.32',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.3214',
//     parent: '1.32',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.3215',
//     parent: '1.32',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.3216',
//     parent: '1.32',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.3217',
//     parent: '1.32',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.33",
//     parent: "1.3",
//     name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.331',
//     parent: '1.33',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.332',
//     parent: '1.33',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.333',
//     parent: '1.33',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.334',
//     parent: '1.33',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.335',
//     parent: '1.33',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.336',
//     parent: '1.33',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.337',
//     parent: '1.33',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.338',
//     parent: '1.33',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.339',
//     parent: '1.33',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.3310',
//     parent: '1.33',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.3311',
//     parent: '1.33',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.3312',
//     parent: '1.33',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.3313',
//     parent: '1.33',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.3314',
//     parent: '1.33',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.3315',
//     parent: '1.33',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.3316',
//     parent: '1.33',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.3317',
//     parent: '1.33',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   // Girls

//   {
//     id: "1.41",
//     parent: "1.4",
//     name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//     value: 17,
//   },
//   {
//     id: '1.411',
//     parent: '1.41',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.412',
//     parent: '1.41',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.413',
//     parent: '1.41',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.414',
//     parent: '1.41',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.415',
//     parent: '1.41',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.416',
//     parent: '1.41',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.417',
//     parent: '1.41',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.418',
//     parent: '1.41',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.419',
//     parent: '1.41',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.4110',
//     parent: '1.41',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.4111',
//     parent: '1.41',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.4112',
//     parent: '1.41',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.4113',
//     parent: '1.41',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.4114',
//     parent: '1.41',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.4115',
//     parent: '1.41',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.4116',
//     parent: '1.41',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.4117',
//     parent: '1.41',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.42",
//     parent: "1.4",
//     name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.421',
//     parent: '1.42',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.422',
//     parent: '1.42',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.423',
//     parent: '1.42',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.424',
//     parent: '1.42',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.425',
//     parent: '1.42',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.426',
//     parent: '1.42',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.427',
//     parent: '1.42',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.428',
//     parent: '1.42',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.429',
//     parent: '1.42',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.4210',
//     parent: '1.42',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.4211',
//     parent: '1.42',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.4212',
//     parent: '1.42',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.4213',
//     parent: '1.42',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.4214',
//     parent: '1.42',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.4215',
//     parent: '1.42',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.4216',
//     parent: '1.42',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.4217',
//     parent: '1.42',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.43",
//     parent: "1.4",
//     name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.431',
//     parent: '1.43',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.432',
//     parent: '1.43',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.433',
//     parent: '1.43',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.434',
//     parent: '1.43',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.435',
//     parent: '1.43',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.436',
//     parent: '1.43',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.437',
//     parent: '1.43',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.438',
//     parent: '1.43',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.439',
//     parent: '1.43',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.4310',
//     parent: '1.43',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.4311',
//     parent: '1.43',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.4312',
//     parent: '1.43',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.4313',
//     parent: '1.43',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.4314',
//     parent: '1.43',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.4315',
//     parent: '1.43',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.4316',
//     parent: '1.43',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.4317',
//     parent: '1.43',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   //Men

//   {
//     id: "1.51",
//     parent: "1.5",
//     name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.511',
//     parent: '1.51',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.512',
//     parent: '1.51',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.513',
//     parent: '1.51',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.514',
//     parent: '1.51',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.515',
//     parent: '1.51',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.516',
//     parent: '1.51',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.517',
//     parent: '1.51',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.518',
//     parent: '1.51',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.519',
//     parent: '1.51',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.5110',
//     parent: '1.51',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.5111',
//     parent: '1.51',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.5112',
//     parent: '1.51',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.5113',
//     parent: '1.51',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.5114',
//     parent: '1.51',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.5115',
//     parent: '1.51',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.5116',
//     parent: '1.51',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.5117',
//     parent: '1.51',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.52",
//     parent: "1.5",
//     name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.521',
//     parent: '1.52',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.522',
//     parent: '1.52',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.523',
//     parent: '1.52',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.524',
//     parent: '1.52',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.525',
//     parent: '1.52',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.526',
//     parent: '1.52',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.527',
//     parent: '1.52',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.528',
//     parent: '1.52',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.529',
//     parent: '1.52',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.5210',
//     parent: '1.52',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.5211',
//     parent: '1.52',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.5212',
//     parent: '1.52',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.5213',
//     parent: '1.52',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.5214',
//     parent: '1.52',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.5215',
//     parent: '1.52',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.5216',
//     parent: '1.52',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.5217',
//     parent: '1.52',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.53",
//     parent: "1.5",
//     name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.531',
//     parent: '1.53',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.532',
//     parent: '1.53',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.533',
//     parent: '1.53',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.534',
//     parent: '1.53',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.535',
//     parent: '1.53',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.536',
//     parent: '1.53',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.537',
//     parent: '1.53',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.538',
//     parent: '1.53',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.539',
//     parent: '1.53',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.5310',
//     parent: '1.53',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.5311',
//     parent: '1.53',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.5312',
//     parent: '1.53',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.5313',
//     parent: '1.53',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.5314',
//     parent: '1.53',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.5315',
//     parent: '1.53',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.5316',
//     parent: '1.53',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.5317',
//     parent: '1.53',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   //Women

//   {
//     id: "1.61",
//     parent: "1.6",
//     name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 17,
//   },
//   {
//     id: '1.611',
//     parent: '1.61',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.612',
//     parent: '1.61',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.613',
//     parent: '1.61',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.614',
//     parent: '1.61',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.615',
//     parent: '1.61',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.616',
//     parent: '1.61',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.617',
//     parent: '1.61',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.618',
//     parent: '1.61',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.619',
//     parent: '1.61',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.6110',
//     parent: '1.61',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6111',
//     parent: '1.61',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6112',
//     parent: '1.61',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.6113',
//     parent: '1.61',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.6114',
//     parent: '1.61',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6215',
//     parent: '1.61',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6216',
//     parent: '1.61',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.6217',
//     parent: '1.61',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.62",
//     parent: "1.6",
//     name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.621',
//     parent: '1.62',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.622',
//     parent: '1.62',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.623',
//     parent: '1.62',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.624',
//     parent: '1.62',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.625',
//     parent: '1.62',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.626',
//     parent: '1.62',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.627',
//     parent: '1.62',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.628',
//     parent: '1.62',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.629',
//     parent: '1.62',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.6210',
//     parent: '1.62',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6211',
//     parent: '1.62',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6212',
//     parent: '1.62',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.6213',
//     parent: '1.62',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.6214',
//     parent: '1.62',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6215',
//     parent: '1.62',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6216',
//     parent: '1.62',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.6217',
//     parent: '1.62',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.63",
//     parent: "1.6",
//     name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.631',
//     parent: '1.63',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.632',
//     parent: '1.63',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.633',
//     parent: '1.63',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.634',
//     parent: '1.63',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.635',
//     parent: '1.63',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.636',
//     parent: '1.63',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.637',
//     parent: '1.63',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.638',
//     parent: '1.63',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.639',
//     parent: '1.63',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.6310',
//     parent: '1.63',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6311',
//     parent: '1.63',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6312',
//     parent: '1.63',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.6313',
//     parent: '1.63',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.6314',
//     parent: '1.63',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6315',
//     parent: '1.63',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6316',
//     parent: '1.63',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.6317',
//     parent: '1.63',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.64",
//     parent: "1.6",
//     name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//     value: 17,
//   },
//   {
//     id: '1.641',
//     parent: '1.64',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.642',
//     parent: '1.64',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.643',
//     parent: '1.64',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.644',
//     parent: '1.64',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.645',
//     parent: '1.64',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.646',
//     parent: '1.64',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.647',
//     parent: '1.64',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.648',
//     parent: '1.64',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.649',
//     parent: '1.64',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.6410',
//     parent: '1.64',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6411',
//     parent: '1.64',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6412',
//     parent: '1.64',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.6413',
//     parent: '1.64',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.6414',
//     parent: '1.64',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6415',
//     parent: '1.64',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6416',
//     parent: '1.64',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.6417',
//     parent: '1.64',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.65",
//     parent: "1.6",
//     name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.651',
//     parent: '1.65',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.652',
//     parent: '1.65',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.653',
//     parent: '1.65',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.654',
//     parent: '1.65',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.655',
//     parent: '1.65',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.656',
//     parent: '1.65',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.657',
//     parent: '1.65',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.658',
//     parent: '1.65',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.659',
//     parent: '1.65',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.6510',
//     parent: '1.65',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6511',
//     parent: '1.65',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6512',
//     parent: '1.65',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.6513',
//     parent: '1.65',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.6514',
//     parent: '1.65',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6515',
//     parent: '1.65',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6516',
//     parent: '1.65',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.6517',
//     parent: '1.65',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.66",
//     parent: "1.6",
//     name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//     value: 17.0,
//   },
//   {
//     id: '1.661',
//     parent: '1.66',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.662',
//     parent: '1.66',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.663',
//     parent: '1.66',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.664',
//     parent: '1.66',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.665',
//     parent: '1.66',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.666',
//     parent: '1.66',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.667',
//     parent: '1.66',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.668',
//     parent: '1.66',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.669',
//     parent: '1.66',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.6610',
//     parent: '1.66',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6611',
//     parent: '1.66',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6612',
//     parent: '1.66',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.6613',
//     parent: '1.66',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.6614',
//     parent: '1.66',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.6615',
//     parent: '1.66',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.6616',
//     parent: '1.66',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.6617',
//     parent: '1.66',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   // Elderly

//   {
//     id: "1.71",
//     parent: "1.7",
//     name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//     value: 17,
//   },

//   {
//     id: '1.711',
//     parent: '1.71',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.712',
//     parent: '1.71',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.713',
//     parent: '1.71',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.714',
//     parent: '1.71',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.715',
//     parent: '1.71',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.716',
//     parent: '1.71',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.717',
//     parent: '1.71',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.718',
//     parent: '1.71',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.719',
//     parent: '1.71',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.7110',
//     parent: '1.71',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.7111',
//     parent: '1.71',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.7112',
//     parent: '1.71',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.7113',
//     parent: '1.71',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.7114',
//     parent: '1.71',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.7215',
//     parent: '1.71',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.7216',
//     parent: '1.71',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.7217',
//     parent: '1.71',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

//   {
//     id: "1.72",
//     parent: "1.7",
//     name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//     value: 17,
//   },

//   {
//     id: '1.721',
//     parent: '1.72',
//     name: 'Energy <br> ',
//     value: 1
//   },
//   {
//     id: '1.722',
//     parent: '1.72',
//     name: 'Dietary Fibre <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.723',
//     parent: '1.72',
//     name: 'Protein <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.724',
//     parent: '1.72',
//     name: 'VitaminA <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.725',
//     parent: '1.72',
//     name: 'Thiamin <br> ',
//     value: 1
//   },
//   {
//     id: '1.726',
//     parent: '1.72',
//     name: 'Riboflavin B2 <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.727',
//     parent: '1.72',
//     name: 'Niacin <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.728',
//     parent: '1.72',
//     name: 'VitaminC <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.729',
//     parent: '1.72',
//     name: 'Vitamin-B6 <br> ',
//     value: 1
//   },
//   {
//     id: '1.7210',
//     parent: '1.72',
//     name: 'Folate <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.7211',
//     parent: '1.72',
//     name: 'Vitamin-B12 <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.7212',
//     parent: '1.72',
//     name: 'VitaminD <br> 840 (Micro grams)',
//     value: 1
//   },
//   {
//     id: '1.7213',
//     parent: '1.72',
//     name: 'Calcium <br> ',
//     value: 1
//   },
//   {
//     id: '1.7214',
//     parent: '1.72',
//     name: 'Magnesium <br> RDA 30 (g/d)',
//     value: 1
//   },
//   {
//     id: '1.7215',
//     parent: '1.72',
//     name: 'Iron <br> 46.0 (g)',
//     value: 1
//   },
//   {
//     id: '1.7216',
//     parent: '1.72',
//     name: 'Zinc <br> 840 (mg)',
//     value: 1
//   },
//   {
//     id: '1.7217',
//     parent: '1.72',
//     name: 'Iodine <br> 840 (Micro grams)',
//     value: 1
//   },

// ];

const RdaTable = () => {
  useEffect(() => {
    // Load the Sunburst module
    HighchartsSunburst(Highcharts);

    // Define your custom colors array
    const customColors = [
      "#2C74BE",
      "#662C90",
      "#EF5A2A",
      "#F8B13F",
      "#FCEF0A",
      "#8CC63C",
      "#3AB549",
    ];

    // Create the first chart for 'data'
    // Highcharts.chart("container1", {
    //   chart: {
    //     height: "100%",
    //   },
    //   colors: ["transparent"].concat(customColors),
    //   // colors: ["transparent"].concat(Highcharts.getOptions().colors),
    //   title: {
    //     text: "Recommended Dietary Allowance (RDA) - Chart 1",
    //   },
    //   credits: {
    //     enabled: false,
    //   },
    //   series: [{
    //     type: 'sunburst',
    //     data: data,
    //     name: 'Root',
    //     allowDrillToNode: true,
    //     borderRadius: 3,
    //     cursor: 'pointer',
    //     dataLabels: {
    //       format: '{point.name}',
    //       filter: {
    //         property: 'innerArcLength',
    //         operator: '>',
    //         value: 5
    //       },
    //       rotationMode: "parallel",
    //       style: {
    //         textOutline: "none",
    //       },
    //     },
    //     levels: [{
    //       level: 1,
    //       levelSize: {
    //             value: 2
    //           },
    //       levelIsConstant: false,
    //       dataLabels: {
    //         filter: {
    //           property: 'outerArcLength',
    //           operator: '>',
    //           value: 64
    //         }
    //       }
    //     }, {
    //       level: 2,
    //       colorByPoint: true
    //     },
    //     {
    //       level: 3,
    //       colorVariation: {
    //         key: 'brightness',
    //         to: -0.5
    //       }
    //     }, {
    //       level: 4,
    //       colorVariation: {
    //         key: 'brightness',
    //         to: 0.5
    //       }
    //     }]

    //   }],
    //   tooltip: {
    //     formatter: function () {
    //       return this.point.name;
    //     },
    //   },
    // });

    // Create the second chart for 'data1'

    Highcharts.chart("rdacontainer", {
      chart: {
        height: "100%",
      },
      colors: ["transparent"].concat(customColors),
      title: {
        text: "Sunburst Chart of Recommended Dietary Allowance (RDA) - ICMR-NIN,2020",
      },
      credits: {
        enabled: true,
        text: "Click on segment to view in detailed information on each age group", // Replace with your desired text
        href: null, // Optional: Replace with a link if needed
      },
      series: [
        {
          type: "sunburst",
          data: data1,
          name: "Main Table",
          allowDrillToNode: true,
          borderRadius: 3,
          cursor: "pointer",
          dataLabels: {
            formatter: function () {
              // Get the point object
              const point = this.point;

              // Split the name to separate the main label and the measuring unit
              const nameParts = point.name.split(" ");

              // The main label is the first part of the name
              const mainLabel = nameParts.slice(0, nameParts.length - 1).join(" ");

              // The measuring unit is the last part of the name
              const measuringUnit = nameParts[nameParts.length - 1];

              // Create the desired data label format
              const dataLabel = mainLabel + "<br>" + point.rdavalue + " " + measuringUnit;

              return dataLabel;
            },
            filter: {
              property: "innerArcLength",
              operator: ">",
              value: 5,
            },
            rotationMode: "parallel",
            style: {
              textOutline: "none",
            },
          },
          levels: [
            {
              level: 1,
              levelIsConstant: false,
              dataLabels: {
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64,
                },
              },
            },
            {
              level: 2,
              colorByPoint: true,
            },
            {
              level: 3,
              dataLabels: {
                formatter: function () {
                  // Get the point object
                  const point = this.point;

                  // Split the name to separate the main label and the measuring unit
                  const nameParts = point.name.split("<br>");

                  // The main label is the first part of the name
                  const mainLabel = nameParts[0].trim();

                  const dataLabel = mainLabel;

                  return dataLabel;
                },
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64,
                },
              },
              colorVariation: {
                key: "brightness",
                to: -0.5,
              },
            },
            {
              level: 4,
              colorVariation: {
                key: "brightness",
                to: 0.5,
              },
            },
          ],
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
            ],
          },
        },
      },
      tooltip: {
        formatter: function () {
          // Get the point object
          const point = this.point;

          // Split the name to separate the main label and the measuring unit
          const nameParts = point.name.split(" ");

          // The main label is the first part of the name
          const mainLabel = nameParts.slice(0, nameParts.length - 1).join(" ");

          // The measuring unit is the last part of the name
          const measuringUnit = nameParts[nameParts.length - 1];

          // Create the desired data label format
          const dataLabel = mainLabel + "<br>" + point.rdavalue + " " + measuringUnit;

          return dataLabel;
        },
      },
    });
  }, []);

  return (
    <figure className="highcharts-figure">
      <div id="rdacontainer"></div>
    </figure>
    //   <div className="highcharts-container">
    //     <div className="highcharts-chart">
    //       <figure className="highcharts-figure">
    //         <div id="container"></div>
    //       </figure>
    //     </div>
    //     <div className="highcharts-chart">
    //       <figure className="highcharts-figure">
    //         <div id="container2"></div>
    //       </figure>
    //     </div>
    //   </div>
  );
};

export default RdaTable;
