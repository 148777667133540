import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import SunburstChart from "../Vitamins/SunburstChart";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import nerve_impulse from '../../../../../assets/images/icons/minerals/Functions/nerve_impulse.png';
import blood_pressure from '../../../../../assets/images/icons/minerals/Functions/blood_pressure.png';
import heart_contraction from '../../../../../assets/images/icons/minerals/Functions/heart_contraction.png';
import fluid_balance from '../../../../../assets/images/icons/minerals/Functions/fluid_balance.png';
import fatigue from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png';
import irregular_heart_rate from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png';
import muscle_cramps from '../../../../../assets/images/icons/minerals/Deficiency/muscle_cramps.png';
import weakness from '../../../../../assets/images/icons/minerals/Deficiency/weakness.png';
import constipation from '../../../../../assets/images/icons/minerals/Deficiency/constipation.png';
import muscle_paralysis from '../../../../../assets/images/icons/minerals/Deficiency/muscle_paralysis.png';
import dried_fruits from "../../../../../assets/FoodSources/Minerals/Sources/dried_fruits.png";
import nuts from "../../../../../assets/FoodSources/Minerals/Sources/nuts.png";
import spinach from "../../../../../assets/FoodSources/Minerals/Sources/spinach.png";
import mushrooms from "../../../../../assets/FoodSources/Minerals/Sources/mushrooms.png";
import wheat_bran from "../../../../../assets/FoodSources/Minerals/Sources/wheat_bran.png";
import yam from "../../../../../assets/FoodSources/Minerals/Sources/yam.png";
import banana_orange from "../../../../../assets/FoodSources/Minerals/Sources/banana_orange.png";
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import poultry from "../../../../../assets/FoodSources/Minerals/Sources/poultry.png";
import legumes from "../../../../../assets/FoodSources/Minerals/Sources/legumes.png";
import tender_coconut_water from "../../../../../assets/FoodSources/Minerals/Sources/tender_coconut_water.png";


export default function Potassium() {
    return (
        <>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of  potassium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-1px' }, }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={fluid_balance} />
                                </ListItemAvatar>
                                <ListItemText primary="Potassium helps to maintain fluid balance in our cells." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={blood_pressure} />
                                </ListItemAvatar>
                                <ListItemText primary="It is involved in maintaining blood pressure." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={heart_contraction} />
                                </ListItemAvatar>
                                <ListItemText primary="It aids muscles and heart in contraction." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={nerve_impulse} />
                                </ListItemAvatar>
                                <ListItemText primary="It is involved in conduction of nerve impulse." />
                            </ListItem>
                            {/* <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={nervoussytem} />
                                </ListItemAvatar>
                                <ListItemText primary="Involved in transmission of nerve impulses" />
                            </ListItem> */}
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of potassium?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                            <ListItem>
                                Good sources of potassium are  dried fruits, nuts, green leafy vegetables, mushrooms, wheat bran, wheat germ, yams, bananas and oranges. Meats, fish, poultry, and legumes are also good sources of potassium.
                            </ListItem>
                            <ListItem>
                                Tender coconut water is a good source of potassium.
                            </ListItem>
                            {/* <ListItem>
                                Tender coconut water is a good source of potassium
                            </ListItem> */}
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        mt={1}

                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="dried fruits">
                                <Avatar src={dried_fruits} size="lg" alt="dried fruits" variant="square" />
                            </Tooltip>
                            <Tooltip title="nuts">
                                <Avatar src={nuts} alt="nuts" variant="square" />
                            </Tooltip>
                            <Tooltip title="green leafy vegetables">
                                <Avatar src={spinach} alt="spinach" variant="square" />
                            </Tooltip>
                            <Tooltip title="mushrooms">
                                <Avatar src={mushrooms} alt="mushrooms" variant="square" />
                            </Tooltip>
                            <Tooltip title="wheat bran and wheat germ">
                                <Avatar src={wheat_bran} alt="wheat bran" variant="square" />
                            </Tooltip>
                            <Tooltip title="yams">
                                <Avatar src={yam} alt="yams" variant="square" />
                            </Tooltip>
                            <Tooltip title="bananas and oranges">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={banana_orange} alt="bananas" variant="square" />
                            </Tooltip>
                            <Tooltip title="fish">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={fish} alt="fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="poultry">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={poultry} alt="poultry" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="tender coconut water">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={tender_coconut_water} alt="tender coconut water" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="bananas and oranges">
                                <Avatar src={banana_orange} alt="bananas" variant="square" />
                            </Tooltip>
                            <Tooltip title="fish">
                                <Avatar src={fish} alt="fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="poultry">
                                <Avatar src={poultry} alt="poultry" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="tender coconut water">
                                <Avatar src={tender_coconut_water} alt="tender coconut water" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
            <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is potassium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                About 90% of ingested potassium is absorbed in the small intestine by passive diffusion.
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1,
                        mt: { xs: 1, sm: 0 },
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What is the recommended dietary intake of potassium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Human} />
                                </ListItemAvatar>
                                <ListItemText primary="The recommended  daily allowance of potassium for adult man and woman is 3500 mg/day." />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is potassium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                About 90% of ingested potassium is absorbed in the small intestine by passive diffusion.
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the signs  and symptoms of potassium deficiency?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Although potassium deficiency is rare, it could occur during prolonged diarrhea, vomiting, or laxative use. The symptoms of potassium deficiency are fatigue, muscle cramps, weakness, and constipation. With severe hypokalemia, muscle paralysis and irregular heart rate can occurs.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        mt={1}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="fatigue">
                                <Avatar src={fatigue} size="lg" alt="fatigue" variant="square" />
                            </Tooltip>
                            <Tooltip title="muscle cramps">
                                <Avatar src={muscle_cramps} alt="muscle cramps" variant="square" />
                            </Tooltip>
                            <Tooltip title="weakness">
                                <Avatar src={weakness} alt="weakness" variant="square" />
                            </Tooltip>
                            <Tooltip title="constipation">
                                <Avatar src={constipation} alt="constipation" variant="square" />
                            </Tooltip>
                            <Tooltip title="muscle paralysis">
                                <Avatar src={muscle_paralysis} alt="muscle paralysis" variant="square" />
                            </Tooltip>
                            <Tooltip title="irregular heart rate ">
                                <Avatar src={irregular_heart_rate} alt="irregular heart rate" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the interactions of potassium with other nutrients?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Potassium does not have significant interactions but it hinders the absorption of magnesium when consumed together." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>   
        </>
    );
}



