// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import coenzyme from '../../../../../assets/images/icons/minerals/Functions/coenzyme.png';
import growth from '../../../../../assets/images/icons/minerals/Deficiency/growth.png';
import anaemia from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/anemia.png';
import vascular_complications from '../../../../../assets/images/icons/minerals/Deficiency/vascular_complications.png';
import neurological_manifestations from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/nerve_damage.png';
import Osteoporosis from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Osteoporosis.png';
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import legumes from "../../../../../assets/FoodSources/Minerals/Sources/legumes.png";
import sesame_seeds from "../../../../../assets/FoodSources/Minerals/Sources/sesame_seeds.png";
import Beetal_leave from "../../../../../assets/FoodSources/Minerals/Sources/Beetal_leave.png";
import fruits from "../../../../../assets/FoodSources/Minerals/Sources/fruits.png";
import vegetables from "../../../../../assets/FoodSources/Minerals/Sources/vegetables.png";
import sunflower from "../../../../../assets/FoodSources/Minerals/Sources/sunflower.png";
import nuts from "../../../../../assets/FoodSources/Minerals/Sources/nuts.png";

export default function Copper() {
    return (
        <>

            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of copper?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={coenzyme} />
                                </ListItemAvatar>
                                <ListItemText primary="Copper is a co-factor for many enzymes which are involved in energy production, iron metabolism, antioxidant defense, and connective tissue formation." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1.5
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of  copper?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                            <ListItem>
                                Sea foods, legumes, and oilseeds like sesame, beetal leaves, sunflower and nuts are rich sources of Copper.
                            </ListItem>
                            <ListItem>
                                Fruits and vegetables are moderate sources of copper.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 }}}>
                            <Tooltip title="Sea foods">
                                <Avatar src={fish} size="lg" alt="fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="oilseeds like sesame">
                                <Avatar src={sesame_seeds} alt="sesame seeds" variant="square" />
                            </Tooltip>
                            <Tooltip title="beetal leaves">
                                <Avatar src={Beetal_leave} alt="beetal leaves" variant="square" />
                            </Tooltip>
                            <Tooltip title="sunflower">
                                <Avatar src={sunflower} alt="sunflower" variant="square" />
                            </Tooltip>
                            <Tooltip title="nuts">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={nuts} alt="Green vegetables" variant="square" />
                            </Tooltip>
                            <Tooltip title="fruits">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={fruits} alt="fruits" variant="square" />
                            </Tooltip>
                            <Tooltip title="vegetables">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={vegetables} alt="vegetables" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 }}}>
                            <Tooltip title="nuts">
                                <Avatar src={nuts} alt="Green vegetables" variant="square" />
                            </Tooltip>
                            <Tooltip title="fruits">
                                <Avatar src={fruits} alt="fruits" variant="square" />
                            </Tooltip>
                            <Tooltip title="vegetables">
                                <Avatar src={vegetables} alt="vegetables" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the interactions of copper with other nutrients?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Copper and Zinc compete with each other for absorption." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
            <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is copper absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Copper is absorbed from all parts of the gastrointestinal tract including the stomach and large intestine." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1,
                        mt: { xs: 1, sm:0},
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What is the recommended dietary intake of copper?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Human} />
                                </ListItemAvatar>
                                <ListItemText primary="The recommended intake of copper required for adult man and adult woman is 1.7 mg/day" />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is copper absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Copper is absorbed from all parts of the gastrointestinal tract including the stomach and large intestine." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens when you are deficient in copper?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Copper deficiency is rare in humans. It occurs mainly in infants resulting in growth retardation, anaemia, vascular complications, osteoporosis and neurological manifestations.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        mt={1}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="infants resulting in growth retardation">
                                <Avatar src={growth} size="lg" alt="growth retardation" variant="square" />
                            </Tooltip>
                            <Tooltip title="anaemia">
                                <Avatar src={anaemia} alt="anaemia" variant="square" />
                            </Tooltip>
                            <Tooltip title="vascular complications">
                                <Avatar src={vascular_complications} alt="vascular complications" variant="square" />
                            </Tooltip>
                            <Tooltip title="Osteoporosis">
                                <Avatar src={Osteoporosis} alt="Osteoporosis" variant="square" />
                            </Tooltip>
                            <Tooltip title="neurological manifestations">
                                <Avatar src={neurological_manifestations} alt="neurological manifestations" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the interactions of copper with other nutrients?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Copper and Zinc compete with each other for absorption." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}



