import React, { useState } from "react";
import { Grid, TextField, Button, Card, CardContent, Typography } from "@mui/material";

export default function Feedback() {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    occupation: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //const response = await fetch("http://localhost:5000/submitFeedback", {
      const response = await fetch("https://nutritionatlasapi.cyclic.app/submitFeedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // console.log("Form submitted successfully:", formData);
        setSubmitted(true);
        setFormData(initialFormData);

        // Display the thank-you message for 3 seconds
        setTimeout(() => {
          setSubmitted(false);
        }, 2000);
      } else {
        console.error("Form submission failed.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <Typography gutterBottom variant="h3" align="center" mt={2}>
        FeedBack
      </Typography>
      <Grid>
        <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" }}>
          <CardContent>
            {submitted ? (
              <div>
                <Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
                  Thank you for your valuable feedback!
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  We appreciate your input.
                </Typography>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      name="firstName"
                      placeholder="Enter first name"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={handleChange}
                      value={formData.firstName}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      name="lastName"
                      placeholder="Enter last name"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={handleChange}
                      value={formData.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={handleChange}
                      value={formData.email}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="occupation"
                      label="Occupation"
                      placeholder="Type your occupation here"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={handleChange}
                      value={formData.occupation}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="message"
                      label="Message"
                      multiline
                      rows={4}
                      placeholder="Type your feedback here"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={handleChange}
                      value={formData.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
