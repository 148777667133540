// import * as React from 'react';
// import Box from '@mui/joy/Box';
// import Sheet from '@mui/joy/Sheet';
// import Button from '@mui/joy/Button';
// import { useTheme } from "@mui/material/styles";
// import { Card, CardActions, CardContent, CardMedia, Container, CssBaseline, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
// import Minerals from "../../../../../../src/assets/FoodSources/Minerals/MineralsHome/MineralsHome.jpg";
// import { FavoriteBorder, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, OpenInNew } from '@mui/icons-material';
// import SunburstChart from '../Vitamins/SunburstChart';

// export default function MineralsHome() {
//     const theme = useTheme();
//     const [showBelowBox, setShowBelowBox] = React.useState(true); // State to toggle the visibility of the below box
//     const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
//     const handleBComplexClick = () => {
//         setShowBelowBox(!showBelowBox);
//     };

//     return (
//         <div style={{ overflowX: 'hidden' }}>
//             <React.Fragment>
//                 <CssBaseline />
//                 <Container fixed mb={4}>
//                     <Typography level="h2" fontSize="2em" textColor="#000" fontWeight="bold" marginTop="0.5em" style={{ fontFamily: "cursive" }}>
//                         Minerals
//                     </Typography>
//                     <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginTop: '20px', maxHeight: { xs: '100%' } }}>
//                         <CardMedia
//                             component="img"
//                             sx={{
//                                 width: { xs: '100%', sm: 'auto' },
//                                 maxWidth: { xs: '100%', sm: '350px' },
//                                 boxShadow: 'none', // Remove the shadow effect by setting it to 'none'
//                             }}
//                             image={Minerals}
//                             alt="Vitamins"
//                         />
//                         <CardContent >
//                             <Typography level="body2" >
//                                 Minerals are found in foods like cereals, bread, meat, fish, milk, dairy, nuts, fruit (especially dried fruit) and vegetables. We need more of some minerals than others. For example, we need more calcium, phosphorus, magnesium, sodium, potassium and chloride than we do iron, zinc, iodine, selenium and copper.
//                             </Typography>
//                         </CardContent>
//                     </Card>
//                     <Grid md={12} mt={4}>
//                         <Box
//                             sx={{
//                                 display: 'grid',
//                                 gridTemplateColumns: isSmallScreen ? 'repeat(2, minmax(80px, 1fr))' : 'repeat(4, minmax(80px, 1fr))',
//                                 gap: 1,
//                             }}
//                         >
//                             <Typography variant="h5" component="h2" sx={{
//                                 marginBottom: '16px', fontSize: '1.4em',
//                                 fontWeight: 'bold', color: "#054DA7",textDecoration: 'underline',
//                             }}>
//                                 Electrolytes:
//                             </Typography>
//                             <Button size="lg" variant="soft" color="danger" >
//                                 Calcium
//                             </Button>
//                             <Button size="lg" variant="soft" color="info" >
//                                 Potassium
//                             </Button>
//                             <Button size="lg" variant="soft" color="success" >
//                                 Phosphorus
//                             </Button>
//                             <Button size="lg" variant="soft" color="warning" >
//                                 Sodium
//                             </Button>
//                             <Button size="lg" variant="soft" color="primary" >
//                                 Magnesium
//                             </Button>
//                             <Button size="lg" variant="soft" color="neutral" >
//                                 Chloride
//                             </Button>
//                         </Box>
//                     </Grid>
//                     <Box
//                         sx={{
//                             display: 'grid',
//                             gridTemplateColumns: isSmallScreen ? 'repeat(2, minmax(80px, 1fr))' : 'repeat(4, minmax(80px, 1fr))',
//                             gap: 1,
//                         }}
//                         mb={10}
//                         mt={4}
//                     >
//                         <Typography variant="h5" component="h2" sx={{ marginBottom: '16px',fontSize: '1.4em',
//                                     fontWeight: 'bold',color:"#054DA7",textDecoration: 'underline',}}>
//                         Trace Minerals:
//                         </Typography>
//                         <Button size="lg" variant="soft" color="primary" endDecorator={<OpenInNew />}>
//                             Iron
//                         </Button>
//                         <Button size="lg" variant="soft" color="neutral" endDecorator={<OpenInNew />}>
//                             Zinc
//                         </Button>
//                         <Button size="lg" variant="soft" color="danger" endDecorator={<OpenInNew />}>
//                             Iodine
//                         </Button>
//                         <Button size="lg" variant="soft" color="info" endDecorator={<OpenInNew />}>
//                             Chromium
//                         </Button>
//                         <Button size="lg" variant="soft" color="success" endDecorator={<OpenInNew />}>
//                             Manganese
//                         </Button>
//                         <Button size="lg" variant="soft" color="warning" endDecorator={<OpenInNew />}>
//                             Copper
//                         </Button>
//                         <Button size="lg" variant="soft" color="primary" endDecorator={<OpenInNew />}>
//                             Selenium
//                         </Button>
//                     </Box>
//                     <Grid md={12} mt={4}>
//                         <SunburstChart />
//                     </Grid>

//                 </Container>
//             </React.Fragment>
//         </div>
//     );
// }

import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";

import { useTheme } from "@mui/material/styles";
import {
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import CalciumIcon from "../../../../../assets/images/icons/minerals/calcium.png";
import PotassiumIcon from "../../../../../assets/images/icons/minerals/potassium.png";
import PhosphorusIcon from "../../../../../assets/images/icons/minerals/phosphorus.png";
import SodiumIcon from "../../../../../assets/images/icons/minerals/sodium.png";
import MagnesiumIcon from "../../../../../assets/images/icons/minerals/magnesium.png";
import ChlorideIcon from "../../../../../assets/images/icons/minerals/chloride.png";
import IronIcon from "../../../../../assets/images/icons/minerals/iron.png";
import ZincIcon from "../../../../../assets/images/icons/minerals/zinc.png";
import IodineIcon from "../../../../../assets/images/icons/minerals/iodine.png";
import ChromiumIcon from "../../../../../assets/FoodSources/Minerals/MineralsHome/10_Chromium.png";
import ManganeseIcon from "../../../../../assets/images/icons/minerals/manganese.png";
import CopperIcon from "../../../../../assets/images/icons/minerals/copper.png";
import SeleniumIcon from "../../../../../assets/images/icons/minerals/selinium.png";
import Minerals from "../../../../../assets/FoodSources/Minerals/MineralsHome/MineralsHome.jpg";
import Button from "@mui/joy/Button";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AspectRatio, AvatarGroup, CardOverflow, ListItemContent, ListItemDecorator, Tooltip } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";

import {
  ArrowCircleDown,
  ArrowDownward,
  ArrowDropDown,
  KeyboardArrowDown,
} from "@mui/icons-material";
import Calcium from "./Calcium";
import Potassium from "./Potassium";
import Phosphorus from "./Phosphorus";
import Sodium from "./Sodium";
import Magnesium from "./Magnesium";
import Chloride from "./Chloride";
import Iron from "./Iron";
import Zinc from "./Zinc";
import Iodine from "./Iodine";
import Chromium from "./Chromium";
import Manganese from "./Manganese";
import Copper from "./Copper";
import Selenium from "./Selenium";

export default function MineralsHome() {
  const theme = useTheme();
  const myRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const buttonRefat = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openfat, setOpenfat] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFat = () => {
    setOpenfat(false);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("dropdownSection");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <React.Fragment>
        <CssBaseline />
        <Container fixed m={4} fluid maxWidth="lg">
          <Typography
            level="h1"
            fontSize="2em"
            textColor="#000"
            fontWeight="bold"
            marginTop="0.5em"
          >
            Minerals
          </Typography>
          <Grid container direction="row">
            <Grid item lg={4} md={4} sm={12}>
              <Box>
                <img src={Minerals} alt="Minerals" width="60%" />
              </Box>
            </Grid>
            <Grid item lg={8} md={8} sm={12}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  marginTop: "20px",
                  maxHeight: { xs: "100%", md: "300px" },
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography level="h6" align="justify" gutterBottom>
                    Minerals are essential micronutrients that our body requires in small amounts for various physiological functions like fluid/electrolyte balance, co-factors to enzymes and involved in energy production. They are divided into macro minerals and micro/trace minerals based on the quantity required in the body. A balanced diet should provide all of the essential minerals required by the body.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid md={12} mt={4} id="dropdownSection">
            <Fab
              color="primary"
              aria-label="scroll b
              ack to top"
              onClick={handleClickScroll}
              style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
              <Typography level="h5" gutterBottom m={2}>
                Types of Minerals
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
            </div>
          </Grid>
          <Grid container alignItems="stretch">
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card sx={{
                  height: "100%", border: "0.5px solid #9494f1",
                  boxShadow: "none",
                }} >
                  <CardHeader title="Macro Minerals" sx={{ backgroundColor: "#9494f1" }} />
                  <CardContent>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      <ListItem alignItems="flex-start" sx={{ textAlign: "justify" }}>
                        <ListItemText primary="Macro Minerals are required in larger amounts in the body (>100mg/day)." />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card sx={{ height: "100%", border: "0.5px solid #9494f1", boxShadow: "none", }}>
                  <CardHeader
                    title="Micro/Trace Minerals"
                    sx={{ backgroundColor: "#9494f1" }}
                  />
                  <CardContent>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      {/* <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                        <ListItemText primary="They are required in relatively smaller quantities (<100 mg/day)." />
                      </ListItem> */}
                      <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                        <ListItemText primary="Trace elements are minerals present in living tissues at levels less than 0.05 percent in the human body. They are required in relatively smaller quantities (<100 mg/day)." />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={1}
              my={2}
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
            >
              <Grid item xs={12} md={6} lg={6}>
                <div>
                  <Button
                    ref={buttonRef}
                    id="basic-demo-button"
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="plain"
                    color="primary"
                    size="lg"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    Macro Minerals <ArrowDropDown />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={buttonRef.current}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    aria-labelledby="basic-demo-button"
                  >
                    <MenuItem
                      onClick={() =>
                        document.getElementById("calcium").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Calcium
                      <ListItemIcon>
                        <img
                          src={CalciumIcon}
                          alt="Calcium icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("potassium").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Potassium
                      <ListItemIcon>
                        <img
                          src={PotassiumIcon}
                          alt="Potassium icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("phosphorus").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {" "}
                      Phosphorus
                      <ListItemIcon>
                        <img
                          src={PhosphorusIcon}
                          alt="Phosphorus icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("sodium").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {" "}
                      Sodium
                      <ListItemIcon>
                        <img
                          src={SodiumIcon}
                          alt="Sodium icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("magnesium").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {" "}
                      Magnesium
                      <ListItemIcon>
                        <img
                          src={MagnesiumIcon}
                          alt="vitamin b3 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("chloride").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Chloride{" "}
                      <ListItemIcon>
                        <img
                          src={ChlorideIcon}
                          alt="vitamin b7 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div>
                  <Button
                    ref={buttonRefat}
                    id="basic-demo-button"
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={openfat ? "true" : undefined}
                    variant="plain"
                    color="info"
                    size="lg"
                    onClick={() => {
                      setOpenfat(!openfat);
                    }}
                  >
                    Trace Minerals <ArrowDropDown />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={buttonRefat.current}
                    open={openfat}
                    onClose={handleCloseFat}
                    onClick={handleCloseFat}
                    aria-labelledby="basic-demo-button"
                  >
                    <MenuItem
                      onClick={() =>
                        document.getElementById("iron").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Iron{" "}
                      <ListItemIcon>
                        <img
                          src={IronIcon}
                          alt="Iron icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("zinc").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Zinc{" "}
                      <ListItemIcon>
                        <img
                          src={ZincIcon}
                          alt="Zinc icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("iodine").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Iodine{" "}
                      <ListItemIcon>
                        <img
                          src={IodineIcon}
                          alt="Iodine icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("chromium").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Chromium{" "}
                      <ListItemIcon>
                        <img
                          src={ChromiumIcon}
                          alt="Chromium icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("manganese").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Manganese{" "}
                      <ListItemIcon>
                        <img
                          src={ManganeseIcon}
                          alt="Manganese icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("copper").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Copper{" "}
                      <ListItemIcon>
                        <img
                          src={CopperIcon}
                          alt="Copper icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("selenium").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Selenium{" "}
                      <ListItemIcon>
                        <img
                          src={SeleniumIcon}
                          alt="Selenium icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Grid container fluid spacing={1} direction="row" mb={8} alignContent="stretch">
            <Grid item id="iron" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={IronIcon} alt="Iron icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Iron :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Iron is crucial for carrying out various function in the body. The total iron content of the normal adult estimated to be about 2.5 to 4 g. Most of the body iron exists in complex forms bound to protein as ferrin and transferrin.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Iron />
            <Grid item id="calcium" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={CalciumIcon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Calcium :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Calcium is a mineral needed for strong bones and teeth, healthy muscle and nerve function.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Calcium />
            <Grid item id="magnesium" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={MagnesiumIcon} alt="Magnesium icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Magnesium :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Magnesium plays a role in maintaining bone health and acts as a catalyst in various physiological reactions.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Magnesium />
            <Grid item id="zinc" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={ZincIcon} alt="Zinc icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Zinc :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Zinc is a trace mineral, which is required only in small quantity in the body. It is present in all cells, tissues, organs, fluids and secretions, although about 90 percent of the body's zinc is in muscle and bone. Adults body contains total zinc between 1.5 to 2.5 g.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Zinc />
            <Grid item id="iodine" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={IodineIcon} alt="Iodine icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Iodine :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Iodine is one of the essential micronutrient required for normal growth and development of the human brain and body. The body contains a total of 15 to 23 mg of iodine. Over 75% of this is present in thyroid gland. Adequate intake of iodine is important for everyone, especially infants and women who are pregnant.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Iodine />
            <Grid item id="potassium" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={PotassiumIcon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Potassium :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Potassium is one of the major electrolyte of the body. It carries a small electrical charge that activates cell and nerve functions.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Potassium />
            <Grid item id="phosphorus" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={PhosphorusIcon} alt="Phosphorus icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Phosphorus :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Phosphorus is second most abundant mineral in our body and mostly found combined with calcium in bones and teeth.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Phosphorus />
            <Grid item id="sodium" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={SodiumIcon} alt="Sodium icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Sodium :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Sodium is one of the important electrolytes present as the principal cation(+) in the extracellular fluid of the body.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Sodium />
            <Grid item id="chloride" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={ChlorideIcon} alt="Chloride icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Chloride :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Chloride is an significant electrolyte, involved in maintain electrolyte balance and majorly found in common salt (Sodium chloride).
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Chloride />
            <Grid item id="chromium" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={ChromiumIcon} alt="Chromium icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Chromium :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Chromium content of adult body is estimated to be 6mg. It is present in all organic matters and appear to be an essential nutrient.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Chromium />
            <Grid item id="manganese" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={ManganeseIcon} alt="Manganese icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Manganese :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Manganese is a trace mineral that is present in tiny amounts in the body. It is found mostly in bones, the liver, kidneys, and pancreas. Manganese helps the body form connective tissue, bones, blood clotting factors, and sex hormones.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Manganese />
            <Grid item id="copper" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={CopperIcon} alt="Copper icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Copper :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                The adult human body contains about 100-150 mg of copper, 40 % of which is found in muscles. The liver with 15% body copper is the major copper storage site.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Copper />
            <Grid item id="selenium" lg={12}>
              <Typography level="h6" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={SeleniumIcon} alt="Selenium icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Selenium :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                In the adult human body  total selenium ranges from 13 to 30 mg, with approximately 28-46% of this stored in skeletal muscle.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Selenium />
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}
