import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import OysterDry from "../../../../../../assets/FoodSources/Protein/Mushrooms/1_OysterDry.jpg"
import ButtonMushroom from "../../../../../../assets/FoodSources/Protein/Mushrooms/2_ButtonMushroom.jpg"
import ShiitakeMushroom from "../../../../../../assets/FoodSources/Protein/Mushrooms/3_ShiitakeMushroom.jpg"
import ChickenMushroom from "../../../../../../assets/FoodSources/Protein/Mushrooms/4_chickenMushroom.jpg"
import { Typography } from '@mui/material';

export default function Mushrooms() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: OysterDry,
    title: 'Oyster mushroom, dried',
    author: '19.04 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: ButtonMushroom,
    title: 'Button mushroom, fresh',
    author: '3.68 per 100gm',
  },
  {
    img: ShiitakeMushroom,
    title: 'Shiitake mushroom, fresh',
    author: '3.19 per 100gm',
  },
  {
    img: ChickenMushroom,
    title: 'Chicken mushroom, fresh',
    author: '1.84 per 100gm',
    cols: 2,
  }
];