import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import indicatorslist from "../../../../data/newmenuforcompare.json";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ListItemIcon } from "@mui/material";
import Checkbox from "@mui/material/Checkbox"; // Add this import
import ListItemText from "@mui/material/ListItemText";
import { setSelectedOption } from "../../../home/HomeRadialComponents/selectedOptionSlice";
import { getMapKeyValue, setMapKey } from "../../charts/MapKeySlice";
import { useDispatch, useSelector } from "react-redux";
import { purple } from "@mui/material/colors";
import image1 from "../../../../assets/images/menu/infant.png";
import image2 from "../../../../assets/images/menu/schoolchildren.png";
import image3 from "../../../../assets/images/menu/adolscentboy.png";
import image4 from "../../../../assets/images/menu/adolescentgirl.png";
import image5 from "../../../../assets/images/menu/adults.png";
import image6 from "../../../../assets/images/menu/adultwomen.png";
import image7 from "../../../../assets/images/menu/pregnantwomen.png";
import image8 from "../../../../assets/images/menu/lactatingwomen.png";
import image9 from "../../../../assets/images/menu/adultmen.png";
import image10 from "../../../../assets/images/menu/elderlycouple.png";
import image11 from "../../../../assets/images/menu/adolscents.png";
import SelectedDropdownData from "./SelectedDropdownData";
import CompareData from "./ComapreData";
//import Infocardsource from "../../charts/infocardsource";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'; 
import {
 
 
  
 
  IconButton,
  
} from '@mui/material';

import '../Datatablestyle.css';
//indicator icons
//import indicatorIcon from "../../../../assets/images/indicatoricons/stunting.jpg";
//import indicatorIcon from `../../../../assets/images/indicatoricons/${selectedIndicatorIcon}`;




function Dropdown() {
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [lifecycle, setLifecycle] = useState(null);
  const [indicator, setIndicator] = useState([]);
  const [subIndicator, setSubIndicator] = useState([]);
  const [indicatorID, setIndicatorID] = useState("");
  const [subIndicatorID, setSubIndicatorID] = useState("");
  const [source, setSource] = useState([]);
  const [sourceID, setSourceID] = useState("");
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedSourceData, setSelectedSourceData] = useState([]);
  const selectedOption = useSelector((state) => state.selectedOptionFromHome);
  const dispatch = useDispatch();
  const [submittedData, setSubmittedData] = useState(null);
  const [secondSource, setSecondSource] = useState([]);
  const [selectedSecondSource, setSelectedSecondSource] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]); 
  const [firstSourceSelected, setFirstSourceSelected] = useState(false);
  const [allSources, setAllSources] = useState([]);
 
  const [isCompareDataAvailable, setIsCompareDataAvailable] = useState(false);
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const [showSecondSourceDropdown, setShowSecondSourceDropdown] = useState(false);
  const [previousSelectedSecondSource, setPreviousSelectedSecondSource] = useState([]);
  const [selectedIndicatorDescription, setSelectedIndicatorDescription] = useState("");
  const [selectedIndicatorName, setSelectedIndicatorName] = useState("");
  const [selectedIndicatorIcon, setSelectedIndicatorIcon] = useState("");
  const [indicatorIcon, setIndicatorIcon] = useState(null);
 // console.log("selectedIndicatorIcon:", selectedIndicatorIcon);

  useEffect(() => {
    // Dynamically import the image based on the selectedIndicatorIcon
    import(`../../../../assets/images/indicatoricons/${selectedIndicatorIcon}`)
      .then((module) => {
        // Use the imported image
        setIndicatorIcon(module.default);
      })
      .catch((error) => {
        //console.error('Error loading indicator icon:', error);
      });
  }, [selectedIndicatorIcon]);


  const areAllFieldsSelected = () => {
    return lifecycle && indicatorID && subIndicatorID && selectedSource;
  };
 
  const handleLifecycle = (e) => {
    const getLifecycle = indicatorslist.find(
      (indicator) => indicator.lifecycle_id === e.target.value
    );
    setLifecycle(e.target.value);
    setIndicator(getLifecycle.indicators);
    setIndicatorID("");
    setSubIndicator([]);
    setSubIndicatorID("");
    setSource([]);
    setSourceID("");
    setSelectedSource(null);
  };

  useEffect(() => {
    if (selectedOption) {
      const getLifecycle = indicatorslist.find(
        (indicator) => indicator.lifecycle_id === selectedOption
      );
      setLifecycle(selectedOption);
      setIndicator(getLifecycle.indicators);
      setIndicatorID("");
      setSubIndicator([]);
      setSubIndicatorID("");
      setSource([]);
      setSourceID("");
      setSelectedSource(null);
    }
  }, [selectedOption]);
 
  useEffect(() => {
    // Check if all necessary data is available
    const isDataAvailable = lifecycle && indicatorID && subIndicatorID && selectedSource && selectedSecondSource;
    setIsCompareDataAvailable(isDataAvailable);
  }, [lifecycle, indicatorID, subIndicatorID, selectedSource,selectedSecondSource],);
  useEffect(() => {
    setSelectedSecondSource([]);
  }, [lifecycle]);
  useEffect(() => {
    // Store the previous selection before resetting
    setPreviousSelectedSecondSource(selectedSecondSource);
    setSelectedSecondSource([]);
  }, [source]);

  const handleIndicator = (e) => {
    const selectedIndicator = indicator.find(
      (indicator) => indicator.indicator_id === e.target.value
    );
    setIndicatorID(e.target.value);
    setIsResetDisabled(false);
    if (selectedIndicator) {
      setSubIndicator(selectedIndicator.indicator_subgroup || []);
      setSubIndicatorID("");
      setSource(selectedIndicator.sources || []);
      setSourceID("");
      setSelectedSource(null);
         // Set the selected indicator description here
         setSelectedIndicatorDescription(selectedIndicator.indicator_description || "");
         
      // Set the selected indicator name
      setSelectedIndicatorName(selectedIndicator.indicator_name || "");
      setSelectedIndicatorIcon(selectedIndicator.indicator_icon || "");
    } else {
      setSubIndicator([]);
      setSubIndicatorID("");
      setSource([]);
      setSourceID("");
      setSelectedSource(null);
       // Clear the selected indicator description
       setSelectedIndicatorDescription("");
       setSelectedIndicatorName("");
       setSelectedIndicatorIcon("");
    }
  };

  const handleSubIndicator = (e) => {
    const selectedSubIndicator = subIndicator.find(
      (subIndicator) => subIndicator.subgroup_id === e.target.value
    );
    setSubIndicatorID(e.target.value);
    if (selectedSubIndicator.sources) {
      setSource(selectedSubIndicator.sources || []);
      setSourceID("");
      setSelectedSource(null);
    } else {
      setSubIndicator([]);
      setSubIndicatorID("");
      setSource([]);
      setSourceID("");
      setSelectedSource(null);
    }
  };

 



  const handleSource = (e) => {
    const sourceId = e.target.value;
    const selectedSource = source.find((s) => s.source_id === sourceId);
  
    if (selectedSource) {
      setSelectedSource(selectedSource);
      setSelectedSourceData([lifecycle, indicatorID, subIndicatorID, selectedSource.source_code]);
  
     
      const remainingSources = source.filter((s) => s.source_id !== sourceId);
  
  
      const filteredRemainingSources = remainingSources.filter((s) => {
       
        if (selectedSource.source_code === 'D4'|| selectedSource.source_code === 'D3'|| selectedSource.source_code === 'D2' || selectedSource.source_code === 'D1') {
          return s.source_code !== 'AHSCAB' && s.source_code !== 'AHS10_11' && s.source_code !== 'AHS11_12'&& s.source_code !== 'AHS12_13';
        } else if (selectedSource.source_code === 'AHSCAB'|| selectedSource.source_code === 'AHS10_11'|| selectedSource.source_code === 'AHS11_12'|| selectedSource.source_code === 'AHS12_13') {
          return s.source_code !== 'D4' && s.source_code !== 'D3' && s.source_code !== 'D2' && s.source_code !== 'D1';
        }
    
        return true;
      });
  
      setAllSources(filteredRemainingSources);
            
      
      setSelectedSecondSource([]);
    } else {
      setSelectedSource(null);
      setSelectedSourceData([null]);
      setAllSources([null]);
  
      setSelectedSecondSource([]);
    }
  };
  
  const handleSecondSourceChange = (e) => {
    const selectedSourceIds = e.target.value;
  

    const selectedSourcesFromSecondSource = allSources.filter((item) =>
      selectedSourceIds.includes(item.source_id)
    );
  

    const isDSourceSelected = selectedSourcesFromSecondSource.some((selectedSource) =>
      ['D4', 'D3', 'D2', 'D1'].includes(selectedSource.source_code)
    );
  
  
    const isAHSSelected = selectedSourcesFromSecondSource.some((selectedSource) =>
      ['AHSCAB', 'AHS10_11', 'AHS11_12', 'AHS12_13'].includes(selectedSource.source_code)
    );
  
    // Update the selected second sources state
    setSelectedSecondSource(selectedSourcesFromSecondSource);
  
  
    const updatedAllSources = allSources.map((source) => {
      
      const shouldDisable = (isDSourceSelected && ['AHSCAB', 'AHS10_11', 'AHS11_12', 'AHS12_13'].includes(source.source_code)) ||
        (isAHSSelected && ['D4', 'D3', 'D2', 'D1'].includes(source.source_code));
  
      return { ...source, disabled: shouldDisable };
    });
  
  
    setAllSources(updatedAllSources);
  
 
    setSelectedSources([selectedSource, ...selectedSourcesFromSecondSource]);
  };
  
  useEffect(() => {
    // Log the selected sources to check if the data is being updated correctly
    console.log("Selected Sources:", selectedSources);
  }, [selectedSources]);

  
  

  const handleCompareClick = () => {
    // Toggle the compare state
    setIsCompareOpen(!isCompareOpen);
  };



  useEffect(() => {
    if (lifecycle) {
      const getLifecycle = indicatorslist.find((indicator) => indicator.lifecycle_id === lifecycle);
      setIndicator(getLifecycle.indicators);
    }
  }, [lifecycle]);
 
  

  // Construct the dynamic import path
  //const selectedIndicatorIconPath = `${indicatorIcons}${selectedIndicatorIcon}`;

  return (
    <>
      <Grid container spacing={0} display="flex" justifyContent="center" alignItems="center">
      <Grid item xs={12} >
      <Box sx={{ m: 1 }}>
          <FormControl fullWidth  className="custom-form-control" sx={{ m:1, minWidth: 200 }}>
              <InputLabel id="simple-select-autowidth-label">Life Cycle</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                label="Select Life Cycle"
                value={lifecycle || selectedOption || ""}
                onChange={handleLifecycle}
                style={{borderRadius: '50px'}}
              >
                {indicatorslist.map((indicatorlist) => {
                  let icon;
                  let rotationAngle = 'none'; 
                  switch (indicatorlist.lifecycle_id) {
                    case 1:
                      icon = image1;
                      rotationAngle = 'rotate(-10deg)';
                      break;
                    case 2:
                      icon = image2;
                      rotationAngle = 'rotate(-5deg)';
                      break;
                    case 3:
                      icon = image3;
                      break;
                    case 4:
                      icon = image4;
                      break;
                    case 5:
                      icon = image9;
                      break;
                    case 6:
                      icon = image6;
                      rotationAngle = 'rotate(-5deg)';
                      break;
                    case 7:
                      icon = image7;
                      break;
                    case 8:
                      icon = image8;
                      break;
                    case 9:
                      icon = image10;
                      rotationAngle = 'rotate(-5deg)';
                      break;
                    case 10:
                      icon = image11;
                      break;
                    case 11:
                      icon = image5;
                      rotationAngle = 'rotate(-10deg)';
                      break;

                    default:
                      icon = null;
                  }
                  return (
                    <MenuItem key={indicatorlist.lifecycle_id} value={indicatorlist.lifecycle_id}>
                     
                      {indicatorlist.lifecycle_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            </Box>
        </Grid>
          
 



        <Grid item xs={12} >
        <Box sx={{ m: 1 }}>
            <FormControl fullWidth disabled={!lifecycle} sx={{ m: 1, minWidth: 100 }}>
              <InputLabel id="simple-select-autowidth-label">Indicator</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                label="Select Indicator"
                value={indicatorID}
                onChange={handleIndicator}
                style={{borderRadius: '50px'}}
              >
                {indicator.map((ind) => (
                  <MenuItem key={ind.indicator_id} value={ind.indicator_id}>
                
                     {ind.indicator_name}
                    
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
          
        </Grid>
        <Grid item xs={12} >
          <Box sx={{ m: 1 }}>
            <FormControl fullWidth disabled={!subIndicator.length} sx={{ m: 1, minWidth: 100 }}>
              <InputLabel id="simple-select-autowidth-label">Sub Indicator</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                label="Select Sub Indicator"
                value={subIndicatorID}
                onChange={handleSubIndicator}
                style={{borderRadius: '50px'}}
              >
                {subIndicator.map((subind) => (
                  <MenuItem key={subind.subgroup_id} value={subind.subgroup_id}>
                   
                  {subind.subgroup_name}
                   
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
   
        <Grid item xs={12} >
        <Box sx={{ m: 1 }}>
            <FormControl fullWidth disabled={!source.length} sx={{ m: 1, minWidth: 100 }}>
              <InputLabel id="simple-select-autowidth-label">Source</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                value={selectedSource ? selectedSource.source_id : ""}
                label="Select Source"
                onChange={handleSource}
                style={{borderRadius: '50px'}}
              >
                {source.map((s) => (
                  <MenuItem key={s.source_id} value={s.source_id}>
                    {s.source_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
        </Grid>
       
  {/* Conditionally render the second source dropdown */}
  {allSources.length >= 1 && (
          <Grid item xs={12}>
          <Box sx={{ m: 1 }}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 100 }}>
  <InputLabel id="second-source-label">Compare Source</InputLabel>
  <Select
    labelId="second-source-label"
    multiple
    value={selectedSecondSource.map((s) => s.source_id)}
    label="Select Second Source"
    onChange={handleSecondSourceChange}
    style={{ borderRadius: '50px' }}
    renderValue={(selected) =>
      selected
        .map((source_id) => {
          const selectedSource = allSources.find((s) => s.source_id === source_id);
          return selectedSource ? selectedSource.source_name : '';
        })
        .join(', ')
    }
  >
    {allSources.map((s) => (
      <MenuItem key={s.source_id} value={s.source_id} disabled={s.disabled}>
        <Checkbox
          checked={selectedSecondSource.some((selectedSource) => selectedSource.source_id === s.source_id)}
          color="primary"
        />
        <ListItemText primary={s.source_name} />
      </MenuItem>
    ))}
  </Select>
</FormControl>
              </Box>
          </Grid>
        )}



  <Grid item xs={52}>
    <SelectedDropdownData {...selectedSourceData} />
    <SelectedDropdownData
      {...selectedSourceData}
      allSources={allSources}
    />
    {isCompareDataAvailable && (
      <CompareData
        {...selectedSourceData}
        allSources={allSources}
        secondSource={selectedSecondSource}
      />
    )}
    {areAllFieldsSelected() && selectedSecondSource.length >= 0 && (
      <div>
        
  <div >
   
  </div>
        {/* Render the selected indicator icon, name, and description when all dropdowns are selected */}
        {/* Add the condition to display the message based on source_area_level */}
        {selectedSources.some(source => source.source_arealevel === 2) ? (
          <p>This selected sources comparison is at <strong style={{ background: 'lightyellow' }}>state</strong> level.</p>
        ) : (
          <p>This selected sources comparison is at both <strong style={{ background: 'lightyellow' }}>state</strong> and <strong style={{ background: 'lightyellow' }}>district</strong> levels.</p>
        )}
      </div>
    )}
  </Grid>
  


      </Grid>

      
      
    </>
  );
}

export default Dropdown; 