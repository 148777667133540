import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsSunburst from "highcharts/modules/sunburst";
import MenIcon from "../../../../../assets/testImages/icon8-male.png";
import WomenIcon from "../../../../../assets/testImages/girld.png";
import InfantIcon from "../../../../../assets/testImages/icon1-infant_text.png";
import ChildrenIcon from "../../../../../assets/testImages/icon2-school-age.png";
import BoyIcon from "../../../../../assets/testImages/icon4-boy.png";
import GirlIcon from "../../../../../assets/testImages/icon5-adolescent-girl_text.png";
import ElderlyIcon from "../../../../../assets/testImages/icon11-elder-men.png";
import { data1 } from "./RDAtableData";
import "./RdaTable.css";
import HCExport from "highcharts/modules/exporting";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import { Button } from "@mui/material";

HCExport(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

// var data = [
//     {
//         id: "0.0",
//         parent: "",
//         name: "RDA",
//     },

//     // Infants
//     {
//         id: "1.1",
//         parent: "0.0",
//         name: "Protein",
//         value: 1,
//     },

//     {
//         id: "1.111",
//         parent: "1.1",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.1111",
//         parent: "1.111",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 1,
//     },

//     {
//         id: "1.1112",
//         parent: "1.111",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.121",
//         parent: "1.1",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.1211",
//         parent: "1.121",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1212",
//         parent: "1.121",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1213",
//         parent: "1.121",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 1,
//     },

//     // Boys

//     {
//         id: "1.131",
//         parent: "1.1",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.1311",
//         parent: "1.131",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1312",
//         parent: "1.131",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1313",
//         parent: "1.131",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 1,
//     },

//     // Girls

//     {
//         id: "1.141",
//         parent: "1.1",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.1411",
//         parent: "1.141",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1412",
//         parent: "1.141",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1413",
//         parent: "1.141",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 1,
//     },

//     //Men
//     {
//         id: "1.151",
//         parent: "1.1",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.1511",
//         parent: "1.151",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1512",
//         parent: "1.151",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1513",
//         parent: "1.151",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 1,
//     },

//     //Women

//     {
//         id: "1.161",
//         parent: "1.1",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.1611",
//         parent: "1.161",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1612",
//         parent: "1.161",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1613",
//         parent: "1.161",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1614",
//         parent: "1.161",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 1,
//     },

//     {
//         id: "1.1615",
//         parent: "1.161",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1616",
//         parent: "1.161",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 1,
//     },
//     // Elderly

//     {
//         id: "1.171",
//         parent: "1.1",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.1711",
//         parent: "1.171",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.1712",
//         parent: "1.171",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 1,
//     },

//     {
//         id: "1.2",
//         parent: "0.0",
//         name: "Dietary Fiber",
//         value: 1,
//     },
//     {
//         id: "1.211",
//         parent: "1.2",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.2111",
//         parent: "1.211",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.2112",
//         parent: "1.211",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.221",
//         parent: "1.2",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.2211",
//         parent: "1.221",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.2212",
//         parent: "1.221",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.2213",
//         parent: "1.221",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.231",
//         parent: "1.2",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.2311",
//         parent: "1.231",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.2312",
//         parent: "1.231",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.2313",
//         parent: "1.231",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.241",
//         parent: "1.2",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.2411",
//         parent: "1.241",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.2412",
//         parent: "1.241",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.2413",
//         parent: "1.241",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.251",
//         parent: "1.2",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.2511",
//         parent: "1.251",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.2512",
//         parent: "1.251",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.2513",
//         parent: "1.251",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.261",
//         parent: "1.2",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.2611",
//         parent: "1.261",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.2612",
//         parent: "1.261",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.2613",
//         parent: "1.261",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.2614",
//         parent: "1.261",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.2615",
//         parent: "1.261",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.2616",
//         parent: "1.261",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.271",
//         parent: "1.2",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.2711",
//         parent: "1.271",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.2712",
//         parent: "1.271",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.3",
//         parent: "0.0",
//         name: "Calcium",
//         value: 1,
//     },
//     {
//         id: "1.311",
//         parent: "1.3",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.3111",
//         parent: "1.311",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.3112",
//         parent: "1.311",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.321",
//         parent: "1.3",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.3211",
//         parent: "1.321",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.3212",
//         parent: "1.321",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.3213",
//         parent: "1.321",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.331",
//         parent: "1.3",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.3311",
//         parent: "1.331",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.3312",
//         parent: "1.331",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.3313",
//         parent: "1.331",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.341",
//         parent: "1.3",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.3411",
//         parent: "1.341",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.3412",
//         parent: "1.341",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.3413",
//         parent: "1.341",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.351",
//         parent: "1.3",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.3511",
//         parent: "1.351",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.3512",
//         parent: "1.351",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.3513",
//         parent: "1.351",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.361",
//         parent: "1.3",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.3611",
//         parent: "1.361",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.3612",
//         parent: "1.361",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.3613",
//         parent: "1.361",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.3614",
//         parent: "1.361",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.3615",
//         parent: "1.361",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.3616",
//         parent: "1.361",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.371",
//         parent: "1.3",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.3711",
//         parent: "1.371",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.3712",
//         parent: "1.371",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.4",
//         parent: "0.0",
//         name: "Magnesium",
//         value: 1,
//     },
//     {
//         id: "1.411",
//         parent: "1.4",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.4111",
//         parent: "1.411",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.4112",
//         parent: "1.411",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.421",
//         parent: "1.4",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.4211",
//         parent: "1.421",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.4212",
//         parent: "1.421",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.4213",
//         parent: "1.421",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.431",
//         parent: "1.4",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.4311",
//         parent: "1.431",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.4312",
//         parent: "1.431",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.4313",
//         parent: "1.431",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.441",
//         parent: "1.4",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.4411",
//         parent: "1.441",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.4412",
//         parent: "1.441",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.4413",
//         parent: "1.441",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.451",
//         parent: "1.4",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.4511",
//         parent: "1.451",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.4512",
//         parent: "1.451",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.4513",
//         parent: "1.451",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.461",
//         parent: "1.4",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.4611",
//         parent: "1.461",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.4612",
//         parent: "1.461",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.4613",
//         parent: "1.461",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.4614",
//         parent: "1.461",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.4615",
//         parent: "1.461",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.4616",
//         parent: "1.461",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.471",
//         parent: "1.4",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.4711",
//         parent: "1.471",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.4712",
//         parent: "1.471",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.5",
//         parent: "0.0",
//         name: "Iron",
//         value: 1,
//     },
//     {
//         id: "1.511",
//         parent: "1.5",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.5111",
//         parent: "1.511",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.5112",
//         parent: "1.511",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.521",
//         parent: "1.5",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.5211",
//         parent: "1.521",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.5212",
//         parent: "1.521",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.5213",
//         parent: "1.521",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.531",
//         parent: "1.5",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.5311",
//         parent: "1.531",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.5312",
//         parent: "1.531",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.5313",
//         parent: "1.531",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.541",
//         parent: "1.5",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.5411",
//         parent: "1.541",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.5412",
//         parent: "1.541",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.5413",
//         parent: "1.541",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.551",
//         parent: "1.5",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.5511",
//         parent: "1.551",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.5512",
//         parent: "1.551",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.5513",
//         parent: "1.551",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.561",
//         parent: "1.5",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.5611",
//         parent: "1.561",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.5612",
//         parent: "1.561",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.5613",
//         parent: "1.561",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.5614",
//         parent: "1.561",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.5615",
//         parent: "1.561",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.5616",
//         parent: "1.561",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.571",
//         parent: "1.5",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.5711",
//         parent: "1.571",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.5712",
//         parent: "1.571",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.6",
//         parent: "0.0",
//         name: "Zinc",
//         value: 1,
//     },
//     {
//         id: "1.611",
//         parent: "1.6",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.6111",
//         parent: "1.611",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.6112",
//         parent: "1.611",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.621",
//         parent: "1.6",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.6211",
//         parent: "1.621",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.6212",
//         parent: "1.621",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.6213",
//         parent: "1.621",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.631",
//         parent: "1.6",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.6311",
//         parent: "1.631",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.6312",
//         parent: "1.631",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.6313",
//         parent: "1.631",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.641",
//         parent: "1.6",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.6411",
//         parent: "1.641",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.6412",
//         parent: "1.641",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.6413",
//         parent: "1.641",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.651",
//         parent: "1.6",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.6511",
//         parent: "1.651",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.6512",
//         parent: "1.651",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.6513",
//         parent: "1.651",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.661",
//         parent: "1.6",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.6611",
//         parent: "1.661",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.6612",
//         parent: "1.661",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.6613",
//         parent: "1.661",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.6614",
//         parent: "1.661",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.6615",
//         parent: "1.661",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.6616",
//         parent: "1.661",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.671",
//         parent: "1.6",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.6711",
//         parent: "1.671",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.6712",
//         parent: "1.671",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.7",
//         parent: "0.0",
//         name: "Iodine",
//         value: 1,
//     },
//     {
//         id: "1.711",
//         parent: "1.7",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.7111",
//         parent: "1.711",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.7112",
//         parent: "1.711",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.721",
//         parent: "1.7",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.7211",
//         parent: "1.721",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.7212",
//         parent: "1.721",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.7213",
//         parent: "1.721",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.731",
//         parent: "1.7",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.7311",
//         parent: "1.731",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.7312",
//         parent: "1.731",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.7313",
//         parent: "1.731",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.741",
//         parent: "1.7",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.7411",
//         parent: "1.741",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.7412",
//         parent: "1.741",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.7413",
//         parent: "1.741",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.751",
//         parent: "1.7",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.7511",
//         parent: "1.751",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.7512",
//         parent: "1.751",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.7513",
//         parent: "1.751",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.761",
//         parent: "1.7",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.7611",
//         parent: "1.761",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.7612",
//         parent: "1.761",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.7613",
//         parent: "1.761",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.7614",
//         parent: "1.761",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.7615",
//         parent: "1.761",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.7616",
//         parent: "1.761",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.771",
//         parent: "1.7",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.7711",
//         parent: "1.771",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.7712",
//         parent: "1.771",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.8",
//         parent: "0.0",
//         name: "Thiamine",
//         value: 1,
//     },
//     {
//         id: "1.811",
//         parent: "1.8",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.8111",
//         parent: "1.811",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.8112",
//         parent: "1.811",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.821",
//         parent: "1.8",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.8211",
//         parent: "1.821",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.8212",
//         parent: "1.821",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.8213",
//         parent: "1.821",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.831",
//         parent: "1.8",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.8311",
//         parent: "1.831",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.8312",
//         parent: "1.831",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.8313",
//         parent: "1.831",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.841",
//         parent: "1.8",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.8411",
//         parent: "1.841",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.8412",
//         parent: "1.841",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.8413",
//         parent: "1.841",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.851",
//         parent: "1.8",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.8511",
//         parent: "1.851",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.8512",
//         parent: "1.851",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.8513",
//         parent: "1.851",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.861",
//         parent: "1.8",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.8611",
//         parent: "1.861",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.8612",
//         parent: "1.861",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.8613",
//         parent: "1.861",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.8614",
//         parent: "1.861",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.8615",
//         parent: "1.861",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.8616",
//         parent: "1.861",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.871",
//         parent: "1.8",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.8711",
//         parent: "1.871",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.8712",
//         parent: "1.871",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.9",
//         parent: "0.0",
//         name: "Riboflavin",
//         value: 1,
//     },
//     {
//         id: "1.911",
//         parent: "1.9",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.9111",
//         parent: "1.911",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.9112",
//         parent: "1.911",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.921",
//         parent: "1.9",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.9211",
//         parent: "1.921",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.9212",
//         parent: "1.921",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.9213",
//         parent: "1.921",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.931",
//         parent: "1.9",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.9311",
//         parent: "1.931",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.9312",
//         parent: "1.931",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.9313",
//         parent: "1.931",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.941",
//         parent: "1.9",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.9411",
//         parent: "1.941",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.9412",
//         parent: "1.941",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.9413",
//         parent: "1.941",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.951",
//         parent: "1.9",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.9511",
//         parent: "1.951",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.9512",
//         parent: "1.951",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.9513",
//         parent: "1.951",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.961",
//         parent: "1.9",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.9611",
//         parent: "1.961",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.9612",
//         parent: "1.961",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.9613",
//         parent: "1.961",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.9614",
//         parent: "1.961",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 69(3rd trimester)",
//         value: 69,
//     },

//     {
//         id: "1.9615",
//         parent: "1.961",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.9616",
//         parent: "1.961",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.971",
//         parent: "1.9",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.9711",
//         parent: "1.971",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.9712",
//         parent: "1.971",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.10",
//         parent: "0.0",
//         name: "Niacin",
//         value: 1,
//     },
//     {
//         id: "1.1011",
//         parent: "1.10",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.10111",
//         parent: "1.1011",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.10112",
//         parent: "1.1011",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.1021",
//         parent: "1.10",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.10211",
//         parent: "1.1021",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.10212",
//         parent: "1.1021",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.10213",
//         parent: "1.1021",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.1031",
//         parent: "1.10",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.10311",
//         parent: "1.1031",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.10312",
//         parent: "1.1031",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.10313",
//         parent: "1.1031",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.1041",
//         parent: "1.10",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.10411",
//         parent: "1.1041",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.10412",
//         parent: "1.1041",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.10413",
//         parent: "1.1041",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.1051",
//         parent: "1.10",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.10511",
//         parent: "1.1051",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.10512",
//         parent: "1.1051",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.10513",
//         parent: "1.1051",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.1061",
//         parent: "1.10",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.10611",
//         parent: "1.1061",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.10612",
//         parent: "1.1061",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.10613",
//         parent: "1.1061",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.10614",
//         parent: "1.1061",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.10615",
//         parent: "1.1061",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.10616",
//         parent: "1.1061",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.1071",
//         parent: "1.10",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.10711",
//         parent: "1.1071",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.10712",
//         parent: "1.1071",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.11",
//         parent: "0.0",
//         name: "Vit-B6",
//         value: 1,
//     },
//     {
//         id: "1.1111",
//         parent: "1.11",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.11111",
//         parent: "1.1111",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.11112",
//         parent: "1.1111",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.1121",
//         parent: "1.11",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.11211",
//         parent: "1.1121",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.11212",
//         parent: "1.1121",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.11213",
//         parent: "1.1121",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.1131",
//         parent: "1.11",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.11311",
//         parent: "1.1131",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.11312",
//         parent: "1.1131",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.11313",
//         parent: "1.1131",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.1141",
//         parent: "1.11",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.11411",
//         parent: "1.1141",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.11412",
//         parent: "1.1141",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.11413",
//         parent: "1.1141",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.1151",
//         parent: "1.11",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.11511",
//         parent: "1.1151",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.11512",
//         parent: "1.1151",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.11513",
//         parent: "1.1151",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.1161",
//         parent: "1.11",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.11611",
//         parent: "1.1161",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.11612",
//         parent: "1.1161",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.11613",
//         parent: "1.1161",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.11614",
//         parent: "1.1161",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.11615",
//         parent: "1.1161",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.11616",
//         parent: "1.1161",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.1171",
//         parent: "1.11",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.11711",
//         parent: "1.1171",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.11712",
//         parent: "1.1171",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.12",
//         parent: "0.0",
//         name: "Folate",
//         value: 1,
//     },
//     {
//         id: "1.1211",
//         parent: "1.12",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.12111",
//         parent: "1.1211",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.12112",
//         parent: "1.1211",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.1221",
//         parent: "1.12",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.12211",
//         parent: "1.1221",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.12212",
//         parent: "1.1221",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.12213",
//         parent: "1.1221",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.1231",
//         parent: "1.12",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.12311",
//         parent: "1.1231",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.12312",
//         parent: "1.1231",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.12313",
//         parent: "1.1231",
//         name: "16-112 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.1241",
//         parent: "1.12",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.12411",
//         parent: "1.1241",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.12412",
//         parent: "1.1241",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.12413",
//         parent: "1.1241",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.1251",
//         parent: "1.12",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.12511",
//         parent: "1.1251",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.12512",
//         parent: "1.1251",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.12513",
//         parent: "1.1251",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.1261",
//         parent: "1.12",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.12611",
//         parent: "1.1261",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.12612",
//         parent: "1.1261",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.12613",
//         parent: "1.1261",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.12614",
//         parent: "1.1261",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.12615",
//         parent: "1.1261",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.12616",
//         parent: "1.1261",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.1271",
//         parent: "1.12",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.12711",
//         parent: "1.1271",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.12712",
//         parent: "1.1271",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.13",
//         parent: "0.0",
//         name: "Vit-B12",
//         value: 1,
//     },
//     {
//         id: "1.1311",
//         parent: "1.13",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.13111",
//         parent: "1.1311",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.13112",
//         parent: "1.1311",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.1321",
//         parent: "1.13",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.13211",
//         parent: "1.1321",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.13212",
//         parent: "1.1321",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.13213",
//         parent: "1.1321",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.1331",
//         parent: "1.13",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.13311",
//         parent: "1.1331",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.13312",
//         parent: "1.1331",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.13313",
//         parent: "1.1331",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.1341",
//         parent: "1.13",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.13411",
//         parent: "1.1341",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.13412",
//         parent: "1.1341",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.13413",
//         parent: "1.1341",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.1351",
//         parent: "1.13",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.13511",
//         parent: "1.1351",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.13512",
//         parent: "1.1351",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.13513",
//         parent: "1.1351",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.1361",
//         parent: "1.13",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.13611",
//         parent: "1.1361",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.13612",
//         parent: "1.1361",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.13613",
//         parent: "1.1361",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.13614",
//         parent: "1.1361",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.13615",
//         parent: "1.1361",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.13616",
//         parent: "1.1361",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.1371",
//         parent: "1.13",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.13711",
//         parent: "1.1371",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.13712",
//         parent: "1.1371",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.14",
//         parent: "0.0",
//         name: "Vit-C",
//         value: 1,
//     },
//     {
//         id: "1.1411",
//         parent: "1.14",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.14111",
//         parent: "1.1411",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.14112",
//         parent: "1.1411",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.1421",
//         parent: "1.14",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.14211",
//         parent: "1.1421",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.14212",
//         parent: "1.1421",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.14213",
//         parent: "1.1421",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.1431",
//         parent: "1.14",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.14311",
//         parent: "1.1431",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.14312",
//         parent: "1.1431",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.14313",
//         parent: "1.1431",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.1441",
//         parent: "1.14",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.14411",
//         parent: "1.1441",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.14412",
//         parent: "1.1441",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.14413",
//         parent: "1.1441",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.1451",
//         parent: "1.14",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.14511",
//         parent: "1.1451",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.14512",
//         parent: "1.1451",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.14513",
//         parent: "1.1451",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.1461",
//         parent: "1.14",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.14611",
//         parent: "1.1461",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.14612",
//         parent: "1.1461",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.14613",
//         parent: "1.1461",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.14614",
//         parent: "1.1461",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.14615",
//         parent: "1.1461",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.14616",
//         parent: "1.1461",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.1471",
//         parent: "1.14",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.14711",
//         parent: "1.1471",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.14712",
//         parent: "1.1471",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.15",
//         parent: "0.0",
//         name: "Vit-A",
//         value: 1,
//     },

//     {
//         id: "1.1511",
//         parent: "1.15",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.15111",
//         parent: "1.1511",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.15112",
//         parent: "1.1511",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.1521",
//         parent: "1.15",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.15211",
//         parent: "1.1521",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.15212",
//         parent: "1.1521",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.15213",
//         parent: "1.1521",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.1531",
//         parent: "1.15",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.15311",
//         parent: "1.1531",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.15312",
//         parent: "1.1531",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.15313",
//         parent: "1.1531",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.1541",
//         parent: "1.15",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.15411",
//         parent: "1.1541",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.15412",
//         parent: "1.1541",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.15413",
//         parent: "1.1541",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.1551",
//         parent: "1.15",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.15511",
//         parent: "1.1551",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.15512",
//         parent: "1.1551",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.15513",
//         parent: "1.1551",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.1561",
//         parent: "1.15",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.15611",
//         parent: "1.1561",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.15612",
//         parent: "1.1561",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.15613",
//         parent: "1.1561",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.15614",
//         parent: "1.1561",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.15615",
//         parent: "1.1561",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.15616",
//         parent: "1.1561",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.1571",
//         parent: "1.15",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.15711",
//         parent: "1.1571",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.15712",
//         parent: "1.1571",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.16",
//         parent: "0.0",
//         name: "Vit-D",
//         value: 1,
//     },
//     {
//         id: "1.1611",
//         parent: "1.16",
//         name: "Infants",
//         value: 2 / 22,
//     },

//     {
//         id: "1.16111",
//         parent: "1.1611",
//         name: "0-6 Months*  <br> (Reference body Wt 5.8 Kg) RDA 7.0",
//         value: 8.0,
//     },

//     {
//         id: "1.16112",
//         parent: "1.1611",
//         name: "6-12 Months <br> (Reference body Wt 8.5 Kg) <br>RDA 9.0 (g/d)",
//         value: 10.5,
//     },

//     {
//         id: "1.1621",
//         parent: "1.16",
//         name: "Children",
//         value: 3 / 22,
//     },
//     {
//         id: "1.16211",
//         parent: "1.1621",
//         name: "1-3 Years <br> (Reference body Wt 12.9 Kg) <br> RDA 12.5 (g/d)",
//         value: 12.5,
//     },

//     {
//         id: "1.16212",
//         parent: "1.1621",
//         name: "4-6 Years <br> (Reference body Wt 18.3 Kg) <br> RDA 16.0 (g/d)",
//         value: 16.0,
//     },

//     {
//         id: "1.16213",
//         parent: "1.1621",
//         name: "7-9 Years <br> (Reference body Wt 25.3 Kg) <br>RDA 23.0 (g/d)",
//         value: 23.0,
//     },

//     // Boys

//     {
//         id: "1.1631",
//         parent: "1.16",
//         name: "Boys",
//         value: 3 / 22,
//     },

//     {
//         id: "1.16311",
//         parent: "1.1631",
//         name: "10-12 Years <br> (Reference body Wt 34.9 Kg) <br> RDA 32.0 (g/d)",
//         value: 32.0,
//     },

//     {
//         id: "1.16312",
//         parent: "1.1631",
//         name: "13-15 Years <br> (Reference body Wt 50.5 Kg) <br> RDA 45.0 (g/d)",
//         value: 45.0,
//     },

//     {
//         id: "1.16313",
//         parent: "1.1631",
//         name: "16-18 Years <br> (Reference body Wt 64.4 Kg) <br>RDA 55.0 (g/d)",
//         value: 55.0,
//     },

//     // Girls

//     {
//         id: "1.1641",
//         parent: "1.16",
//         name: "Girls",
//         value: 3 / 22,
//     },

//     {
//         id: "1.16411",
//         parent: "1.1641",
//         name: "10-12 Years <br> (Reference body Wt 36.4 Kg) <br>RDA 33.0 (g/d)",
//         value: 33,
//     },

//     {
//         id: "1.16412",
//         parent: "1.1641",
//         name: "13-15 Years <br> (Reference body Wt 49.6 Kg) <br> RDA 43.0 (g/d)",
//         value: 43.0,
//     },

//     {
//         id: "1.16413",
//         parent: "1.1641",
//         name: "16-18 Years <br> (Reference body Wt 55.7 Kg) <br> RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     //Men
//     {
//         id: "1.1651",
//         parent: "1.16",
//         name: "Men",
//         value: 3 / 22,
//     },

//     {
//         id: "1.16511",
//         parent: "1.1651",
//         name: "Sedentary <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.16512",
//         parent: "1.1651",
//         name: "Moderate <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.16513",
//         parent: "1.1651",
//         name: "Heavy <br> (Reference body Wt 65 Kg) <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     //Women

//     {
//         id: "1.1661",
//         parent: "1.16",
//         name: "Women",
//         value: 6 / 22,
//     },

//     {
//         id: "1.16611",
//         parent: "1.1661",
//         name: "Sedentary <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.16612",
//         parent: "1.1661",
//         name: "Moderate <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.16613",
//         parent: "1.1661",
//         name: "Heavy <br> (Reference body Wt 55 Kg) <br>RDA 46.0 (g/d)",
//         value: 46.0,
//     },

//     {
//         id: "1.16614",
//         parent: "1.1661",
//         name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg) <br>RDA 55.5 (2nd trimester), 68(3rd trimester)",
//         value: 68,
//     },

//     {
//         id: "1.16615",
//         parent: "1.1661",
//         name: "Lactation(0-6m) <br>RDA 63.0 (g/d)",
//         value: 63.0,
//     },

//     {
//         id: "1.16616",
//         parent: "1.1661",
//         name: "Lactation(7-12m) <br>RDA 59.0 (g/d)",
//         value: 59.0,
//     },
//     // Elderly

//     {
//         id: "1.1671",
//         parent: "1.16",
//         name: "Elderly",
//         value: 2 / 22,
//     },

//     {
//         id: "1.16711",
//         parent: "1.1671",
//         name: "Men >= 60 Years <br> RDA 54.0 (g/d)",
//         value: 54.0,
//     },

//     {
//         id: "1.16712",
//         parent: "1.1671",
//         name: "Women >= 60 Years <br>RDA 46.0 (g/d)",
//         value: 54.0,
//     },

// ];

const EarTable = () => {
  const chartContainerRef = useRef(null);
  useEffect(() => {
    // Load the Sunburst module
    HighchartsSunburst(Highcharts);

    // Define your custom colors array
    const customColors = [
      "#2C74BE",
      "#662C90",
      "#EF5A2A",
      "#F8B13F",
      "#FCEF0A",
      "#8CC63C",
      "#3AB549",
    ];
    const chartContainer = document.getElementById("earcontainer");
    if (!chartContainer) {
      console.error("Chart container not found");
      return;
    }

    const chartInstance = Highcharts.chart("earcontainer", {
      chart: {
        height: "100%",
      },
      colors: ["transparent"].concat(customColors),
      title: {
        text: "Sunburst Chart of Estimated Average Requirements (EAR) - ICMR-NIN,2020",
      },
      credits: {
        enabled: true,
        text: "Click on segment to view in detailed information on each age group", // Replace with your desired text
        href: null, // Optional: Replace with a link if needed
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
            ],
          },
        },
      },
      series: [
        {
          type: "sunburst",
          data: data1,
          name: "Main Table",
          allowDrillToNode: true,
          borderRadius: 3,
          cursor: "pointer",
          dataLabels: {
            formatter: function () {
              // Get the point object
              const point = this.point;

              // Split the name to separate the main label and the measuring unit
              const nameParts = point.name.split(" ");

              // The main label is the first part of the name
              const mainLabel = nameParts.slice(0, nameParts.length - 1).join(" ");

              // The measuring unit is the last part of the name
              const measuringUnit = nameParts[nameParts.length - 1];

              // Create the desired data label format
              const dataLabel = mainLabel + "<br>" + point.earvalue + " " + measuringUnit;

              return dataLabel;
            },
            filter: {
              property: "innerArcLength",
              operator: ">",
              value: 5,
            },
            rotationMode: "parallel",
            style: {
              textOutline: "none",
            },
          },
          levels: [
            {
              level: 1,
              name: "Main Table",
              levelIsConstant: false,
              dataLabels: {
                formatter: function () {
                  return "EAR";
                },
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64,
                },
              },
            },
            {
              level: 2,
              dataLabels: {
                formatter: function () {
                  return this.point.name;
                },
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64,
                },
              },
              colorByPoint: true,
            },
            {
              level: 3,
              dataLabels: {
                formatter: function () {
                  // Get the point object
                  const point = this.point;

                  // Split the name to separate the main label and the measuring unit
                  const nameParts = point.name.split("<br>");

                  // The main label is the first part of the name
                  const mainLabel = nameParts[0].trim();

                  const dataLabel = mainLabel;

                  return dataLabel;
                },
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64,
                },
              },
              colorVariation: {
                key: "brightness",
                to: -0.5,
              },
            },
            {
              level: 4,
              colorVariation: {
                key: "brightness",
                to: 0.5,
              },
            },
          ],
        },
      ],
      // tooltip: {
      //     formatter: function () {
      //         // Get the point object
      //         const point = this.point;

      //         // Split the name to separate the main label and the measuring unit
      //         const nameParts = point.name.split(' ');

      //         // The main label is the first part of the name
      //         const mainLabel = nameParts.slice(0, nameParts.length - 1).join(' ');

      //         // The measuring unit is the last part of the name
      //         const measuringUnit = nameParts[nameParts.length - 1];

      //         // Create the desired data label format
      //         const dataLabel = mainLabel + '<br>' +
      //           point.earvalue + ' ' + measuringUnit;
      //         return dataLabel;
      //       },
      // },
      tooltip: {
        formatter: function () {
          // Get the point object
          const point = this.point;

          // Split the name to separate the main label and the measuring unit
          const nameParts = point.name.split(" ");

          // The main label is the first part of the name
          const mainLabel = nameParts.slice(0, nameParts.length - 1).join(" ");

          // The measuring unit is the last part of the name
          const measuringUnit = nameParts[nameParts.length - 1];

          // Check if the current point is at level 1
          if (point.level === 1) {
            return "Custom Tooltip Content for Level 1: " + mainLabel;
          } else {
            // Create the desired data label format for other levels
            const dataLabel = mainLabel + "<br>" + point.earvalue + " " + measuringUnit;
            return dataLabel;
          }
        },
      },
    });
    const handleFullScreen = () => {
      if (chartInstance) {
        const container = chartInstance.container;

        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.mozRequestFullScreen) {
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          container.msRequestFullscreen();
        }
      }
    };

    // Attach the full-screen button click event
    const fullScreenButton = document.getElementById("btn");
    if (fullScreenButton) {
      fullScreenButton.addEventListener("click", handleFullScreen);
    }

    // Cleanup function to destroy the chart on component unmount
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }

      // Remove the full-screen button click event listener
      if (fullScreenButton) {
        fullScreenButton.removeEventListener("click", handleFullScreen);
      }
    };
  }, []);

  return (
    <figure className="highcharts-figure">
      <div id="earcontainer"></div>
      {/* <Button id="btn">View Full Screen</Button> */}
    </figure>
  );
};

export default EarTable;
