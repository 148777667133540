import React from 'react'
import './NotFoundPage.css'

function NotFoundPage() {
  return (
    <div className='section'>
        <h1 className='error'>404</h1>
        <div className='page'>Ooops!!! This data yet to be loaded!!!</div>
    </div>
  )
}

export default NotFoundPage