// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..

import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import { Avatar, Grid, ListItemAvatar, ListItemText } from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import CalciumRdaTable from "./RdaAndEarTables/CalciumRdaTable";
import rapid_heartbeat from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png';
import Osteomalacia from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Osteomalacia.png';
import Osteoporosis from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Osteoporosis.png';
import Rickets from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Rickets.png';
import bone from '../../../../../assets/images/icons/minerals/Functions/bone.png';
import coenzyme from '../../../../../assets/images/icons/minerals/Functions/coenzyme.png';
import nerve_impulse from '../../../../../assets/images/icons/minerals/Functions/nerve_impulse.png';
import lack_of_vitD from '../../../../../assets/images/icons/minerals/Functions/lack_of_vitD.png';
import excessive_caffeine from '../../../../../assets/images/icons/minerals/Functions/excessive_caffeine.png';
import oxalates from '../../../../../assets/images/icons/minerals/Functions/oxalates.png';
import milk from "../../../../../assets/FoodSources/Minerals/Sources/milk.png";
import curd from "../../../../../assets/FoodSources/Minerals/Sources/milkProduct.png";
import paneer from "../../../../../assets/FoodSources/Minerals/Sources/paneer.png";
import cheese from "../../../../../assets/FoodSources/Minerals/Sources/cheese.png";
import spinach from "../../../../../assets/FoodSources/Minerals/Sources/spinach.png";
import broccoli from "../../../../../assets/FoodSources/Minerals/Sources/broccoli.png";
import brazil_nuts from "../../../../../assets/FoodSources/Minerals/Sources/brazil_nuts.png";
import almond from "../../../../../assets/FoodSources/Minerals/Sources/almond.png";
import sesame_seeds from "../../../../../assets/FoodSources/Minerals/Sources/sesame_seeds.png";
import chia_seeds from "../../../../../assets/FoodSources/Minerals/Sources/chia_seeds.png";
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import soy_milk from "../../../../../assets/FoodSources/Minerals/Sources/soy_milk.png";
import tofu from "../../../../../assets/FoodSources/Minerals/Sources/tofu.png";
import ragi from "../../../../../assets/FoodSources/Minerals/Sources/ragi.png";
import fortifiedCereals from "../../../../../assets/FoodSources/Minerals/Sources/fortifiedCereals.png";



export default function Calcium() {
  return (
    <>
      <Grid item lg={7} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of calcium?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={bone} />
                </ListItemAvatar>
                <ListItemText primary="It is  essential for the growth and maintenance  of bone structure and function." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={nerve_impulse} />
                </ListItemAvatar>
                <ListItemText primary="It is essential for nerve signaling, muscular and heart health." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={coenzyme} />
                </ListItemAvatar>
                <ListItemText primary="Additionally, it serves as a co-enzyme for numerous metabolic processes occurring in body." />
              </ListItem>
              {/* <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={nervoussytem} />
                  </ListItemAvatar>
                  <ListItemText primary="Calcium also acts as a coenzyme for many metabolic processes in the body." />
                </ListItem> */}
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the food sources of calcium?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Dairy products such as milk, curd, paneer, cheese are rich source of calcium.
              </ListItem>
              <ListItem>
                Some of the non-dairy calcium food sources are green leafy vegetables such as spinach, broccoli; brazil nuts and almonds; sesame seeds and chia seeds fish such as sardines and pilchards; soya milk, tofu, finger millet (ragi); fortified foods such as juices and cereals.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={2}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 0.3 }}>
              <Tooltip title="milk">
                <Avatar src={milk} size="lg" alt="milk" variant="square" />
              </Tooltip>
              <Tooltip title="curd">
                <Avatar src={curd} alt="curd" variant="square" />
              </Tooltip>
              <Tooltip title="paneer">
                <Avatar src={paneer} alt="paneer" variant="square" />
              </Tooltip>
              <Tooltip title="cheese">
                <Avatar src={cheese} alt="cheese" variant="square" />
              </Tooltip>
              <Tooltip title="spinach">
                <Avatar src={spinach} alt="spinach" variant="square" />
              </Tooltip>
              <Tooltip title="broccoli ">
                <Avatar src={broccoli} alt="broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="brazil nuts">
                <Avatar src={brazil_nuts} alt="brazil nuts" variant="square" />
              </Tooltip>
              <Tooltip title="almonds">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={almond} alt="almonds" variant="square" />
              </Tooltip>
              <Tooltip title="chia seeds">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={chia_seeds} alt="chia seeds" variant="square" />
              </Tooltip>
              <Tooltip title="fish such as sardines and pilchards ">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={fish} alt="fish" variant="square" />
              </Tooltip>
              <Tooltip title="soya milk">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={soy_milk} alt="soya milk" variant="square" />
              </Tooltip>
              <Tooltip title="tofu">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={tofu} alt="tofu" variant="square" />
              </Tooltip>
              <Tooltip title="finger millet (ragi)">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={ragi} alt="finger millet (ragi)" variant="square" />
              </Tooltip>
              <Tooltip title="fortified foods such as juices and cereals">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={fortifiedCereals} alt="cereals" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>

          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 0.3 }}>
              <Tooltip title="almonds">
                <Avatar src={almond} alt="almonds" variant="square" />
              </Tooltip>
              <Tooltip title="chia seeds">
                <Avatar src={chia_seeds} alt="chia seeds" variant="square" />
              </Tooltip>
              <Tooltip title="fish such as sardines and pilchards ">
                <Avatar src={fish} alt="fish" variant="square" />
              </Tooltip>
              <Tooltip title="soya milk">
                <Avatar src={soy_milk} alt="soya milk" variant="square" />
              </Tooltip>
              <Tooltip title="tofu">
                <Avatar src={tofu} alt="tofu" variant="square" />
              </Tooltip>
              <Tooltip title="finger millet (ragi)">
                <Avatar src={ragi} alt="finger millet (ragi)" variant="square" />
              </Tooltip>
              <Tooltip title="fortified foods such as juices and cereals">
                <Avatar src={fortifiedCereals} alt="cereals" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>


        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is calcium absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left", marginBottom: 2 }}>
              <ListItem>
                Only 30% of ingested calcium is absorbed in the body and Vitamin D plays a major role in the absorption.
              </ListItem>
            </List>
            <Typography level="h5" fontWeight="lg" align="start">
              What hinders the absorption of calcium in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Oxalates and phytates present in plant foods bind to calcium and decreases its
                absorption.
              </ListItem>
              <ListItem>Lack of Vitamin D reduces the absorption of calcium in the body</ListItem>
              <ListItem>Excessive intake of caffeine reduces absorption of calcium.</ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={2}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="oxalates">
                <Avatar src={oxalates} size="lg" alt="oxalates" variant="square" />
              </Tooltip>
              <Tooltip title="phytates ">
                <Avatar src={glv} alt="phytates " variant="square" />
              </Tooltip>
              <Tooltip title="lack of vitamin D">
                <Avatar src={lack_of_vitD} alt="lack of vitamin D" variant="square" />
              </Tooltip>
              <Tooltip title="excessive intake of caffeine">
                <Avatar src={excessive_caffeine} alt="excessive intake of caffeine" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={5} md={5} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <CalciumRdaTable />
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens when you are deficient in calcium?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, }}>
              <ListItem alignItems="flex-start">
                <Tooltip title="rickets">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={Rickets} />
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary="Calcium deficiency in children could result in rickets, a condition which affects bone development." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <Tooltip title="osteomalacia">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={Osteomalacia} />
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary="Where as, deficiency in adults could lead to osteomalacia." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <Tooltip title="osteoporosis">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={Osteoporosis} />
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary="And, prolonged deficiency reduces bone strength and leads to osteoporosis." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <Tooltip title="abnormal heart rhythm">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={rapid_heartbeat} />
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary="Other signs of calcium deficiency are brittle nails, muscle pain and spasms, dermatitis, tooth decay, abnormal heart rhythm." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item lg={7} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is calcium absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left", marginBottom:2}}>
              <ListItem>
              Only 30% of ingested Calcium is absorbed in the body and Vitamin D plays a major role in the absorption.
              </ListItem>
            </List>
            <Typography level="h5" fontWeight="lg" align="start">
              What hinders the absorption of calcium in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Oxalates and phytates present in plant foods bind to calcium and decreases its
                absorption.
              </ListItem>
              <ListItem>Lack of Vitamin D reduces the absorption of Calcium in the body</ListItem>
              <ListItem>Excessive intake of caffeine reduces absorption of calcium.</ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="Whole Grain cereals">
                <Avatar src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="Milk and Milk Products">
                <Avatar src={milk} alt="Milk" variant="square" />
              </Tooltip>
              <Tooltip title="Pork">
                <Avatar src={pork} alt="pork" variant="square" />
              </Tooltip>
              <Tooltip title="Legumes">
                <Avatar src={legumes} alt="Legumes" variant="square" />
              </Tooltip>
              <Tooltip title="Green leafy vegetables (spinach, fenugreek leaves and gogu (gongura)">
                <Avatar src={glv} alt="Green vegetables" variant="square" />
              </Tooltip>
              <Tooltip title="Butter ">
                <Avatar src={butter} alt="Butter" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid> */}
      {/* <Grid item lg={5} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens when you are deficient in calcium?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={beriberi} />
                </ListItemAvatar>
                <ListItemText primary="Calcium deficiency in children could result in rickets, a condition which affects bone development." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={muscleweakness} />
                </ListItemAvatar>
                <ListItemText primary="Where as, deficiency in adults could lead to osteomalacia." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={damagednervous} />
                </ListItemAvatar>
                <ListItemText primary="And, prolonged deficiency reduces bone strength and leads to osteoporosis." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={abnormalheart} />
                </ListItemAvatar>
                <ListItemText primary="Other signs of calcium deficiency are brittle nails, muscle pain and spasms, dermatitis, tooth decay, abnormal heart rhythm." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid> */}
      <Grid item lg={12} md={12} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the interactions of calcium with other nutrients?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Calcium and iron rich foods should not be consumed together as they hinder each other’s absorption." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
      </Grid>

    </>
  );
}
