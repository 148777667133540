import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";

import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import meat from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb2/nuts.png";
import legumes from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/legumes.png";
import egg from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/egg.png";
import milkProduct from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milkProduct.png";
import tiredness from "../../../../../assets/images/icons/vitamins/vitaminb5/tiredness.png";
import diarrhea from "../../../../../assets/images/icons/vitamins/vitaminb3/diarrhea.png";
import fish from "../../../../../assets/images/icons/vitamins/vitaminb3/fish.png";
import avocado from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B5/avocado.png";
import broccoli from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B5/broccoli.png";
import headache from "../../../../../assets/images/icons/vitamins/vitaminb5/headache.png";
import irritability from "../../../../../assets/images/icons/vitamins/vitaminb5/irritability.png";
import sleepdisturbance from "../../../../../assets/images/icons/vitamins/vitaminb5/sleepdisturbance.png";
import vomit from "../../../../../assets/images/icons/vitamins/vitaminb5/vomit.png";
import metabolism from "../../../../../assets/images/icons/vitamins/vitaminb5/metabolism.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import fatigue from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png";
import numbness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/numbness.png";
import insomnia from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB5/insomnia.png";
import stomachache from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB5/stomachache.png";

export default function Vitaminb5() {
  return (
    <>
      <Grid item lg={6} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of vitamin B5?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={nervoussytem} />
                </ListItemAvatar>
                <ListItemText primary="Pantothenic acid plays a key role in the synthesis of Coenzyme A (CoA), which is involved in breakdown of fatty acids and other metabolic functions." />
              </ListItem>
            </List>

          </CardContent>

        </Card>

        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 0.5
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the food sources of vitamin B5?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Good sources of pantothenic acid include meat (poultry and beef), fish, eggs, dairy products, legumes, whole grains, and vegetables like broccoli and avocados.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 0.5,
              mb: 0.5
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1.5 }}>
              <Tooltip title="Meat (such as poultry, beef)">
                <Avatar src={meat} alt="meat" variant="square" />
              </Tooltip>
              <Tooltip title="Fish">
                <Avatar src={fish} alt="Fish" variant="square" />
              </Tooltip>
              <Tooltip title="Eggs">
                <Avatar src={egg} alt="Egg" variant="square" />
              </Tooltip>
              <Tooltip title="Dairy products">
                <Avatar src={milkProduct} alt="Curd" variant="square" />
              </Tooltip>
              <Tooltip title="Legumes ">
                <Avatar src={legumes} alt="Legumes" variant="square" />
              </Tooltip>
              <Tooltip title="Whole Grains">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="Broccoli">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={broccoli} alt="Broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="avocados">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={avocado} alt="Avocados" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1
            }}
            
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1.5 }}>
              <Tooltip title="Whole Grains">
                <Avatar src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="Broccoli">
                <Avatar src={broccoli} alt="Broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="avocados">
                <Avatar src={avocado} alt="Avocados" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={6} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 0, sm: 1 },
            py: 1.5,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin B5 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="It is readily absorbed in the stomach and small intestine and a limited amount is stored in kidney, liver and brain. Excess niacin is excreted in urine." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1,
            mt: { xs: 1, sm: 0 },
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What is the recommended dietary intake of pantothenic?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar>
                <ListItemText
                  primary="The recommended intake of pantothenic acid is 5 mg per day for adults and pregnant women, 7 mg/day for lactating women accounting for losses through breast milk. The Adequate intake (AI) for children and adolescent is set at 4 and 5 mg/day."
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1.5,
            mt: { xs: 0, sm: 1 },
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin B5 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="It is readily absorbed in the stomach and small intestine and a limited amount is stored in kidney, liver and brain. Excess niacin is excreted in urine." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1,
            mt: { xs: 0 },
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens if you are deficient in vitamin B5?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
              <ListItem>
                Deficiency of pantothenic acid can result in tiredness, headache, irritability, nausea, vomiting, stomach and muscle cramps, sleep disturbance, numbness or burning sensation in hands or feet.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 0.5 }}>
              <Tooltip title="tiredness">
                <Avatar src={fatigue} size="lg" alt="tiredness" variant="square" />
              </Tooltip>
              <Tooltip title="headache">
                <Avatar src={headache} alt="headache" variant="square" />
              </Tooltip>
              <Tooltip title="irritability">
                <Avatar src={irritability} alt="irritability" variant="square" />
              </Tooltip>
              <Tooltip title="vomiting">
                <Avatar src={vomit} alt="vomiting" variant="square" />
              </Tooltip>
              <Tooltip title="stomachache">
                <Avatar src={stomachache} alt="stomachache" variant="square" />
              </Tooltip>
              <Tooltip title="sleep disturbance ">
                <Avatar src={insomnia} alt="insomnia" variant="square" />
              </Tooltip>
              <Tooltip title="numbness in hands">
                <Avatar src={numbness} alt="numbness in hands" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
    </>
  );
}
