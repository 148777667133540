// pages
import Home from "./layouts/home/Home";
import Dashboard from "./layouts/dashboard/Dashboard";
import Compare from "./layouts/compare/Compare";
import Contact from "./layouts/contact/Contact";
import Feedback from "./layouts/feedback/Feedback";
import Nutrieducation from "./layouts/nutrieducation/Nutrieducation";
// @mui icons
import {
  FcAreaChart,
  FcHome,
  FcReading,
  FcTimeline,
  FcBusinessContact,
  FcInspection,
  FcList,
} from "react-icons/fc";
import Micronutrient from "./layouts/nutrieducation/components/Micronutrients";
import Macronutrient from "./layouts/nutrieducation/components/Macronutrients";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <FcHome />,
    route: "/Home",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <FcAreaChart />,
    route: "/Dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Compare",
    key: "Compare",
    icon: <FcTimeline />,
    route: "/Compare",
    component: <Compare />,
  },
  {
    type: "collapse",
    name: "Nutrieducation",
    key: "Nutrieducation",
    icon: <FcReading />,
    route: "/Nutrieducation",
    component: <Nutrieducation />,
  },
  // {
  //   type: "collapse",
  //   name: "More",
  //   key: "feedback",
  //   icon: <FcInspection />,
  //   route: "/Feedback",
  //   component: <Feedback />,
  // },

  // {
  //   type: "collapse",
  //   name: "Contact",
  //   key: "contact",
  //   icon: <FcBusinessContact />,
  //   route: "/Contact",
  //   component: <Contact />,
  // },
  // {
  //   type: "collapse",
  //   name: "FeedBack",
  //   key: "feedback",
  //   icon: <FcInspection />,
  //   route: "/Feedback",
  //   component: <Feedback />,
  // },
];

export default routes;
