import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mapKey: "India",
};

export const MapKeySlice = createSlice({
  name: 'mapKeyValue',
  initialState,
  reducers: {
    setMapKey: (state, action) => {
      state.mapKey = action.payload;
    },
  },
});

export const { setMapKey } = MapKeySlice.actions;
export const getMapKeyValue = (state) => state.mapKeyValue.mapKey;

export default MapKeySlice.reducer;


