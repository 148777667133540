// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..

import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import { Avatar, Grid, ListItemAvatar, ListItemText } from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import IronRdaTable from "./RdaAndEarTables/IronRdaTable";
import fatigue from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png';
import dizziness from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB12/dizziness.png';
import pale_skin from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/pale_skin.png';
import shortness_of_breath from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/shortness_of_breath.png';
import HairLossIcon from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/HairLossIcon.png';
import brittle_nails from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/brittle_nails.png';
import rapid_heartbeat from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png';
import body_temperature from '../../../../../assets/images/icons/minerals/Deficiency/body_temperature.png';
import sensitivity_to_cold from '../../../../../assets/images/icons/minerals/Deficiency/sensitivity_to_cold.png';
import brain from '../../../../../assets/images/icons/minerals/Functions/brain.png';
import hemoglobin from '../../../../../assets/images/icons/minerals/Functions/hemoglobin.png';
import muscle_myoglobin from '../../../../../assets/images/icons/minerals/Functions/muscle_myoglobin.png';
import redblood_cells from '../../../../../assets/images/icons/minerals/Functions/redblood_cells.png';
import Red_meat from "../../../../../assets/FoodSources/Minerals/Sources/Red_meat.png";
import poultry from "../../../../../assets/FoodSources/Minerals/Sources/poultry.png";
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import legumes from "../../../../../assets/FoodSources/Minerals/Sources/legumes.png";
import spinach from "../../../../../assets/FoodSources/Minerals/Sources/spinach.png";
import fortifiedCereals from "../../../../../assets/FoodSources/Minerals/Sources/fortifiedCereals.png";
import seeds from "../../../../../assets/FoodSources/Minerals/Sources/seeds.png";

export default function Iron() {
  return (
        <>
          <Grid item lg={6} md={6} sm={12} >
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
              }}
            >
              <Typography level="h5" fontWeight="lg" align="start">
                What are the functions of iron?
              </Typography>
              <CardContent>
                <List sx={{ textAlign: "left" }}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={redblood_cells} />
                    </ListItemAvatar>
                    <ListItemText primary="Iron is crucial for red blood cell formation." />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={hemoglobin} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="It is a key component of haemoglobin, an oxygen carrying protein that is responsible for transporting oxygen from the lungs to other parts of the body."
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={muscle_myoglobin} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="It is important for synthesis of myoglobin a protein that stores and releases oxygen in muscle cells."
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={brain} />
                    </ListItemAvatar>
                    <ListItemText primary="In Children iron is important for healthy brain development and growth, and for the synthesis and function of various cells and hormones." />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1,
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" align="start">
                  What are the food sources of Iron?

                </Typography>
                <Typography align="start">
                  Iron is present as different forms in food sources i.e. Heme iron and Non-Heme Iron.
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem>
                    <Typography>
                      <Box
                        component="span"
                        sx={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          backgroundColor: "pink",
                          padding: "0.2rem 0.3rem",
                          borderRadius: "4px",
                          marginRight: "0.5em",
                          color: "#333333",
                        }}
                      >
                        Heme iron:
                      </Box>
                      The body absorbs heme iron more readily than non-heme iron. Meat (Red meat), poultry (chicken) and seafood (fish) contain high amounts of heme iron.
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                mb={1}
              >
                <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                  <Tooltip title="Meat (Red meat)">
                    <Avatar src={Red_meat} size="lg" alt="Meat (Red meat)" variant="square" />
                  </Tooltip>
                  <Tooltip title="poultry (chicken)">
                    <Avatar src={poultry} alt="poultry (chicken)" variant="square" />
                  </Tooltip>
                  <Tooltip title="seafood (fish)">
                    <Avatar src={fish} alt="seafood (fish)" variant="square" />
                  </Tooltip>
                </AvatarGroup>
              </Box>


              {/* <Typography level="h5" fontWeight="lg" align="start">
                What are the food sources of Non-heme Iron?

              </Typography> */}

              <List sx={{ textAlign: "left" }}>
                <ListItem>
                  <Typography>
                    <Box
                      component="span"
                      sx={{
                        fontWeight: "600",
                        fontSize: "1rem",
                        backgroundColor: "pink",
                        padding: "0.2rem 0.3rem",
                        borderRadius: "4px",
                        marginRight: "0.5em",
                        color: "#333333"
                      }}
                    >
                      Non-heme iron:
                    </Box>
                    Non-heme iron is found in plant-based sources such as legumes, leafy greens, fortified cereals and seeds, is less readily absorbed. The absorption of non-heme iron can be improved by consuming it with vitamin C rich foods.
                  </Typography>
                </ListItem>
              </List>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                mb={1}
              >
                <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                  <Tooltip title="legumes">
                    <Avatar src={legumes} size="lg" alt="legumes" variant="square" />
                  </Tooltip>
                  <Tooltip title="leafy greens">
                    <Avatar src={spinach} alt="spinach" variant="square" />
                  </Tooltip>
                  <Tooltip title="fortified cereals">
                    <Avatar src={fortifiedCereals} alt="fortified cereals and seeds" variant="square" />
                  </Tooltip>
                  <Tooltip title="seeds">
                    <Avatar src={seeds} alt="seeds" variant="square" />
                  </Tooltip>
                </AvatarGroup>
              </Box>

            </Card>

            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: { xs: 1, sm: 1.5 },
                display: { xs: 'block', sm: 'none' }
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" align="start">
                  How is iron absorbed in the body?
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Heme iron is absorbed as high as 25 per cent. Most of the iron absorption takes place at the brush border cells of small intestine." />
                  </ListItem>

                </List>

                <Typography level="h5" fontWeight="lg" align="start">
                  What hinders the absorption of iron in the body?
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Phytates and oxalates, polyphenols, low gastric acidity and infections affect the iron absorption in the body." />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Non-heme iron absorption can be inhibited by a number of substances, including tannins, phytates, excessive calcium intakes, and bran fiber." />
                  </ListItem>

                </List>
              </CardContent>


            </Card>

            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                display: { xs: 'block', sm: 'none' },
                mt: { xs: 1, sm:0 }
              }}
            >
              <IronRdaTable />
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1,
                backgroundColor: "#BFEFFF",
                color: "#333333",
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <CardContent>
                <Typography level="h4" fontWeight="lg" align="start">
                  Nutrition Program :
                </Typography>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  Nutritional Anaemia Prophylaxis Programme initiated in 1970.
                </Typography>
                <Typography level="h5" fontWeight="lg" align="start" sx={{ margin: "0", padding: "0", marginLeft: 2 }}>
                  Beneficiaries:
                </Typography>
                <List sx={{ textAlign: "left", margin: "0", padding: "0", marginLeft: 2, marginBottom: 2 }}>
                  <ListItem>
                    All age groups namely children aged 6-59 months, 5-10 yr, adolescents aged 10-19 yr, pregnant and lactating women, and women in the reproductive age group.
                  </ListItem>
                  {/* <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={energetic} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="All age groups namely children aged 6-59 months, 5-10 yr, adolescents aged 10-19 yr, pregnant and lactating women, and women in the reproductive age group."
                    />
                  </ListItem> */}
                </List>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  Iron and Folic Acid Supplementations:
                </Typography>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  For pregnant and lactating women and women of reproductive age:
                </Typography>
                <List sx={{ textAlign: "left", marginLeft: 2, padding: "0" }}>
                  <ListItem>
                    100mg of elemental iron+ 500 mcg folic acid tablets.
                  </ListItem>
                </List>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  For Children (5-10 years):
                </Typography>
                <List sx={{ textAlign: "left", marginLeft: 2, padding: "0" }}>
                  <ListItem>
                    20mg of elemental iron+ 100 mcg of folic acid tablets
                  </ListItem>
                </List>

              </CardContent>



            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1,
                backgroundColor: "#FFFFCC",
                color: "#333333",
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <CardContent>
                <Typography level="h4" fontWeight="lg" align="start">
                  The National Iron Plus Initiative (NIPI):
                </Typography>
                <List sx={{ textAlign: "left", margin: "0", padding: "0", marginLeft: 2, }}>
                  <ListItem>
                    The National Iron Plus Initiative (NIPI) is another initiative by the Government of India to combat anemia in all age groups.
                  </ListItem>
                </List>
                {/* <Typography level="h6" fontWeight="lg" align="start">
                  The National Iron Plus Initiative (NIPI) is another initiative by the Government of India to combat anemia in all age groups.
                </Typography> */}
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                backgroundColor: "#FFD9E8",
                mt: 1,
                display: { xs: 'none', sm: 'block' }
                // padding: '0 0 10px 10px'
              }}
            >
              <CardContent>
                <Typography level="h4" fontWeight="lg" align="start">
                  Anemia Mukt Bharat Programe:
                </Typography>
                <List sx={{ textAlign: "left", padding: "0px", marginLeft: 2, }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="It has 6*6*6 (triple-6) strategy to reduce the prevalence of anemia
    by 3% points per year starting from 2018 to 2022. Anemia Mukt Bharat strategy is implemented to
    reduce anaemia among six beneficiaries age group in a life cycle approach through implementation of six interventions
    through six institutional mechanisms." />
                  </ListItem>

                </List>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1.5,
                display: { xs: 'block', sm: 'none' }
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" align="start">
                  What are the signs and symptoms of Iron deficiency?
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem>
                    Anemia occurs when the blood doesn't have enough haemoglobin. Physical signs of iron deficiency includes pale skin and tongue, hair loss, brittle nails. Other symptoms include fatigue, weakness, dizziness, loss of concentration, shortness of breath, rapid heartbeat, sensitivity to cold and inability to regulate body temperature.
                  </ListItem>
                  <ListItem>
                    Koilonychia: bilateral spoon shaped deformities of the nails (Concave nails).
                  </ListItem>
                </List>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                mt={1}
                mb={3}
              >
                <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                  <Tooltip title="pale skin">
                    <Avatar src={pale_skin} size="lg" alt="pale skin" variant="square" />
                  </Tooltip>
                  <Tooltip title="hair loss">
                    <Avatar src={HairLossIcon} alt="hair loss" variant="square" />
                  </Tooltip>
                  <Tooltip title="brittle nails">
                    <Avatar src={brittle_nails} alt="brittle nails" variant="square" />
                  </Tooltip>
                  <Tooltip title="fatigue">
                    <Avatar src={fatigue} alt="fatigue" variant="square" />
                  </Tooltip>
                  <Tooltip title="dizziness">
                    <Avatar src={dizziness} alt="dizziness" variant="square" />
                  </Tooltip>
                  <Tooltip title="shortness of breath">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={shortness_of_breath} alt="shortness of breath" variant="square" />
                  </Tooltip>
                  <Tooltip title="rapid heartbeat">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={rapid_heartbeat} alt="rapid heartbeat" variant="square" />
                  </Tooltip>
                  <Tooltip title="sensitivity to cold ">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={sensitivity_to_cold} alt="sensitivity to cold" variant="square" />
                  </Tooltip>
                  <Tooltip title="inability to regulate body temperature ">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={body_temperature} alt="inability to regulate body temperature" variant="square" />
                  </Tooltip>
                </AvatarGroup>
              </Box>

              <Box
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                  <Tooltip title="shortness of breath">
                    <Avatar src={shortness_of_breath} alt="shortness of breath" variant="square" />
                  </Tooltip>
                  <Tooltip title="rapid heartbeat">
                    <Avatar src={rapid_heartbeat} alt="rapid heartbeat" variant="square" />
                  </Tooltip>
                  <Tooltip title="sensitivity to cold ">
                    <Avatar src={sensitivity_to_cold} alt="sensitivity to cold" variant="square" />
                  </Tooltip>
                  <Tooltip title="inability to regulate body temperature ">
                    <Avatar src={body_temperature} alt="inability to regulate body temperature" variant="square" />
                  </Tooltip>
                </AvatarGroup>
              </Box>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1.5,
                display: { xs: 'block', sm: 'none' }
              }}
            >
              <Typography level="h5" fontWeight="lg" align="start" >
                Who are at the risk of getting Iron deficiency anemia?
              </Typography>
              <List sx={{ textAlign: "left" }}>
                <ListItem>
                  Adolescents especially girls, women of reproductive age who menstruate or will become mothers in future need to maintain good reserves of iron stores in the body.
                </ListItem>
                <ListItem>
                  Pregnant women need extra iron intake to support the growing fetus and placenta.
                </ListItem>
                <ListItem>
                  Since plant-based foods contain non-heme iron, which is less absorbed in our body, vegetarians and vegans should consume more iron-rich foods along with vitamin C-rich foods for better absorption.
                </ListItem>
                <ListItem>
                  Individuals with GI disorders such as celiac disease, inflammatory bowel disease which can impair the absorption of iron.
                </ListItem>
              </List>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} >
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <IronRdaTable />
            </Card>

            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1.5,
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" align="start">
                  How is iron absorbed in the body?
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Heme iron is absorbed as high as 25 per cent. Most of the iron absorption takes place at the brush border cells of small intestine." />
                  </ListItem>

                </List>

                <Typography level="h5" fontWeight="lg" align="start">
                  What hinders the absorption of iron in the body?
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Phytates and oxalates, polyphenols, low gastric acidity and infections affect the iron absorption in the body." />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Non-heme iron absorption can be inhibited by a number of substances, including tannins, phytates, excessive calcium intakes, and bran fiber." />
                  </ListItem>

                </List>
              </CardContent>


            </Card>

            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1.5,
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" align="start">
                  What are the signs and symptoms of Iron deficiency?
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem>
                    Anemia occurs when the blood doesn't have enough haemoglobin. Physical signs of iron deficiency includes pale skin and tongue, hair loss, brittle nails. Other symptoms include fatigue, weakness, dizziness, loss of concentration, shortness of breath, rapid heartbeat, sensitivity to cold and inability to regulate body temperature.
                  </ListItem>
                  <ListItem>
                    Koilonychia: bilateral spoon shaped deformities of the nails (Concave nails).
                  </ListItem>
                </List>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                mt={1}
                mb={3}
              >
                <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                  <Tooltip title="pale skin">
                    <Avatar src={pale_skin} size="lg" alt="pale skin" variant="square" />
                  </Tooltip>
                  <Tooltip title="hair loss">
                    <Avatar src={HairLossIcon} alt="hair loss" variant="square" />
                  </Tooltip>
                  <Tooltip title="brittle nails">
                    <Avatar src={brittle_nails} alt="brittle nails" variant="square" />
                  </Tooltip>
                  <Tooltip title="fatigue">
                    <Avatar src={fatigue} alt="fatigue" variant="square" />
                  </Tooltip>
                  <Tooltip title="dizziness">
                    <Avatar src={dizziness} alt="dizziness" variant="square" />
                  </Tooltip>
                  <Tooltip title="shortness of breath">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={shortness_of_breath} alt="shortness of breath" variant="square" />
                  </Tooltip>
                  <Tooltip title="rapid heartbeat">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={rapid_heartbeat} alt="rapid heartbeat" variant="square" />
                  </Tooltip>
                  <Tooltip title="sensitivity to cold ">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={sensitivity_to_cold} alt="sensitivity to cold" variant="square" />
                  </Tooltip>
                  <Tooltip title="inability to regulate body temperature ">
                    <Avatar sx={{
                      display: { xs: 'none', sm: 'block' },
                    }} src={body_temperature} alt="inability to regulate body temperature" variant="square" />
                  </Tooltip>
                </AvatarGroup>
              </Box>

              <Box
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                  <Tooltip title="shortness of breath">
                    <Avatar src={shortness_of_breath} alt="shortness of breath" variant="square" />
                  </Tooltip>
                  <Tooltip title="rapid heartbeat">
                    <Avatar src={rapid_heartbeat} alt="rapid heartbeat" variant="square" />
                  </Tooltip>
                  <Tooltip title="sensitivity to cold ">
                    <Avatar src={sensitivity_to_cold} alt="sensitivity to cold" variant="square" />
                  </Tooltip>
                  <Tooltip title="inability to regulate body temperature ">
                    <Avatar src={body_temperature} alt="inability to regulate body temperature" variant="square" />
                  </Tooltip>
                </AvatarGroup>
              </Box>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1.5,
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <Typography level="h5" fontWeight="lg" align="start" >
                Who are at the risk of getting Iron deficiency anemia?
              </Typography>
              <List sx={{ textAlign: "left" }}>
                <ListItem>
                  Adolescents especially girls, women of reproductive age who menstruate or will become mothers in future need to maintain good reserves of iron stores in the body.
                </ListItem>
                <ListItem>
                  Pregnant women need extra iron intake to support the growing fetus and placenta.
                </ListItem>
                <ListItem>
                  Since plant-based foods contain non-heme iron, which is less absorbed in our body, vegetarians and vegans should consume more iron-rich foods along with vitamin C-rich foods for better absorption.
                </ListItem>
                <ListItem>
                  Individuals with GI disorders such as celiac disease, inflammatory bowel disease which can impair the absorption of iron.
                </ListItem>
              </List>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                py: 0.5,
                display: { xs: 'block', sm: 'none' }
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" align="start">
                  What are the interactions of iron with other nutrients?
                </Typography>
                <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Calcium and iron rich foods should not be consumed together as they hinder each other’s absorption." />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Copper deficiency affects the metabolism of iron." />
                  </ListItem>

                </List>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1,
                backgroundColor: "#BFEFFF",
                color: "#333333",
                display: { xs: 'block', sm: 'none' }
              }}
            >
              <CardContent>
                <Typography level="h4" fontWeight="lg" align="start">
                  Nutrition Program :
                </Typography>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  Nutritional Anaemia Prophylaxis Programme initiated in 1970.
                </Typography>
                <Typography level="h5" fontWeight="lg" align="start" sx={{ margin: "0", padding: "0", marginLeft: 2 }}>
                  Beneficiaries:
                </Typography>
                <List sx={{ textAlign: "left", margin: "0", padding: "0", marginLeft: 2, marginBottom: 2 }}>
                  <ListItem>
                    All age groups namely children aged 6-59 months, 5-10 yr, adolescents aged 10-19 yr, pregnant and lactating women, and women in the reproductive age group.
                  </ListItem>
                  {/* <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={energetic} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="All age groups namely children aged 6-59 months, 5-10 yr, adolescents aged 10-19 yr, pregnant and lactating women, and women in the reproductive age group."
                    />
                  </ListItem> */}
                </List>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  Iron and Folic Acid Supplementations:
                </Typography>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  For pregnant and lactating women and women of reproductive age:
                </Typography>
                <List sx={{ textAlign: "left", marginLeft: 2, padding: "0" }}>
                  <ListItem>
                    100mg of elemental iron+ 500 mcg folic acid tablets.
                  </ListItem>
                </List>
                <Typography level="h5" fontWeight="lg" align="start" marginLeft={2}>
                  For Children (5-10 years):
                </Typography>
                <List sx={{ textAlign: "left", marginLeft: 2, padding: "0" }}>
                  <ListItem>
                    20mg of elemental iron+ 100 mcg of folic acid tablets
                  </ListItem>
                </List>

              </CardContent>



            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1,
                backgroundColor: "#FFFFCC",
                color: "#333333",
                display: { xs: 'block', sm: 'none' }
              }}
            >
              <CardContent>
                <Typography level="h4" fontWeight="lg" align="start">
                  The National Iron Plus Initiative (NIPI):
                </Typography>
                <List sx={{ textAlign: "left", margin: "0", padding: "0", marginLeft: 2, }}>
                  <ListItem>
                    The National Iron Plus Initiative (NIPI) is another initiative by the Government of India to combat anemia in all age groups.
                  </ListItem>
                </List>
                {/* <Typography level="h6" fontWeight="lg" align="start">
                  The National Iron Plus Initiative (NIPI) is another initiative by the Government of India to combat anemia in all age groups.
                </Typography> */}
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                backgroundColor: "#FFD9E8",
                mt: 1,
                display: { xs: 'block', sm: 'none' }
                // padding: '0 0 10px 10px'
              }}
            >
              <CardContent>
                <Typography level="h4" fontWeight="lg" align="start">
                  Anemia Mukt Bharat Programe:
                </Typography>
                <List sx={{ textAlign: "left", padding: "0px", marginLeft: 2, }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="It has 6*6*6 (triple-6) strategy to reduce the prevalence of anemia
    by 3% points per year starting from 2018 to 2022. Anemia Mukt Bharat strategy is implemented to
    reduce anaemia among six beneficiaries age group in a life cycle approach through implementation of six interventions
    through six institutional mechanisms." />
                  </ListItem>

                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} >
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                py: 0.5,
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" align="start">
                  What are the interactions of iron with other nutrients?
                </Typography>
                <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Calcium and iron rich foods should not be consumed together as they hinder each other’s absorption." />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary="Copper deficiency affects the metabolism of iron." />
                  </ListItem>

                </List>
              </CardContent>
            </Card>
          </Grid>
        </>
  );
}
