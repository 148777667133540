import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Rice from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/1_RiceAndRiceProducts.jpg"
import Makhana from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/2_Makhana.jpg"
import Wheat from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/3_Wheat.jpg"
import Panivaragu from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/4_Panivaragu.jpg"
import Jowar from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/5_Jowar.jpg"
import Ragi from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/6_Ragi.jpg"
import Varagu from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/7_Varagu.jpg"
import Samai from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/8_samai.jpg"
import Barnyard from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/9_Barnyard.jpg"
import WholeWheat from "../../../../../../assets/FoodSources/Carbohydrate/Cereal Grains/10_WholeWheat.jpg"
import { Typography } from '@mui/material';

export default function CerealGrains() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: Rice,
    title: 'Rice (raw, puffed, parboiled, flakes, brown)',
    author: '78.24 – 74.8 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Makhana,
    title: 'Makhana',
    author: '76.9 per 100gm',
  },
  {
    img: Wheat,
    title: 'Wheat (refined flour, vermicelli, bulgur, semolina)',
    author: '74.27 – 68.43 per 100gm',
  },
  {
    img: Panivaragu,
    title: 'Panivaragu (proso millet)',
    author: '70.4 per 100gm',
    cols: 2,
  },
  {
    img: Jowar,
    title: 'Jowar',
    author: '67.68 per 100gm',
    cols: 2,
  },
  {
    img: Ragi,
    title: 'Ragi',
    author: '66.82 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Varagu,
    title: 'Varagu',
    author: '66.19 per 100gm',
  },
  {
    img: Samai,
    title: 'Samai (Little Millet)',
    author: '65.55 per 100gm.4',
  },
  {
    img: Barnyard,
    title: 'Barnyard millet',
    author: '65.5 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: WholeWheat,
    title: 'Wheat flour atta',
    author: '64.72 per 100gm',
  }
];