import React from "react";
// import "./testdietary.css";
import { CssBaseline, Grid, useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Container, ListItemText } from "@mui/material";

import Avatar from "@mui/joy/Avatar";

import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";

import Typography from "@mui/joy/Typography";

import gb from "../../../../../../assets/images/dietaryguidelines.png";

import DietaryResponsive from "./DietaryResponsive";
import pdfFile from "../../../../../../assets/images/dietary_guidelines.pdf";


import gd1 from "../../../../../../assets/images/guidelines/g1.png";
import gd2 from "../../../../../../assets/images/guidelines/g2.png";
import gd3 from "../../../../../../assets/images/guidelines/g3.png";
import gd4 from "../../../../../../assets/images/guidelines/g4.png";
import gd5 from "../../../../../../assets/images/guidelines/g5.png";
import gd6 from "../../../../../../assets/images/guidelines/g6.png";
import gd7 from "../../../../../../assets/images/guidelines/g7.png";
import gd8 from "../../../../../../assets/images/guidelines/g8.png";
import gd9 from "../../../../../../assets/images/guidelines/g9.png";
import gd10 from "../../../../../../assets/images/guidelines/g10.png";
import gd11 from "../../../../../../assets/images/guidelines/g11.png";
import gd12 from "../../../../../../assets/images/guidelines/g12.png";
import gd13 from "../../../../../../assets/images/guidelines/g13.png";
import gd14 from "../../../../../../assets/images/guidelines/g14.png";
import gd15 from "../../../../../../assets/images/guidelines/g15.png";
import gd16 from "../../../../../../assets/images/guidelines/g16.png";
import gd17 from "../../../../../../assets/images/guidelines/g17.png";
import { useNavigate } from "react-router-dom";

const guidelines = [
    "1.Eat a variety of foods to ensure a balanced diet",
    "2.Ensure provision of extra food and healthcare during pregnancy and lactation",
    "3.Ensure exclusive breastfeeding for the first six months and continue breastfeeding till two years and beyond",
    "4.Start feeding homemade semi-solid complementary foods to the infant soon a fter six months of age",
    "5.Ensure adequate and appropriate diets for children and adolescents both in health and sickness",
    "6.Eat plenty of vegetables and legumes",
    "7.Use oils/fats in moderation; choose a variety of oil seeds, nuts, nutricereals and legumes to meet daily needs of fats and essential fatty acids (EFA)",
    "8.Obtain good quality proteins and essential amino acids (EAA) through appropriate combination of foods and avoid protein supplements to build muscle mass",
    "9.Adopt a healthy lifestyle to prevent abdominal obesity, overweight and overall obesity",
    "10.Be physically active and exercise regularly to maintain good health",
    "11.Restrict salt intake",
    "12.Consume safe and clean foods",
    "13.Adopt appropriate pre-cooking and cooking methods",
    "14.Drink adequate quantity of water",
    "15.Minimize the consumption of high fat, sugar, salt (HFSS) and ultra-processed foods (UPF)",
    "16.Include nutrient-rich foods in the diets of the elderly for health and wellness",
    "17.Read information on food labels to make informed and healthy food choices",
];


function DietaryResponsiveMobile() {
    const theme = createTheme();
    const listItemHeight = 80;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate(); // Use useNavigate hook

    // const navigateToPDFPage = (pageNumber) => {
    //     const url = `${pdfFile}#page=${pageNumber}`;
    //     window.location.href = url;
    // };

    const navigateToPDFPage = (pageNumber) => {
        const url = `${pdfFile}#page=${pageNumber}`;
        console.log(`Navigating to URL: ${url}`);
        window.location.href = url;
    };
    
    const handleItemClick = (event, pageNumber) => {
        event.preventDefault();
        navigateToPDFPage(pageNumber);
    };
    
    
    return (
        <div style={{ overflowX: "hidden" }} id="backToTop">
            <React.Fragment>
                <CssBaseline />
                <Container fixed m={2} maxWidth="lg" alignItems="center" justifycontent="center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
                        <Typography level="h4" gutterBottom m={2}>
                            Dietary Guidelines
                        </Typography>

                        <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
                    </div>

                    {/* <Grid
            container
            spacing={2}
            mt={2}
            mb={3}
            sx={{
              backgroundImage: `url(${gb})`,
              backgroundSize: "26% 100%", // Adjust the percentage to make the image smaller
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
            }}
          > */}
                    <Grid item xs={12} alignItems="flex-start" mt={0}>
                        <List
                            sx={{
                                alignItems: "start",
                                "--ListItem-paddingX": "0.6em",
                                "--ListDivider-gap": "1px",
                                "--ListItem-minHeight": `${listItemHeight}px`,
                            }}
                        >
                            {/* Guideline 1 */}
                            {/* <ListItem
                  onClick={() => navigateToPDFPage(19)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "100%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "100%",
                      backgroundColor: "#63b0b7",
                      border: "none",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: "calc(100% - 149px)",
                      top: 0,
                      width: "150px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#ffffff",
                        fontWeight: "400",
                      }}
                      level="body1"
                    >
                      Eat a variety of foods to ensure a balanced diet
                    </Typography>
                  </ListItemText>
                </ListItem> */}
                            <ListItem
                                // onClick={(event) => handleItemClick(event, 19)}
                                // onTouchStart={(event) => handleItemClick(event, 19)}
                                onClick={() => navigate('/DietaryGuidelinesHome')}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%", // Set the width as needed
                                    // "&.rectangle-container": {
                                    //     position: "relative",
                                    //     width: "100%",
                                    //     backgroundColor: "#63b0b7",
                                    //     border: "none",
                                    //     height: `${listItemHeight}px`,
                                    // },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            // className="rectangle-container"
                            >
                                {/* <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div> */}
                                {/* <Avatar
                                    sx={{
                                        bgcolor: "#63b0b7",
                                        color: "white",
                                        width: 50,
                                        height: 50,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                        fontSize: "70px"
                                    }}
                                >
                                    1
                                </Avatar> */}

                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            fontSize: "1.15em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                1.
                                            </Avatar>
                                        </span>

                                        Eat a variety of foods to ensure a balanced diet
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd1}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>

                            {/* Guideline 1 */}
                            {/* <ListItem
                                onClick={() => navigateToPDFPage(39)}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "72.5%", // Set the width as needed
                                    borderLeft: "1px solid #63b0b7",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.5em",
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: "#c76c20",
                                        color: "white",
                                        width: 30,
                                        height: 30,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    3
                                </Avatar>
                                <ListItemText>
                                    <Typography
                                        sx={{
                                            width: "95%",
                                            textAlign: "justify",
                                            fontSize: "1em",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                        }}
                                        level="body1"
                                    >
                                        Ensure exclusive breastfeeding for the first six months and continue
                                        breastfeeding till two years and beyond
                                    </Typography>
                                </ListItemText>
                            </ListItem> */}
                            <ListItem
                                onClick={() => console.log("guideline 2")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%", // Set the width as needed
                                    // "&.rectangle-container": {
                                    //     position: "relative",
                                    //     width: "100%",
                                    //     backgroundColor: "#63b0b7",
                                    //     border: "none",
                                    //     height: `${listItemHeight}px`,
                                    // },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            // className="rectangle-container"
                            >
                                {/* <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div> */}
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd2}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.15em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                2.
                                            </Avatar>
                                        </span>
                                        Ensure provision of extra food and healthcare during pregnancy and lactation
                                    </Typography>
                                </ListItemText>
                            </ListItem>

                            {/* Guideline 2 */}
                            {/* <ListItem
                                onClick={() => navigateToPDFPage(55)}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "red",
                                    position: "relative",
                                    width: "75%", // Set the width as needed
                                    "&.rectangle-container": {
                                        position: "relative",
                                        width: "75%",
                                        backgroundColor: "#63b0b7",
                                        height: `${listItemHeight}px`,
                                    },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                                className="rectangle-container"
                            >
                                <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 30,
                                        height: 30,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    5
                                </Avatar>
                                <ListItemText>
                                    <Typography
                                        sx={{
                                            marginRight: "0.8em",
                                            textAlign: "justify",
                                            fontSize: "1em",
                                            color: "#ffff",
                                            fontWeight: "400",
                                        }}
                                        level="body1"
                                    >
                                        Ensure adequate and appropriate diets for children and adolescents both in
                                        health and sickness
                                    </Typography>
                                </ListItemText>
                            </ListItem> */}
                            <ListItem
                                onClick={() => console.log("guideline 3")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%", // Set the width as needed
                                    // "&.rectangle-container": {
                                    //     position: "relative",
                                    //     width: "100%",
                                    //     backgroundColor: "#63b0b7",
                                    //     border: "none",
                                    //     height: `${listItemHeight}px`,
                                    // },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            // className="rectangle-container"
                            >
                                {/* <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div> */}


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "start",
                                            fontSize: "1.15em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                3.
                                            </Avatar>
                                        </span>
                                        Ensure exclusive breastfeeding for the first six months and continue breastfeeding
                                        till two years and beyond
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd3}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>
                            {/* Guideline 4 */}
                            <ListItem
                                onClick={() => console.log("guideline 4")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%", // Set the width as needed
                                    // "&.rectangle-container": {
                                    //     position: "relative",
                                    //     width: "100%",
                                    //     backgroundColor: "#63b0b7",
                                    //     border: "none",
                                    //     height: `${listItemHeight}px`,
                                    // },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            // className="rectangle-container"
                            >
                                {/* <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div> */}
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd4}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.15em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                4.
                                            </Avatar>
                                        </span>
                                        Start feeding homemade semi-solid complementary foods to the infant
                                        soon a fter six months of age
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* Guideline 5 */}
                            <ListItem
                                onClick={() => console.log("guideline 5")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%", // Set the width as needed
                                    // "&.rectangle-container": {
                                    //     position: "relative",
                                    //     width: "100%",
                                    //     backgroundColor: "#63b0b7",
                                    //     border: "none",
                                    //     height: `${listItemHeight}px`,
                                    // },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            // className="rectangle-container"
                            >
                                {/* <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div> */}


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "start",
                                            fontSize: "1.15em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                5.
                                            </Avatar>
                                        </span>
                                        Ensure adequate and appropriate diets for children and adolescents both in
                                        health and sickness
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd5}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>
                            {/* Guideline 6 */}
                            <ListItem
                                onClick={() => console.log("guideline 6")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%", // Set the width as needed
                                    // "&.rectangle-container": {
                                    //     position: "relative",
                                    //     width: "100%",
                                    //     backgroundColor: "#63b0b7",
                                    //     border: "none",
                                    //     height: `${listItemHeight}px`,
                                    // },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            // className="rectangle-container"
                            >
                                {/* <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div> */}
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd6}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.15em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                6.
                                            </Avatar>
                                        </span>
                                        Eat plenty of vegetables and legumes
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* Guideline 7 */}
                            <ListItem
                                onClick={() => console.log("guideline 7")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%", // Set the width as needed
                                    // "&.rectangle-container": {
                                    //     position: "relative",
                                    //     width: "100%",
                                    //     backgroundColor: "#63b0b7",
                                    //     border: "none",
                                    //     height: `${listItemHeight}px`,
                                    // },
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            // className="rectangle-container"
                            >
                                {/* <div
                                    className="rectangle"
                                    style={{
                                        position: "absolute",
                                        left: "calc(100% - 149px)",
                                        top: 0,
                                        width: "150px",
                                        height: `${listItemHeight}px`,
                                        backgroundColor: "#ffffff",
                                    }}
                                ></div> */}


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            fontSize: "1.1em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                7.
                                            </Avatar>
                                        </span>
                                        Use oils/fats in moderation; choose a variety of oil seeds, nuts, nutricereals
                                        and legumes to meet daily needs of fats and essential fatty acids (EFA)
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd7}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>
                            {/* Guideline 8 */}
                            <ListItem
                                onClick={() => console.log("guideline 8")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd8}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.15em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                8.
                                            </Avatar>
                                        </span>
                                        Obtain good quality proteins and essential amino acids (EAA) through appropriate
                                        combination of foods and avoid protein supplements to build muscle mass
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* Guideline 9 */}
                            <ListItem
                                onClick={() => console.log("guideline 9")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            fontSize: "1.1em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                9.
                                            </Avatar>
                                        </span>
                                        Adopt a healthy lifestyle to prevent abdominal obesity, overweight and
                                        overall obesity
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd9}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>
                            {/* Guideline 10 */}
                            <ListItem
                                onClick={() => console.log("guideline 10")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd10}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.1em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                10.
                                            </Avatar>
                                        </span>
                                        Be physically active and exercise regularly to maintain good health
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* Guideline 11 */}
                            <ListItem
                                onClick={() => console.log("guideline 11")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            fontSize: "1.1em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                11.
                                            </Avatar>
                                        </span>
                                        Restrict salt intake
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd11}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>
                            {/* Guideline 12 */}
                            <ListItem
                                onClick={() => console.log("guideline 12")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd12}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.1em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                12.
                                            </Avatar>
                                        </span>
                                        Consume safe and clean foods
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* Guideline 13 */}
                            <ListItem
                                onClick={() => console.log("guideline 13")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            fontSize: "1.1em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                13.
                                            </Avatar>
                                        </span>
                                        Adopt appropriate pre-cooking and cooking methods
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd13}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>
                            {/* Guideline 14 */}
                            <ListItem
                                onClick={() => console.log("guideline 14")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd14}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.1em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                14.
                                            </Avatar>
                                        </span>
                                        Drink adequate quantity of water
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* Guideline 15 */}
                            <ListItem
                                onClick={() => console.log("guideline 15")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            fontSize: "1.1em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                15.
                                            </Avatar>
                                        </span>
                                        Minimize the consumption of high fat, sugar, salt (HFSS) and
                                        ultra-processed foods (UPFs)
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd15}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>
                            {/* Guideline 16 */}
                            <ListItem
                                onClick={() => console.log("guideline 16")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    color: "",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        // marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd16}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>


                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            color: "#c76c20",
                                            fontWeight: "500",
                                            padding: 1,
                                            fontSize: "1.1em",
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 27, // Adjust width
                                                    height: 27, // Adjust height
                                                    fontSize: "1.3em", // Adjust font size
                                                    bgcolor: "#ffff", // Background color
                                                    color: "#c76c20", // Text color
                                                }}
                                            >
                                                16.
                                            </Avatar>
                                        </span>
                                        Include nutrient-rich foods in the diets of the elderly for health and wellness
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* Guideline 17 */}
                            <ListItem
                                onClick={() => console.log("guideline 17")}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#63b0b7",
                                    position: "relative",
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                                        transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                                        cursor: "pointer",
                                        padding: "0.8em",
                                    },
                                }}
                            >
                                <ListItemText>
                                    <Typography
                                        sx={{
                                            textAlign: "justify",
                                            fontSize: "1.1em",
                                            color: "#ffffff",
                                            fontWeight: "500",
                                            padding: 1,
                                        }}
                                        level="body1"
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                marginRight: '0.5em', // Adjust spacing as needed
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 26, // Adjust width
                                                    height: 26, // Adjust height
                                                    fontSize: "1.2em", // Adjust font size
                                                    bgcolor: "#63b0b7", // Background color
                                                    color: "#ffffff", // Text color
                                                }}
                                            >
                                                17.
                                            </Avatar>
                                        </span>
                                        Read information on food labels to make informed and healthy food choices
                                    </Typography>
                                </ListItemText>
                                <Avatar
                                    sx={{
                                        bgcolor: "white",
                                        color: "#63b0b7",
                                        width: 60,
                                        height: 60,
                                        marginRight: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    <img
                                        src={gd17}
                                        alt="Your Alt Text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Avatar>
                            </ListItem>

                        </List>
                    </Grid>

                    
                    {/* </Grid> */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
                        <Typography level="h4" gutterBottom m={2}>
                            Dietary Guidelines pdf
                        </Typography>

                        <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
                    </div>
                    <iframe
                        src="https://drive.google.com/file/d/1sWD0KBdgQdthedlUYKY3FCUMMVjtmYPx/preview"
                        width="100%"
                        height="900px"
                        title="PDF Viewer"
                    />

                    
                </Container>
            </React.Fragment>
        </div>
    )
}

export default DietaryResponsiveMobile