import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import SkimmedMilkPowder from "../../../../../../assets/FoodSources/Carbohydrate/Dairy/1_SkimmedMilkPowder.jpg"
import CowMilk from "../../../../../../assets/FoodSources/Carbohydrate/Dairy/2_Cowmilk.jpg"
import khoa from "../../../../../../assets/FoodSources/Carbohydrate/Dairy/3_Khoa.jpg"
import Paneer from "../../../../../../assets/FoodSources/Carbohydrate/Dairy/4_Paneer.jpg"
import BuffaloMilk from "../../../../../../assets/FoodSources/Carbohydrate/Dairy/5_BuffaloMilk.jpg"
import Cheese from "../../../../../../assets/FoodSources/Carbohydrate/Dairy/6_Cheese.jpg"
import { Typography } from '@mui/material';

export default function Dairy() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: SkimmedMilkPowder,
    title: 'Skimmed Milk powder (cow’s milk)',
    author: '51 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: CowMilk,
    title: 'Whole milk (cow’s milk)',
    author: '38 per 100gm',
  },
  {
    img: khoa,
    title: 'Khoa',
    author: '16.53 per 100gm',
  },
  {
    img: Paneer,
    title: 'Panner',
    author: '12.41 per 100gm',
    cols: 2,
  },
  {
    img: BuffaloMilk,
    title: 'Milk, buffalo',
    author: '8.39 per 100gm',
    cols: 2,
  },
  {
    img: Cheese,
    title: 'Cheese',
    author: '6.3 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  }
];