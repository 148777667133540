import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Spinner from "../../../components/spinner";
import pptxgen from "pptxgenjs";

// require("highcharts/highcharts-3d")(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);
// require("highcharts/modules/export-data")(Highcharts);
// require("highcharts/modules/drilldown")(Highcharts);

require("highcharts"); // Importing the main Highcharts library
require("highcharts/modules/data"); // Importing the data module
require("highcharts/modules/drilldown"); // Importing the drilldown module
require("highcharts/modules/exporting"); // Importing the exporting module
require("highcharts/modules/export-data"); // Importing the export-data module
require("highcharts/modules/accessibility"); // Importing the accessibility module
require("highcharts/modules/offline-exporting");
function BarChart(props) {
  const { mapKey, filteredData } = props;
  const [sortOption, setSortOption] = useState("ascending");
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setLoading(true);
    // simulate fetching data
    setTimeout(() => {
      let data = [];
      if (mapKey && mapKey !== "india") {
        data = filteredData.filter((d) => d.area && d.area.toLowerCase() === mapKey.toLowerCase());
      } else {
        data = filteredData.filter(
          (d) => d.area === d.area_name && (d.area_level === 2 || d.area_level === 1)
        );
      }

      if (data.length === 0) {
        setLoading(false);
        setChartData([]);
        return;
      }

      // if (sortOption === "ascending") {
      //   data.sort((a, b) => a.value - b.value);
      // } else if (sortOption === "descending") {
      //   data.sort((a, b) => b.value - a.value);
      // } else if (sortOption === "alphabetical") {
      //   data.sort((a, b) => a.area_name.localeCompare(b.area_name));
      // }

      const chartData = data.filter((item) => {
        return (
          item.value !== "NA" ||
          item.total !== "NA" ||
          item.rural !== "NA" ||
          item.urban !== "NA" ||
          item.male !== "NA" ||
          item.female !== "NA"
        );
      });

      setLoading(false);
      setChartData(chartData);
    }, 2000);
  }, [mapKey, filteredData, sortOption]);

  if (loading) {
    return <Spinner />;
  }

  if (chartData.length === 0) {
    return null;
  }
  const sourceFootnote = chartData[0]?.source_footnote;
  const footnote1 = chartData[0]?.footnote1;
  const footnote2 = chartData[0]?.footnote2;
  const footnote3 = chartData[0]?.footnote3;
  const boundaries = chartData[0]?.boundaries;

  let captionText =
    "Note:" + "\n" + sourceFootnote + "\n" + footnote1 + "\n" + footnote2 + "\n" + footnote3;
  const chartOptions = {
    chart: {
      type: "column",
      height: "550vh",
      zoomType: "x",
      events: {
        load: function () {
          if (this.options.chart.forExport) {
            Highcharts.each(this.series, function (series) {
              series.update(
                {
                  dataLabels: {
                    enabled: true,
                  },
                },
                false
              );
            });
            this.redraw();
          }
        },
        drilldown: function (e) {
          this.xAxis[0].update({
            labels: {
              rotation: 0,
              style: {
                fontSize: "10px",
                fontWeight: "bold",
              },
            },
          });
        },
        drillup: function (e) {
          this.xAxis[0].update({
            labels: {
              rotation: -90,
              style: {
                fontSize: "10px",
              },
            },
          });
        },
      },

      // options3d: {
      //   enabled: true,
      //   alpha: 0,
      //   beta: 15,
      //   depth: 60,
      //   viewDistance: 500,
      // },
    },
    credits: {
      text: "Nutrtion Atlas 2.0,<br/> Powered by ICMR-National Institute of Nutrition",
      href: "http://nutritionatlasindia.in/",
      position: {
        align: "right",
        x: -10,
        y: -15,
      },
    },
    exporting: {
      fallbackToExportServer: false,
      width: 800,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "separator",
            {
              text: "Download PPTX",
              onclick: function () {
                const pptx = new pptxgen();

                const slide = pptx.addSlide();

                // Create a temporary div to render the chart
                const tempDiv = document.createElement("div");
                tempDiv.style.width = "800px"; // Adjust dimensions as needed
                tempDiv.style.height = "500px";

                // Render the chart inside the temporary div
                const chart = Highcharts.chart(tempDiv, chartOptions);

                // Get the SVG content of the chart
                const chartSVG = chart.getSVG();

                // Convert the SVG content to a data URL
                const chartDataURL = `data:image/svg+xml;base64,${btoa(
                  unescape(encodeURIComponent(chartSVG))
                )}`;

                // Add the image to the slide
                slide.addImage({
                  data: chartDataURL,
                  x: 1,
                  y: 0,
                  w: 8,
                  h: 5,
                });

                pptx.writeFile(chartData[0].title);
              },
            },
          ],
        },
      },
    },
    caption: {
      text: captionText,
      align: "center",
      verticalAlign: "bottom",
      useHTML: true,
      style: {
        fontSize: "10px",
        color: "#000000",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "normal",
      },
      position: {
        align: "center",
        verticalAlign: "bottom",
        y: -10,
      },
    },
    title: {
      align: "center",
      text: `${chartData[0].title}`,
      style: {
        fontSize: "16px",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "normal",
      },
    },
    subtitle: {
      style: {
        fontSize: "10px",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "bold",
      },
      align: "center",
      text: `${chartData[0].area}, Source:${chartData[0].source_name}`,
    },
    accessibility: {
      announceNewData: {
        enabled: false,
      },
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -90,
        style: {
          fontSize: "10px",
        },
        step: 1,
      },
    },
    //   xAxis: {
    //     categories: data.length > 0 ? data.map((item) => item.area_name) : [],
    //     title: {
    //       text: `District names in ${data[0].area}`,
    //     },
    //  },
    yAxis: {
      title: {
        text: `Prevalence of ${chartData[0].indicator_name} in (%)`,
        style: {
          fontSize: "12px",
        },
      },
      labels: {
        rotation: -90,
        style: {
          fontSize: "8px",
        },
      },
      gridLineWidth: 0, // Remove grid lines
      max: 100,
      tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    },
    legend: {
      enabled: false,
    },
    colorAxis: {
      reversed: false,

      stops: [
        [0, "#f4cf65"], // First color at 0% position
        [0.33, "#f48e32"], // Second color at 33% position
        [0.66, "#9e3a26"], // Third color at 66% position
        [1, "#3f1020"], // Third color at 100% position
      ],
    },
    plotOptions: {
      series: {
        colorByPoint: true,
        dataLabels: {
          enabled: true,
        },
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">{point.subindicator_name}</span>: <b>{point.y:.2f}</b><br/>',
    },
    series: [
      {
        name: "Back",
        colorByPoint: true,
        data: chartData.map((item, index) => ({
          name: item.area_name,
          indicator_name: item.indicator_name,
          subindicator_name: item.subindicator_name,
          y: item.value,
          drilldown: item.area_name,
          color:
            item.area_level === 1 && item.area_name === "India"
              ? "#267eb5" // Set a specific color if area_level is 1 and area_name is India
              : item.area_level === 2 && item.area_name === chartData[0].area
              ? "#267eb5" // Set a specific color if area_level is 2 and area_name matches chartData[0].area
              : null, // Default color if none of the conditions match
        })),
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // one decimal
          y: -25, // 10 pixels down from the top
          style: {
            fontSize: "8px",
            fontFamily: "Verdana, sans-serif",
            textDecoration: "none", // Remove underline
          },
        },
      },
    ],
    // drilldown: {
    //   series: chartData.map((item) => ({
    //     id: item.area_name,
    //     indicator_name: item.indicator_name,
    //     name: item.area_name,
    //     data: [
    //       ["Total", item.value !== "NA" ? item.value : null],
    //       ["Urban", item.urban !== "NA" ? item.urban : null],
    //       ["Rural", item.rural !== "NA" ? item.rural : null],
    //       ["Male", item.male !== "NA" ? item.male : null],
    //       ["Female", item.female !== "NA" ? item.female : null],
    //     ],
    //   })),
    // },
    drilldown: {
      breadcrumbs: {
        position: {
          align: "right",
        },
      },
      activeAxisLabelStyle: {
        textDecoration: "none",
        fontWeight: "bold",
        color: "#000000",
      },
      activeDataLabelStyle: {
        textDecoration: "none",
        fontWeight: "bold",
        color: "#000000",
      },
      series: chartData.map((item) => {
        const data = [
          ["Total", item.value !== "NA" ? item.value : null],
          ["Urban", item.urban !== "NA" ? item.urban : null],
          ["Rural", item.rural !== "NA" ? item.rural : null],
          ["Male", item.male !== "NA" ? item.male : null],
          ["Female", item.female !== "NA" ? item.female : null],
        ];

        // Filter out columns with all values as "NA" or null
        const filteredData = data.filter(
          ([_, value]) => value !== "NA" && value !== null && !isNaN(value)
        );

        return {
          id: item.area_name,
          indicator_name: item.indicator_name,
          subindicator_name: item.subindicator_name,
          name: item.area_name,
          data: filteredData,

          dataLabels: {
            enabled: true,
            rotation: 0,
            align: "center",
            format: "{point.y:.1f}", // one decimal
            y: -10, // 10 pixels down from the top
            style: {
              fontSize: "10px",
              textDecoration: "none", // Remove underline
            },
          },
        };
      }),
    },
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      {/* <Compare /> */}
      {/* <CompareTrail /> */}
      {/* <Compare1 /> */}
    </>
  );
}

export default BarChart;
