import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Dates from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/1_Dates.jpg"
import ApricotDried from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/2_ApricotDried.jpg"
import Raisins from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/3_Raisins.jpg"
import DatesProcessed from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/4_DatesProcessed.jpg"
import Bael from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/5_Bael.jpg"
import Banana from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/6_Banana.jpg"
import Grapes from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/7_Grapes.jpg"
import CustardApple from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/8_CustardApple.jpg"
import Rambutan from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/9_Rambutan.jpg"
import Fig from "../../../../../../assets/FoodSources/Carbohydrate/Fruits/10_Fig.jpg"
import { Typography } from '@mui/material';

export default function Fruits() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: Dates,
    title: 'Dates (dark brown and pale brown)',
    author: '84.87 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: ApricotDried,
    title: 'Apricot, dried',
    author: '78.24 – 74.8 per 100gm',
  },
  {
    img: Raisins,
    title: 'Raisins, dried',
    author: '76.9 per 100gm',
  },
  {
    img: DatesProcessed,
    title: 'Dates, processed',
    author: '74.27 – 68.43per 100gm',
    cols: 2,
  },
  {
    img: Bael,
    title: 'Bael fruit',
    author: '74.91 – 72.67 per 100gm',
    cols: 2,
  },
  {
    img: Banana,
    title: 'Banana (red, montham, robusta, poovam)',
    author: '72.63 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Grapes,
    title: 'Grapes',
    author: '71.29 – 68.79 per 100gm',
  },
  {
    img: CustardApple,
    title: 'Custard apple',
    author: '70.4 per 100gm.4',
  },
  {
    img: Rambutan,
    title: 'Rambutan',
    author: '67.95 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: Fig,
    title: 'Fig',
    author: '67.68 per 100gm',
  }
];