import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setApiData } from './dropdownSlice';
import NotFoundPage from '../NotFoundPage';
import { endpoints } from '../../../../utils/config';

function SelectedDropdownData(props) {
    const [selectedData, setSelectedData] = useState(props);
    const [hasError, setHasError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedData(props);
    }, [props]);

    useEffect(() => {
        async function fetchData() {
            if (!selectedData['3']) {
                // source code not selected
                dispatch(setApiData(null));
                return;
            }
            try {
                const url = `${endpoints.dashboard.url}?lifecycle=${selectedData['0']}&indicator_id=${selectedData['1']}&subindicator_id=${selectedData['2']}&source_code=${selectedData['3']}`;
                const response = await axios.get(url);

                const updatedResults = response.data.results.map(item => ({
                    ...item,
                    urban: !isNaN(item.urban) ? parseFloat(item.urban) : "NA",
                    rural: !isNaN(item.rural) ? parseFloat(item.rural) : "NA",
                    value: !isNaN(item.value) ? parseFloat(item.value) : "NA",
                    total: !isNaN(item.total) ? parseFloat(item.total) : "NA",
                    male: !isNaN(item.male) ? parseFloat(item.male) : "NA",
                    female: !isNaN(item.female) ? parseFloat(item.female) : "NA",
                }));

                const updatedResponse = {
                    ...response.data,

                    results: updatedResults,
                };

                dispatch(setApiData(updatedResponse));
                setHasError(false); 
            } catch (error) {
                console.error(error.message);
                setHasError(true);
            }
        }

        fetchData();
    }, [dispatch, selectedData['0'], selectedData['1'], selectedData['2'], selectedData['3']]);

    if (hasError) {
        return <NotFoundPage />;
        
    }
    
    return null
}

export default SelectedDropdownData;