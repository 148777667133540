// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import dna from '../../../../../assets/images/icons/minerals/Functions/dna.png';
import thyroid from '../../../../../assets/images/icons/minerals/Functions/thyroid.png';
import antioxidant from '../../../../../assets/images/icons/minerals/Functions/antioxidant.png';
import WeakImmunity from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/WeakImmunity.png';
import cardiovascular_effects from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png';
import impaired_reproductive_function from '../../../../../assets/images/icons/minerals/Deficiency/impaired_reproductive_function.png';
import goiter  from '../../../../../assets/images/icons/minerals/Deficiency/goiter.png';
import neurological_symptoms from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/nerve_damage.png';
import musculoskeletal_abnormalities  from '../../../../../assets/images/icons/minerals/Deficiency/musculoskeletal_abnormalities.png';
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import organMeat from "../../../../../assets/FoodSources/Minerals/Sources/organMeats.png";
import Millets from "../../../../../assets/FoodSources/Minerals/Sources/Millets.png";
import vegetables from "../../../../../assets/FoodSources/Minerals/Sources/vegetables.png";
import milkProduct from "../../../../../assets/FoodSources/Minerals/Sources/milkProduct.png";
import cereal from "../../../../../assets/FoodSources/Minerals/Sources/cereal.png";




export default function Selenium() {
    return (
        <>

            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of selenium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={antioxidant} />
                                </ListItemAvatar>
                                <ListItemText primary="Selenium is a cofactor for several antioxidant enzymes which provides protection to cells from oxidative damage." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={dna} />
                                </ListItemAvatar>
                                <ListItemText primary="It is necessary for DNA synthesis and repair." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={thyroid} />
                                </ListItemAvatar>
                                <ListItemText primary="It is needed for healthy immune system and thyroid hormone metabolism." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of selenium?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                The rich sources of selenium include organ meat and sea foods.
                            </ListItem>
                            <ListItem>
                                Cereals and millets, dairy products and vegetables are also a major dietary sources of selenium.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        mt={0.5}
                        mb={1}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="organ meat">
                                <Avatar src={organMeat} size="lg" alt="organ meat" variant="square" />
                            </Tooltip>
                            <Tooltip title="sea foods">
                                <Avatar src={fish} size="lg" alt="sea foods" variant="square" />
                            </Tooltip>
                            <Tooltip title="cereals">
                                <Avatar src={cereal} alt="Cereals and millets" variant="square" />
                            </Tooltip>
                            <Tooltip title="millets">
                                <Avatar src={Millets} alt="millets" variant="square" />
                            </Tooltip>
                            <Tooltip title="dairy products">
                                <Avatar src={milkProduct} alt="pork" variant="square" />
                            </Tooltip>
                            <Tooltip title="vegetables">
                                <Avatar src={vegetables} alt="vegetables" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>

            </Grid>
            <Grid item lg={6} md={6} sm={12}>
            <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt:1,
                        padding:"9px",
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is selenium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Selenium is mainly absorbed in the duodenum and caecum by active transprt through a sodium pump." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        padding:"9px",
                        mt: { xs: 1, sm:0},
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What is the recommended dietary intake of selenium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Human} />
                                </ListItemAvatar>
                                <ListItemText primary="The recommended intake of selenium required for adult man and adult woman is 40 µg/day." />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt:1,
                        padding:"9px",
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is selenium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Selenium is mainly absorbed in the duodenum and caecum by active transprt through a sodium pump." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                        What happens when you are deficient in selenium?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                            Selenium deficiency can cause various health issues, including impaired immune function, cardiovascular effects, reproductive and fertility problems, thyroid dysfunction, neurological symptoms, and musculoskeletal abnormalities.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="impaired immune function">
                                <Avatar src={WeakImmunity} size="lg" alt="impaired immune function" variant="square" />
                            </Tooltip>
                            <Tooltip title="cardiovascular effects">
                                <Avatar src={cardiovascular_effects} alt="cardiovascular effects" variant="square" />
                            </Tooltip>
                            <Tooltip title="reproductive and fertility problems">
                                <Avatar src={impaired_reproductive_function} alt="reproductive and fertility problems" variant="square" />
                            </Tooltip>
                            <Tooltip title="thyroid dysfunction">
                                <Avatar src={goiter} alt="thyroid dysfunction" variant="square" />
                            </Tooltip>
                            <Tooltip title="neurological symptoms">
                                <Avatar src={neurological_symptoms} alt="neurological symptoms" variant="square" />
                            </Tooltip>
                            <Tooltip title="musculoskeletal abnormalities">
                                <Avatar src={musculoskeletal_abnormalities} alt="musculoskeletal abnormalities" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
        </>
    );
}



