import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles, styled } from "@mui/material";

function createData(low, medium, high) {
  return { low, medium, high };
}

const ResponsiveTable = styled(TableContainer)(() => ({
  minWidth: 300,
}));


const StyledTableCell = styled(TableCell)(({ theme, color, isFirstColumn }) => ({
  backgroundColor: color,
  color:"#333",
  width: isFirstColumn ? '20%' : '40%', // Adjust the width as needed
}));

function GlycemicIndexTable () {
  return (
    <ResponsiveTable >
      <Table aria-label="responsive table">
      {/* <TableRow>
            <TableCell
            style={{ cursor: 'pointer',color: '#333', fontWeight: "bold", fontSize: "1rem" }} colSpan={2}>
            What counts as high, medium and low Glycaemic index (GI)?</TableCell>
          </TableRow> */}
        <TableBody>
          <TableRow>
            <StyledTableCell color="#cfec9c" isFirstColumn>
            Low <br />(GI value of 55 or lower)
            </StyledTableCell>
            <StyledTableCell color="#cfec9c">
              Whole foods such as unrefined grains, non-starchy vegetables, and fruits tend to have a lower GI.
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell color="#fff089" isFirstColumn>
              Medium <br /> (GI value between 56 and 69)
            </StyledTableCell>
            <StyledTableCell color="#fff089">
              Foods with moderate GI include Dosa, idli, Pongal, Muesli, and Millet porridge.
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell color="#ff89a5" isFirstColumn>
              High <br /> (GI value of 70 or above)
            </StyledTableCell>
            <StyledTableCell color="#ff89a5">
              Processed foods made with refined sugar and flour such as candy, bread, cake, and cookies have a high GI. White Rice is also high in GI.
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ResponsiveTable>
  );
}

export default GlycemicIndexTable ;