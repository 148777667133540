import * as React from "react";
import Sheet from "@mui/joy/Sheet";
// import "./Vitamins.css";

import { useTheme } from "@mui/material/styles";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    CardActions,
    CardHeader,
    CardMedia,
    Container,
    CssBaseline,
    Grid,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
} from "@mui/material";
// import ProteinIcon from "../../../../../../src/assets/images/icons/Protein/Protein.jpg";
import ProteinIcon from "../../../../../../src/assets/images/protein.png";
import BodytissuesIcon from "../../../../../../src/assets/images/icons/Protein/BodytissuesIcon.png";
import MuscleIcon from "../../../../../../src/assets/images/icons/Protein/MuscleIcon.png";
import AntibodyIcon from "../../../../../../src/assets/images/icons/Protein/antibody.png";
import RedBloodCellsIcon from "../../../../../../src/assets/images/icons/Protein/RedBloodCells.png";
import MovementIcon from "../../../../../../src/assets/images/icons/Protein/movement.png";
import DiaryIcon from "../../../../../../src/assets/FoodSources/Minerals/Sources/milk_product.png";
import EggIcon from "../../../../../../src/assets/FoodSources/Minerals/Sources/egg.png";
import PoultryIcon from "../../../../../../src/assets/images/icons/Protein/PoultryIcon.png";
import MeatIcon from "../../../../../../src/assets/images/icons/Protein/MeatIcon.png";
import FishIcon from "../../../../../../src/assets/images/icons/Protein/FishIcon.png";
import SoyIcon from "../../../../../../src/assets/images/icons/Protein/SoyIcon.png";
import GrainIcon from "../../../../../../src/assets/images/icons/Protein/GrainIcon.png";
import NutsSeedsIcon from "../../../../../../src/assets/FoodSources/Minerals/Sources/nuts_seeds.png";
import pulsesIcon from "../../../../../../src/assets/FoodSources/Minerals/Sources/lentils.png";
import green_beansIcon from "../../../../../../src/assets/FoodSources/Minerals/Sources/green_beans.png";
import SoyProductIcon from "../../../../../../src/assets/FoodSources/Minerals/Sources/tofu.png";
import BeansIcon from "../../../../../../src/assets/images/icons/Protein/BeansIcon.png";
import PeasIcon from "../../../../../../src/assets/images/icons/Protein/PeasIcon.png";
import NutsIcon from "../../../../../../src/assets/images/icons/Protein/NutsIcon.png";
import SeedsIcon from "../../../../../../src/assets/images/icons/Protein/SeedsIcon.png";
import VegetablesIcon from "../../../../../../src/assets/images/icons/Protein/VegetablesIcon.png";
import StuntingIcon from "../../../../../../src/assets/images/icons/minerals/Deficiency/growth.png";
import PoorMusculatureIcon from "../../../../../../src/assets/images/icons/Protein/PoorMusculatureIcon.png";
import EdemaIcon from "../../../../../../src/assets/images/icons/Protein/EdemaIcon.png";
import FragileHairIcon from "../../../../../../src/assets/images/icons/vitamins/Deficiency/VitaminB7/Thin_hair.png";
import SkinLesionsIcon from "../../../../../../src/assets/images/icons/Protein/SkinLesionsIcon.png";
import HormonalImbalanceIcon from "../../../../../../src/assets/images/icons/Protein/HormonalImbalanceIcon.png";
import MuscleLossIcon from "../../../../../../src/assets/images/icons/vitamins/Deficiency/VitaminB1/muscleWeakness.png";
import HairLossIcon from "../../../../../../src/assets/images/icons/Protein/HairLossIcon.png";
import Button from "@mui/joy/Button";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ProteinRdaTable from "./ProteinRdaTable";
import ProteinSourcesAccordion from './ProteinSourcesAccordion'
import { FcOk } from "react-icons/fc";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AiFillCheckCircle } from "react-icons/ai";




export default function Protein() {
    const theme = useTheme();
    const myRef = React.useRef(null);
    const buttonRef = React.useRef(null);
    const buttonRefat = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [openfat, setOpenfat] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseFat = () => {
        setOpenfat(false);
    };

    const handleClickScroll = () => {
        const element = document.getElementById("backToTop");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <div style={{ overflowX: "hidden" }} id="backToTop">
            <React.Fragment>
                <CssBaseline />
                <Container fixed m={2} maxWidth="lg">
                    <Typography
                        level="h1"
                        fontSize="2em"
                        color="#000"
                        fontWeight="bold"
                        marginTop="0.5em"
                    >
                        Protein
                    </Typography>
                    <Grid container direction="row">
                        <Grid item lg={5} md={5} sm={12}>
                            <Box display="flex" justifyContent="center">
                                <img src={ProteinIcon} alt="Protein" width="70%" />
                            </Box>
                        </Grid>
                        <Grid item lg={7} md={7} sm={12}>
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    marginTop: "20px",
                                    maxHeight: { xs: "100%", md: "300px" },
                                    border: "none",
                                    boxShadow: "none",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <CardContent>
                                    <ul align="justify">
                                        <li><Typography level="h6" align="justify" gutterBottom> Amino acids are building blocks of proteins which are vital for various body functions.</Typography>
                                        </li>
                                        <li><Typography level="h6" align="justify" gutterBottom> After consumption,  protein is converted to amino acids by the body and then absorbed.</Typography>
                                        </li>
                                        <li><Typography level="h6" align="justify" gutterBottom>
                                            Skin, bone, muscle, hair, nails, and internal organs are largely made of protein.</Typography>
                                        </li>
                                        <li><Typography level="h6" align="justify" gutterBottom>
                                            Each gram of protein provides 4 Kcal.</Typography>
                                        </li>
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* <Grid container direction="row" alignItems="center">
                        <Grid item lg={4} md={4} sm={12}>
                            <Box display="flex" justifyContent="center">
                                <img src={ProteinIcon} alt="Protein" width="80%" />
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={12}>
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    marginTop: "20px",
                                    maxHeight: { xs: "100%", md: "300px" },
                                    border: "none",
                                    boxShadow: "none",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        Amino acids are building blocks of proteins which are vital for various body functions. After consumption,  protein is converted to amino acids by the body and then absorbed. Skin, bone, muscle, hair, nails, and internal organs are largely made of protein. Each gram of protein provides 4 Kcal.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid> */}
                    <Grid md={12} id="dropdownSection">
                        <Fab
                            color="primary"
                            aria-label="scroll back to top"
                            onClick={handleClickScroll}
                            style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
                        >
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </Grid>
                    <Grid md={12} item>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
                            <Typography level="h5" gutterBottom m={2}>
                                Types of amino acids
                            </Typography>

                            <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
                        </div>
                    </Grid>
                    <Grid container alignItems="stretch">
                        <Grid xs={12} md={6} item >

                            <Card sx={{
                                height: "100%",
                                boxShadow: "none",
                                p: 1,
                                borderRadius: '1em'
                            }} >
                                <CardHeader title="Essential amino acids" sx={{ backgroundColor: "#9494f1", borderTopLeftRadius: '0.5em', borderTopRightRadius: '0.5em' }} />
                                <CardContent>
                                    <List sx={{ p: 1, width: "100%", bgcolor: "background.paper", border: "0.5px solid #9494f1", borderBottomLeftRadius: '0.5em', borderBottomRightRadius: '0.5em' }}>
                                        <ListItem alignItems="flex-start" sx={{ textAlign: "justify" }}>
                                            <ListItemText primary="Essential amino acids are required for the body to carry out important body functions. As these cannot be synthesized in the body, it must to be obtained from the diet. There are nine essential amino acids histidine, isoleucine, lysine, methionine, phenylalanine, threonine, valine, leucine, tyrosine." />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Card sx={{ height: "100%", boxShadow: "none", p: 1 }}>
                                <CardHeader
                                    title="Non essential amino acids
"
                                    sx={{ backgroundColor: "#9494f1", borderTopLeftRadius: '0.5em', borderTopRightRadius: '0.5em' }}
                                />
                                <CardContent>
                                    <List sx={{ p: 1, width: "100%", bgcolor: "background.paper", border: "0.5px solid #9494f1", borderBottomLeftRadius: '0.5em', borderBottomRightRadius: '0.5em' }}>
                                        <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                                            <ListItemText primary="Non essential amino acids are those that can be made in the body. These amino acids are obtained from the food containing essential amino acids or during the breakdown of body proteins." />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid md={12} item>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
                            <Typography level="h5" gutterBottom m={2}>
                                Complete vs. Incomplete protein: What’s the difference?
                            </Typography>

                            <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} item>

                        <Box sx={{ textAlign: "justify", }}>
                            <Card variant="outlined" sx={{ height: "100%", padding: 2 }} >
                                <CardContent>
                                    {/* <Typography variant="h5" sx={{ textAlign: "left", marginBottom: 1, fontSize: "1.3rem", fontWeight: "bold" }}>
                            Complete vs. Incomplete protein: What’s the difference?
                        </Typography> */}
                                    <Typography>
                                        Dietary proteins have different combinations of amino acids and they are categorised according to the amount of essential amino acids they contain.
                                    </Typography>
                                    <Typography mt={1}>
                                        <Box
                                            component="span"
                                            sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF5722", color: "#FFFFFF", }}
                                        >
                                            <strong>1.Complete proteins:</strong>
                                        </Box>{" "}  Proteins that adequately supply each of the nine essential amino acids are known as complete proteins. Complete protein sources come from animal products such dairy, eggs, meat, poultry, fish, and soy.
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        mt={2}
                                        mb={2}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Tooltip title="Dairy">
                                                <Avatar
                                                    src={DiaryIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Dairy">
                                                <Avatar
                                                    src={EggIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="eggs"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Meat">
                                                <Avatar
                                                    src={MeatIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Poultry">
                                                <Avatar
                                                    src={PoultryIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Fish">
                                                <Avatar
                                                    src={FishIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Soy">
                                                <Avatar
                                                    src={SoyIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                        </div>
                                    </Box>
                                    <Typography mt={1}>
                                        <Box
                                            component="span"
                                            sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF5722", color: "#FFFFFF", }}
                                        >
                                            <strong>2.Incomplete proteins:</strong>
                                        </Box>{" "}  Proteins that lack one or more of the essential amino acids are considered incomplete proteins. The majority of plant-based foods, including grains, beans, peas, nuts, seeds, and vegetables, are sources of incomplete protein.
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        mt={2}
                                        mb={2}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Tooltip title="Grains">
                                                <Avatar
                                                    src={GrainIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Beans">
                                                <Avatar
                                                    src={BeansIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Peas">
                                                <Avatar
                                                    src={PeasIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Nuts">
                                                <Avatar
                                                    src={NutsIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Seeds">
                                                <Avatar
                                                    src={SeedsIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Vegetables">
                                                <Avatar
                                                    src={VegetablesIcon}
                                                    sx={{ width: '2.3em', height: '2.3em', mr: 1 }} // Adjust width, height, and margin as needed
                                                    alt="Whole Grains"
                                                    variant="square"
                                                />
                                            </Tooltip>
                                        </div>
                                    </Box>
                                    <Typography mt={1}>Two or more incomplete protein sources can be combined to get all the nine essential amino acids. For instance, nuts and legumes are poor in the amino acid methionine, whereas grains are low in the amino acid lysine. When grains and legumes are consumed together (such as  khichdi/Pongal, peanut butter on whole wheat bread), they form a complete protein.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid container direction="row">
                        <Grid xs={12} md={6} p={1} item>
                            <Card
                                variant="outlined"
                                sx={{
                                    //  width: 320,
                                    // to make the card resizable
                                    overflow: "auto",
                                    resize: "horizontal",
                                    py: 1,
                                }}
                            >
                                <Typography level="h5" fontWeight="lg" align="start">
                                    What are the functions of protein?
                                </Typography>
                                <CardContent>
                                    <List sx={{ textAlign: 'left', '& .MuiTypography-root': { fontSize: '1rem' } }}>
                                        <ListItem alignItems="center">
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={BodytissuesIcon} />
                                            </ListItemAvatar>
                                            <ListItemText primary="Protein is required to build and repair all body tissues." />
                                        </ListItem>
                                        <ListItem alignItems="center">
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={MuscleIcon} />
                                            </ListItemAvatar>
                                            <ListItemText primary="It is used for building muscles and organs." />
                                        </ListItem>
                                        <ListItem alignItems="center">
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={AntibodyIcon} />
                                            </ListItemAvatar>
                                            <ListItemText primary="To make hormones and antibodies." />
                                        </ListItem>
                                        <ListItem alignItems="center">
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={RedBloodCellsIcon} />
                                            </ListItemAvatar>
                                            <ListItemText primary="To make new blood cells and necessary enzymes." />
                                        </ListItem>
                                        <ListItem alignItems="center">
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={MovementIcon} />
                                            </ListItemAvatar>
                                            <ListItemText primary="It assists in the movement of substances in the body." />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                            <Card
                                variant="outlined"
                                sx={{
                                    //  width: 320,
                                    // to make the card resizable
                                    overflow: "auto",
                                    resize: "horizontal",
                                    mt: 1,
                                }}
                            >
                                <CardContent>
                                    <Typography level="h5" fontWeight="lg" align="start">
                                        What are the good sources of proteins?
                                    </Typography>
                                    <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, }}>
                                        <ListItem>
                                            <ListItemText primary="The good sources of protein include: Milk and milk products  such as cheese, and yoghurt." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Animal foods such as egg, meat and poultry, seafood (especially fish and shellfish)." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Plant foods such as nuts and seeds, pulses and legumes (beans, peas, and lentils), and soy products." />
                                        </ListItem>
                                    </List>
                                </CardContent>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    mt={1.5}
                                    mb={0.5}
                                >
                                    <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1.5 }}>
                                        <Tooltip title="milk products such as cheese, and yoghurt.">
                                            <Avatar src={DiaryIcon} size="lg" alt="Milk" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="egg">
                                            <Avatar src={EggIcon} size="lg" alt="egg" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="meat">
                                            <Avatar src={MeatIcon} alt="meat" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="poultry">
                                            <Avatar src={PoultryIcon} alt="poultry" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="seafood (especially fish and shellfish)">
                                            <Avatar src={FishIcon} alt="fish" variant="square" />
                                        </Tooltip>
                                        <Tooltip sx={{
                                            display: { xs: 'none', sm: 'block' },
                                        }} title="nuts and seeds">
                                            <Avatar sx={{
                                                display: { xs: 'none', sm: 'block' },
                                            }} src={NutsSeedsIcon} alt="nuts" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="pulses">
                                            <Avatar sx={{
                                                display: { xs: 'none', sm: 'block' },
                                            }} src={pulsesIcon} alt="pulses" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="legumes (beans, peas, and lentils)">
                                            <Avatar sx={{
                                                display: { xs: 'none', sm: 'block' },
                                            }} src={green_beansIcon} alt="peas" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="soy products">
                                            <Avatar sx={{
                                                display: { xs: 'none', sm: 'block' },
                                            }} src={SoyProductIcon} alt="soy products" variant="square" />
                                        </Tooltip>
                                    </AvatarGroup>
                                </Box>
                                <Box
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        mt: 1,
                                    }}
                                >
                                    <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1.5 }}>
                                        <Tooltip title="nuts and seeds">
                                            <Avatar src={NutsSeedsIcon} alt="nuts" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="pulses">
                                            <Avatar src={pulsesIcon} alt="pulses" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="legumes (beans, peas, and lentils)">
                                            <Avatar src={green_beansIcon} alt="peas" variant="square" />
                                        </Tooltip>
                                        <Tooltip title="soy products">
                                            <Avatar src={SoyProductIcon} alt="soy products" variant="square" />
                                        </Tooltip>
                                    </AvatarGroup>
                                </Box>
                            </Card>
                            <Card
                                variant="outlined"
                                sx={{
                                    //  width: 320,
                                    // to make the card resizable
                                    overflow: "auto",
                                    resize: "horizontal",
                                    mt: 1
                                }}
                            >
                                <Typography level="h5" fontWeight="lg" align="start">
                                    Some tips to make sure that the protein you consume is low in fat
                                </Typography>
                                <CardContent>
                                    <List sx={{ width: "100%", '& > li': { marginBottom: '-8px' } }}>
                                        <ListItem alignitems="justify">
                                            <ListItemAvatar>
                                                <FcOk style={{ fontSize: '2em' }} />
                                                {/* <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#3db159" }} /> */}
                                            </ListItemAvatar>
                                            <ListItemText
                                                align="justify"
                                                primary="Animal foods are best source of proteins, however they are also high in saturated fat. Therefore, more often choose chicken with skin removed, fish and plant proteins over meat such as beef,  pork and lamb which have more saturated fat."
                                            />
                                        </ListItem>

                                        <ListItem alignitems="justify">
                                            <ListItemAvatar>
                                                <FcOk style={{ fontSize: '2em' }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                align="justify"
                                                primary="Trim or drain fat from meats before or after cooking and remove poultry skin before eating."
                                            />
                                        </ListItem>
                                        <ListItem alignitems="justify">
                                            <ListItemAvatar>
                                                <FcOk style={{ fontSize: '2em' }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                align="justify"
                                                primary="Follow cooking methods that involve less fat such as baking, broiling, grilling, or steaming."
                                            />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} p={1} item>
                            <Card
                                variant="outlined"
                                sx={{
                                    //  width: 320,
                                    // to make the card resizable
                                    overflow: "auto",
                                    resize: "horizontal",
                                }}
                            >
                                <CardContent>
                                    <ProteinRdaTable />

                                </CardContent>
                            </Card>
                            <Card
                                variant="outlined"
                                sx={{
                                    //  width: 320,
                                    // to make the card resizable
                                    overflow: "auto",
                                    resize: "horizontal",
                                    mt: 1.5
                                }}
                            >
                                <Typography level="h5" fontWeight="lg" align="start">
                                    What are the signs and symptoms of protein deficiency in the body?
                                </Typography>
                                <CardContent>
                                    <List sx={{ textAlign: "left" }}>
                                        <ListItem>
                                            <ListItemText align="justify" primary="Protein deficiency rarely occurs as an isolated condition. It usually accompanies a deficiency of dietary energy and other nutrients resulting from insufficient food intake. The symptoms are most commonly seen in deprived children in poor countries where protein intake is exceptionally low, there are physical signs—stunting, poor musculature, edema, thin and fragile hair, skin lesions—and biochemical changes that include low serum albumin and hormonal imbalances."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText align="justify" primary=" In adults the most notable signs are edema, loss of muscle mass and hair."
                                            />
                                        </ListItem>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                            mt={1}
                                        >
                                            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.2, sm: 1 } }}>
                                                <Tooltip title="Stunting">
                                                    <Avatar src={StuntingIcon} size="lg" alt="Stunting" variant="square" />
                                                </Tooltip>
                                                <Tooltip title="poor musculature">
                                                    <Avatar src={PoorMusculatureIcon} size="lg" alt="Poor Musculature" variant="square" />
                                                </Tooltip>
                                                <Tooltip title="Edema">
                                                    <Avatar src={EdemaIcon} size="lg" alt="Edema" variant="square" />
                                                </Tooltip>
                                                <Tooltip title="fragile hair">
                                                    <Avatar src={FragileHairIcon} size="lg" alt="fragile hair" variant="square" />
                                                </Tooltip>
                                                <Tooltip title="skin lesions">
                                                    <Avatar src={SkinLesionsIcon} size="lg" alt="skin lesions" variant="square" />
                                                </Tooltip>
                                                <Tooltip title="hormonal imbalance">
                                                    <Avatar src={HormonalImbalanceIcon} size="lg" alt="hormonal imbalance" variant="square" />
                                                </Tooltip>
                                                <Tooltip title="loss of muscle mass">
                                                    <Avatar src={MuscleLossIcon} size="lg" alt="loss of muscle mass" variant="square" />
                                                </Tooltip>
                                                <Tooltip title="hair loss">
                                                    <Avatar src={HairLossIcon} size="lg" alt="hair loss" variant="square" />
                                                </Tooltip>
                                            </AvatarGroup>
                                        </Box>
                                    </List>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                    <Grid md={10} item mt={1}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
                            <Typography level="h4" gutterBottom m={2}>
                                Rich sources of Protein
                            </Typography>

                            <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
                        </div>
                        <ProteinSourcesAccordion />
                    </Grid>
                </Container>
            </React.Fragment>
        </div>
    );
}
