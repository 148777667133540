import React, { useState, useEffect } from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";

import DistrictMapData from "../../../maps/2022/india_district_old_v2.json";
import Spinner from "../../../components/spinner";
import MapChart from "../components/Map";
import drilldow from "highcharts/modules/drilldown";
import stateMapsSourceList from "./StateMapsSourceList";
// Load Highcharts modules
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);

const IndiaDistrictMap = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    fetch(
      "https://nutritionatlasapi.cyclic.app/api/data?source_code=N5&indicator_id=1&area_level=3&subindicator_id=1_1"
    )
      .then((response) => response.json())
      .then((json) => setData(json.results))
      .then(setloading(false));
  }, []);

  const mapOptions = {
    chart: {},

    title: {
      text: "Heat Map",
    },

    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    colorAxis: {
      min: 20,
      max: 40,
      minColor: "#086192",
      maxColor: "#e31e24",
    },

    tooltip: {
      formatter: function () {
        let point = this.point;
        return "<b>" + point.NAME1_ + "</b><br>" + point.indicator_name + "  :" + point.value;
      },
      valueSuffix: "NFHS 5",
    },
    plotOptions: {
      map: {
        states: {
          hover: {
            color: "#EEDD66",
          },
        },
      },
    },

    series: [
      {
        data: data,
        joinBy: ["NAME1_", "area_name"],
        borderColor: "white",
        borderWidth: 0.6,
        mapData: DistrictMapData,
        name: "Population density",
        nullColor: "grey",
      },
    ],
  };
  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div>
        <MapChart options={mapOptions} highcharts={Highcharts} />
      </div>
    );
  }
};
export default IndiaDistrictMap;
