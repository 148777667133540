import React from "react";
import Micronutrient from "./components/Micronutrients";
import { Grid } from "@mui/material";
import Macronutrients from "./components/Macronutrients";
import DietaryGuidelines from "./components/DietaryGuidelines";

export default function Nutrieducation() {
  return (
    <div>
      <h2>Nutri-Education</h2>
      <Grid container spacing={0.5} direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} md={4} lg={4} justifyContent={"center"} display="flex">
          <Macronutrients />
        </Grid>
        <Grid item xs={12} md={4} lg={4} justifyContent={"center"} display="flex">
          <Micronutrient />
        </Grid>
        <Grid item xs={12} md={4} lg={4} justifyContent={"center"} display="flex">
          <DietaryGuidelines />
        </Grid>
      </Grid>
    </div>
  );
}
