import andamannicobarislands from "../../../maps/2022/NFHS5_district_maps/andaman & nicobar islands.json";
import andhrapradesh from "../../../maps/2022/NFHS5_district_maps/andhra pradesh.json";
import assam from "../../../maps/2022/NFHS5_district_maps/assam.json";
import bihar from "../../../maps/2022/NFHS5_district_maps/bihar.json";
import Chhattisgarh from "../../../maps/2022/NFHS5_district_maps/chhattisgarh.json";
import chandigarh from "../../../maps/2022/NFHS5_district_maps/chandigarh.json";
import dnhdd from "../../../maps/2022/NFHS5_district_maps/dadra & nagar haveli and daman & diu.json";
import delhi from "../../../maps/2022/NFHS5_district_maps/nct delhi.json";
import goa from "../../../maps/2022/NFHS5_district_maps/goa.json";
import gujarat from "../../../maps/2022/NFHS5_district_maps/gujarat.json";
import haryana from "../../../maps/2022/NFHS5_district_maps/haryana.json";

import himachalpradesh from "../../../maps/2022/NFHS5_district_maps/himachal pradesh.json";

import arunachalpradesh from "../../../maps/2022/NFHS5_district_maps/arunachal pradesh.json"

import india from "../../../maps/2022/india_state_nfhs5.json";
const stateMapsSourceList = [
  {
    id: 0,
    state_name: "India",
    mapData: india,
  },
  {
    id: 1,
    state_name: "Andaman & Nicobar Islands",
    mapData: andamannicobarislands,
  },
  {
    id: 2,
    state_name: "Andhra Pradesh",
    mapData: andhrapradesh,
  },
  {
    id: 3,
    state_name: "Arunachal Pradesh",
    mapData: arunachalpradesh,
  },
  {
    id: 4,
    state_name: "Assam",
    mapData: assam,
  },
  {
    id: 5,
    state_name: "Bihar",
    mapData: bihar,
  },
  {
    id: 6,
    state_name: "Chandigarh",
    mapData: chandigarh,
  },
  {
    id: 7,
    state_name: "Chhattisgarh",
    mapData: Chhattisgarh,
  },
  {
    id: 8,
    state_name: "Dadra & Nagar Haveli and Daman & Diu",
    mapData: dnhdd,
  },
  {
    id: 9,
    state_name: "NCT Delhi",
    mapData: delhi,
  },
  {
    id: 10,
    state_name: "Goa",
    mapData: goa,
  },
  {
    id: 11,
    state_name: "Gujarat",
    mapData: gujarat,
  },
  {
    id: 12,
    state_name: "Himachal Pradesh",
    mapData: himachalpradesh,
  },
  {
    id: 13,
    state_name: "Haryana",
    mapData: haryana,
  },
  {
    id: 14,
    state_name: "Jharkhand",
    mapData: "../../../maps/2022/jharkhand.json",
  },
  {
    id: 15,
    state_name: "Jammu & Kashmir",
    mapData: "../../../maps/2022/jammukashmir.json",
  },
  {
    id: 16,
    state_name: "Karnataka",
    mapData: "../../../maps/2022/karnataka.json",
  },
  {
    id: 17,
    state_name: "Kerala",
    mapData: "../../../maps/2022/kerala.json",
  },
  {
    id: 18,
    state_name: "Lakshadweep",
    mapData: "../../../maps/2022/lakshadweep.json",
  },
  {
    id: 19,
    state_name: "Ladakh",
    mapData: "../../../maps/2022/ladakh.json",
  },
  {
    id: 20,
    state_name: "Maharashtra",
    mapData: "../../../maps/2022/maharashtra.json",
  },
  {
    id: 21,
    state_name: "Meghalaya",
    mapData: "../../../maps/2022/meghalaya.json",
  },
  {
    id: 22,
    state_name: "Manipur",
    mapData: "../../../maps/2022/manipur.json",
  },
  {
    id: 23,
    state_name: "Madhya Pradesh",
    mapData: "../../../maps/2022/madhyapradesh.json",
  },
  {
    id: 24,
    state_name: "Mizoram",
    mapData: "../../../maps/2022/mizoram.json",
  },
  {
    id: 25,
    state_name: "Nagaland",
    mapData: "../../../maps/2022/nagaland.json",
  },
  {
    id: 26,
    state_name: "Odisha",
    mapData: "../../../maps/2022/odisha.json",
  },
  {
    id: 27,
    state_name: "Punjab",
    mapData: "../../../maps/2022/punjab.json",
  },
  {
    id: 28,
    state_name: "Puducherry",
    mapData: "../../../maps/2022/puducherry.json",
  },
  {
    id: 29,
    state_name: "Rajasthan",
    mapData: "../../../maps/2022/rajasthan.json",
  },
  {
    id: 30,
    state_name: "Sikkim",
    mapData: "../../../maps/2022/sikkim.json",
  },
  {
    id: 31,
    state_name: "Telangana",
    mapData: "../../../maps/2022/telangana.json",
  },
  {
    id: 32,
    state_name: "Tamil Nadu",
    mapData: "../../../maps/2022/tamilnadu.json",
  },
  {
    id: 33,
    state_name: "Tripura",
    mapData: "../../../maps/2022/tripura.json",
  },
  {
    id: 34,
    state_name: "Uttar Pradesh",
    mapData: "../../../maps/2022/uttarpradesh.json",
  },
  {
    id: 35,
    state_name: "Uttarakhand",
    mapData: "../../../maps/2022/uttarakhand.json",
  },
  {
    id: 36,
    state_name: "West Bengal",
    mapData: "../../../maps/2022/westbengal.json",
  },
];
export default stateMapsSourceList;
