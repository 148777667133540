import { Avatar, Box, Card, ListItemIcon } from "@mui/material";
import {
  CardContent,
  CardHeader,
  CssBaseline,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/joy/Typography";

import sugarcane from "../.../../../../../../assets/images/icons/sugarcane.png";
import fruits from "../.../../../../../../assets/images/icons/vegetables.png";
import fiber from "../.../../../../../../assets/images/icons/fiber.png";
import wholegrain from "../.../../../../../../assets/images/icons/wholegrain.png";
import starch from "../.../../../../../../assets/images/icons/starch.png";
import processedfood from "../.../../../../../../assets/images/icons/processedfood.png";
import React, { Component } from "react";
import { shadows } from "@mui/system";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { ListItemContent } from "@mui/joy";
import GlycemicIndexTable from "./GlycemicIndexTable ";
import GlycaemicLoadTable from "./GlycaemicLoadTable";

export default class GiGlIntro extends Component {
  render() {
    return (
      <>
        <CssBaseline />
        <Grid md={12} item>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, backgroundColor: "#6f38c5", height: "5px" }} />
            <Typography level="h5" gutterBottom m={1}>
              Gl and GL of foods
            </Typography>

            <div style={{ flex: 1, backgroundColor: "#6f38c5", height: "5px" }} />
          </div>
        </Grid>
        <Grid container alignItems="stretch">
          <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
            <Box>
              <Card sx={{ height: "100%" }} variant="outlined">
                {/* <CardHeader title="Glycaemic index (GI)" sx={{ backgroundColor: "#9494f1" }} /> */}
                <CardContent>
                  <List sx={{ width: "100%", bgcolor: "background.paper", '& > li': { marginBottom: '-5px' } }}>
                  <Typography level="h5" sx={{ color: "#6f38c5",textAlign:"left", marginLeft:'1em'}}>
                    Glycaemic index (GI) :
                  </Typography>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="Glycaemic index (GI) is a numerical value given to impact of carbohydrate-containing foods on blood glucose levels in comparison to another carbohydrate food (usually 50gm of glucose)." />
                      {/* <ListItemContent>
                        <Typography level="title-sm">
                          Glycaemic index (GI) is a numerical value given to impact of carbohydrate-containing foods on blood glucose levels in comparison to another carbohydrate food (usually 50gm of glucose).
                        </Typography>
                      </ListItemContent> */}
                    </ListItem>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="The slower the rise in blood glucose after eating a food, the lower is its glycaemic index and vice versa." />
                    </ListItem>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="The GI ranges from 0 to 100." />
                    </ListItem>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="In general, a food's GI increases with processing, while a food's GI decreases with fiber or fat content." />
                    </ListItem>
                  </List>
                  <Typography level="h6" sx={{ marginBottom:"0.5em", fontSize:"1.1em", textAlign:'left', marginLeft:'0.2em'}}>
                  What counts as high, medium and low Glycaemic index (GI)?
                  </Typography>
                  <GlycemicIndexTable /> {/* Use the GlycemicIndexTable component as a list item */}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
            <Box>
              <Card  variant="outlined">
                {/* <CardHeader
                  title="Glycaemic load (GL)"
                  sx={{ backgroundColor: "#9494f1" }}
                /> */}
                <CardContent>
                  <List sx={{ width: "100%", bgcolor: "background.paper", '& > li': { marginBottom: '-10px' } }}>
                  <Typography level="h5" sx={{ color: "#6f38c5",textAlign:"left", marginLeft:'1em'}}>
                  Glycaemic load (GL) :
                  </Typography>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="Glycaemic load (GL) is a measure of the impact of food on the blood sugar levels based on GI of the food and the amount of carbohydrates consumed." />
                    </ListItem>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="It is a more reliable indicator than GI." />
                    </ListItem>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="It is calculated as GI/100× CHO content of the food." />
                    </ListItem>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="A high GI food if consumed in small amounts will give lower glycaemic load and vice-versa." />
                    </ListItem>
                    <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                      <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                        <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                      </ListItemIcon>
                      <ListItemText primary="A diet with low GI and GL can help in controlling blood sugar levels in type 2 diabetes individuals." />
                    </ListItem>
                  </List>
                  <Typography level="h6" sx={{ marginBottom:"0.5em", fontSize:"1.1em", textAlign:'left', marginLeft:'0.2em'}}>
                  What counts as high, medium and low Glycaemic load (GL)?
                  </Typography>
                  <GlycaemicLoadTable />
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}
