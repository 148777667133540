import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import DnaRna from "../../../../../assets/images/icons/vitamins/Functions/DnaRna.png";
import hemoglobin from "../../../../../assets/images/icons/vitamins/Functions/hemoglobin.png";
import homocysteine from "../../../../../assets/images/icons/vitamins/Functions/homocysteine.png";
import pregnancyBrain from "../../../../../assets/images/icons/vitamins/Functions/pregnancyBrain.png";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import glv from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/spinach.png";
import legumes from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/legumes.png";
import citrusFruits from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B9/orange.png";
import fortifiedCereals from "../../../../../assets/images/icons/vitamins/vitaminD/fortifiedCereals.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import liver from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B9/organMeats.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/vitaminb2/dermatitis.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import FolateRdaTable from "./RdaAndEarTables/FolateRdaTable";
import fatigue from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png";
import HairLossIcon from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/HairLossIcon.png";
import arrhythmia from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png";
import difficulty_concentrating from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/difficulty_concentrating.png";
import mouth_ulcers from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/mouth_ulcers.png";
import pale_skin from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/pale_skin.png";
import shortness_of_breath from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/shortness_of_breath.png";

export default function Vitaminb9() {
  return (<>
    <Grid item lg={7} md={6} sm={12}>
      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
          py: 1
        }}
      >
        <Typography level="h5" fontWeight="lg" align="start">
          What are the functions of vitamin B9?
        </Typography>
        <CardContent>
          <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="DNA RNA" src={DnaRna} />
              </ListItemAvatar>
              <ListItemText primary="It helps in the formation of genetic material (DNA and RNA) and is required for protein metabolism." />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="homocysteine" src={homocysteine} />
              </ListItemAvatar>
              <ListItemText
                primary="It assist in breaking down of homocysteine to methionine."
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="hemoglobin" src={hemoglobin} />
              </ListItemAvatar>
              <ListItemText
                primary="It is important for the formation of a heme group of hemoglobin."
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="pregnancy Brain" src={pregnancyBrain} />
              </ListItemAvatar>
              <ListItemText primary="The role of folate is critical during pregnancy and fetal development when there is rapid growth." />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
          mt: 1,
          py: 0.5,
          display: { xs: 'block', sm: 'none' }
        }}
      >
        <CardContent>
          <Typography level="h5" fontWeight="lg" align="start">
            Why is folic acid important for pregnant women?
          </Typography>
          <List sx={{ textAlign: "left" }}>
            <ListItem alignItems="flex-start">
              <ListItemText primary="Folate is crucial for the development of the neural tube in the fetus, which eventually becomes the baby's brain and spinal cord. Inadequate folic acid intake before and during early pregnancy can lead to neural tube defects, such as spina bifida." />
            </ListItem>

          </List>
        </CardContent>


      </Card>
      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
          mt: 1
        }}
      >
        <CardContent>
          <Typography level="h5" fontWeight="lg" align="start">
            What are the sources of vitamin B9?
          </Typography>
          <List sx={{ textAlign: "left" }}>
            <ListItem>
              Good sources of folate include leafy green vegetables (spinach, kale), legumes (lentils, beans), citrus fruits, fortified grains and cereals, and liver. Folic acid is primarily obtained from dietary supplements and fortified foods.
            </ListItem>
          </List>
        </CardContent>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1
          }}
        >
          <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
            <Tooltip title="leafy green vegetables (spinach, kale)">
              <Avatar src={glv} size="lg" alt="leafy green vegetables" variant="square" />
            </Tooltip>
            <Tooltip title="legumes (lentils, beans)">
              <Avatar src={legumes} alt="legumes" variant="square" />
            </Tooltip>
            <Tooltip title="citrus fruits">
              <Avatar src={citrusFruits} alt="Orange" variant="square" />
            </Tooltip>
            <Tooltip title="fortified cereals">
              <Avatar src={fortifiedCereals} alt="fortified grains" variant="square" />
            </Tooltip>
            <Tooltip title="whole grains">
              <Avatar src={wholegrains} alt="grains" variant="square" />
            </Tooltip>
            <Tooltip title="Liver ">
              <Avatar src={liver} alt="liver" variant="square" />
            </Tooltip>
          </AvatarGroup>
        </Box>
      </Card>

      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
          mt: 1,
          py: 0.5,
          display: { xs: 'none', sm: 'block' }
        }}
      >
        <CardContent>
          <Typography level="h5" fontWeight="lg" align="start">
            Why is folic acid important for pregnant women?
          </Typography>
          <List sx={{ textAlign: "left" }}>
            <ListItem alignItems="flex-start">
              <ListItemText primary="Folate is crucial for the development of the neural tube in the fetus, which eventually becomes the baby's brain and spinal cord. Inadequate folic acid intake before and during early pregnancy can lead to neural tube defects, such as spina bifida." />
            </ListItem>

          </List>
        </CardContent>


      </Card>

      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
          mt: 1,
          backgroundColor: "#BFEFFF",
          color: "#333333",
          py: 1,
          display: { xs: 'none', sm: 'block' }
        }}
      >
        <CardContent>
          <Typography level="h5" fontWeight="lg" align="start">
            Weekly Iron-folic acid supplementation (WIFS) :
          </Typography>
          <List sx={{ textAlign: "left", margin: "0", padding: "0", marginLeft: 2, marginBottom: 2 }}>
            <ListItem>
              Weekly iron-folic acid supplementation (WIFS) has been launched by Ministry of Health and Family Welfare for prevention and treatment of anaemia  across the life cycle i.e school going children, adolescents, women of reproductive age and during pregnancy. IFA tablet containing 100mg elemental iron and 500ug Folic acid is given for 52 weeks in a year.

            </ListItem>
            {/* <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={energetic} />
                </ListItemAvatar>
                <ListItemText
                  primary="All age groups namely children aged 6-59 months, 5-10 yr, adolescents aged 10-19 yr, pregnant and lactating women, and women in the reproductive age group."
                />
              </ListItem> */}
          </List>
        </CardContent>
      </Card>
    </Grid>
    <Grid item lg={5} md={6} sm={12}>
      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <FolateRdaTable />
      </Card>

      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
          mt: 1,
          py: 1
        }}
      >
        <CardContent>
          <Typography level="h5" fontWeight="lg" align="start">
            What happens if you are deficient in vitamin B9?
          </Typography>
          <List sx={{ textAlign: "left" }}>
            <ListItem>
              Deficiency of folate can result in megaloblastic anemia (a condition in which less red blood cells (RBC’s) are produced and RBC’s are larger than the normal cells);
            </ListItem>
            <ListItem>
              lack of energy, pale skin, weakness, fatigue, arrhythmia (irregular heart beat), shortness of breath; difficulty concentrating; hair loss; pallor; mouth ulcers.
            </ListItem>
          </List>
        </CardContent>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mt={1}
          mb={0.5}
        >
          <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.6, sm: 1 }}}>
            <Tooltip title="lack of energy">
              <Avatar src={fatigue} size="lg" alt="lack of energy" variant="square" />
            </Tooltip>
            <Tooltip title="pale skin">
              <Avatar src={pale_skin} alt="pale skin" variant="square" />
            </Tooltip>
            <Tooltip title="arrhythmia (irregular heart beat)">
              <Avatar src={arrhythmia} alt="arrhythmia" variant="square" />
            </Tooltip>
            <Tooltip title="shortness of breath">
              <Avatar src={shortness_of_breath} alt="shortness of breath" variant="square" />
            </Tooltip>
            <Tooltip title="difficulty concentrating">
              <Avatar src={difficulty_concentrating} alt="difficulty concentrating" variant="square" />
            </Tooltip>
            <Tooltip title="hair loss">
              <Avatar src={HairLossIcon} alt="hair loss" variant="square" />
            </Tooltip>
            <Tooltip title="mouth ulcers ">
              <Avatar src={mouth_ulcers} alt="mouth ulcers" variant="square" />
            </Tooltip>
          </AvatarGroup>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          //  width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
          mt: 1,
          backgroundColor: "#BFEFFF",
          color: "#333333",
          py: 1,
          display: { xs: 'block', sm: 'none' }
        }}
      >
        <CardContent>
          <Typography level="h5" fontWeight="lg" align="start">
            Weekly Iron-folic acid supplementation (WIFS) :
          </Typography>
          <List sx={{ textAlign: "left", margin: "0", padding: "0", marginLeft: 2, marginBottom: 2 }}>
            <ListItem>
              Weekly iron-folic acid supplementation (WIFS) has been launched by Ministry of Health and Family Welfare for prevention and treatment of anaemia  across the life cycle i.e school going children, adolescents, women of reproductive age and during pregnancy. IFA tablet containing 100mg elemental iron and 500ug Folic acid is given for 52 weeks in a year.

            </ListItem>
            {/* <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={energetic} />
                </ListItemAvatar>
                <ListItemText
                  primary="All age groups namely children aged 6-59 months, 5-10 yr, adolescents aged 10-19 yr, pregnant and lactating women, and women in the reproductive age group."
                />
              </ListItem> */}
          </List>
        </CardContent>
      </Card>
    </Grid>
  </>
  );
}
