// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import insulin from '../../../../../assets/images/icons/minerals/Functions/insulin.png';
import atp from '../../../../../assets/images/icons/minerals/Functions/atp.png';
import weight_loss from '../../../../../assets/images/icons/minerals/Deficiency/weight_loss.png';
import confusion from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/confusion.png';
import dizziness from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB12/dizziness.png';
import blood_sugar from '../../../../../assets/images/icons/minerals/Deficiency/blood_sugar.png';
import oyster from "../../../../../assets/FoodSources/Minerals/Sources/oyster.png";
import yeast from "../../../../../assets/FoodSources/Minerals/Sources/yeast.png";
import liver from "../../../../../assets/FoodSources/Minerals/Sources/organMeats1.png";
import potato from "../../../../../assets/FoodSources/Minerals/Sources/potato.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import broccoli from "../../../../../assets/FoodSources/Minerals/Sources/broccoli.png";
import green_beans from "../../../../../assets/FoodSources/Minerals/Sources/green_beans.png";
import nuts from "../../../../../assets/FoodSources/Minerals/Sources/nuts.png";
import seeds from "../../../../../assets/FoodSources/Minerals/Sources/seeds.png";
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import poultry from "../../../../../assets/FoodSources/Minerals/Sources/poultry.png";
import meat_poultry from "../../../../../assets/FoodSources/Minerals/Sources/meat1.png";
import spices from "../../../../../assets/FoodSources/Minerals/Sources/spices.png";
import apple_banana from "../../../../../assets/FoodSources/Minerals/Sources/apple_banana.png";


export default function Chromium() {
    return (
        <>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        padding: "0.5em"
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of chromium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={insulin} />
                                </ListItemAvatar>
                                <ListItemText primary="Chromium enhances the action of insulin." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={atp} />
                                </ListItemAvatar>
                                <ListItemText primary="It aids in the breakdown and absorption of carbohydrate, proteins, and fats." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={energetic} />
                                </ListItemAvatar>
                                <ListItemText primary="Children with malnutrition often have glucose intolerance which improves when chromium supplement is given." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        // padding: "0.5em"
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of chromium?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-6px' }, }}>
                            <ListItem>
                                Brewers yeast, oysters, liver and potatoes have high chromium concentration.
                            </ListItem>
                            <ListItem>
                                The other dietary sources of chromium are whole grains, broccoli, green beans, nuts and seeds, meat, poultry, seafood, spices and fruits like apples, bananas.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}

                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="yeast">
                                <Avatar src={yeast} size="lg" alt="yeast" variant="square" />
                            </Tooltip>
                            <Tooltip title="oysters">
                                <Avatar src={oyster} alt="oysters" variant="square" />
                            </Tooltip>
                            <Tooltip title="potatoes">
                                <Avatar src={potato} alt="potatoes" variant="square" />
                            </Tooltip>
                            <Tooltip title="whole grains">
                                <Avatar src={wholegrains} alt="whole grains" variant="square" />
                            </Tooltip>
                            <Tooltip title="broccoli">
                                <Avatar src={broccoli} alt="broccoli" variant="square" />
                            </Tooltip>
                            <Tooltip title="green beans ">
                                <Avatar src={green_beans} alt="green beans" variant="square" />
                            </Tooltip>
                            <Tooltip title="nuts and seeds">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={nuts} alt="nuts and seeds" variant="square" />
                            </Tooltip>
                            <Tooltip title="meat and poultry">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={meat_poultry} alt="meat" variant="square" />
                            </Tooltip>
                            <Tooltip title="seafood">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={fish} alt="seafood" variant="square" />
                            </Tooltip>
                            <Tooltip title="spices">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={spices} alt="spices" variant="square" />
                            </Tooltip>
                            <Tooltip title="fruits like apples, bananas">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={apple_banana} alt="fruits" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>

                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}

                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="nuts and seeds">
                                <Avatar src={nuts} alt="nuts and seeds" variant="square" />
                            </Tooltip>
                            <Tooltip title="meat and poultry">
                                <Avatar src={meat_poultry} alt="meat" variant="square" />
                            </Tooltip>
                            <Tooltip title="seafood">
                                <Avatar src={fish} alt="seafood" variant="square" />
                            </Tooltip>
                            <Tooltip title="spices">
                                <Avatar src={spices} alt="spices" variant="square" />
                            </Tooltip>
                            <Tooltip title="fruits like apples, bananas">
                                <Avatar src={apple_banana} alt="fruits" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>

            </Grid>
            <Grid item lg={6} md={6} sm={12}>
            <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 0, sm:1 },
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is chromium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Chromium ions are absorbed in small intestine" />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 1, sm:0},
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What is the recommended dietary intake of chromium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Human} />
                                </ListItemAvatar>
                                <ListItemText primary="The recommended intake of Chromium required for adult man and adult woman is 50 µg/day" />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is chromium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Chromium ions are absorbed in small intestine" />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens when you are deficient in chromium?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Symptoms of chromium deficiency may include weight loss, confusion, impaired coordination, and a reduced response to glucose in blood, increasing the risk of diabetes.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="weight loss">
                                <Avatar src={weight_loss} size="lg" alt="weight loss" variant="square" />
                            </Tooltip>
                            <Tooltip title="confusion">
                                <Avatar src={confusion} alt="confusion" variant="square" />
                            </Tooltip>
                            <Tooltip title="impaired coordination">
                                <Avatar src={dizziness} alt="impaired coordination" variant="square" />
                            </Tooltip>
                            <Tooltip title="increasing the risk of diabetes">
                                <Avatar src={blood_sugar} alt="increasing the risk of diabetes" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
        </>
    );
}



