import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import SoyaBeanWhite from "../../../../../../assets/FoodSources/Protein/Pulses/1_SoyaBeanWhite.jpg"
import SoyaBeanBrown from "../../../../../../assets/FoodSources/Protein/Pulses/2_SoyaBeanBrown.jpg"
import GoaBeans from "../../../../../../assets/FoodSources/Protein/Pulses/3_GoaBeans.jpg"
import KesariDal from "../../../../../../assets/FoodSources/Protein/Pulses/4_KesariDal.jpg"
import LentilDal from "../../../../../../assets/FoodSources/Protein/Pulses/5_lentilDal.jpg"
import GreengramDal from "../../../../../../assets/FoodSources/Protein/Pulses/6_GreengramDal.jpg"
import BlackgramDal from "../../../../../../assets/FoodSources/Protein/Pulses/7_BlackgramDal.jpg"
import PeasRoasted from "../../../../../../assets/FoodSources/Protein/Pulses/8_PeasRoasted.jpg"
import LentilWhole from "../../../../../../assets/FoodSources/Protein/Pulses/9_LentilWhole.jpg"
import GreenGram from "../../../../../../assets/FoodSources/Protein/Pulses/10_GreenGram.jpg"
import { Typography } from '@mui/material';

export default function PulsesAndGrains() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: SoyaBeanWhite,
    title: 'Soya bean, white',
    author: '37.8 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: SoyaBeanBrown,
    title: 'Soya bean, brown',
    author: '35.58 per 100gm',
  },
  {
    img: GoaBeans,
    title: 'Goa beans',
    author: '32.7 per 100gm',
  },
  {
    img: KesariDal,
    title: 'Khesari, dhal',
    author: '28.2 per 100gm',
    cols: 2,
  },
  {
    img: LentilDal,
    title: 'lentil dal',
    author: '24.35 per 100gm',
    cols: 2,
  },
  {
    img: GreengramDal,
    title: 'Green gram, dal',
    author: '23.88 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: BlackgramDal,
    title: 'Black gram, dal',
    author: '23.06 per 100gm',
  },
  {
    img: PeasRoasted,
    title: 'Peas, roasted',
    author: '22.9 per 100gm.4',
  },
  {
    img: LentilWhole,
    title: 'Lentil whole, yellowish',
    author: '22.87 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: GreenGram,
    title: 'Green gram, whole',
    author: '22.53 per 100gm',
  }
];