import ReactGA from "react-ga4";

const initializeAnalytics = () => {
  ReactGA.initialize("G-W8FKZ94YZ5", {
    gaOptions: {
      linker: {
        domains: ["nutritionatlasindia.in", "nutritionatlasindia.com"], // Add your domains here
      },
    },
  });
};

export default initializeAnalytics;
