// config.js
// This localhost uses when  app runs in localmachine mode
//const baseUrl = "http://localhost:5000/api/";
//const baseUrl = "https://nutritionatlasapi.cyclic.app/api/";
const baseUrl = "https://api.nutritionatlasindia.in/api/";
export const endpoints = {
  dashboard: {
    url: baseUrl + "data",
    auth: false,
  },
  compare: {
    url: baseUrl + "compare1",
    auth: false,
  },
};
