import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import WaterMelonSeeds from "../../../../../../assets/FoodSources/Protein/Oils and fats/1_watermelonSeeds.jpg"
import GroundnutRoasted from "../../../../../../assets/FoodSources/Protein/Oils and fats/2_GroundnutRoasted.jpg"
import PumpkinSeeds from "../../../../../../assets/FoodSources/Protein/Oils and fats/3_PumpkinSeeds.jpg"
import Groundnut from "../../../../../../assets/FoodSources/Protein/Oils and fats/4_GroundNut.jpg"
import SunflowerSeeds from "../../../../../../assets/FoodSources/Protein/Oils and fats/5_SunflowerSeeds.jpg"
import GradenCress from "../../../../../../assets/FoodSources/Protein/Oils and fats/6_GardenCress.jpg"
import PistachioNut from "../../../../../../assets/FoodSources/Protein/Oils and fats/7_PistachioNut.jpg"
import GingellySeeds from "../../../../../../assets/FoodSources/Protein/Oils and fats/8_GingellySeeds.jpg"
import MustardSeeds from "../../../../../../assets/FoodSources/Protein/Oils and fats/9_MustardSeeds.jpg"
import NigerSeeds from "../../../../../../assets/FoodSources/Protein/Oils and fats/10_NigerSeeds.jpg"
import { Typography } from '@mui/material';

export default function NutsAndOils() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: WaterMelonSeeds,
    title: 'Water melon seeds (kernal)',
    author: '34.1 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: GroundnutRoasted,
    title: 'Groundnut, roasted',
    author: '26.2 per 100gm',
  },
  {
    img: PumpkinSeeds,
    title: 'Pumpkin seed',
    author: '24.3 per 100gm',
  },
  {
    img: Groundnut,
    title: 'Ground nut',
    author: '23.65 per 100gm',
    cols: 2,
  },
  {
    img: SunflowerSeeds,
    title: 'sunflower seeds',
    author: '23.53 per 100gm',
    cols: 2,
  },
  {
    img: GradenCress,
    title: 'Garden cress, seeds',
    author: '23.36 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: PistachioNut,
    title: 'Pistachio nuts',
    author: '23.35 per 100gm',
  },
  {
    img: GingellySeeds,
    title: 'Gingelly seeds',
    author: '21.7 per 100gm.4',
  },
  {
    img: MustardSeeds,
    title: 'Mustard seeds',
    author: '19.51 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: NigerSeeds,
    title: 'niger seeds',
    author: '18.92 per 100gm',
  }
];