import React, { useEffect, useState } from "react";
import myplateicon from "../../../../../assets/images/myplateicon1.png";
import MyPlateDataTable from "./MyPlateDataTable";
import myplateglass from "../../../../../assets/images/myplateglass.png";
import { Grid, Paper } from "@mui/material";

const dataMap = {
  "Cereals (incl.nutricereals)": {
    name: "Cereals (incl.nutricereals)",
    consumedFoods: 250,
    energy: 42,
    totalEnergy: "~843",
    totalProtein: "~25",
    totalFat: "~5",
    totalCabs: "~172",
  },
  "Vegetables* greeen Leafy vegetable (GLV)": {
    name: "Vegetables* greeen Leafy vegetable (GLV)",
    consumedFoods: 400,
    energy: 9,
    totalEnergy: "~174",
    totalProtein: "~10",
    totalFat: "~2",
    totalCabs: "~28",
  },
  "Fruits *#": {
    name: "Fruits *#",
    consumedFoods: 100,
    energy: 3,
    totalEnergy: "~56",
    totalProtein: "~1",
    totalFat: "~1",
    totalCabs: "~11",
  },
  "Pulses*": {
    name: "Pulses*",
    consumedFoods: 85,
    energy: 14,
    totalEnergy: "~274",
    totalProtein: "~20",
    totalFat: "~3",
    totalCabs: "~42",
  },
  "Nuts & Seeds": {
    name: "Nuts & Seeds",
    consumedFoods: 35,
    energy: 9,
    totalEnergy: "~181",
    totalProtein: "~6",
    totalFat: "~15",
    totalCabs: "~6",
  },
  "Fats & Oils$": {
    name: "Fats & Oils$",
    consumedFoods: 27,
    energy: 12,
    totalEnergy: "~243",
    totalProtein: "-",
    totalFat: "~27",
    totalCabs: "-",
  },
  "Milk / Curd (ml)": {
    name: "Milk / Curd (ml)",
    consumedFoods: 300,
    energy: 11,
    totalEnergy: "~216",
    totalProtein: "~10",
    totalFat: "~13",
    totalCabs: "~16",
  },
};

const MyPlateSvg = () => {
  const colors = ["#2C74BE", "#662C90", "#EF5A2A", "#F8B13F", "#FCEF0A", "#8CC63C", "#3AB549"];
  const colorsWithOpacity = [
    "rgba(44, 116, 190, 0.7)",
    "rgba(102, 44, 144, 0.7)",
    "rgba(239, 90, 42, 0.7)",
    "rgba(248, 177, 63, 0.7)",
    "rgba(252, 239, 10, 0.7)",
    "rgba(140, 198, 60, 0.7)",
    "rgba(58, 181, 73, 0.7)",
  ];
  const imageSize = 450;
  const enlargedImageSize = 550;
  const radius = 130;
  const center = imageSize / 2;
  const glassSize = 120; // Adjust the size of the glass as needed
  const glassX = center - imageSize + 1.15 * glassSize; // Adjust the position of the glass
  const glassY = center + imageSize / 4;

  const percentages = [25, 35, 16.5, 10.5, 7, 6];

  // Set the initial selected sector to the second sector
  const initialSelectedSectorData = dataMap[Object.keys(dataMap)[1]];
  const [selectedSectorData, setSelectedSectorData] = useState(initialSelectedSectorData);
  const [selectedColor, setSelectedColor] = useState(null);
  const [hoveredSector, setHoveredSector] = useState(1);
  const [prevHoveredColor, setPrevHoveredColor] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const viewBox =
    windowWidth < 600
      ? `-${30} -${30} ${imageSize + 60} ${imageSize + 60}`
      : `-${5} -${40} ${imageSize + 20} ${imageSize + 20}`;

  const handleSectorHover = (index) => {
    setHoveredSector(index);
    const sectorName = Object.keys(dataMap)[index];
    const sectorData = dataMap[sectorName];
    setSelectedSectorData(sectorData);
  };

  const handleGlassHover = () => {
    setHoveredSector("glass");
    setSelectedSectorData(dataMap["Milk / Curd (ml)"]);
  };

  const handleGlassLeave = () => {
    setPrevHoveredColor(selectedColor || colorsWithOpacity[hoveredSector]);
    setHoveredSector(null);
  };

  const handleGlassClick = () => {
    setSelectedSectorData(dataMap["Milk / Curd (ml)"]);
  };

  const handleClick = (index) => {
    const sectorName = Object.keys(dataMap)[index];
    const sectorData = dataMap[sectorName];
    setSelectedSectorData(sectorData);
    setHoveredSector(index);
  };

  const getOuterEdgeColor = (index) => {
    return hoveredSector === index || selectedSectorData === dataMap[Object.keys(dataMap)[index]]
      ? selectedColor || colorsWithOpacity[hoveredSector]
      : hoveredSector === null
      ? prevHoveredColor
      : colors[index];
  };

  let startAngle = 0;
  const sectors = percentages.map((percentage, index) => {
    const endAngle = startAngle + (percentage / 100) * 360;
    const isSelected =
      hoveredSector === index || selectedSectorData === dataMap[Object.keys(dataMap)[index]];
    const currentImageSize = isSelected ? enlargedImageSize : imageSize;

    const pathData = `
      M ${center} ${center}
      L ${center + 2 * radius * Math.cos((startAngle * Math.PI) / 180)} ${
      center + 2 * radius * Math.sin((startAngle * Math.PI) / 180)
    }
      A ${2 * radius} ${2 * radius} 0 0 1 ${
      center + 2 * radius * Math.cos((endAngle * Math.PI) / 180)
    } ${center + 2 * radius * Math.sin((endAngle * Math.PI) / 180)}
      Z
    `;
    startAngle = endAngle;
    const outerEdgeColor = getOuterEdgeColor(index);

    const rotationAngle = 1;

    return (
      <g
        key={index}
        onMouseOver={() => handleSectorHover(index)}
        style={{
          filter: isSelected ? "drop-shadow(0 0 15px rgba(0,0,0,0.7))" : "none",
          transition: "filter 0.3s ease-in-out",
        }}
      >
        <clipPath id={`clipPath${index + 1}`}>
          <path d={pathData} />
        </clipPath>
        <g clipPath={`url(#clipPath${index + 1})`}>
          <image
            href={myplateicon}
            width={currentImageSize}
            height={currentImageSize}
            x={center - currentImageSize / 2}
            y={center - currentImageSize / 2.07}
            transform={`rotate(${rotationAngle}, ${center}, ${center})`}
            onClick={() => handleClick(index)}
            style={{
              filter: isSelected ? "none" : "grayscale(10%)", // Adjust the grayscale level as needed
              transition: "filter 0.3s ease-in-out",
            }}
          />
        </g>
        <path
          d={pathData}
          fill={outerEdgeColor}
          stroke={outerEdgeColor}
          strokeWidth="2"
          fillOpacity="0"
          onClick={() => handleClick(index)}
          style={{ cursor: "pointer" }}
        />
      </g>
    );
  });

  const glassElement = (
    <svg
      width={glassSize}
      height={glassSize}
      viewBox={`0 30 ${glassSize + 10} ${glassSize - 40}`}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
      }}
    >
      <g
        onMouseOver={handleGlassHover}
        onMouseOut={handleGlassLeave}
        onClick={handleGlassClick}
        style={{
          cursor: "pointer",
          transform: `scale(${hoveredSector === "glass" ? 1.2 : 1})`,
          transition: hoveredSector === "glass" ? "transform 0.3s ease-in-out" : "none",
          width: "100%",
        }}
      >
        <image
          href={myplateglass}
          width={glassSize}
          height={glassSize}
          style={{
            filter: hoveredSector === "glass" ? "none" : "grayscale(100%)", // Adjust the grayscale level as needed
            transition: "filter 0.3s ease-in-out",
          }}
        />
      </g>
    </svg>
  );

  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} md={6}>
        <svg
          width={imageSize + 40}
          height={imageSize - 40}
          viewBox={viewBox}
          style={{
            overflow: "visible",
            width: "100%", // Make the SVG container responsive
          }}
        >
          {/* Circles */}
          {sectors}

          {/* Glass */}
          {glassElement}
        </svg>
      </Grid>
      <Grid item xs={12} md={6} sx={{ overflowX: "auto", marginBottom: 5 }}>
        {selectedSectorData && (
          <MyPlateDataTable
            data={selectedSectorData}
            backgroundColor={
              selectedSectorData.name === "Milk / Curd (ml)"
                ? "rgba(58, 181, 73, 0.7)"
                : selectedColor || colorsWithOpacity[hoveredSector]
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MyPlateSvg;
