import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import bone from '../../../../../assets/images/icons/minerals/Functions/bone.png';
import dna from '../../../../../assets/images/icons/minerals/Functions/dna.png';
import atp from '../../../../../assets/images/icons/minerals/Functions/atp.png';
import enzyme from '../../../../../assets/images/icons/minerals/Functions/enzyme.png';
import joint_pain from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminC/joint_pain.png';
import loss_appetite from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/loss_appetite.png';
import fatigue from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png';
import irritability from '../../../../../assets/images/icons/vitamins/vitaminb5/irritability.png';
import fracture from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminK/fracture.png';
import red_meat from "../../../../../assets/FoodSources/Minerals/Sources/Red_meat.png";
import poultry from "../../../../../assets/FoodSources/Minerals/Sources/poultry.png";
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import legumes from "../../../../../assets/FoodSources/Minerals/Sources/legumes.png";
import nuts from "../../../../../assets/FoodSources/Minerals/Sources/nuts.png";
import milk from "../../../../../assets/FoodSources/Minerals/Sources/milk.png";




export default function Phosphorus() {
    return (
        <>
            <Grid item lg={7} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of phosphorus?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={bone} />
                                </ListItemAvatar>
                                <ListItemText primary="Phosphorus is essential for bone and teeth health." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={enzyme} />
                                </ListItemAvatar>
                                <ListItemText primary="It aids in activating various enzymes and maintaining blood pH within a normal range." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={dna} />
                                </ListItemAvatar>
                                <ListItemText primary="It is a essential component of cell membrane and deoxyribonucleic acid (DNA)." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={atp} />
                                </ListItemAvatar>
                                <ListItemText primary="An integral component of ATP- adenosine triphosphate, the major energy-generating molecule in the system." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1,
                        mt: 0.5
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of phosphorus?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Rich sources of phosphorus include dairy, red meat, poultry, seafood, legumes and nuts.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 0.5
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="dairy">
                                <Avatar src={milk} size="lg" alt="milk" variant="square" />
                            </Tooltip>
                            <Tooltip title="red meat">
                                <Avatar src={red_meat} alt="red meat" variant="square" />
                            </Tooltip>
                            <Tooltip title="poultry">
                                <Avatar src={poultry} alt="poultry" variant="square" />
                            </Tooltip>
                            <Tooltip title="seafood">
                                <Avatar src={fish} alt="fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="nuts">
                                <Avatar src={nuts} alt="nuts" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 0.5,
                        py: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is phosphorus absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Phosphorus is absorbed in the small intestine by passive diffusion." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={5} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What is the recommended dietary intake of phosphorus?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Human} />
                                </ListItemAvatar>
                                <ListItemText primary="The recommended intake of phosphorus required for adult man and woman is 1000 mg/d." />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 0.5
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens when you are deficient in phosphorus?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Deficiency in phosphorus could result in joint or bone pain, loss of appetite, fatigue, irritability or anxiety, and poor bone development in children.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 0.5
                        }}

                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="joint pain">
                                <Avatar src={joint_pain} size="lg" alt="joint pain" variant="square" />
                            </Tooltip>
                            <Tooltip title="loss appetite">
                                <Avatar src={loss_appetite} alt="loss appetite" variant="square" />
                            </Tooltip>
                            <Tooltip title="fatigue">
                                <Avatar src={fatigue} alt="fatigue" variant="square" />
                            </Tooltip>
                            <Tooltip title="irritability">
                                <Avatar src={irritability} alt="irritability" variant="square" />
                            </Tooltip>
                            <Tooltip title="poor bone development in children">
                                <Avatar src={fracture} alt="fracture" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>

                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 0.5
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the interactions of phosphorus with other nutrients?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Phosphorus hinders the absorption of zinc and Vit D increases the absorption of phosphorus." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}



