import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedOption } from "./selectedOptionSlice";
import image1 from "../../../../src/assets/images/menu/infant.png";
import image2 from "../../../../src/assets/images/menu/schoolchildren.png";
import image3 from "../../../../src/assets/images/menu/adolscentboy.png";
import image4 from "../../../../src/assets/images/menu/adolescentgirl.png";
import image5 from "../../../../src/assets/images/menu/adolscents.png";
import image6 from "../../../../src/assets/images/menu/adultmen.png";
import image7 from "../../../../src/assets/images/menu/adultwomen.png";
import image8 from "../../../../src/assets/images/menu/adults.png";
import image9 from "../../../../src/assets/images/menu/pregnantwomen.png";
import image10 from "../../../../src/assets/images/menu/lactatingwomen.png";
import image11 from "../../../../src/assets/images/menu/elderlycouple.png";
import indiaMap from "../../../assets/images/IndiaMap.png";
import * as d3 from "d3";
//import * as d3 from "d3/dist/d3.min";

import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const eachButton = [
  { label: 9, image: image11, color: "#2C74BE", tooltip: "Elderly" },
  { label: 8, image: image10, color: "#662C90", tooltip: "Delivery & && Motherhood" },
  { label: 7, image: image9, color: "#EF5A2A", tooltip: "Pregnancy & && Lactating Women" },
  { label: 11, image: image8, color: "#F8B13F", tooltip: "Adults" },
  { label: 6, image: image7, color: "#FCEF0A", tooltip: "Adult Women" },
  { label: 5, image: image6, color: "#8CC63C", tooltip: "Adult Men" },
  { label: 10, image: image5, color: "#3AB549", tooltip: "Adolescence" },
  { label: 4, image: image4, color: "#29A89F", tooltip: "Adolescent && Girls" },
  { label: 3, image: image3, color: "#3CADF1", tooltip: "Adolescent && Boys" },
  { label: 2, image: image2, color: "#2C74BE", tooltip: "School Age && Children" },
  { label: 1, image: image1, color: "#662C90", tooltip: "Infant & && Preschool" },
  { label: "Dashboard", image: null, color: null },
];

const ArcDesignLarge = () => {
  const navigate = useNavigate();
  const svgRef = useRef();
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.selectedOptionFromHome);
  // const buttonRadius = 380;
  const buttonRadius = Math.min(window.innerWidth + 1000, window.innerHeight + 1000) * 0.4;
  const anglePerButton = (2.0 * Math.PI) / 13;
  const outerRadius = buttonRadius * 0.9;
  const innerRadius = buttonRadius * 0.6;
  const outerRadiusHovered = outerRadius;
  const padAngle = 0.01;

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const g = svg
      .append("g")
      .attr("transform", `translate(${buttonRadius}, ${buttonRadius}) rotate(-10)`);
    let startAngle = 33.2;
    let endAngle = anglePerButton;
    const arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(startAngle)
      .endAngle(endAngle)
      .padAngle(padAngle);

    const arcHovered = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadiusHovered)
      .startAngle(startAngle)
      .endAngle(endAngle);

    // const textArc = d3
    //   .arc()
    //   .innerRadius(innerRadius)
    //   .outerRadius(outerRadius)
    //   .padAngle(padAngle);

    const textArc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius + 60)
      .padAngle(padAngle);

    const textArcFlipped = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius + 30)
      .padAngle(padAngle);

    // textArcFlipped.startAngle((d) => d.endAngle).endAngle((d) => d.startAngle);

    const buttons = eachButton.slice(0, 11).map((button, i) => ({
      onClick: () => {
        dispatch(setSelectedOption(button.label));
        navigate("/dashboard");
      },
      color: button.color,
      image: button.image,
      tooltip: button.tooltip,
    }));
    buttons.forEach((button, i) => {
      endAngle = startAngle + anglePerButton;
      arc.startAngle(startAngle).endAngle(endAngle);
      arcHovered.startAngle(startAngle).endAngle(endAngle);

      const group = g.append("g").style("cursor", "pointer").on("click", button.onClick);

      group.append("path").attr("d", arc).attr("fill", button.color);

      group
        .append("path")
        .attr("d", arcHovered)
        .attr("fill", "white")
        .style("opacity", 0)
        .on("mouseenter", function () {
          d3.select(this).transition().duration(100).style("opacity", 0.3);
        })
        .on("mouseleave", function () {
          d3.select(this).transition().duration(100).style("opacity", 0);
        });

      const a = startAngle + (endAngle - startAngle) / 2 - Math.PI / 2;
      const arcPath = (Math.cos(a) * (outerRadius + innerRadius)) / 2 - buttonRadius / 10;
      const arcHoveredPath = (Math.sin(a) * (outerRadius + innerRadius)) / 2 - buttonRadius / 10;
      // group
      //   .append("image")
      //   .attr("href", button.image)
      //   .attr("width", buttonRadius / 5)
      //   .attr("x", arcPath)
      //   .attr("y", arcHoveredPath);
      const foreignObject = group
        .append("foreignObject")
        .attr("x", arcPath) // Positioning the foreignObject at the same position as the image
        .attr("y", arcHoveredPath)
        .attr("width", buttonRadius / 5) // Set the width of the foreignObject to match the image width
        .attr("height", buttonRadius / 5); // Set the height of the foreignObject to match the image height

      foreignObject
        .append("xhtml:img") // Add the HTML img tag inside the foreignObject
        .attr("src", button.image) // Set the image source
        .style("width", "100%") // Set the width of the img tag to 100% to fill the foreignObject
        .style("height", "100%") // Set the height of the img tag to 100% to fill the foreignObject
        .style("border-radius", "30%");

      // Calculate mid-angle for positioning the text
      const midAngle = (startAngle + endAngle) / 2;
      let textArcStartAngle = startAngle + Math.PI; // Add a small offset for better alignment
      let textArcEndAngle = endAngle - Math.PI; // Add a small offset for better alignment

      let textArcToUse = textArc; // By default, use the regular textArc
      let startOffsetValue = "28.5%";
      if (
        button.tooltip === "Infant & && Preschool" ||
        button.tooltip === "School Age && Children" ||
        button.tooltip === "Adolescent && Boys" ||
        button.tooltip === "Adolescent && Girls"
      ) {
        [textArcStartAngle, textArcEndAngle] = [textArcEndAngle, textArcStartAngle];
        textArcToUse = textArcFlipped; // Use the flipped textArc for flipped texts
        startOffsetValue = "28.5%";
      }

      // // Add a textPath for the tooltip text to follow the curve of the button
      const textPath = g
        .append("path")
        .attr("id", `text-path-${i}`)
        .attr("d", textArcToUse({ startAngle: textArcStartAngle, endAngle: textArcEndAngle }))
        .style("fill", "none");

      // Add a textPath for the tooltip text to follow the curve of the button
      // Add the text element and attach it to the textPath
      var fontSize = buttonRadius / 27;
      const text = g
        .append("text")
        .append("textPath")
        .attr("xlink:href", `#text-path-${i}`)
        .attr("startOffset", startOffsetValue)
        .style("letter-spacing", "0.08em")
        .style("font-size", fontSize)
        .style("text-anchor", "middle")
        .style("font-weight", "bold")
        // .style("fill", button.tooltip === "Adult Women" ? "blue" : "snow");
        .style("fill", "snow");

      // Add two tspans to create two lines of text
      text
        .append("tspan")
        .text(button.tooltip.split(" && ")[0]) // Split the text at " & " and take the first part
        .attr("x", 0) // Set the x-position for the first line
        .attr("dy", "-0.8em"); // Set the y-position for the first line

      text
        .append("tspan")
        .text(button.tooltip.split(" && ")[1]) // Split the text at " & " and take the second part
        .attr("x", 0) // Set the x-position for the second line
        .attr("dy", "1em"); // Set the y-position for the second line

      // Raise the text above the background rectangle
      text.raise();

      // Add Tooltip to the image
      const tooltipGroup = group.append("g").style("display", "none");

      tooltipGroup
        .append("text")
        .attr("class", "tooltip-text")
        .text(button.tooltip.replace(" && ", " "))
        // .attr("x", arcPath + buttonRadius / 8.5)
        // .attr("y", arcHoveredPath + 180)
        .style("font-size", "1.5rem")
        .style("text-anchor", "middle")
        .style("font-weight", "bold")
        .style("fill", function () {
          if (button.tooltip === "Adult Women") {
            return "#5b7c99";
          } else {
            return button.color;
          }
        });

      const tooltipPadding = 10;
      const textElement = tooltipGroup.select("text");
      const textWidth = textElement.node().getBBox().width;
      const textHeight = textElement.node().getBBox().height - 10;

      tooltipGroup
        .append("rect")
        .attr("class", "tooltip-rect")
        .attr("width", textWidth + tooltipPadding * 2) // Adjust the width of the rectangle based on text width
        .attr("height", buttonRadius / 23.4 + tooltipPadding)
        .attr("fill", "white")
        .attr("stroke", button.color) // Add border color
        .attr("stroke-width", 2) // Set border width
        .attr("x", arcPath + buttonRadius / 100 - tooltipPadding - 32)
        .attr("y", arcHoveredPath + 162 - tooltipPadding);

      textElement.attr("x", arcPath + buttonRadius / 105 + (textWidth + 10) / 2 - 36);
      textElement.attr("y", arcHoveredPath + 158 + (buttonRadius / 23.4 + textHeight) / 2);
      textElement.raise();

      // Show tooltip on mouseenter
      group.on("mouseenter", function () {
        tooltipGroup.style("display", "block");
        d3.select(this).raise();
      });

      // Hide tooltip on mouseleave
      group.on("mouseleave", function () {
        tooltipGroup.style("display", "none");
        d3.select(this).lower();
      });

      startAngle = endAngle;
    });
    svg
      .append("image")
      .attr("href", indiaMap)
      .attr("width", buttonRadius - 65)
      .attr("height", buttonRadius - 65)
      .attr("x", buttonRadius / 1.7)
      .attr("y", buttonRadius / 1.9);
  }, [selectedOption]);

  return (
    <svg
      className="readial-component"
      viewBox={`0 0 ${buttonRadius * 2} ${buttonRadius * 2}`}
      ref={svgRef}
    />
  );
};

export default ArcDesignLarge;
