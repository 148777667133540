// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import nerve_impulse from '../../../../../assets/images/icons/minerals/Functions/nerve_impulse.png';
import muscle_contraction from '../../../../../assets/images/icons/minerals/Functions/muscle_contraction.png';
import fluid_balance from '../../../../../assets/images/icons/minerals/Functions/fluid_balance.png';
import nutrient_absorbption from '../../../../../assets/images/icons/minerals/Functions/nutrient_absorbption.png';
import headache from '../../../../../assets/images/icons/vitamins/vitaminb5/headache.png';
import vomit from '../../../../../assets/images/icons/vitamins/vitaminb5/vomit.png';
import confusion from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/confusion.png';
import weakness from '../../../../../assets/images/icons/minerals/Deficiency/weakness.png';
import muscle_cramps from '../../../../../assets/images/icons/minerals/Deficiency/muscle_cramps.png';
import seizure from '../../../../../assets/images/icons/minerals/Deficiency/seizure.png';
import salt from "../../../../../assets/FoodSources/Minerals/Sources/salt.png";
import sauce from "../../../../../assets/FoodSources/Minerals/Sources/sauce.png";
import mayonnaise from "../../../../../assets/FoodSources/Minerals/Sources/mayonnaise.png";
import ketchep from "../../../../../assets/FoodSources/Minerals/Sources/ketchep.png";
import pickles from "../../../../../assets/FoodSources/Minerals/Sources/pickles.png";
import canned_foods from "../../../../../assets/FoodSources/Minerals/Sources/canned_foods.png";
import bread from "../../../../../assets/FoodSources/Minerals/Sources/bread.png";
import packaged_foods from "../../../../../assets/FoodSources/Minerals/Sources/packaged_foods.png";




export default function Sodium() {
    return (
        <>

            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of sodium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={fluid_balance} />
                                </ListItemAvatar>
                                <ListItemText primary="It is an essential mineral for maintaining fluid/electrolyte balance." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={muscle_contraction} />
                                </ListItemAvatar>
                                <ListItemText primary="It plays a significant role in the process of muscle contraction." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={nerve_impulse} />
                                </ListItemAvatar>
                                <ListItemText primary="It is necessary for proper cell function and nerve impulse transmission." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={nutrient_absorbption} />
                                </ListItemAvatar>
                                <ListItemText primary="It helps our cells to uptake nutrients and water." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of sodium?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                The main source of sodium in diet is common salt (Sodium chloride-NaCl) which is approximately 40% sodium and 60% chloride, so about 90% of sodium comes from salt.
                            </ListItem>
                            <ListItem>
                                Most of the processed and packaged foods contains high amounts of sodium, as a preservative and taste enhancer.
                            </ListItem>
                            <ListItem>
                                Sauces, mayonnaise, ketchups, pickles, canned foods and breads are few of the commonly used foods that contain high amounts of sodium.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        mt={2}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
                            <Tooltip title="salt">
                                <Avatar src={salt} size="lg" alt="salt" variant="square" />
                            </Tooltip>
                            <Tooltip title="processed and packaged foods">
                                <Avatar src={packaged_foods} alt="processed and packaged foods" variant="square" />
                            </Tooltip>
                            <Tooltip title="sauces">
                                <Avatar src={sauce} alt="sauces" variant="square" />
                            </Tooltip>
                            <Tooltip title="mayonnaise">
                                <Avatar src={mayonnaise} alt="mayonnaise" variant="square" />
                            </Tooltip>
                            <Tooltip title="ketchep">
                                <Avatar src={ketchep} alt="ketchep" variant="square" />
                            </Tooltip>
                            <Tooltip title="pickle">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={pickles} alt="pickles" variant="square" />
                            </Tooltip>
                            <Tooltip title="canned foods">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={canned_foods} alt="canned foods" variant="square" />
                            </Tooltip>
                            <Tooltip title="bread">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={bread} alt="bread" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
                            <Tooltip title="pickle">
                                <Avatar src={pickles} alt="pickles" variant="square" />
                            </Tooltip>
                            <Tooltip title="canned foods">
                                <Avatar src={canned_foods} alt="canned foods" variant="square" />
                            </Tooltip>
                            <Tooltip title="bread">
                                <Avatar src={bread} alt="bread" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
            <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is sodium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Sodium is absorbed in the small intestine by passive diffusion." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 1, sm:0 }
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What is the recommended dietary intake of sodium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Human} />
                                </ListItemAvatar>
                                <ListItemText primary="A safe intake of <2000 mg of sodium per day, or <5 grams of salt per day, is advised due to increasing concerns about the prevalence of hypertension." />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is sodium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Sodium is absorbed in the small intestine by passive diffusion." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>

                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the signs and symptoms of sodium deficiency?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Deficiency of sodium in the body could cause headache, nausea, vomiting, confusion, drowsiness, fatigue, lack of energy, muscle cramps, and seizures.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="headache">
                                <Avatar src={headache} size="lg" alt="headache" variant="square" />
                            </Tooltip>
                            <Tooltip title="vomiting">
                                <Avatar src={vomit} alt="vomiting" variant="square" />
                            </Tooltip>
                            <Tooltip title="confusion">
                                <Avatar src={confusion} alt="confusion" variant="square" />
                            </Tooltip>
                            <Tooltip title="lack of energy ">
                                <Avatar src={weakness} alt="lack of energy" variant="square" />
                            </Tooltip>
                            <Tooltip title="muscle cramps ">
                                <Avatar src={muscle_cramps} alt="muscle cramps" variant="square" />
                            </Tooltip>
                            <Tooltip title="seizures">
                                <Avatar src={seizure} alt="seizures" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the interactions of sodium with other nutrients?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Sodium hinders the absorption of potassium to a small extent, when consumed together." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How can you reduce sodium intake in the diet?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                            <ListItem>
                                Choose fresh vegetables and fruits over canned ones as they contain preservative in which sodium is an essential component.
                            </ListItem>
                            <ListItem>
                                Read back of the label for Sodium quantity information and check nutrition facts label for claims like “low sodium,” “reduced sodium,” or “no salt added”.
                            </ListItem>
                            <ListItem>
                                High sodium snacks like, namkeens, papads, pickles and chips must be avoided.
                            </ListItem>
                            <ListItem>
                                Avoid purchasing a product, in which salt is listed as the first five ingredient in the ingredient list.
                            </ListItem>
                            <ListItem>
                                Do not add extra salt in rice and dough for chapatis, pori, and paratha and avoid sprinkling salt on salad, cut fruits, curd or cooked vegetables.
                            </ListItem>
                            <ListItem>
                                Choose alternatives such as garlic, citrus juice, salt-free seasonings, or spices to replace or reduce the amount of salt while cooking.
                            </ListItem>
                            <ListItem>
                                Moreover, Sodium recommendation for hypertensive individuals is {"<1500"} mg/day which is around 3.7g of salt, so further reduction in salt intake is recommended.
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}



