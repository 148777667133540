import React, { useState, useEffect } from "react";
import MapChart from "./components/Map";
import mapData from "../../maps/2022/india_state_nfhs5.json";
import {
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Dropdown from "./components/dropdown/Dropdown";
import Highcharts from "highcharts/highstock";
import DataTable from "./components/DataTable";
import BarChart from "./charts/BarChart";
import StatedrilldownMap from "./charts/StatedrilldownMap";
import IndiaDistrictMap from "./charts/IndiaDistrictMap";
import IndiaStateMap from "./charts/IndiaStateMap";
import { Box } from "@mui/system";
import StateDataTable from "./charts/StateDataTable";
import India2014 from "./charts/India2014";
import India2013 from "./charts/India2013";
import GeoMap from "./charts/GeoMap";
import India2019 from "./charts/india2019";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { getApiData } from "../dashboard/components/dropdown/dropdownSlice";
import SelectedDropdownData from "./components/dropdown/SelectedDropdownData";
import Spinner from "../../components/spinner";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Dashboard = () => {
  const [filteredData, setFilteredData] = useState([]);
  const apiData = useSelector(getApiData);
  const [isLoading, setIsLoading] = useState(true);
  const [areFieldsSelected, setAreFieldsSelected] = useState(false);

  useEffect(() => {
    // Simulate API data loading
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  //console.log(process.env);
  const handleAreFieldsSelectedChange = (value) => {
    setAreFieldsSelected(value);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <Grid spacing={1} mt={2}>
          <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center">
            <Dropdown onAreFieldsSelectedChange={handleAreFieldsSelectedChange} />
          </Grid>
        </Grid>
        {areFieldsSelected ? (
          // Render the chart and other elements here
          <Grid>
            <Grid>{isLoading || apiData === null ? <Spinner /> : <India2014 />}</Grid>
            <SelectedDropdownData />
          </Grid>
        ) : (
          // Display the text when all fields are not selected
          <div style={{ textAlign: "center", margin: "20px" }}>
            <p>Select all the indicators to get the data.</p>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};
export default Dashboard;
