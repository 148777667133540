import React from "react";
import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MyPlateSvg from "./MyPlateSvg";
import MyPlateCompleteTable from "./MyPlateCompleteTable";



const MyPlateMainPage = () => {
  return (
    <>
      <Container fixed fluid maxWidth="lg">
        <Typography variant="h3" style={{ fontSize: '2.5rem', fontFamily: 'Open Sans, sans-serif', fontWeight: 'bold', color: '#FF4081', marginTop: '1em' }}>
          My Plate For The Day
        </Typography>
        <Typography variant="subtitle2" style={{ fontSize: '1rem', fontFamily: 'Open Sans, sans-serif', fontWeight: 'bold', color: '#555', marginTop: '0.5em', marginBottom: '0.5em' }}>
          Promotes Health, Prevents Hidden Hunger, and Protects from Diseases
        </Typography>
        <MyPlateCompleteTable />

        <Grid container direction="row" mb={2} style={{ padding: '0.5em', background: '#f5f5f5', color: 'black' }}>

          <Grid item xs={12} md={6}>
            <List >
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="Consumption of proportions of food groups indicated in the plate helps prevent macronutrient & micronutrient malnutrition (Hidden Hunger)" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="The proportion indicated in the plate ensures adequate intake of all
                micronutrients (vitamins & minerals), bioactive compounds, functional
                foods, antioxidants etc." />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="No vitamin or mineral (micronutrients) supplements will provide the
                adequacy of all nutrients that can be met from this model plate" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="Routine consumption of certain nutrients as supplements will interfere with
                absorption of other nutrients" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="Micronutrients from food are better absorbed and more bioavailable than
vitamin and mineral supplements/tablets/ capsules/ fortification" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="A complete diet as indicated in the model plate provides many unknown
substances/nutrients that cannot be met from supplements or fortified foods" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{ mt: 3, color: "#FF4081", fontWeight:'bold'}}>
              Regular consumption of foods in proportions as per the model plate
            </Typography>
            <List>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="improves immunity and resistance to infections" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="maintains good microbial flora (beneficial bacteria in the intestine)" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="prevents Diabetes Mellitus, Cardiovascular Diseases (CVDs) such as
heart attack, stroke and many other diseases" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="maintains appropriate alkalinity and thereby reduces inflammation and
decreases chances of kidney stone formation" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="prevents insulin resistance and maintains appropriate insulin sensitivity
and glycaemic index" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="ensures adequate intake of fibre and therefore prevents constipation" />
              </ListItem>
              <ListItem alignItems="center" sx={{ textAlign: "justify", mb: -2 }}>
                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                  <CheckCircleIcon style={{ color: '#FF4081' }} />
                </ListItemIcon>
                <ListItemText primary="prevents adverse effects of environmental pollution and toxins such as
heavy metals and pesticides by working as a detoxifying diet" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <MyPlateSvg />
      </Container>
    </>
  );
};

export default MyPlateMainPage;