import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import WaterChestnuts from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/1_WaterChestnuts.jpg"
import Karonda from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/2_Karonda.jpg"
import Sundakkai from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/3_Sundakkai.png"
import LotusStem from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/4_LotusStem.jpg"
import SeeWeed from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/5_SeeWeeds.jpg"
import Beans from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/6_Beans.png"
import JackSeeds from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/7_jackSeeds.jpg"
import RedgramTender from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/8_RedgramTender.jpg"
import Plantain from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/9_Plantain.jpg"
import Peas from "../../../../../../assets/FoodSources/Carbohydrate/Vegetables/10_Peas.jpg"
import { Typography } from '@mui/material';

export default function Vegetables() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: WaterChestnuts,
    title: 'Water chestnut, dry',
    author: '68.9 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Karonda,
    title: 'Karonda dry',
    author: '67.1 per 100gm',
  },
  {
    img: Sundakkai,
    title: 'Sundakai, dry',
    author: '55 per 100gm',
  },
  {
    img: LotusStem,
    title: 'Lotus stem, dry',
    author: '51.4 per 100gm',
    cols: 2,
  },
  {
    img: SeeWeed,
    title: 'Sea weeds,dry',
    author: '51.2 per 100gm',
  },
  {
    img: Beans,
    title: 'Beans, scarlet runner',
    author: '28.9 per 100gm',
    cols: 2,
  },
  {
    img: JackSeeds,
    title: 'Jackfruit seed',
    author: '25.8 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: RedgramTender,
    title: 'Red gram, tender, fresh ',
    author: '19.46 per 100gm',
  },
  {
    img: Plantain,
    title: 'Plantain, green ',
    author: '17.58 per 100gm.4',
  },
  {
    img: Peas,
    title: 'Peas, fresh',
    author: '11.88 per 100gm',
    rows: 2,
    cols: 2,
  },
];