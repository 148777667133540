import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apiData: null,
};

export const selectedDropdownDataSlice = createSlice({
  name: 'selectedDropdownData',
  initialState,
  reducers: {
    setApiData: (state, action) => {
      state.apiData = action.payload;
    },
  },
});

export const { setApiData } = selectedDropdownDataSlice.actions;
export const getApiData = (state) => state.selectedDropdownData.apiData;

export default selectedDropdownDataSlice.reducer;