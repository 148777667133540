import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

import Button from "@mui/material/Button";

import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import routes from "../routes";

import BottomAppBar from "./menu/BottomAppBar";
import PositionedMenu from "./menu/PositionedMenu";
function ResponsiveAppBar() {
 
  const [, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ background: "#f5f5f5" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/Home"
            sx={{
              mr: 1,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 600,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="nutritionatlas logo" style={{ height: "50px" }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <BottomAppBar />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 1,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 600,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="nutritionatlas logo" style={{ height: "40px" }} />
          </Typography>
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "flex-end" }}
          >
            {routes.map((route) => (
              <Button
                key={route.key}
                icon={route.icon}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#6c6666", display: "block" }}
              >
                <IconButton color="inherit" aria-label="icon" component="label">
                  {route.icon}
                </IconButton>

                <Link style={{ textDecoration: "none", color: "black" }} to={route.route}>
                  {route.name}
                </Link>
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <PositionedMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
