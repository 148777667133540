import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import GardenCress from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/1_GardenCress.jpg"
import SunflowerSeeds from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/2_Sunflower.jpg"
import PineSeed from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/3_PineSeeds.jpg"
import GroundnutRoasted from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/4_GroundnutRoasted.jpg"
import CashewNut from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/5_CashewNut.jpg"
import NigerSeeds from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/6_NigerSeeds.jpg"
import GroundNut from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/7_GroundNut.jpg"
import MustardSeed from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/8_MustardSeeds.jpg"
import PistachioNuts from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/9_PistachioNut.jpg"
import PumpkinSeeds from "../../../../../../assets/FoodSources/Carbohydrate/Oils and fats/10_PumpkinSeeds.jpg"
import { Typography } from '@mui/material';

export default function NutsAndOils() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: GardenCress,
    title: 'Garden cress, seeds',
    author: '33.66 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: SunflowerSeeds,
    title: 'Sunflower Seeds',
    author: '30.18 per 100gm',
  },
  {
    img: PineSeed,
    title: 'Pine seeds',
    author: '26.77 per 100gm',
  },
  {
    img: GroundnutRoasted,
    title: 'Groundnut roasted',
    author: '26.7 per 100gm',
    cols: 2,
  },
  {
    img: CashewNut,
    title: 'Cashew nut ',
    author: '25.46 per 100gm',
    cols: 2,
  },
  {
    img: NigerSeeds,
    title: 'Niger seeds (black & gray)',
    author: '22.98 – 20.59 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: GroundNut,
    title: 'Ground nut',
    author: '17.27 per 100gm',
  },
  {
    img: MustardSeed,
    title: 'Mustard seeds',
    author: '16.8 per 100gm.4',
  },
  {
    img: PistachioNuts,
    title: 'Pistachio nuts',
    author: '15.82 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: PumpkinSeeds,
    title: 'Pumpkin seeds',
    author: '15.6 per 100gm',
  }
];