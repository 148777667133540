import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function BarChart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://nutritionatlasapi.cyclic.app/api/data?source_id=1&indicator_id=1&area_level=2")
      .then((response) => response.json())
      .then((data) => {
        setData(data.results);
      });
  }, []);

  //   const data1 = data.length > 0 ? data.map((item) => item.area_name) : "notfound";
  //   const data1 = data.map((item) => item.value);
  var parsedData = [];

  data.forEach((element) => {
    parsedData.push({
      name: element.area_name,
      value: parseInt(element.value),
    });
  });
  console.log(parsedData);
  const options = {
    chart: {
      type: "packedbubble",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Bar Chart",
    },
    xAxis: {
      categories: data.length > 0 ? data.map((item) => item.area_name) : [],
    },
    yAxis: {
      title: {
        text: "stunting values",
      },
    },
    plotOptions: {
      packedbubble: {
        minSize: "30%",
        maxSize: "120%",
        zMin: 0,
        zMax: 1000,
        layoutAlgorithm: {
          splitSeries: false,
          gravitationalConstant: 0.02,
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          filter: {
            property: "y",
            operator: ">",
            value: 250,
          },
          style: {
            color: "black",
            textOutline: "none",
            fontWeight: "normal",
          },
        },
      },
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      shadow: true,
    },
    series: parsedData,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default BarChart;
