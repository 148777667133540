import * as React from "react";
import { Box, CardHeader, CardMedia, CssBaseline, Divider, Grid, ListItemIcon } from "@mui/material";
import Sheet from "@mui/joy/Sheet";
// import "./Vitamins.css";

import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Container,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { AspectRatio, AvatarGroup, CardOverflow, ListItemContent, ListItemDecorator, Tooltip } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";

import Dietaryfibreicon from "../../../../../../src/assets/images/dietaryfibre.png";
import dietaryfiber_main from "../../../../../../src/assets/images/dietaryfiber_main.png";
import Dietaryfibreicon1 from "../../../../../../src/assets/images/dietaryfibre1.png";
import Dietaryfibreicon2 from "../../../../../../src/assets/images/dietaryfibre2.png";
import Bullet from "../../../../../../src/assets/images/bullet.png";
import Human from "../../../../../../src/assets/FoodSources/Carbohydrate/user.png";
import bodyenergy from "../../../../../../src/assets/images/icons/fats/bodyenergy.png";
import absorption from "../../../../../../src/assets/images/icons/fats/absorption.png";
import palatability from "../../../../../../src/assets/images/icons/fats/palatability.png";
import braingrowth from "../../../../../../src/assets/images/icons/fats/braingrowth.png";
import healthyskin from "../../../../../../src/assets/images/icons/Protein/movement.png";
import bowel_health from "../../../../../../src/assets/images/icons/fibre/bowel_health.png";
import bread from "../../../../../../src/assets/images/icons/fibre/bread.png";
import nutrition_label from "../../../../../../src/assets/images/icons/fibre/nutrition_label.jpg";
import constipation from "../../../../../../src/assets/images/icons/fibre/constipation.png";
import fruits from "../../../../../../src/assets/images/icons/fibre/fruits.png";
import nuts from "../../../../../../src/assets/FoodSources/Minerals/Sources/nuts.png";
import vegetables from "../../../../../../src/assets/FoodSources/Minerals/Sources/vegetables.png";
import green_beans from "../../../../../../src/assets/FoodSources/Minerals/Sources/green_beans.png";
import dried_fruits from "../../../../../../src/assets/FoodSources/Minerals/Sources/dried_fruits.png";
import hdl from "../../../../../../src/assets/images/icons/fibre/hdl.png";
import pop_corn from "../../../../../../src/assets/images/icons/fibre/pop_corn.png";
import salad from "../../../../../../src/assets/images/icons/fibre/salad.png";
import sugar_blood_level from "../../../../../../src/assets/images/icons/fibre/sugar_blood_level.png";
import weight_scale from "../../../../../../src/assets/images/icons/fibre/weight_scale.png";
import Stack from '@mui/joy/Stack';
import { FcOk } from "react-icons/fc";
import { AiFillCheckCircle } from "react-icons/ai"
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GridCheckCircleIcon } from "@mui/x-data-grid";

export default function Dietaryfibre() {
  const theme = useTheme();
  const myRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const buttonRefat = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openfat, setOpenfat] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFat = () => {
    setOpenfat(false);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("backToTop");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div style={{ overflowX: "hidden" }} id="backToTop">
      <React.Fragment>
        <CssBaseline />
        <Container fixed m={2} maxWidth="lg">
          <Typography level="h1" fontSize="2em" color="#000" fontWeight="bold" marginTop="0.5em">
            Dietary Fibre
          </Typography>
          <Grid container direction="row">
            <Grid item lg={4} md={4} sm={12}>
              <Box>
                <img src={dietaryfiber_main} alt="Fats icon" width="100%" />
              </Box>
            </Grid>
            <Grid item lg={8} md={8} sm={12}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  marginTop: "20px",
                  maxHeight: { xs: "100%", md: "300px" },
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  {/* <p align="left">
                    <strong>Dietary Fibre : </strong>
                  </p> */}
                  <ul align="justify">
                    <li>Dietary Fibre is carbohydrates that cannot be fully digested in the gut.</li>
                    <li>
                      Some parts of the plants are not digested in the human digestive track due to
                      lack of enzymes. These are then fermented in large intestine by gut microflora
                      producing gas and some beneficial prodcts.
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid md={12} id="dropdownSection">
            <Fab
              color="primary"
              aria-label="scroll back to top"
              onClick={handleClickScroll}
              style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Grid>
          <Grid md={12} item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
              <Typography level="h5" gutterBottom m={2}>
                Fibre
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            </div>
          </Grid>
          <Typography
            level="h6"
            sx={{ textAlign: "left", marginBottom: 1 }}
          >
            Dietary fibre is classified into two categories : Insoluble fibres: cellulose, hemicellulose, lignin and the soluble fibres: pectin, gums and mucilages.
          </Typography>

          <Grid container alignItems="stretch">
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card variant="outlined" sx={{ height: "100%", }} boxshadow={3}>
                  <CardHeader title="Soluble Fibre" sx={{ backgroundColor: "#9494f1" }} />
                  {/* <CardMedia
                    component="img"
                    height="280"
                    image={Dietaryfibreicon1}
                    alt="Paella dish"
                  /> */}
                  <CardMedia
                    component="img"
                    sx={{
                      height: { xs: 'auto', sm: 280, md: 280, lg: 280, xl: 280 },
                    }}
                    image={Dietaryfibreicon1}
                    alt="Paella dish"
                  />
                  <CardContent>

                    <List
                      aria-labelledby="ellipsis-list-demo"
                      sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px', '& > li': { marginBottom: '-4px' } }}
                    >
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">This type of fiber dissolves in water to form a gel-like material.</Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">It can help lower blood cholesterol and glucose levels.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Short-chain fatty acids (SCFAs) are produced when bacteria in the large intestine digest soluble fiber. SCFA’s are essential for gut, body and brain health.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Soluble fiber is found in oats, peas, beans, apples, citrus fruits, carrots, barley and psyllium.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card variant="outlined" sx={{ height: "100%" }} boxshadow={3}>
                  <CardHeader title="Insoluble Fibre" sx={{ backgroundColor: "#9494f1" }} />
                  {/* <CardMedia
                    component="img"
                    height="280"
                    image={Dietaryfibreicon2}
                    alt="Paella dish"
                  /> */}
                  <CardMedia
                    component="img"
                    sx={{
                      height: { xs: 'auto', sm: 280, md: 280, lg: 280, xl: 280 },
                    }}
                    image={Dietaryfibreicon2}
                    alt="Paella dish"
                  />
                  
                  <CardContent>

                    <List
                      aria-labelledby="ellipsis-list-demo"
                      sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px', '& > li': { marginBottom: '-4px' } }}
                    >
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">
                            This type of fiber promotes the movement of food material through the digestive system.</Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Insoluble fibre adds bulk to the stool and promotes laxation.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Prevent conditions like hemorrhoids, diverticulosis, and constipation.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Whole-wheat flour, wheat bran, nuts, beans and vegetables, such as cauliflower, green beans and potatoes, are good sources of insoluble fiber.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start">
                  Why is fibre beneficial to health?
                </Typography>
                <CardContent>
                  {/* <List sx={{ textAlign: "justify", '& > li': { marginBottom: '-5px' }, }}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={bodyenergy} />
                      </ListItemAvatar>
                      <ListItemText primary="Promotes regular bowel movements: Fiber adds bulk and weight to stool, making it easier to pass and reducing the likelihood of constipation. It can also help solidify loose stools by absorbing water and increasing stool bulk." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={bodyenergy} />
                      </ListItemAvatar>
                      <ListItemText primary="Supports bowel health: Consuming ample fiber may decrease the risk of hemorrhoids and diverticular disease, colorectal cancer, possibly through fermentation of fiber in the colon." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={bodyenergy} />
                      </ListItemAvatar>
                      <ListItemText primary="Lowers cholesterol: Soluble fiber can help reduce total cholesterol levels, including 'bad' LDL cholesterol. Additionally, high-fiber foods may have heart-healthy effects such as reducing blood pressure and inflammation." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={bodyenergy} />
                      </ListItemAvatar>
                      <ListItemText primary="Controls blood sugar: Soluble fiber can slow down sugar absorption thus improving blood sugar levels in diabetics." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={bodyenergy} />
                      </ListItemAvatar>
                      <ListItemText primary="Aids in weight management: High-fiber foods are more filling, leading to reduced calorie intake and increased satisfaction." />
                    </ListItem>
                  </List> */}
                  <List
                    aria-labelledby="ellipsis-list-demo"
                    sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px' }}
                  >
                    <ListItem>
                      <ListItemDecorator sx={{ marginRight: 1 }}>
                        <Avatar src={constipation} sx={{ width: 50, height: 50 }} variant="rounded" /> {/* Use "variant" prop */}
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography level="title-sm">Promotes regular bowel movements: Fiber adds bulk and weight to stool, making it easier to pass and reducing the likelihood of constipation. It can also help solidify loose stools by absorbing water and increasing stool bulk.</Typography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ marginRight: 1 }}>
                        <Avatar src={bowel_health} sx={{ width: 50, height: 50 }} variant="rounded" />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography level="title-sm">Supports bowel health: Consuming ample fiber may decrease the risk of hemorrhoids and diverticular disease, colorectal cancer, possibly through fermentation of fiber in the colon.
                        </Typography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ marginRight: 1 }}>
                        <Avatar src={hdl} sx={{ width: 50, height: 50 }} variant="rounded" />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography level="title-sm">Lowers cholesterol: Soluble fiber can help reduce total cholesterol levels, including "bad" LDL cholesterol. Additionally, high-fiber foods may have heart-healthy effects such as reducing blood pressure and inflammation.
                        </Typography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ marginRight: 1 }}>
                        <Avatar src={sugar_blood_level} sx={{ width: 50, height: 50 }} variant="rounded" />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography level="title-sm">Controls blood sugar: Soluble fiber can slow down sugar absorption thus improving blood sugar levels in diabetics.
                        </Typography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ marginRight: 1 }}>
                        <Avatar src={weight_scale} sx={{ width: 50, height: 50 }} variant="rounded" />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography level="title-sm">Aids in weight management: High-fiber foods are more filling, leading to reduced calorie intake and increased satisfaction.
                        </Typography>
                      </ListItemContent>
                    </ListItem>
                  </List>

                </CardContent>

              </Card>
            </Grid>
            <Grid xs={12} md={6} p={1} item>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start">
                  What is the recommended dietary intake of fibre?
                </Typography>
                <CardContent>
                  <List aria-labelledby="ellipsis-list-demo"
                    sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px' }}>
                    <ListItem>
                      <ListItemDecorator sx={{ marginRight: 1 }}>
                        <Avatar alt="Remy Sharp" src={Human} />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography level="title-sm">According to ICMR NIN, about 15g/1000 kcal and 30g/2000 kcal are considered
                          as safe intake for adults.
                        </Typography>
                      </ListItemContent>
                    </ListItem>
                  </List>

                </CardContent>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 2
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start" >
                  Tips for incorporating more fiber into your diet:
                </Typography>
                <List sx={{ textAlign: "left" }}>
                  <ListItem>
                    To make your diet rich in fibre, look for "whole grain," "bran," or "fibre," or add unprocessed options in cereals, bakery products in place of processed products.
                  </ListItem>
                  <ListItem>
                    Incorporate more legumes like beans, peas, and lentils into soup or salads.
                  </ListItem>
                  <ListItem>
                    Eat plenty of fruits and vegetables, aiming for five or more servings daily.
                  </ListItem>
                  <ListItem>
                    Choose fiber-rich snacks such as fresh fruits, raw vegetables, low-fat popcorn, whole-grain crackers, nuts, or dried fruits.
                  </ListItem>
                </List>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  mt={1}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.3, sm: 1 } }}>
                    <Tooltip title="whole grain">
                      <Avatar src={bread} alt="whole grain" variant="square" />
                    </Tooltip>
                    <Tooltip title="legumes like beans, peas">
                      <Avatar src={green_beans} alt="peas" variant="square" />
                    </Tooltip>
                    <Tooltip title="lentils into soup or salads">
                      <Avatar src={salad} alt="salad" variant="square" />
                    </Tooltip>
                    <Tooltip title="fruits">
                      <Avatar src={fruits} alt="fruits" variant="square" />
                    </Tooltip>
                    <Tooltip title="vegetables">
                      <Avatar src={vegetables} alt="vegetables" variant="square" />
                    </Tooltip>
                    {/* <Tooltip title="popcorn">
                      <Avatar src={pop_corn} alt="popcorn" variant="square" />
                    </Tooltip> */}
                    <Tooltip title="nuts">
                      <Avatar src={nuts} alt="nuts" variant="square" />
                    </Tooltip>
                    <Tooltip title="dried fruits">
                      <Avatar src={dried_fruits} alt="dried fruits" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>
              </Card>
            </Grid>


          </Grid>
          <Grid container direction="row">
            <Grid xs={12} md={6} p={1} item>
              {/* <Typography
                variant="h5"
                sx={{ textAlign: "left", marginBottom: 1, fontSize: "1.3rem", fontWeight: "bold" }}
              >
                Why is fibre beneficial to health?
              </Typography>
              <Box sx={{ textAlign: "left" }}>
                <Typography mt={1}>
                  Maintaining good health and preventing diseases including colon cancer, diabetes,
                  heart disease, and obesity by limiting the digestion and absorption of
                  macronutrients.
                </Typography>
              </Box> */}
              <AspectRatio ratio="1" sx={{ display: { xs: 'block', sm: 'none' }, mt: 2 }}>
                <img
                  src={nutrition_label}
                  // srcSet="https://images.unsplash.com/photo-1507833423370-a126b89d394b?auto=format&fit=crop&w=90&dpr=2 2x"
                  loading="lazy"
                  alt=""
                />
              </AspectRatio>
              <Card orientation="horizontal" variant="outlined" sx={{ display: 'flex', alignItems: 'center', }}>
                <CardOverflow>
                  <AspectRatio ratio="1" sx={{ width: "13em", display: { xs: 'none', sm: 'block' } }}>
                    <img
                      src={nutrition_label}
                      // srcSet="https://images.unsplash.com/photo-1507833423370-a126b89d394b?auto=format&fit=crop&w=90&dpr=2 2x"
                      loading="lazy"
                      alt=""
                    />
                  </AspectRatio>
                  {/* <CardMedia
                  component="img"
                  height="auto" // Height will adjust based on the image's aspect ratio
                  image={nutrition_label}
                  alt="Nutrition label image"
                  
                /> */}
                </CardOverflow>
                <CardContent>
                  <Typography level="h5" sx={{ textAlign: "left", px: 1 }}>
                    Nutrition Facts Label :
                  </Typography>
                  <Typography textAlign="justify" p={1}>
                    Packaged foods can be regarded as high or rich source of dietary fiber if the product contains at least 6 g per 100 g.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6} p={1} item>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start">
                  What happens when there is over consumption of fibre?
                </Typography>
                <List sx={{ textAlign: "justify", '& > li': { marginBottom: '-8px' } }}>
                  <ListItem>
                    Increase your fiber intake gradually over a few weeks to avoid gastrointestinal discomfort like gas, bloating, and cramping. Remember to stay hydrated by drinking plenty of water, as fiber absorbs water to soften and bulk up stool effectively.
                  </ListItem>
                  <ListItem>
                    Over consumption of fibre can hamper micronutrient absorption.
                  </ListItem>
                </List>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}
