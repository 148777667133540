import * as React from "react";
import { Box, CssBaseline, Grid } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";

import { FcOk } from "react-icons/fc";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SankeyChart from "./sankeychart";
import { Team } from "./Team";

export default function Aboutus() {
  const theme = useTheme();

  const handleClickScroll = () => {
    const element = document.getElementById("backToTop");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div style={{ overflowX: "hidden" }} id="backToTop">
      <React.Fragment>
        <CssBaseline />
        <Container fixed m={2} maxWidth="lg">
          <Typography level="h1" fontSize="2em" color="#000" fontWeight="bold" marginTop="0.5em">
            Nutrition Atlas
          </Typography>
          <Grid container direction="row">
            <Typography variant="body1">
              Nutrition Atlas is a dynamic and interactive web Application Funded by Indian Council
              Medical Research and Intiated & developed by ICMR National Institute of Nutrition. It
              gives an overview of nutritional deficiencies and disorders prevalent in the country
              along with all-important nutrition related databases and vital statistics sourced from
              various authentic surveys.
            </Typography>
          </Grid>
          <Grid md={12} mt={4} id="dropdownSection">
            <Fab
              color="primary"
              aria-label="scroll back to top"
              onClick={handleClickScroll}
              style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Grid>
          <Grid md={12} mt={1} item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
              <Typography variant="h5" gutterBottom m={2}>
                What is Nutrition Atlas 2.0?
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            </div>
          </Grid>
          <Grid container alignItems="stretch">
            <Grid item>
              <Box mb={4}>
                <Typography variant="body1">
                  “Nutrition Atlas 2.0” is not only a revised and updated version but a completely a
                  new avatar of Nutrition Atlas launched by NIN in 2017. The current web application
                  is developed as a one-stop ready-reckoner that enables users to access nutrition
                  data from different time points in the past to present by age, gender,
                  physiological group and geography.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid xs={12} md={12} p={1} item>
              <Typography
                variant="h5"
                sx={{ textAlign: "left", marginBottom: 1, fontSize: "1.3rem", fontWeight: "bold" }}
              >
                Where are the data sourced from?
              </Typography>
              <Typography variant="body1">
                National Family Health Surveys (NFHS I-V) | District Level Household and Facility
                Surveys (DLHS I-IV)| Annual Health Surveys (AHS 2011-2013, CAB) | National Nutrition
                Monitoring Bureau (NNMB 2006, 2009, 2012, 2017) | Comprehensive National Nutrition
                Survey (CNNS) | Longitudinal Aging Study in India Wave I (LASI WAVE I)
              </Typography>
              <SankeyChart />
            </Grid>
          </Grid>
          <Grid xs={12} md={6} p={1} item>
            <Typography
              variant="h5"
              sx={{ textAlign: "left", marginBottom: 1, fontSize: "1.3rem", fontWeight: "bold" }}
            >
              What are the special features of Nutrition Atlas 2.0?
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Grid item md={12}>
              <Box sx={{ textAlign: "left" }}>
                <Typography align="justify" p={1}>
                  <FcOk /> The Application follows Responsive web design (RWD) to retain its optimal
                  usability and appearance automatically adjusted to screen size of device it’s
                  displayed on.
                </Typography>

                <Typography align="justify" p={1}>
                  <FcOk /> The charts, geo-maps, tabular data visualized on dashboard can be
                  downloaded in multiple file formats like PDF, PNG, JPEG, SVG and PPTX for use in
                  academics, research and education.
                </Typography>
                <Typography align="justify" p={1}>
                  <FcOk /> Geo-maps and charts can drill down into particular focused areas at micro
                  level.
                </Typography>
                <Typography align="justify" p={1}>
                  <FcOk /> The nutrient requirements (RDA & EAR) of ICMR-NIN have been visualized in
                  form of sunburst chart using globally accepted color gradients.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid md={10} item style={{ marginBottom: "6rem" }}>
            <Box sx={{ textAlign: "left" }}>
              <Typography align="justify" p={1}>
                <FcOk /> The application compliance to Model website accessibility Policy of GoI in
                order to provide equal access to visually impaired and color-blind people.
              </Typography>

              <Typography align="justify" p={1}>
                <FcOk /> A New compare section module has been introduced which compares the data of
                multiple sources of different timelines with similar indicators.
              </Typography>
              <Typography align="justify" p={1}>
                <FcOk /> Entire Application is developed in three parts to allow future updation of
                Database / Design / Vital statics individually as per the requirement without
                disturbing other components.
              </Typography>
              <Typography align="justify" p={1}>
                <FcOk /> Application is light weight, optimized and fragmented in way that it will
                utilize very minimal amount of device memory and network.
              </Typography>
              <Typography align="justify" p={1}>
                <FcOk /> Additional information is provided in footnote section of every chart with
                a minimal description of indicator, its standard deviations, and survey information
                of states and districts covered.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={6} p={1} item>
            <Typography
              variant="h5"
              sx={{ textAlign: "left", marginBottom: 1, fontSize: "1.3rem", fontWeight: "bold" }}
            >
              What are the components of Nutrition Atlas 2.0?
            </Typography>
            <Box sx={{ textAlign: "left" }}>
              <Typography mt={1}>
                <Box
                  component="span"
                  sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF4081", color: "#FFFFFF" }}
                >
                  <strong>Home:</strong>
                </Box>{" "}
                This is a creative, colourful webpage providing easy maneuverability to information
                from various datasets. The design of two conjoining circles one representing the
                ‘current nutrition status of population’ and the other ‘nutrition guidelines and
                education’ have been depicted as a symbol of infinity. This infinity design
                indicates the motto of the web application - from awareness to knowledge of
                nutrition for infinity of health.
              </Typography>
              <Typography mt={1}>
                <Box
                  component="span"
                  sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF4081", color: "#FFFFFF" }}
                >
                  <strong>Dashboard:</strong>
                </Box>{" "}
                Multi-level drop down menu gives access to nutrition indicators and relevant sub
                indicators of different stages of life cycle from authentic sources.
              </Typography>
              <Typography mt={1}>
                <Box
                  component="span"
                  sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF4081", color: "#FFFFFF" }}
                >
                  <strong>Compare:</strong>
                </Box>{" "}
                A given nutrition assessment indicator can be compared from multiple data sources
                and illustrated in the form of heat maps, graphs and tables.
              </Typography>
              <Typography mt={1}>
                <Box
                  component="span"
                  sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF4081", color: "#FFFFFF" }}
                >
                  <strong>NutriEducation:</strong>
                </Box>{" "}
                This page provides detailed information on macro and micronutrients, dietary
                guidelines, recommended dietary allowances as per the standard guidelines of
                ICMR-NIN. There is also a section on nutrition games.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <Team />
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}
