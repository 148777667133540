import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import "./Vitamins.css";

import { useTheme } from "@mui/material/styles";
import { AspectRatio, AvatarGroup, CardOverflow, ListItemContent, ListItemDecorator, Tooltip } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import Vitamins from "../../../../../../src/assets/images/icons/vitamins/vitaminwallpaper.png";
import Vitaminb1icon from "../../../../../assets/images/icons/vitamins/vitaminb1.png";
import Vitaminb2icon from "../../../../../assets/images/icons/vitamins/vitaminb2.png";
import Vitaminb3icon from "../../../../../assets/images/icons/vitamins/vitaminb3.png";
import Vitaminb5icon from "../../../../../assets/images/icons/vitamins/vitaminb5.png";
import Vitaminb7icon from "../../../../../assets/images/icons/vitamins/vitaminb7.png";
import Vitaminb9icon from "../../../../../assets/images/icons/vitamins/vitaminb9.png";
import Vitamincicon from "../../../../../assets/images/icons/vitamins/vitaminc.png";
import Vitamincaicon from "../../../../../assets/images/icons/vitamins/vitamina.png";
import Vitaminb6icon from "../../../../../assets/images/icons/vitamins/vitaminb6.png";
import Vitaminb12icon from "../../../../../assets/images/icons/vitamins/vitaminb12.png";
import Vitamindicon from "../../../../../assets/images/icons/vitamins/vitamind.png";
import Vitamineicon from "../../../../../assets/images/icons/vitamins/vitamine.png";
import Vitaminkicon from "../../../../../assets/images/icons/vitamins/vitamink.png";
import Button from "@mui/joy/Button";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  ArrowCircleDown,
  ArrowDownward,
  ArrowDropDown,
  KeyboardArrowDown,
} from "@mui/icons-material";
import Vitaminb1 from "./Vitaminb1";
import Vitaminb2 from "./Vitaminb2";
import Vitaminb3 from "./Vitaminb3";
import Vitaminb5 from "./Vitaminb5";
import Vitaminb6 from "./Vitaminb6";
import Vitaminb7 from "./Vitaminb7";
import Vitaminb9 from "./Vitaminb9";
import Vitaminb12 from "./Vitaminb12";
import Vitaminc from "./Vitaminc";
import Vitamina from "./Vitamina";
import Vitamind from "./Vitamind";
import Vitamine from "./Vitamine";
import Vitamink from "./Vitamink";
export default function VitaminHome() {
  const theme = useTheme();
  const myRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const buttonRefat = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openfat, setOpenfat] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFat = () => {
    setOpenfat(false);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("dropdownSection");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <React.Fragment>
        <CssBaseline />
        <Container fixed m={4} fluid maxWidth="lg">
          <Typography
            level="h1"
            fontSize="2em"
            textColor="#000"
            fontWeight="bold"
            marginTop="0.5em"
          >
            Vitamins
          </Typography>
          <Grid container direction="row">
            <Grid item lg={4} md={4} sm={12}>
              <Box>
                <img src={Vitamins} alt="vitamins" width="60%" />
              </Box>
            </Grid>
            <Grid item lg={8} md={8} sm={12}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  marginTop: "20px",
                  maxHeight: { xs: "100%", md: "300px" },
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography level="h6" align="justify" gutterBottom>
                    Vitamins are organic compounds that people need in small quantities. Each has a
                    different role in maintaining health and bodily function. Some people need
                    supplements to boost their supply, but this depends on their lifestyle and
                    overall health.
                  </Typography>
                </CardContent>
              </Card>
              {/* <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  marginTop: "20px",
                  maxHeight: { xs: "100%", md: "300px" },
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="justify" gutterBottom>
                    Vitamins are organic compounds that people need in small quantities. Each has a
                    different role in maintaining health and bodily function. Some people need
                    supplements to boost their supply, but this depends on their lifestyle and
                    overall health.
                  </Typography>
                </CardContent>
              </Card> */}
            </Grid>
          </Grid>


          {/* <Grid md={12} mt={4} id="dropdownSection">
          <Fab
              color="primary"
              aria-label="scroll back to top"
              onClick={handleClickScroll}
              style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
              <Typography variant="h5" gutterBottom m={2}>
                Types of Vitamins
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
            </div>
          </Grid> */}
          <Grid md={12} mt={4} id="dropdownSection">
            <Fab
              color="primary"
              aria-label="scroll b
              ack to top"
              onClick={handleClickScroll}
              style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
              <Typography level="h5" gutterBottom m={2}>
                Types of Vitamins
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#6F38C5", height: "5px" }} />
            </div>
          </Grid>
          <Grid container alignItems="stretch">
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card sx={{ height: "100%", border: "0.5px solid #9494f1", boxShadow: "none", padding:"0px"}}>
                  <CardHeader
                    title="Water-soluble"
                    sx={{ backgroundColor: "#9494f1", borderTopLeftRadius:"0.5em", borderTopRightRadius:"0.5em"}}
                  />
                  <CardContent>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                        <ListItemText primary="Water-soluble vitamins, as the name suggests are vitamins that dissolve in water which include  B-complex vitamins and vitamin C. They are not stored in the body and must be consumed daily in appropriate quantities. Any excess or leftover amount of these is excreted through urine. They are abundantly present in many plant and animal foods." />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card sx={{
                  height: "100%", border: "0.5px solid #9494f1",
                  boxShadow: "none", padding:'0px'
                }} >
                  <CardHeader title="Fat-soluble" sx={{ backgroundColor: "#9494f1", borderTopLeftRadius:"0.5em", borderTopRightRadius:"0.5em" }} />
                  <CardContent>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      <ListItem alignItems="flex-start" sx={{ textAlign: "justify" }}>
                        <ListItemText primary="Vitamin A, D, E, K are called as fat soluble vitamins because of their nature which allows them to be absorbed and transported with fats in the body.  They are required in the body in small amounts for maintaining proper growth and functioning." />
                      </ListItem>
                      <ListItem alignItems="flex-start" sx={{ textAlign: "justify" }}>
                        <ListItemText primary="Fat soluble vitamins are stored in the liver." />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={1}
              my={2}
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
            >
              <Grid item xs={12} md={6} lg={6}>
                <div>
                  <Button
                    ref={buttonRef}
                    id="basic-demo-button"
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="plain"
                    color="primary"
                    size="lg"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    1. Water Soluble Vitamins <ArrowDropDown />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={buttonRef.current}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    aria-labelledby="basic-demo-button"
                  >
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb1").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin B1 (Thiamine)
                      <ListItemIcon>
                        <img
                          src={Vitaminb1icon}
                          alt="vitamin b1 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb2").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin B2 (Riboflavin)
                      <ListItemIcon>
                        <img
                          src={Vitaminb2icon}
                          alt="vitamin b2 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb3").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {" "}
                      Vitamin B3 (Niacin)
                      <ListItemIcon>
                        <img
                          src={Vitaminb3icon}
                          alt="vitamin b3 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb5").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {" "}
                      Vitamin B5 (Pantothenic acid)
                      <ListItemIcon>
                        <img
                          src={Vitaminb5icon}
                          alt="vitamin b5 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb6").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin B6 (Pyridoxine)
                      <ListItemIcon>
                        <img
                          src={Vitaminb6icon}
                          alt="vitamin b9 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb7").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin B7 (Biotin){" "}
                      <ListItemIcon>
                        <img
                          src={Vitaminb7icon}
                          alt="vitamin b7 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb9").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin B9 (Folic acid)
                      <ListItemIcon>
                        <img
                          src={Vitaminb9icon}
                          alt="vitamin b9 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminb12").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin B12 (Cobalamin)
                      <ListItemIcon>
                        <img
                          src={Vitaminb12icon}
                          alt="vitamin b9 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitaminc").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin C (Ascorbic acid)
                      <ListItemIcon>
                        <img
                          src={Vitamincicon}
                          alt="vitamin c icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div>
                  <Button
                    ref={buttonRefat}
                    id="basic-demo-button"
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={openfat ? "true" : undefined}
                    variant="plain"
                    color="info"
                    size="lg"
                    onClick={() => {
                      setOpenfat(!openfat);
                    }}
                  >
                    2. Fat Soluble Vitamins <ArrowDropDown />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={buttonRefat.current}
                    open={openfat}
                    onClose={handleCloseFat}
                    onClick={handleCloseFat}
                    aria-labelledby="basic-demo-button"
                  >
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitamina").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin A (Retinoids)
                      <ListItemIcon>
                        <img
                          src={Vitamincaicon}
                          alt="vitamin b9 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitamind").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin D (Calciferol, 1,25-dihydroxy vitamin D){" "}
                      <ListItemIcon>
                        <img
                          src={Vitamindicon}
                          alt="vitamin b9 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitamine").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {" "}
                      Vitamin E (tocopherol){" "}
                      <ListItemIcon>
                        <img
                          src={Vitamineicon}
                          alt="vitamin b9 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        document.getElementById("vitamink").scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Vitamin K{" "}
                      <ListItemIcon>
                        <img
                          src={Vitaminkicon}
                          alt="vitamin b9 icon"
                          style={{ height: 30, paddingLeft: 10 }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Grid container fluid spacing={2} direction="row" mb={2} alignContent={"stretch"}>
            <Grid item id="vitaminb1" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb1icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B1 (Thiamine) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Thiamine is known for preventing beriberi, a disease resulting from a lack of neuromotor coordination. It is present in large amounts in skeletal muscles, heart, liver, kidney and brain.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb1 />
            <Grid item id="vitaminb2" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb2icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B2 (Riboflavin) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                It is a water-soluble vitamin and also sometimes referred to as Vitamin G.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb2 />
            <Grid item id="vitaminb3" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb3icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B3 (Niacin) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Niacin is a water-soluble vitamin which is essential for  proper functioning of all cells of the body. The common supplemental form of niacin are nicotinamide and nicotinic acid.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb3 />
            <Grid item id="vitaminb5" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb5icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B5 (Pantothenic acid)
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb5 />
            <Grid item id="vitaminb6" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb6icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B6 (Pyridoxine) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                The active coenzyme form of vitamin B6 is Pyridoxal 5’ phosphate (PLP).
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb6 />
            <Grid item id="vitaminb7" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb7icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B7 (Biotin):
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                It is also called as vitamin H or hair vitamin and acts as an important vitamin in various metabolic reactions in the body.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb7 />
            <Grid item id="vitaminb9" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb9icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B9 (Folic Acid) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Vitamin B9 also called as folic acid was discovered from green leafy vegetables. It is also synthesized by the micro-organisms in our gut.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb9 />
            <Grid item id="vitaminb12" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminb12icon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin B12 (Cobalamin) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Vitamin B12 is also known as anti-pernicious anemia factor.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminb12 />
            <Grid item id="vitaminc" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitamincicon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin C(ascorbic acid) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Vitamin C is a six-carbon-containing atom that is stable to acid but gets destroyed by oxidation, light, alkali and heat. Vitamin C is a reducing agent and an antioxidant in our body.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitaminc />
            <Grid item id="vitamina" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitamincaicon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin A (Retinoids) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Vitamin A is a fat soluble vitamin and is essential for proper functioning of our immune system. It is a critical nutrient required for vision and is necessary for cell growth and differentiation. It is present as preformed vitamin A (retinol and retinyl esters) and provitamin A (beta-carotene) which is the precursor of vitamin A.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitamina />
            <Grid item id="vitamind" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitamindicon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin D (Calciferol, 1,25-dihydroxy vitamin D) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Vitamin D is a fat soluble vitamin which is also denoted as “the sunshine vitamin”. The two forms in which vitamin D is present in food are Vitamin D2 (ergocalciferol) and Vitamin D3 (Cholecalciferol). Vitamin D3 is produced by the action of sunlight on 7-dehydrocholesterol in the skin.  It acts as a vitamin as well as a hormone.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitamind />
            <Grid item id="vitamine" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitamineicon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin E (tocopherol) :
              </Typography>
              <Typography level="body1" gutterBottom align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Vitamin E is the principal membrane-associated antioxidant molecule. It plays a major role in preventing oxidative damage to membrane lipids by scavenging free radicals. It composes of range of molecules (tocopherols and tocotrienols). It is of the great nutritional significance.
              </Typography>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitamine />
            <Grid item id="vitamink" lg={12}>
              <Typography level="h5" gutterBottom align="start" sx={{ fontWeight: 600 }}>
                <ListItemIcon>
                  <img src={Vitaminkicon} alt="vitamin b1 icon" style={{ height: 50 }} />
                </ListItemIcon>{" "}
                Vitamin K :
              </Typography>
              <Typography level="body1" align="start" sx={{ fontWeight: 500, marginLeft: 10 }}>
                Vitamin K, known for its anti-haemorrhagic properties (blood clot formation), is a fat-soluble vitamin mainly composed of 3 compounds having biological properties:
              </Typography>
              <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, marginLeft: 10 }}>
                <ListItem>
                  Phylloquinone (Vitamin K1),the normal dietary source, found in green vegetables
                </ListItem>
                <ListItem>
                  Menaquinones (vitamin K2),synthesized by intestinal bacteria
                </ListItem>
                <ListItem>
                  Menadione and menadiol diacetate, synthetic compounds that can be metabolized to phylloquinone.
                </ListItem>
              </List>
              <hr
                style={{
                  background: "#e82371",
                  height: "5px",
                  border: "none",
                }}
              />
            </Grid>
            <Vitamink />
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}
