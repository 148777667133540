// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import ZincRdaTable from "./RdaAndEarTables/ZincRdaTable"
import WeakImmunity from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/WeakImmunity.png';
import Delayed_wound_healing from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminC/Delayed_wound_healing.png';
import growth from '../../../../../assets/images/icons/minerals/Deficiency/growth.png';
import HairLossIcon from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/HairLossIcon.png';
import loss_appetite from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/loss_appetite.png';
import Dermatitis from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/Dermatitis.png';
import Hypogeusia from '../../../../../assets/images/icons/minerals/Deficiency/Hypogeusia.png';
import hyposmia from '../../../../../assets/images/icons/minerals/Deficiency/hyposmia.png';
import blood_sugar from '../../../../../assets/images/icons/minerals/Deficiency/blood_sugar.png';
import dna from '../../../../../assets/images/icons/minerals/Functions/dna.png';
import smell from '../../../../../assets/images/icons/minerals/Functions/smell.png';
import insulin from '../../../../../assets/images/icons/minerals/Functions/insulin.png';
import immunity from '../../../../../assets/images/icons/minerals/Functions/immunity.png';
import pregnancyfetus_brain from '../../../../../assets/images/icons/minerals/Functions/pregnancyfetus_brain.png';
import nuts from "../../../../../assets/FoodSources/Minerals/Sources/nuts.png";
import legumes from "../../../../../assets/FoodSources/Minerals/Sources/legumes.png";
import egg from "../../../../../assets/FoodSources/Minerals/Sources/egg.png";
import poultry from "../../../../../assets/FoodSources/Minerals/Sources/poultry.png";
import lamb from "../../../../../assets/FoodSources/Minerals/Sources/lamb.png";
import pork from "../../../../../assets/FoodSources/Minerals/Sources/meat.png";
import beef from "../../../../../assets/FoodSources/Minerals/Sources/beef.png";
import yeast from "../../../../../assets/FoodSources/Minerals/Sources/yeast.png";
import milkProduct from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milkProduct.png";




export default function Zinc() {
    return (
        <>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of zinc?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={immunity} />
                                </ListItemAvatar>
                                <ListItemText primary="Zinc is necessary for proper functioning of immune system" />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={dna} />
                                </ListItemAvatar>
                                <ListItemText primary="It is also involved in wound healing, DNA synthesis, and cell division" />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={smell} />
                                </ListItemAvatar>
                                <ListItemText primary="It is necessary for the proper functioning of taste buds and olfactory receptors, therefore needed for senses of smell and taste" />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={insulin} />
                                </ListItemAvatar>
                                <ListItemText primary="It enhances the action of insulin." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={pregnancyfetus_brain} />
                                </ListItemAvatar>
                                <ListItemText primary="During pregnancy, infancy, and childhood the body needs zinc to grow and develop properly." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1.5
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of zinc?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Good sources of zinc include animal proteins such as beef, pork,  poultry, egg, and lamb.
                            </ListItem>
                            <ListItem>
                                Other good sources are nuts, whole grains, legumes, dairy product and yeast.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}

                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="beef">
                                <Avatar src={beef} size="lg" alt="beef" variant="square" />
                            </Tooltip>
                            <Tooltip title="pork">
                                <Avatar src={pork} alt="pork" variant="square" />
                            </Tooltip>
                            <Tooltip title="poultry">
                                <Avatar src={poultry} alt="poultry" variant="square" />
                            </Tooltip>
                            <Tooltip title="egg">
                                <Avatar src={egg} alt="egg" variant="square" />
                            </Tooltip>
                            <Tooltip title="lamb">
                                <Avatar src={lamb} alt="lamb" variant="square" />
                            </Tooltip>
                            <Tooltip title="nuts">
                                <Avatar src={nuts} size="lg" alt="nuts" variant="square" />
                            </Tooltip>
                            <Tooltip title="whole grains">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={wholegrains} alt="whole grains" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="dairy product">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={milkProduct} alt="curd" variant="square" />
                            </Tooltip>
                            <Tooltip title="yeast">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={yeast} alt="yeast" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>

                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="whole grains">
                                <Avatar src={wholegrains} alt="whole grains" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="dairy product">
                                <Avatar src={milkProduct} alt="curd" variant="square" />
                            </Tooltip>
                            <Tooltip title="yeast">
                                <Avatar src={yeast} alt="yeast" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 1, sm:1.5 },
                        padding: "0.8em",
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is zinc absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="The average absorption is 20% unless the content of fibre and phytates are exceptionally high. Zinc absorption takes place in small intestine." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>

            </Grid>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <ZincRdaTable />
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens when you are deficient in zinc?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, }}>
                            <ListItem>
                                Zinc deficiency can lead to a range of symptoms, including impaired immune function, delayed wound healing, growth retardation in children, hair loss, skin problems, and loss of appetite.
                            </ListItem>
                            <ListItem>
                                Hypogeusia (impaired taste) and hyposmia (impaired smell) appears in zinc deficiency.
                            </ListItem>
                            <ListItem>
                                Zinc deficiency is associated with impaired glucose tolerance.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        mt={1}
                        mb={0.5}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="impaired immune function">
                                <Avatar src={WeakImmunity} size="lg" alt="impaired immune function" variant="square" />
                            </Tooltip>
                            <Tooltip title="delayed wound healing">
                                <Avatar src={Delayed_wound_healing} alt="delayed wound healing" variant="square" />
                            </Tooltip>
                            <Tooltip title="growth retardation in children">
                                <Avatar src={growth} alt="growth retardation in children" variant="square" />
                            </Tooltip>
                            <Tooltip title="hair loss">
                                <Avatar src={HairLossIcon} alt="hair loss" variant="square" />
                            </Tooltip>
                            <Tooltip title="skin problems">
                                <Avatar src={Dermatitis} alt="skin problems" variant="square" />
                            </Tooltip>
                            <Tooltip title="loss of appetite">
                                <Avatar src={loss_appetite} alt="loss of appetite" variant="square" />
                            </Tooltip>
                            <Tooltip title="Hypogeusia">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={Hypogeusia} alt="Hypogeusia" variant="square" />
                            </Tooltip>
                            <Tooltip title="hyposmia">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={hyposmia} alt="hyposmia" variant="square" />
                            </Tooltip>
                            <Tooltip title="impaired glucose tolerance">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={blood_sugar} alt="impaired glucose tolerance" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="Hypogeusia">
                                <Avatar src={Hypogeusia} alt="Hypogeusia" variant="square" />
                            </Tooltip>
                            <Tooltip title="hyposmia">
                                <Avatar src={hyposmia} alt="hyposmia" variant="square" />
                            </Tooltip>
                            <Tooltip title="impaired glucose tolerance">
                                <Avatar src={blood_sugar} alt="impaired glucose tolerance" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1,
                        mt: 0.5
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the interactions of zinc with other nutrients?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' } }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="In the presence of excessive zinc, a decrease in copper absorption was reported." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}



