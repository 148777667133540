import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import nerveImpulse from "../../../../../assets/images/icons/vitamins/Functions/nerveImpulse.png";
import woundHealing from "../../../../../assets/images/icons/vitamins/Functions/woundHealing.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import antioxidant from "../../../../../assets/images/icons/vitamins/Functions/antioxidant.png";
import hormone from "../../../../../assets/images/icons/vitamins/Functions/hormone1.png";
import calciumIron from "../../../../../assets/images/icons/vitamins/Functions/calciumIron.png"
import collagen from "../../../../../assets/images/icons/vitamins/Functions/collagen.png"
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import orange from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B9/orange.png";
import lemon from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/lemons.png";
import grapeFruits from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/grapefruits.png";
import strawberries from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/strawberries.png";
import kiwi from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/kiwi.png";
import papaya from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/papaya.png";
import tomatoes from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/tomatoes.png";
import peppers from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/peppers.png";
import broccoli from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B5/broccoli.png";
import glv from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/spinach.png";
import VitCRdaTable from "./RdaAndEarTables/VitCRdaTable";
import fatigue from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png";
import Delayed_wound_healing from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminC/Delayed_wound_healing.png";
import bleeding_gums from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminC/bleeding_gums.png";
import joint_pain from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminC/joint_pain.png";
import petechial_hemorrhages from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminC/petechial_hemorrhages.png";
import skin_problems from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminC/skin_problems.png";


export default function Vitaminc() {
  return (
    <>
      <Grid item lg={8} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1.5
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of vitamin C?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="wound healing" src={woundHealing} />
                </ListItemAvatar>
                <ListItemText primary="It helps in wound healing and controlling infections." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="calcium Iron" src={calciumIron} />
                </ListItemAvatar>
                <ListItemText primary="It plays an important role in absorption and metabolism of iron and calcium." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="collagen" src={collagen} />
                </ListItemAvatar>
                <ListItemText primary="It is necessary for the formation of collagen (a protein that provide structure in connective tissue)." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="antioxidant" src={antioxidant} />
                </ListItemAvatar>
                <ListItemText primary="It acts as an antioxidant, which can neutralize the harmful free radicals." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="nerves" src={nerveImpulse} />
                </ListItemAvatar>
                <ListItemText primary="It helps to make several hormones and chemical messengers used in the brain and nerves." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the sources of vitamin C?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Vitamin C is found in abundance in citrus fruits like oranges, lemons, and grapefruits, as well as in strawberries, kiwi, papaya, tomatoes, bell peppers, broccoli, and leafy greens.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
              <Tooltip title="Oranges">
                <Avatar src={orange} size="lg" alt="Orange" variant="square" />
              </Tooltip>
              <Tooltip title="Lemons">
                <Avatar src={lemon} alt="Lemons" variant="square" />
              </Tooltip>
              <Tooltip title="GrapeFruits">
                <Avatar src={grapeFruits} alt="GrapeFruits" variant="square" />
              </Tooltip>
              <Tooltip title="strawberries">
                <Avatar src={strawberries} alt="strawberries" variant="square" />
              </Tooltip>
              <Tooltip title="kiwi">
                <Avatar src={kiwi} alt="kiwi" variant="square" />
              </Tooltip>
              <Tooltip title="papaya">
                <Avatar src={papaya} alt="papaya" variant="square" />
              </Tooltip>
              <Tooltip title="tomatoes">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={tomatoes} alt="tomatoes" variant="square" />
              </Tooltip>
              <Tooltip title="Bell peppers">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={peppers} alt="Bell peppers" variant="square" />
              </Tooltip>
              <Tooltip title="Broccoli ">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={broccoli} alt="Broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="leafy greens">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={glv} alt="leafy greens" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
              <Tooltip title="tomatoes">
                <Avatar src={tomatoes} alt="tomatoes" variant="square" />
              </Tooltip>
              <Tooltip title="Bell peppers">
                <Avatar src={peppers} alt="Bell peppers" variant="square" />
              </Tooltip>
              <Tooltip title="Broccoli ">
                <Avatar src={broccoli} alt="Broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="leafy greens">
                <Avatar src={glv} alt="leafy greens" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h6" fontWeight="lg" align="start">
              What happens if you are deficient in Vitamin C?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Vitamin C deficiency can lead to a condition known as scurvy. Symptoms include fatigue, weakness, bleeding gums, joint pain, slow wound healing, and skin problems. Delayed wound healing, tenderness in bones, petechial hemorrhages.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="fatigue">
                <Avatar src={fatigue} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="bleeding gums">
                <Avatar src={bleeding_gums} alt="bleeding gums" variant="square" />
              </Tooltip>
              <Tooltip title="joint pain">
                <Avatar src={joint_pain} alt="joint pain" variant="square" />
              </Tooltip>
              <Tooltip title="slow wound healing">
                <Avatar src={Delayed_wound_healing} alt="slow wound healing" variant="square" />
              </Tooltip>
              <Tooltip title="skin problems">
                <Avatar src={skin_problems} alt="skin problems" variant="square" />
              </Tooltip>
              <Tooltip title="petechial hemorrhages ">
                <Avatar src={petechial_hemorrhages} alt="petechial hemorrhages" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>

      </Grid>
      <Grid item lg={4} md={5} sm={12}>
      <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: -1, sm: 1 },
            py: 1,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin C absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Most of the vitamin C is absorbed in the small intestine. The absorption of vitamin C is enhanced in the presence of iron.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 1, sm: 0 },
          }}
        >
          <VitCRdaTable />
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin C absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Most of the vitamin C is absorbed in the small intestine. The absorption of vitamin C is enhanced in the presence of iron.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h6" fontWeight="lg" align="start">
              What happens if you are deficient in Vitamin C?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Vitamin C deficiency can lead to a condition known as scurvy. Symptoms include fatigue, weakness, bleeding gums, joint pain, slow wound healing, and skin problems. Delayed wound healing, tenderness in bones, petechial hemorrhages.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="fatigue">
                <Avatar src={fatigue} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="bleeding gums">
                <Avatar src={bleeding_gums} alt="bleeding gums" variant="square" />
              </Tooltip>
              <Tooltip title="joint pain">
                <Avatar src={joint_pain} alt="joint pain" variant="square" />
              </Tooltip>
              <Tooltip title="slow wound healing">
                <Avatar src={Delayed_wound_healing} alt="slow wound healing" variant="square" />
              </Tooltip>
              <Tooltip title="skin problems">
                <Avatar src={skin_problems} alt="skin problems" variant="square" />
              </Tooltip>
              <Tooltip title="petechial hemorrhages ">
                <Avatar src={petechial_hemorrhages} alt="petechial hemorrhages" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>

    </>
  );
}
