// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import IodineRdaTable from "./RdaAndEarTables/IodineRdaTable"
import fatigue from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png';
import weight_gain  from '../../../../../assets/images/icons/minerals/Deficiency/weight_gain.png';
import sensitivity_to_cold  from '../../../../../assets/images/icons/minerals/Deficiency/sensitivity_to_cold.png';
import dry_skin  from '../../../../../assets/images/icons/minerals/Deficiency/dry_skin.png';
import goiter  from '../../../../../assets/images/icons/minerals/Deficiency/goiter.png';
import growth  from '../../../../../assets/images/icons/minerals/Deficiency/growth.png';
import cognitive_impairments  from '../../../../../assets/images/icons/minerals/Deficiency/cognitive_impairments.png';
import thyroid from '../../../../../assets/images/icons/minerals/Functions/thyroid.png';
import pregnancyfetus_brain from '../../../../../assets/images/icons/minerals/Functions/pregnancyfetus_brain.png';
import Pregnancy from '../../../../../assets/images/icons/minerals/Functions/Pregnancy.png';
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import sea_weed from "../../../../../assets/FoodSources/Minerals/Sources/sea_weed.png";
import iodised_salt from "../../../../../assets/FoodSources/Minerals/Sources/iodised_salt.png";
import milkProduct from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milkProduct.png";
import legumes from "../../../../../assets/FoodSources/Minerals/Sources/legumes.png";
import egg from "../../../../../assets/FoodSources/Minerals/Sources/egg.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";



export default function Iodine() {
    return (
        <>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of iodine?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" ,'& > li': { marginBottom: '-8px' },}}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={thyroid} />
                                </ListItemAvatar>
                                <ListItemText primary="Primary role in the body is in production of thyroid hormones: thyroxine and triiodothyronine which are essential for regulating metabolism, growth, and development." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Pregnancy} />
                                </ListItemAvatar>
                                <ListItemText primary="During pregnancy  the production of thyroid hormones increase, therefore the requirement for iodine also increases." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={pregnancyfetus_brain} />
                                </ListItemAvatar>
                                <ListItemText primary="Iodine is crucial for normal physical growth and brain development of both fetus and infant." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>

                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of iodine?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Good sources of iodine include seafood, seaweed, iodized salt, dairy products, eggs ,legumes and cereal grains.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                            mb: 1
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.5, sm: 1 }}}>
                            <Tooltip title="seafood">
                                <Avatar src={fish} size="lg" alt="fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="seaweed">
                                <Avatar src={sea_weed} alt="seaweed" variant="square" />
                            </Tooltip>
                            <Tooltip title="iodised salt">
                                <Avatar src={iodised_salt} alt="iodised salt" variant="square" />
                            </Tooltip>
                            <Tooltip title="dairy products">
                                <Avatar src={milkProduct} alt="dairy products" variant="square" />
                            </Tooltip>
                            <Tooltip title="eggs">
                                <Avatar src={egg} alt="Green vegetables" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="cereal grains">
                                <Avatar src={wholegrains} alt="cereal grains" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        padding: '0.8em',
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What is the toxicity of iodine ?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Excess of iodine ingestion can be harmful, which may inhibit the synthesis of thyroid hormones by the thyroid gland.It might contribute to the development of hyperthyroidism." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        padding: '0.8em'
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            Goitrogens
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Goitrogens are naturally occurring substances that can interfere with the function of the thyroid gland. Goitrogens get their name from the term 'goiter' which means the enlargement of the thyroid gland." />
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    <Box
                                        component="span"
                                        sx={{
                                            fontWeight: "600",
                                            fontSize: "1rem",
                                            backgroundColor: "pink",
                                            padding: "0.2rem 0.3rem",
                                            borderRadius: "4px",
                                            marginRight: "0.5em",
                                            color: "#333333",
                                        }}
                                    >
                                        Food sources:
                                    </Box>
                                    Cruciferous vegetables such as broccoli, cauliflower, cabbage, kale and brussels sprouts.
                                </Typography>
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        padding: '0.8em',
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is iodine absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Iodine ions are absorbed rapidly primarily in small intestine and then become distributed throughout the extracellular fluid. Free iodine is reduced to iodide ions, and absorbed." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <IodineRdaTable />
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        backgroundColor: "#FFD9E8",
                        color: "#333333",
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h4" fontWeight="lg" align="start" marginBottom={1}>
                        Nutritional Programs :
                        </Typography>
                        <Typography level="h5" fontWeight="lg" align="start">
                            National Iodine Deficiency Disorder Control Program (NIDDCP) Goal :
                        </Typography>
                        <ol align="justify">
                            <li>
                                To bring the prevalence of IDD to below 5% in the country.
                            </li>
                            <li>
                                To ensure 100% consumption of adequately iodated salt (15ppm) at the household level.
                            </li>
                        </ol>
                    </CardContent>
                </Card>



            </Grid>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <IodineRdaTable />
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        padding: '0.8em',
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What is the toxicity of iodine ?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Excess of iodine ingestion can be harmful, which may inhibit the synthesis of thyroid hormones by the thyroid gland.It might contribute to the development of hyperthyroidism." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        padding: '0.8em',
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is iodine absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Iodine ions are absorbed rapidly primarily in small intestine and then become distributed throughout the extracellular fluid. Free iodine is reduced to iodide ions, and absorbed." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens when you are deficient in iodine?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Deficiency in iodine can lead to decreased production of thyroid hormones, resulting in hypothyroidism.
                            </ListItem>
                            <ListItem>
                                Symptoms of hypothyroidism include fatigue, weight gain, sensitivity to cold, and dry skin.
                            </ListItem>
                            <ListItem>
                                Iodine deficiency causes goiter which is enlargement of the thyroid gland.
                            </ListItem>
                            <ListItem>
                                Moderate to severe iodine deficiency in children can cause cognitive impairments and stunted growth.
                            </ListItem>
                            <ListItem>
                                Iodine Deficiency Disorders (IDD) is one of the most important public health problem in India.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt:1
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.6, sm: 1 } }}>
                            <Tooltip title="fatigue">
                                <Avatar src={fatigue} size="lg" alt="fatigue" variant="square" />
                            </Tooltip>
                            <Tooltip title="weight gain">
                                <Avatar src={weight_gain} alt="weight gain" variant="square" />
                            </Tooltip>
                            <Tooltip title="sensitivity to cold">
                                <Avatar src={sensitivity_to_cold} alt="sensitivity to cold" variant="square" />
                            </Tooltip>
                            <Tooltip title="dry skin">
                                <Avatar src={dry_skin} alt="dry skin" variant="square" />
                            </Tooltip>
                            <Tooltip title="goiter">
                                <Avatar src={goiter} alt="goiter" variant="square" />
                            </Tooltip>
                            <Tooltip title="stunted growth">
                                <Avatar src={growth} alt="stunted growth" variant="square" />
                            </Tooltip>
                            <Tooltip title="cognitive_impairments">
                                <Avatar src={cognitive_impairments} alt="cognitive_impairments" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        backgroundColor: "#FFD9E8",
                        color: "#333333",
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h4" fontWeight="lg" align="start" marginBottom={1}>
                        Nutritional Programs :
                        </Typography>
                        <Typography level="h5" fontWeight="lg" align="start">
                            National Iodine Deficiency Disorder Control Program (NIDDCP) Goal :
                        </Typography>
                        <ol align="justify">
                            <li>
                                To bring the prevalence of IDD to below 5% in the country.
                            </li>
                            <li>
                                To ensure 100% consumption of adequately iodated salt (15ppm) at the household level.
                            </li>
                        </ol>
                    </CardContent>
                </Card>


            </Grid>
        </>
    );
}



