import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useSelector
import { setCompareData, getCompareData } from './CompareDataSlice'; // Import getCompareData
import { endpoints } from '../../../../utils/config';
import NotFoundPage from '../NotFoundPage';
import Compare from '../../charts/Compare';
import { getMapKeyValue, setMapKey } from "../../charts/MapKeySlice";
function CompareData(props) {
    const [CompareDataState, setCompareDataState] = useState(props);
    const [hasError, setHasError] = useState(false);
    const dispatch = useDispatch();
    const mapKeyValue = useSelector(getMapKeyValue);
  
    useEffect(() => {
        setCompareDataState(props);
    }, [props]);

    const compareData = useSelector(getCompareData); // Use the selector here

    useEffect(() => {
        async function fetchData() {
            if (!CompareDataState['3']) {
                // source code not selected
                dispatch(setCompareData(null));
                return;
            }

            try {
                const url = constructURL(CompareDataState);
                //console.log("compare URL:", url);
                const response = await axios.get(url);

                const updatedResults = response.data.results.map(item => ({
                    ...item,
                    urban: !isNaN(item.urban) ? parseFloat(item.urban) : "NA",
                    rural: !isNaN(item.rural) ? parseFloat(item.rural) : "NA",
                    value: !isNaN(item.value) ? parseFloat(item.value) : "NA",
                    total: !isNaN(item.total) ? parseFloat(item.total) : "NA",
                    male: !isNaN(item.male) ? parseFloat(item.male) : "NA",
                    female: !isNaN(item.female) ? parseFloat(item.female) : "NA",
                }));

                const updatedResponse = {
                    ...response.data,
                    results: updatedResults,
                };
                //console.log("Dispatching action:", updatedResponse);

                dispatch(setCompareData(updatedResponse));
                setHasError(false);
            } catch (error) {
                console.error(error.message);
                setHasError(true);
            }
        }

        fetchData();
    }, [dispatch, CompareDataState]);

    function constructURL(data) {
        //let url = `https://nutritionatlasapi.cyclic.app/api/compare1?lifecycle=${data['0']}&indicator_id=${data['1']}&subindicator_id=${data['2']}`;
       // url += `&source_code=${data['3']}`;
       let url = `${endpoints.compare.url}?lifecycle=${data['0']}&indicator_id=${data['1']}&subindicator_id=${data['2']}`;
    url += `&source_code=${data['3']}`;

        // Append all sources from the second source array to the URL
        for (const sourceData of data['secondSource']) {
            if (sourceData?.source_code) {
                url += `&source_code=${sourceData.source_code}`;
            }
        }

        return url;
    }

    if (hasError) {
        return <NotFoundPage />;
    }
    const dataURL = constructURL(CompareDataState);

    return CompareDataState.selectedSecondSource ? (
        <Compare dataURL={dataURL} />
    ) : null;
}

export default CompareData;
