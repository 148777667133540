import React from 'react';
import { Grid, Paper, Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material';
import myplateicon from "../../../../../assets/images/myplateicon.png";

const MyPlateCompleteTable = () => {

  const formatTableCell = (text) => {
    if (typeof text === 'string') {
      const formattedText = text
        .replace('*', '<strong style="font-size: 1em; vertical-align: super;">*</strong>')
        .replace('+', '<strong style="font-size: 1em; vertical-align: super;">+</strong>')
        .replace('#', '<strong style="font-size: 1em; vertical-align: super;">#</strong>')
        .replace('$', '<strong style="font-size: 1em; vertical-align: super;">$</strong>')
      // .replace(/(\d)%/g, (_, match) => `<span style="font-size: 1.2em; vertical-align: super;">${match}</span><sup>%</sup>`);
      return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
    }
    return text;
  };

  const tableData = [
    ["Cereals (incl. nutricereals)", 250, 42, "~843", "~25", "~5", "~172"],
    ["Pulses*", 85, 14, "~274", "~20", "~3", "~42"],
    ["Milk/Curd (ml)", 300, 11, "~216", "~10", "~13", "~16"],
    ["Vegetables+ green leafy vegetable (GLV)", 400, 9, "~174", "~10", "~2", "~28"],
    ["Fruits#", 100, 3, "~56", "~1", "~1", "~11"],
    ["Nuts & Seeds", 35, 9, "~181", "~6", "~15", "~6"],
    ["Fats & Oils $", 27, 12, "~243", "_", "~27", "_"],
    ["Total", "~1200", "_", "~2000", "15%E", "30%E", "_"],
  ];

  const additionalText1 = [
    "* Eggs/fish/meat can substitute a portion of pulses",
    "+ Prescribed amount of vegetables (excluding potato) may be consumed either in cooked form/ salad",
    "# Prefer fresh fruits (avoid juices)",
    "$ Use different varieties of cooking oils, vegetables, fruits, nuts, etc., to obtain a variety of phytonutrients, vitamins, minerals, and bioactive compounds",
  ];

  const additionalText = [
    "‘My Plate for the day’ represents proportions of different food groups for meeting ~2000 Kcal",
    "Individuals trying to reduce weight may cut-down on cereal intake",
    "The meal plan is not for any specific medical condition"
  ];

  const noteText = "Note: All raw vegetables and fruits should be thoroughly washed before cutting or peeling";

  return (
    <Grid container justifyContent="center" spacing={3} mb={3}>
      {/* Image section */}
      <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', textAlign: 'center', marginTop: 25 }}>
        <img src={myplateicon} alt="MyPlate Icon" style={{ maxWidth: '80%', height: 'auto', margin: 'auto' }} />
      </Grid>

      {/* Text section */}
      <Grid item xs={12} md={6} container alignItems="center" >
        <div style={{ textAlign: 'justify', marginTop: 10, }}>
          {additionalText.map((text, index) => (
            <Typography key={index} variant="h6" color="#212529" style={{ marginBottom: 10, fontFamily: 'Baskerville, serif' }}>
              {text.includes('‘My Plate for the day’') ? (
                <>
                  <Box
                    component="span"
                    sx={{ px: 0.5, py: 0.5, backgroundColor: "#FF4081", color: "primary.contrastText" }}
                  >
                    ‘My Plate for the day’
                  </Box>
                  {text.replace('‘My Plate for the day’', '')}
                </>
              ) : (
                text
              )}
            </Typography>
          ))}
          <Box border={1} borderRadius={5} p={1.5} mt={5} backgroundColor="#faebd7a8" style={{ borderColor: '#FF4081' }}>
            <Typography variant="h6" color="#212529" style={{ fontFamily: 'Baskerville' }}>
              {noteText}
            </Typography>
          </Box>
        </div>
      </Grid>

      {/* Table section */}
      <Grid item xs={12} md={12} mb={1} p={1} m={-3}>
        {/* <Paper elevation={3} style={{ padding: '1.3em', marginTop: 20 }}> */}
        <TableContainer style={{ overflowX: 'auto', marginTop: 20 }}>
          <Table style={{ minWidth: 650, borderRadius: 8 }}>
            <TableHead>
              <TableRow style={{ backgroundColor: '#e0e0e0' }}>
                <TableCell style={{ fontWeight: 'bold' }}>Food Groups (2000 Kcal)</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Foods to be Consumed Raw Weight (g/day)</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>% of Energy (per day)</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Total Energy (g/day)</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Total Protein (g/day)</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Total Fat (g/day)</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Total Carbs (g/day)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'inherit' }}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} style={{ minWidth: 100, padding: '8px' }}>
                      {formatTableCell(cell)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ marginTop: 10, textAlign: 'left', color: '#333' }}>
          {additionalText1.map((text, index) => (
            <Typography key={index} variant="body2" color="#333" style={{ marginBottom: 10, textAlign: 'justify' }}>
              <strong style={{ fontWeight: 'bold', fontSize: '1.3em' }}>{text.substring(0, 1)}</strong>
              {text.slice(1)}
            </Typography>
          ))}
        </div>
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
};

export default MyPlateCompleteTable;