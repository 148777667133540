import React from "react";
import { CardMedia, Container, CssBaseline, Grid, ListItemIcon, useMediaQuery } from "@mui/material";
import {  AvatarGroup, ListItemContent, Tooltip } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useSpeechSynthesis } from "react-speech-kit";
import GiGlIntro from "./GiGlIntro";
import SourcesAccordion from "./SourcesAccordion";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import nutritionfacts from "../../../../../assets/images/nutritionfacts.png";
import ProteinIcon from "../../../../../../src/assets/images/icons/Protein/Protein.jpg";
import carbohydrateImage from "../../../../../assets/images/carbohydrates.png";
import carbohydrateImage1 from "../../../../../assets/images/carbohydrates_main3.png";
import simpleCarbohydrate from "../../../../../assets/images/simpleCarbohydrate.png";
import complexCarbohydrate from "../../../../../assets/images/complexCarbohydrate.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import weight_loss from "../../../../../assets/images/icons/fats/weight_loss.png";
import muscleWeakness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/muscleWeakness.png";
import WeakImmunity from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/WeakImmunity.png";
import nutritionLabel from "../../../../../assets/images/icons/Cabs_nutrition_label.png";
import ClassificationImage from "../../../../../assets/images/Classification.png";
import Classification_mobile from "../../../../../assets/images/Classification_mobile.png";
import body_building from "../../../../../assets/images/icons/carbohydrate/body_building.png";
import metabolism from "../../../../../assets/images/icons/carbohydrate/metabolism.png";
import protein_breakdown from "../../../../../assets/images/icons/carbohydrate/protein_breakdown.png";
import energy_brain from "../../../../../assets/images/icons/carbohydrate/energy_brain.png";
import hypoglycemia from "../../../../../assets/images/icons/carbohydrate/hypoglycemia.png";
import obesity from "../../../../../assets/images/icons/fats/obese.png";
import lactose_intolerance from "../../../../../assets/images/icons/carbohydrate/lactose_intolerance.png";
import blood_sugar from "../../../../../assets/images/icons/minerals/Deficiency/blood_sugar.png";
import heart_problem from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png";
import heart_problem1 from "../../../../../assets/images/icons/fats/heart-problem.png";
import Classification from "./Classification";
import { GridCheckCircleIcon } from "@mui/x-data-grid";





export default function Carbohydrates() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openfat, setOpenfat] = React.useState(false);
  const { speak, voices } = useSpeechSynthesis();
  // console.log(voices[2]);
  const voiceURI = voices[2];

  const text =
    "Carbohydrates  Are one of the three main macronutrients required for the body functioning. Carbohydrates are broken down into glucose in body, which can be used immediately or stored in the liver and muscles for later use. ";

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseFat = () => {
    setOpenfat(false);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("backToTop");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <React.Fragment>
        <CssBaseline />
        <Container fixed m={2} fluid maxWidth="lg" id="backToTop">
          <Typography
            level="h1"
            fontSize="2em"
            textColor="#000"
            fontWeight="bold"
            marginTop="0.5em"
          >
            Carbohydrates
          </Typography>
          <Grid container direction="row">
            <Grid item lg={4} md={4} sm={12} >
              <Box>
                <img src={carbohydrateImage1} alt="carbohydrateImage" width="100%"/>
              </Box>
            </Grid>

            <Grid item lg={8} md={8} sm={12}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  marginTop: "20px",
                  maxHeight: { xs: "100%", md: "300px" },
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  {/* <p align="left">
                    <strong>Carbohydrates : </strong>
                  </p> */}
                  <ul align="justify">
                    <li>Carbohydrates are one of the three main macronutrients required for the proper body functioning, along with proteins and fats.
                    </li>
                    <li>These are in form of sugar or starches and are the key fuel to our body. Also called "quick energy" foods as they are easily broken down into glucose, which can either be used right away or gets converted to glycogen and stored in the liver and muscles for later use.
                    </li>
                    <li>
                      Carbohydrates dominate diets across the world in the form of staples as rice, wheat, grains, roots and tubers.
                    </li>
                    <li>
                      Out of the total calories consumed, 50-55% should come from carbohydrate.
                    </li>
                    <li>
                      The quantity and type of carbohydrates included in the diet are important to maintain good health.
                    </li>
                    <li>
                      Each gram of CHO gives 4 Kcal.
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid md={12} id="dropdownSection">
            <Fab
              color="primary"
              aria-label="scroll back to top"
              onClick={handleClickScroll}
              style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Grid>
          <Grid md={12} item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#6f38c5", height: "5px" }} />
              <Typography level="h5" gutterBottom m={2}>
                Classification of carbohydrates
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#6f38c5", height: "5px" }} />
            </div>
          </Grid>
          {/* <Typography
            level="h5"
            sx={{ textAlign: "left", marginBottom: 1 }}
          >
            What are the different types of carbohydrates?
          </Typography> */}
          {/* <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="500px"
              width={{ xs: '100%', sm: '80%', md: '100%' }}
            >
              <div style={{ width: '100%', height: '100%' }}>
                <Classification />
              </div>
            </Box> */}
          <Grid xs={12} sm={12}  sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img
              src={ClassificationImage}
              style={{ "max-width": "auto", "margin-top": "1em" }}
              width={"100%"}
              height={"auto"}
              alt="carbohydrate banner"
            />
          </Grid>
          <Grid xs={12} sm={12}  sx={{ display: { xs: 'block', sm: 'none' } }}>
            <img
              src={Classification_mobile}
              style={{ "max-width": "auto", "margin-top": "1em", "padding-right": "0.5em"}}
              width={"100%"}
              height={"auto"}
              alt="carbohydrate banner"
            />
          </Grid>
          <Typography
            level="h5"
            sx={{ textAlign: "left", marginBottom: 1, marginTop: 4 }}
          >
            Based on the digestion, carbohydrates can be classified as:
          </Typography>
          <Grid container alignItems="stretch">
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card variant="outlined" sx={{ height: "100%", }} >
                  {/* <CardHeader title="Simple Carbohydrates" sx={{ color: "#6f38c5", textAlign:"left"}} /> */}
                  <Typography
                    level="h5"
                    sx={{ textAlign: "left", color: "#6f38c5", marginLeft: "1em" }}
                  ></Typography>
                  <CardMedia
                    component="img"
                    height="250"
                    image={simpleCarbohydrate}
                    alt="Paella dish"
                  // style={{ border: '1px solid #000' }} 
                  />
                  <Typography
                    level="h5"
                    // sx={{ textAlign: "left", color: "#6f38c5",marginLeft:"1em"}}
                    sx={{ textAlign: "center", color: "#6f38c5", marginBottom: "-0.5em" }}
                  >
                    Simple Carbohydrates
                  </Typography>
                  <CardContent>

                    <List
                      aria-labelledby="ellipsis-list-demo"
                      sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px', '& > li': { marginBottom: '-4px' } }}
                    >
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">
                            Simple carbohydrates when ingested are quickly digested and absorbed thereby raising the blood glucose levels rapidly.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">
                            Natural sugars are present in foods like fruits, vegetables, and milk. They are healthy due to the presence of vitamins, minerals and fibre complexed with the natural sugars.</Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Unlike natural sugars, processed foods like candies, pastries, biscuits, and sugary beverages are high in added refined sugars. They pose some serious health concerns like weight gain, diabetes, heart problems, etc.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Foods such as white bread, white rice and flour are made after a lot of processing of whole grains. This leads to loss of nutrients especially fibre leading to spike in blood sugar.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
              <Box>
                <Card variant="outlined" sx={{ height: "100%" }} boxshadow={3}>
                  {/* <CardHeader title="Complex Carbohydrates" sx={{ backgroundColor: "#9494f1" }} /> */}
                  <CardMedia
                    component="img"
                    height="250"
                    image={complexCarbohydrate}
                    alt="Paella dish"
                  />
                  <CardContent>
                    <Typography
                      level="h5"
                      // sx={{ textAlign: "left", color: "#6f38c5",marginLeft:"1em"}}
                      sx={{ textAlign: "center", color: "#6f38c5", marginBottom: "-0.5em" }}
                    >
                      Complex Carbohydrates
                    </Typography>

                    <List
                      aria-labelledby="ellipsis-list-demo"
                      sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px', '& > li': { marginBottom: '-4px' } }}
                    >
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">
                            Starches are complex carbohydrates which take longer to get digested and absorbed thereby releasing glucose slowly into the blood stream.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">
                            Foods that are high in starch includes whole grains, legumes and starchy vegetables.</Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">The unprocessed or minimally processed whole grains, vegetables, fibre-rich fruits and beans are good sources of healthy carbohydrates.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                      <ListItem>
                        {/* <ListItemDecorator sx={{ marginRight: 1 }}>
                          <AiFillCheckCircle style={{ fontSize: '2.5em', color: "#9a8fec" }} />
                        </ListItemDecorator> */}
                        <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                          <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                        </ListItemIcon>
                        <ListItemContent>
                          <Typography level="title-sm">Fibre, a type of complex carbohydrate is present in fruits, vegetables, whole grains, nuts and legumes.
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item lg={6} md={7} sm={12}>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start">
                  What are the functions of carbohydrates?
                </Typography>
                <CardContent>
                  <List aria-labelledby="ellipsis-list-demo"
                    sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px' }}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="body building" src={body_building} />
                      </ListItemAvatar>
                      <ListItemText primary="It is the major source of energy in the diet, and under normal condition it is the sole source of energy for the vital functions of key organs especially brain." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="protein breakdown" src={protein_breakdown} />
                      </ListItemAvatar>
                      <ListItemText primary="It is stored form of energy in liver and muscles as glycogen. Glycogen breaks down to produce glucose to  provide constant supply of energy." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="metabolism" src={metabolism} />
                      </ListItemAvatar>
                      <ListItemText primary="Protein sparing action: Sufficient intake of carbohydrates prevent breakdown of protein for required energy and retain them for  body building." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="energy brain" src={energy_brain} />
                      </ListItemAvatar>
                      <ListItemText primary="It is necessary for normal fat metabolism. When the carbohydrate intake is low, body excessively uses fat for energy, that can produce ketone bodies." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="heart" src={heart_problem1} />
                      </ListItemAvatar>
                      <ListItemText primary="Adequate intake of carbohydrates is necessary to maintain  vital bodily functions of heart and  central nervous system." />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} md={7} sm={12}>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  py: 1,
                  mt: { xs: 1, sm: 0 },
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start">
                  How much carbohydrates should be included in a healthy diet?
                </Typography>
                <CardContent>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem alignItems="flex-start">
                      {/* <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar> */}
                      <ListItemText primary="In general carbohydrates should contribute to about 45-65 % of the daily caloric intake, which roughly equals to 200 – 300 g of carbohydrates per day." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={Human} />
                      </ListItemAvatar>
                      <ListItemText primary="ICMR-NIN recommends a minimum intake of 100 – 130 g of carbohydrates per day for ages 1 year and above." />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 1,
                  py: 1.5
                  // display: { xs: 'none', sm: 'block' }
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    Which kind of carbohydrates are good for health?
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    {/* <ListItem>
                      Complex carbohydrates such as unprocessed or minimally processed cereals & grains along with fruits and vegetables are healthy.
                    </ListItem>
                    <ListItem>
                      Whereas simple carbohydrates such as highly processed foods, refined flours and grains, sugary drinks, white bread,  pastries, cookies & cakes, candies & chocolates should be avoided as they cause sudden spike in blood glucose levels.
                    </ListItem> */}
                    <ListItem>
                      Complex carbohydrates, found in unprocessed or minimally processed cereals & grains, fruits, and vegetables, are beneficial, whereas simple carbohydrates in highly processed foods, refined flours, sugary drinks, and sweets should be avoided due to their tendency to cause rapid spikes in blood glucose levels.
                    </ListItem>
                  </List>
                </CardContent>
                <Box sx={{ textAlign: "justify", }}>
                  <Typography>
                    <Box
                      component="span"
                      sx={{ px: 0.8, py: 0.3, backgroundColor: "#FF5722", color: "#FFFFFF", }}
                    >
                      <strong>Note:</strong>
                    </Box>{" "}  In lens of rising prevalence of  overnutrition and obesity, it is to be noted that excess carbohydrate intake will be stored as fat in body!
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item lg={6} md={7} sm={12}>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 1,
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    What are the signs and symptoms of carbohydrates deficiency?
                  </Typography>
                  <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Deficiency of carbohydrates occurs as part of overall energy undernutrition.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Carbohydrate deficiency can be manifested in the body as depletion of body fat, loss of muscle mass, loss of protein from heart, liver and kidney, impaired immune response, infections and reduced absorption of available nutrients.
                    </ListItem>
                  </List>
                </CardContent>
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    What happens when carbohydrates intake is low:
                  </Typography>
                  <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Hypoglycemia – can occur due to fasting, alcohol intake, inadequate energy intake, poor food habits.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Lactose intolerance*- due to absence of enzyme lactase that converts milk sugar and lactose to glucose and galactose.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Note: Proper diagnosis by trained medical professional is crucial to identify intolerance
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt:0.5
                  }}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="depletion of body fat">
                      <Avatar src={weight_loss} alt="depletion of body fat" variant="square" />
                    </Tooltip>
                    <Tooltip title="loss of muscle mass">
                      <Avatar src={muscleWeakness} alt="loss of muscle mass" variant="square" />
                    </Tooltip>
                    <Tooltip title="impaired immune response">
                      <Avatar src={WeakImmunity} alt="impaired immune response" variant="square" />
                    </Tooltip>
                    <Tooltip title="hypoglycemia">
                      <Avatar src={hypoglycemia} alt="hypoglycemia" variant="square" />
                    </Tooltip>
                    <Tooltip title="lactose intolerance">
                      <Avatar src={lactose_intolerance} alt="Lactose intolerance" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>

                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start" mt={2}>
                    What happens when there is overconsumption of carbohydrates?
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Excessive carbohydrate consumption, especially of refined carbohydrates like sugary foods and drinks, can increase the risk of developing obesity and chronic diseases such as type 2 diabetes, cardiovascular disease, and metabolic syndrome.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt:0.5
                  }}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="obesity">
                      <Avatar src={obesity} alt="obesity" variant="square" />
                    </Tooltip>
                    <Tooltip title="type 2 diabetes">
                      <Avatar src={blood_sugar} alt="type 2 diabetes" variant="square" />
                    </Tooltip>
                    <Tooltip title="cardiovascular disease">
                      <Avatar src={heart_problem} alt="cardiovascular disease" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={6} md={7} sm={12}>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 1,
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    Nutrition label:
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      On the nutrition label, the term "total carbohydrate" refers to all three forms of carbohydrates: starch, sugar, and fiber.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      (The term ‘total carbohydrate’ on the nutrition label includes all the three types of carbohydrates i.e., Starch, sugar and fiber).
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Total Sugars in the Nutrition Facts label  indicates both the natural sugar present in food and added sugar in the product. Added sugars indicates the  sugars and syrups added during processing or preparation of the product. Terms such as fructose, dextrose, cane juice, corn syrup, maple syrup and honey are also considered added sugars.
                    </ListItem>
                  </List>
                </CardContent>
                <CardMedia
                  component="img"
                  height="auto" // Height will adjust based on the image's aspect ratio
                  image={nutritionLabel}
                  alt="Nutrition label image"
                  sx={{
                    m: -2
                  }}
                />
              </Card>
            </Grid>
          </Grid>
          <GiGlIntro />
          <Grid xs={12} item>
            <Card
              variant="outlined"
              sx={{
                //  width: 320,
                // to make the card resizable
                overflow: "auto",
                resize: "horizontal",
                mt: 1
              }}
            >
              <Typography level="h5" fontWeight="lg" align="start">
                When planning your meals:
              </Typography>
              <CardContent>
                <List sx={{'& > li': { marginBottom: '-5px' } }} >
                  <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                    <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                      <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                    </ListItemIcon>
                    <ListItemText primary="Choose foods that have a low to medium GI." />
                  </ListItem>
                  <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                    <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                      <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                    </ListItemIcon>
                    <ListItemText primary="When eating a high GI, combine it with low GI foods to balance the effect on your glucose levels." />
                  </ListItem>
                  <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                    <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                      <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                    </ListItemIcon>
                    <ListItemText primary="Portion size of the food consumed also determines the glycaemic response." />
                  </ListItem>
                  <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                    <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                      <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                    </ListItemIcon>
                    <ListItemText primary="The glycaemic response of the food depends on a number of factors such as type of carbohydrate, amount of carbohydrate, amount of protein and fat, food matrix, nutrient interactions, type of food processing, other food components, etc. (RDA, 2020)." />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={12} item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#6f38c5", height: "5px" }} />
              <Typography level="h5" gutterBottom m={2}>
                Rich sources of Carbohydrates
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#6f38c5", height: "5px" }} />
            </div>
          </Grid>
          <Grid item style={{ marginBottom: "6rem" }}>
            {/* <SourceTable /> */}
            <SourcesAccordion />
          </Grid>
          {/* </Grid> */}
        </Container>
      </React.Fragment>
    </div>
  );
}


