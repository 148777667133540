import React, { useState, useEffect } from "react";
// Import Highcharts
import Highcharts, { defaultOptions } from "highcharts/highstock";
import drilldown from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";

import mapData1 from "../../../maps/2022/india_state_nfhs5.json";
import andhrapradesh from "../../../maps/2022/NFHS5_district_maps/andhra pradesh.json";

import stateMapsSourceList from "./StateMapsSourceList";
import DistrictMapData from "../../../maps/2022/india_district_old_v2.json";
import Spinner from "../../../components/spinner";
import MapChart from "../components/Map";
import Switch from "@mui/material/Switch";
import { Label } from "@mui/icons-material";
import { FormGroup, Stack, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import { getApiData } from "../components/dropdown/dropdownSlice";
// Load Highcharts modules
drilldown(Highcharts);
dataModule(Highcharts);

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
// require("highcharts/modules/drilldown")(Highcharts);
require("highcharts/modules/map")(Highcharts);
const label = { inputProps: { "aria-label": "Switch demo" } };

const IndiaStateMap = () => {
  const apiData = useSelector(getApiData);
  const data = JSON.parse(JSON.stringify(apiData.results)); // parse the data back to an array of objects
  console.log(`selected Data :${JSON.stringify(data)}`);
  var parsedData = data.map((element) => element.value);
  // const [data, setData] = useState([]);
  // const [mapData, setmapData] = useState(stateMapsSourceList[0].mapData);
  // console.log(mapData);
  // const [arealevel, setArealevel] = useState("");
  const [loading, setloading] = useState(true);
  // const chartRef = useRef(null);
  // // const [checked, setChecked] = useState(false);
  
  

  // useEffect(() => {
  //   fetch(
  //     `https://nutritionatlasapi.cyclic.app/api/data?source_code=N5&indicator_id=1&area_level=2&subindicator_id=1_1`
  //   )
  //     .then((response) => response.json())
  //     .then((json) => setData(json.results))
  //     .then(setloading(false));
  //   //console.log(data);
  // }, [setloading]);
  var parsedData = data.map((element) => element.value);

  data.forEach((element) => {
    parsedData.push({
      "hc-key": element.area_name,
      value: parseInt(element.value),
    });
  });
  console.log(parsedData);
  const handleMap = () => {
    console.log("event worked");
  };

  var dummydata = [
    { "hc-key": "Andaman & Nicobar Islands", value: 22 },
    { "hc-key": "Andhra Pradesh", value: 31 },
    { "hc-key": "Arunachal Pradesh", value: 28 },
    { "hc-key": "Assam", value: 35 },
    { "hc-key": "Bihar", value: 42 },
    { "hc-key": "Chandigarh", value: 25 },
    { "hc-key": "Chhattisgarh", value: 34 },
  ];

  const [mapOptions, setMapOptions] = useState({
    chart: {
      map: stateMapsSourceList[0].mapData,
    },
    title: {
      text: "Heat Map",
    },

    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    colorAxis: {
      min: 20,
      max: 40,
      minColor: "#BAD2F2",
      maxColor: "#05426C",
    },

    tooltip: {
      formatter: function () {
        let point = this.point;
        return "<b>" + point.NAME1_ + "</b><br>" + point.indicator_name + "  :" + point.value;
      },
      valueSuffix: "NFHS 5",
    },
    plotOptions: {
      map: {
        states: {
          hover: {
            color: "#EEDD66",
          },
        },
      },
    },

    series: [
      {
        data: dummydata,
        joinBy: ["NAME1_", "hc-key"],
        borderColor: "white",
        borderWidth: 0.6,
        // mapData: stateMapsSourceList[0].mapData,
        name: "Population density",
        nullColor: "grey",
        dataLabels: {
          enabled: true,
          format: "{point.NAME1_}",
        },
        point: {
          events: {
            click: function (e) {
              console.log(e.point.NAME1_);
              const drilldownMap = stateMapsSourceList.find(
                (stateMapsSourceList) => stateMapsSourceList.state_name === e.point.NAME1_
              );
              console.log(drilldownMap);

              setMapOptions({
                chart: {
                  events: {
                    render: function () {
                      var chart = this;

                      chart.renderer
                        .button("< Reset", 50, 30, function (e) {
                          // Fires on click
                          // chart.series[0].update({
                          //   mapData: mapData1,
                          // });
                          // MapReset();
                          //e.preventDefault();
                          //console.log(chart.series[0].mapData);
                          //chart.series[0].mapData.update(stateMapsSourceList[0].mapData);

                          //chart.series[0].remove(true);

                          // MapReset();
                          // chart.series[0].update({
                          //   mapData: stateMapsSourceList[0].mapData,
                          // });
                          // setMapOptions((prev) => {
                          //   console.log(prev);
                          //   return {
                          //     ...prev,
                          //     chart: {
                          //       map: stateMapsSourceList[0].mapData,
                          //     },
                          //   };
                          // });
                          chart.destroy();

                          console.log("dfd");
                        })
                        .attr({
                          zIndex: 3,
                        })
                        .add();
                    },
                  },
                },
                series: {
                  data: data,
                  mapData: drilldownMap.mapData,
                },
              });
            },
          },
        },
      },
    ],
  });
  const MapReset = () => {
    setMapOptions({
      chart: {
        map: stateMapsSourceList[0].mapData,
        events: [],
      },
      series: {
        data: dummydata,
        joinBy: ["NAME1_", "hc-key"],
        borderColor: "white",
        borderWidth: 0.6,
        // mapData: stateMapsSourceList[0].mapData,
        name: "Population density",
        nullColor: "grey",
        dataLabels: {
          enabled: true,
          format: "{point.NAME1_}",
        },
      },
    });

    console.log(data);
  };
  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div>
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>State Level</Typography>
            <Switch {...label} defaultChecked onChange={MapReset} />
            <Typography>District Level</Typography>
          </Stack>
        </FormGroup>
        <MapChart options={mapOptions} highcharts={Highcharts} />
      </div>
    );
  }
};
export default IndiaStateMap;
