import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import antioxidant from "../../../../../assets/images/icons/vitamins/Functions/antioxidant.png";
import reproductiveSystem from "../../../../../assets/images/icons/vitamins/Functions/reproductiveSystem.png";
import skin from "../../../../../assets/images/icons/vitamins/Functions/skin.png";
import eyeProtection from "../../../../../assets/images/icons/vitamins/Functions/eyeProtection.png";
import gene from "../../../../../assets/images/icons/vitamins/Functions/gene.png";
import immunity from "../../../../../assets/images/icons/vitamins/Functions/immunity.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import glv from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/spinach.png";
import broccoli from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B5/broccoli.png";
import orange from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B9/orange.png";
import carrot from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit A/carrot.png";
import sweetPotato from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit A/sweetPotato.png";
import pumpkin from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit A/pumpkin.png";
import squash from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit A/squash.png";
import tomatoes from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/tomatoes.png";
import peppers from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit C/peppers.png";
import mango from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit A/mango.png";
import fishOil from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/Vit A/fishOil.png";
import milk from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milk.png";
import egg from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/egg.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/vitaminb2/dermatitis.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import BitotsSpot from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/BitotsSpot.png";
import ConjuctivalXerosis from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/ConjuctivalXerosis.png";
import Keratomalacia from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/Keratomalacia.png";
import NightBlindness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/NightBlindness.png";
import fatigue from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png";
import dryHair from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/dryHair.png";

export default function Vitamina() {
  return (
    <>
      <Grid item lg={7} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1,
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the Functions of Vitamin A?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="eye protection" src={eyeProtection} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin A is an important part of photo pigment. This pigment converts the light entering the eyes into electrical signals in brain, enabling us to see things." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="skin" src={skin} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin A maintains the integrity of skin and mucous membrane." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="gene expression" src={gene} />
                </ListItemAvatar>
                <ListItemText primary="It is also involved in gene expression i.e. activation or suppression of various genes in reaction to the environmental stimuli." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="immunity" src={immunity} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin A plays an important role in the development and differentiation of white blood cells involved in immune system." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="reproductive system" src={reproductiveSystem} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin A also has a potent role in reproduction process in both the genders." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={antioxidant} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin A acts as an antioxidant." />
              </ListItem>
              {/* <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={nervoussytem} />
                  </ListItemAvatar>
                  <ListItemText primary="Calcium also acts as a coenzyme for many metabolic processes in the body." />
                </ListItem> */}
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py:0.5
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the Sources of Vitamin A?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Beta-carotene - present in vegetarian sources like dark green leafy vegetables (spinach, broccoli), orange and yellow vegetables (carrots, sweet potatoes, pumpkin, winter squash, summer squash) and others like tomatoes, red bell pepper, cantaloupe, and mango.
              </ListItem>
              <ListItem>
                Retinol - present in animal foods like liver, fish oils, milk, eggs, fortified foods.
              </ListItem>
              <ListItem>
                Beta-carotene is converted into retinol (vitamin A) in the body for absorption.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={0.5}
            mb={0.5}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.5, sm: 1 } }}>
              <Tooltip title="Dark green leafy vegetables">
                <Avatar src={glv} size="lg" alt="Spinach" variant="square" />
              </Tooltip>
              <Tooltip title="Broccoli">
                <Avatar src={broccoli} alt="Broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="Orange ">
                <Avatar src={orange} alt="Orange" variant="square" />
              </Tooltip>
              <Tooltip title="Carrot ">
                <Avatar src={carrot} alt="Carrot" variant="square" />
              </Tooltip>
              <Tooltip title="Sweet Potato">
                <Avatar src={sweetPotato} alt="Sweet Potato" variant="square" />
              </Tooltip>
              <Tooltip title="Pumpkin">
                <Avatar src={pumpkin} alt="Pumpkin" variant="square" />
              </Tooltip>
              <Tooltip title="Winter squash, summer squash">
                <Avatar src={squash} alt="Winter squash, summer squash" variant="square" />
              </Tooltip>
              <Tooltip title="Tomatoes">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={tomatoes} alt="Tomatoes" variant="square" />
              </Tooltip>
              <Tooltip title="Red bell pepper">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={peppers} alt="Red bell pepper" variant="square" />
              </Tooltip>
              <Tooltip title="Mango">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={mango} alt="Mango" variant="square" />
              </Tooltip>
              <Tooltip title="Fish oil">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={fishOil} alt="Fish oil" variant="square" />
              </Tooltip>
              <Tooltip title="Milk">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={milk} alt="Milk" variant="square" />
              </Tooltip>
              <Tooltip title="Eggs">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={egg} alt="Eggs" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="Tomatoes">
                <Avatar src={tomatoes} alt="Tomatoes" variant="square" />
              </Tooltip>
              <Tooltip title="Red bell pepper">
                <Avatar src={peppers} alt="Red bell pepper" variant="square" />
              </Tooltip>
              <Tooltip title="Mango">
                <Avatar src={mango} alt="Mango" variant="square" />
              </Tooltip>
              <Tooltip title="Fish oil">
                <Avatar src={fishOil} alt="Fish oil" variant="square" />
              </Tooltip>
              <Tooltip title="Milk">
                <Avatar src={milk} alt="Milk" variant="square" />
              </Tooltip>
              <Tooltip title="Eggs">
                <Avatar src={egg} alt="Eggs" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={5} md={5} sm={12}>
      <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: -1, sm: 1 },
            py: 1,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is Vitamin A absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left", marginBottom: 2 }}>
              <ListItem>
                Vitamin A is absorbed in the small intestine in form of retinyl esters in the presence of fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1,
            mt: { xs: 1, sm: 0 },
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What is the RDA of Vitamin A?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar>
                <ListItemText primary="The RDA  for vitamin A for an adult male is 1000 µg per day and for an adult female is 840 µg per day." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is Vitamin A absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left", marginBottom: 2 }}>
              <ListItem>
                Vitamin A is absorbed in the small intestine in form of retinyl esters in the presence of fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py:1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens if you are deficient in Vitamin A?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
              <ListItem>
                Deficiency of vitamin A can result in fatigue, dryness of skin and hair and impaired immunity.
              </ListItem>
              <ListItem>
                Severe deficiency of vitamin A could lead to the following:
              </ListItem>
              {/* <ListItem>
                Xerophthalmia: a severe dryness of the eye, Night blindness (Nyctalopia),Conjuctival Xerosis: conjuctiva becomes dry and non-wettable,Bitots’ spot: Dirty white patch is seen in conjuctiva, Keratomalacia or corneal ulceration
              </ListItem> */}
              <ListItem>
                Xerophthalmia, a severe dryness of the eye
              </ListItem>
              <ListItem>
                Night blindness (Nyctalopia)
              </ListItem>
              <ListItem>
                Conjuctival Xerosis – conjuctiva becomes dry and non-wettable
              </ListItem>
              <ListItem>
                Bitots’ spot – Dirty white patch is seen in conjuctiva
              </ListItem>
              <ListItem>
                Keratomalacia or corneal ulceration
              </ListItem>

            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
            mb={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="fatigue">
                <Avatar src={fatigue} size="lg" alt="fatigue" variant="square" />
              </Tooltip>
              <Tooltip title="dry hair">
                <Avatar src={dryHair} alt="dry hair" variant="square" />
              </Tooltip>
              <Tooltip title="night blindness">
                <Avatar src={NightBlindness} alt="night blindness" variant="square" />
              </Tooltip>
              <Tooltip title="Conjuctival Xerosis">
                <Avatar src={ConjuctivalXerosis} alt="Conjuctival Xerosis" variant="square" />
              </Tooltip>
              <Tooltip title="Bitots spot">
                <Avatar src={BitotsSpot} alt="Bitots spot" variant="square" />
              </Tooltip>
              <Tooltip title="Keratomalacia">
                <Avatar src={Keratomalacia} alt="Keratomalacia" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: -1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the Interactions of Vitamin A with other Nutrients?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Vitamin A and vitamin E work in conjugation to protect the body. Also, vitamin E increases the absorption of  vitamin A. They work synergistically to reduce the inflammation in the body." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Iron and zinc are required in the conversion process of provitamin A." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Vitamin A is involved in thyroid hormone production by stimulating the uptake of iodine.
" />
              </ListItem>

            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            backgroundColor: "#FFD9E8",
            py: 1,
            mt: 1
          }}
        >
          <CardContent>
            <Typography level="h4" fontWeight="lg" align="start">
              Vitamin A deficiency control programme:
            </Typography>
            <List sx={{ textAlign: "left", padding: "0px", marginLeft: 2, }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="The National prophylaxis programme against  nutritional blindness due to vitamin A deficiency  (VAD) was started in 1970 with the objective of reducing Keratomalacia. Under the national program for prophylaxis against blindness in children due to VAD, every child has been provided prophylaxis against VAD in the form of 5 oral doses starting at 9 months  and then every 6 months till the age of 5 years." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>

      </Grid>

    </>
  );
}
