import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import bloodClot from "../../../../../assets/images/icons/vitamins/Functions/bloodClot.png"
import bone from "../../../../../assets/images/icons/vitamins/Functions/bone.png"
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import egg from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/egg.png";
import liver from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B9/organMeats.png";
import sunflowerOil from "../../../../../assets/images/icons/vitamins/VitaminE/sunflowerOil.png";
import cheese from "../../../../../assets/images/icons/vitamins/VitaminK/cheese.png";
import kale from "../../../../../assets/images/icons/vitamins/VitaminK/kale.png";
import paneer from "../../../../../assets/images/icons/vitamins/VitaminK/paneer.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/spinach.png";
import broccoli from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B5/broccoli.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/vitaminb2/dermatitis.png";
import fatigue from "../../../../../assets/images/icons/vitamins/vitaminb2/fatigue.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import Osteoporosis from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminK/Osteoporosis.png";
import WeakenedBones from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminK/WeakenedBones.png";
import bloodClotting from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminK/bloodClotting.png";
import excessiveBleeding from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminK/excessiveBleeding.png";
import fracture from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminK/fracture.png";


export default function Vitamink() {
  return (
    <>
      <Grid item lg={6} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py:1
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start" marginBottom={1}>
            What are the Functions of Vitamin K?
          </Typography>
          <CardContent>
            <Typography level="h6" fontWeight="lg" align="start">
              Vitamin K has two well-established functions in the body:
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="blood clot" src={bloodClot} />
                </ListItemAvatar>
                <ListItemText primary="Blood clotting: When you get a cut, whether small or large, and start to bleed, a series of reactions forms a clot which stops the blood flow." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="bone development" src={bone} />
                </ListItemAvatar>
                <ListItemText primary="Bone development: Vitamin K helps in bone development too. It is required for the synthesis of the second most abundant protein in bone, osteocalcin." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py:1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the sources of Vitamin K?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Good vegetarian sources of vitamin K include green leafy vegetables like kale, spinach, and broccoli, as well as vegetable oils.
              </ListItem>
              <ListItem>
                Animal-based foods like liver, paneer, cheese and egg yolks are also good sources of vitamin K.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}

          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.5, sm: 1 } }}>
              <Tooltip title="kale">
                <Avatar src={kale} size="lg" alt="kale" variant="square" />
              </Tooltip>
              <Tooltip title="spinach">
                <Avatar src={glv} alt="spinach" variant="square" />
              </Tooltip>
              <Tooltip title="broccoli">
                <Avatar src={broccoli} alt="broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="liver">
                <Avatar src={liver} alt="liver" variant="square" />
              </Tooltip>
              <Tooltip title="paneer">
                <Avatar src={paneer} alt="paneer" variant="square" />
              </Tooltip>
              <Tooltip title="cheese">
                <Avatar src={cheese} alt="cheese" variant="square" />
              </Tooltip>
              <Tooltip title="egg ">
                <Avatar src={egg} alt="egg yolks" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={6} md={5} sm={12}>
      <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: -1, sm: 1.5 },
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is Vitamin K absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Vitamin K is absorbed in the small intestine, the absorption is enhanced in presence of fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 1, sm: 0 },
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What is the RDA for Vitamin K?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar>
                <ListItemText primary="The RDA  for vitamin K for an adult is 55 µg per day." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1.5,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is Vitamin K absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Vitamin K is absorbed in the small intestine, the absorption is enhanced in presence of fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1.5,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens if you are deficient in vitamin K?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
              <ListItem>
                Impaired blood clotting, leading to excessive bleeding.
              </ListItem>
              <ListItem>
                Long-term deficiency may also affect bone health, potentially increasing the risk of fractures and Osteoporosis.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={2}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="blood clotting">
                <Avatar src={bloodClotting} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="excessive bleeding">
                <Avatar src={excessiveBleeding} alt="excessive bleeding" variant="square" />
              </Tooltip>
              <Tooltip title="weakened bones">
                <Avatar src={WeakenedBones} alt="weakened bones" variant="square" />
              </Tooltip>
              <Tooltip title="increased risk of fractures">
                <Avatar src={fracture} alt="increased risk of fractures" variant="square" />
              </Tooltip>
              <Tooltip title="Osteoporosis">
                <Avatar src={Osteoporosis} alt="Osteoporosis" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt:-1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the Interactions of Vitamin K with other nutrients?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Many drugs can interfere with the effects of vitamin K especially warfarin.  Vitamin K works in conjugation with vitamin D and E." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>

    </>
  );
}
