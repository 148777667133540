import * as React from "react";
import { DataGrid, GridPagination, GridToolbar } from "@mui/x-data-grid";
import './Datatablestyle.css';
export default function BarChart(props) {
  const { mapKey, filteredData } = props;
  // const [pageSize,setPageSize] = React.useState(10)
  let data = [];

  const StatesData = filteredData.filter((data) => {
    if (mapKey !== null && mapKey !== undefined) {
      if (mapKey.toLowerCase() === "india") {
        return data.area === data.area_name && (data.area_level === 2 || data.area_level === 1);
      } else {
        return data.area && data.area.toLowerCase() === mapKey.toLowerCase();
      }
    } else {
      return true;
    }
  }).map((row, index) => ({ ...row, id: index }));

  

  const columns = [
    { field: "area_name", headerName: "State/District", flex: 2.3 },
    { field: "value", headerName: "Total", type: "number", flex: 1 },
    { field: "urban", headerName: "Urban", type: "number", flex: 1 },
    { field: "rural", headerName: "Rural", type: "number", flex: 1 },
    { field: "male", headerName: "Male", type: "number", flex: 1 },
    { field: "female", headerName: "Female", type: "number", flex: 1 },
  ];
  const generateColumns = React.useMemo(() => {
    const nonNullFields = new Set();
    StatesData.forEach((row) => {
      Object.keys(row).forEach((field) => {
        if (field === "area_name" || (row[field] !== "NA" && row[field] !== null && !isNaN(row[field]))) {
          nonNullFields.add(field);
        }
      });
    });

    return columns.filter((column) => nonNullFields.has(column.field));
  }, [StatesData]);
  return (
    <>
    <div style={{ height: filteredData[0].indicator_id === 'L1_13' ? 450 : 300, width: "100%" }}>
        {StatesData.length > 0 ? (
          <DataGrid
            rows={StatesData}
            columns={generateColumns}
            density="compact"
      components={{
        Toolbar: GridToolbar,
        Footer: () => <GridFooter StatesData={StatesData} />,
      }}
      componentsProps={{
        toolbar: {
          printOptions: {
            disableToolbarButton: false,
            hideHeader:true,
            hideFooter: true,
            hideToolbar: true,
            hideFooterPagination: true,
            hideFooterRowCount: true,
            hideFooterSelectedRowCount: true,
            disablePagination: true,
          },
          csvOptions: {
            disableToolbarButton: true,
          },
        },
        
      }}
      loading={false}
      // pagination={false}
      // pageSize={pageSize}
      // rowsPerPageOptions={filteredData[0].indicator_id === 'L1_13' ? [10, 20, 30, 50, 76] : [5, 10, 20, 50, 76]}
      // onPageSizeChange={(newPageSize) => {
      //   setPageSize(newPageSize);
      //   // Update your component state or perform any other necessary actions here
      // }}
      disableColumnFilter={true}
      disableColumnMenu={false}
      disableColumnReorder={false}
      disableDensitySelector={true}
      disableSelectionOnClick={false}
      disableExtendRowFullWidth={true}
      disableMultipleColumnsFiltering={false}
      hideFooterRowCount={false}
      hideFooterSelectedRowCount={false}
      hideFooterPagination={false}
      rowHeight={40}
      rowRenderer={(params) => (
        <div>
          {params.row.id} {params.row.indicator_name} {params.row.value}
        </div>
      )}
      getRowClassName={(params) => {
        if (params.rowClassName) {
          return params.rowClassName;
        }
        return "";
      }}
      headerClassName="header-cell"
    />
  ) : (
    <div>No data available.</div>
  )}
</div>


    </>
  );
}


function GridFooter(props) {
  const { StatesData } = props;

  return (
    <div  className="print-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
      <div>
        {/* Add your custom content for the footer */}
        {StatesData.length > 0 && StatesData[0]?.title && (
          <p>
            {StatesData[0].title}<b> <span>{StatesData[0].source_name}</span></b>
          </p>
        )}
      </div>
      <div className="hide-in-print">
        {/* Default pagination details and navigation buttons */}
        {/* <GridPagination/> */}
      </div>
    </div>
  );
}