import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import bone from "../../../../../assets/images/icons/vitamins/Functions/bone.png"
import muscleContraction from "../../../../../assets/images/icons/vitamins/Functions/muscleContraction.png";
import fish from "../../../../../assets/images/icons/vitamins/vitaminb3/fish.png";
import codLiverOil from "../../../../../assets/images/icons/vitamins/vitaminD/codLiverOil.png";
import fortifiedMilk from "../../../../../assets/images/icons/vitamins/vitaminD/fortifiedMilk.png";
import fortifiedCereals from "../../../../../assets/images/icons/vitamins/vitaminD/fortifiedCereals.png";
import egg from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/egg.png";
import nerveImpulse from "../../../../../assets/images/icons/vitamins/Functions/nerveImpulse.png";
import hormone2 from "../../../../../assets/images/icons/vitamins/Functions/hormone2.png";
import kidney from "../../../../../assets/images/icons/vitamins/Functions/kidney.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb2/nuts.png";
import nuts from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/vitaminb2/dermatitis.png";
import fatigue from "../../../../../assets/images/icons/vitamins/vitaminb2/fatigue.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import Demineralisation from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Demineralisation.png";
import ImpairmentOfBone from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/ImpairmentOfBone.png";
import Osteomalacia from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Osteomalacia.png";
import Osteoporosis from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Osteoporosis.png";
import Rickets from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminD/Rickets.png";

export default function Vitamind() {
  return (
  <>
      <Grid item lg={6} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
          What are the Functions of Vitamin D?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="kidney" src={kidney} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin D is important for absorption of calcium in the gut and kidney. It also helps in maintaining the levels of calcium and phosphorus in the body." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="bone calcification" src={muscleContraction} />
                </ListItemAvatar>
                <ListItemText primary="It increases bone calcification and mineralization and helps in muscle contraction." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="nerve impulse" src={nerveImpulse} />
                </ListItemAvatar>
                <ListItemText primary="It  plays a role in transmitting neural impulses to the brain and body." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="hormone" src={hormone2} />
                </ListItemAvatar>
                <ListItemText primary="It acts as a hormone by stimulating  various processes in the body." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            What are the Sources of Vitamin D?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
              The primary source of Vitamin D is sunlight. When exposed to sunlight, the body can produce Vitamin D in the skin.  Additionally, Vitamin D can be obtained from certain foods such as fatty fish (e.g., salmon, mackerel), cod liver oil,  fortified dairy products, fortified cereals, and egg yolks.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="fatty fish (e.g., salmon, mackerel)">
                <Avatar src={fish} size="lg" alt="fish" variant="square" />
              </Tooltip>
              <Tooltip title="cod liver oil">
                <Avatar src={codLiverOil} alt="codLiverOil" variant="square" />
              </Tooltip>
              <Tooltip title="fortified dairy products">
                <Avatar src={fortifiedMilk} alt="milk" variant="square" />
              </Tooltip>
              <Tooltip title="fortified cereals">
                <Avatar src={fortifiedCereals} alt="cereals" variant="square" />
              </Tooltip>
              <Tooltip title="egg yolks">
                <Avatar src={egg} alt="Green vegetables" egg="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={6} md={5} sm={12}>
      <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: -1, sm: 1 },
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            How is Vitamin D absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left"}}>
              <ListItem>
              Vitamin D is absorbed in the small intestine by passive diffusion, the absorption is enhanced in presence of fats. Vitamin D is also absorbed through the skin and gets activated first, in the liver and then kidney and is circulated throughout the body with fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 1, sm: 0 },
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
          What is the RDA for vitamin D?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar>
                <ListItemText primary="The RDA for vitamin D for an adult is 600 IU per day." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            How is Vitamin D absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left"}}>
              <ListItem>
              Vitamin D is absorbed in the small intestine by passive diffusion, the absorption is enhanced in presence of fats. Vitamin D is also absorbed through the skin and gets activated first, in the liver and then kidney and is circulated throughout the body with fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            What happens if you are deficient in Vitamin D?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem sx={{ fontweight: "bold" }}>
              The deficiency of Vitamin D can lead to:-
              </ListItem>
              <ListItem>
              Rickets : Softening and weakening of bones in children; Osteomalacia: Softening of bone in adults, reduced quality (mostly women in reproductive age); Osteoporosis: loss of bone, reduced quantity (postmenopausal stage); Impairment of bone growth in children; Demineralisation in adults (older people)
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="rickets">
                <Avatar src={Rickets} alt="rickets" variant="square" />
              </Tooltip>
              <Tooltip title="osteomalacia">
                <Avatar src={Osteomalacia} alt="osteomalacia" variant="square" />
              </Tooltip>
              <Tooltip title="osteoporosis">
                <Avatar src={Osteoporosis} alt="osteoporosis" variant="square" />
              </Tooltip>
              <Tooltip title="Impairment of bone">
                <Avatar src={ImpairmentOfBone} alt="Impairment of bone" variant="square" />
              </Tooltip>
              <Tooltip title="Demineralisation in adults (older people) ">
                <Avatar src={Demineralisation} alt="Demineralisation in adults (older people)" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt:-1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            What are the Interactions of vitamin D with other nutrients?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Apart from calcium, phosphorus and magnesium, vitamin D activation enzyme requires iron for the activation of vitamin D." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Vitamin D acts with vitamin K in bone formation." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Long-term use of anticonvulsant drugs, increase losses of vitamin D." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>

    </>
  );
}
