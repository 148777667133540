import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ResponsiveAppBar from "./components/Nav";
import Compare from "./layouts/compare/Compare";
import Contact from "./layouts/contact/Contact";
import Dashboard from "./layouts/dashboard/Dashboard";
import Feedback from "./layouts/feedback/Feedback";
import Home from "./layouts/home/Home";
import Nutrieducation from "./layouts/nutrieducation/Nutrieducation";
import Macronutrient from "./layouts/nutrieducation/components/Macronutrients";
import Carbohydrates from "./layouts/nutrieducation/components/pages/carbohydrates/Carbohydrates";
import Micronutrient from "./layouts/nutrieducation/components/Micronutrients";
import VitaminHome from "./layouts/nutrieducation/components/pages/Vitamins/VitaminHome";
import MineralsHome from "./layouts/nutrieducation/components/pages/Minerals/MineralsHome";
import RdaAndEarHome from "./layouts/nutrieducation/components/pages/RDA/RdaAndEarHome";
import Fats from "./layouts/nutrieducation/components/pages/Fats/Fats";
import Protein from "./layouts/nutrieducation/components/pages/Protein/Protein";
import UnderConstructionPage from "./layouts/nutrieducation/components/pages/UnderConstructionPage";
import MyPlateMainPage from "./layouts/nutrieducation/components/Guidelines/MyPlateForTheDay/MyPlateMainPage";
import Aboutus from "./layouts/aboutus/Aboutus";
import ReactGA from "react-ga4";
import StickyFooter from "./layouts/footer/footer";
import Dietaryfibre from "./layouts/nutrieducation/components/pages/Dietaryfibre/Dietaryfibre";
import TestDietary from "./layouts/nutrieducation/components/Guidelines/MyPlateForTheDay/dietary/TestDietary";
import Resources from "./layouts/resources/Resources";
import DietaryGuidelinesHome from "./layouts/nutrieducation/components/Guidelines/MyPlateForTheDay/DietaryGuidelines/DietaryGuidelinesHome";
// import RdaTable from "./layouts/nutrieducation/components/pages/carbohydrates/PieChart";
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
};

function App() {
  usePageTracking();
  return (
    <div className="App">
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Compare" element={<Compare />} />
        <Route path="/Nutrieducation" element={<Nutrieducation />} />
        <Route path="/Macronutrients" element={<Macronutrient />} />
        <Route path="/Micronutrients" element={<Micronutrient />} />
        <Route path="/Carbohydrates" element={<Carbohydrates />} />
        <Route path="/Minerals" element={<MineralsHome />} />
        <Route path="/Vitamins" element={<VitaminHome />} />
        <Route path="/rdaAndEar" element={<RdaAndEarHome />} />
        <Route path="/Fats" element={<Fats />} />
        <Route path="/Protein" element={<Protein />} />
        <Route path="/Myplatefortheday" element={<MyPlateMainPage />} />
        <Route path="/UnderConstructionPage" element={<UnderConstructionPage />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/DietaryGuidelines" element={<TestDietary />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Dietaryfibre" element={<Dietaryfibre />} />
        <Route path="/Resources" element={<Resources />} />
        <Route path="/DietaryGuidelinesHome" element={<DietaryGuidelinesHome />} />
      </Routes>
      <StickyFooter />
    </div>
  );
}

export default App;
