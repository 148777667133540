// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellGrowth from "../../../../../assets/images/icons/vitamins/Functions/cellGrowth.png";
import nutrientToEnergy from "../../../../../assets/images/icons/vitamins/Functions/nutrientToEnergy.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import meat from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import milk from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milk.png";
import fish from "../../../../../assets/images/icons/vitamins/vitaminb3/fish.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/spinach.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import nuts from "../../../../../assets/images/icons/vitamins/vitaminb3/nuts.png";
import milkProduct from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milkProduct.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import RiboflavinRdaTable from "./RdaAndEarTables/RiboflavinRdaTable";
import Angular_stomatitis from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/Angular_stomatitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/Dermatitis.png";
import photophobia from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/Photophobia.png";
import burning_sensation_in_mouth from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/burning_sensation_in_mouth.png";
import fatigue_of_eyes from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/fatigue_of_eyes.png";
import glossitis from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/glossitis.png";

export default function Vitaminb2() {
    return (
        <>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of vitamin B2 (Riboflavin)?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={nutrientToEnergy} />
                                </ListItemAvatar>
                                <ListItemText primary="Riboflavin is involved in the breakdown of carbohydrates, protein, and fats." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="cell growth" src={cellGrowth} />
                                </ListItemAvatar>
                                <ListItemText primary="It is important for cells growth and function." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>

                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1.5
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the food sources of riboflavin?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Good sources of riboflavin include meat, green leafy vegetables and nuts. It is also found in whole grain cereals, dairy products and fish.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.5, sm: 1 } }}>
                            <Tooltip title="Meat">
                                <Avatar src={meat} alt="meat" variant="square" />
                            </Tooltip>
                            <Tooltip title="Green leafy vegetables">
                                <Avatar src={glv} alt="Green vegetables" variant="square" />
                            </Tooltip>
                            <Tooltip title="Nuts">
                                <Avatar src={nuts} size="lg" alt="nuts" variant="square" />
                            </Tooltip>
                            <Tooltip title="Whole Grain cereals">
                                <Avatar src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
                            </Tooltip>
                            <Tooltip title="Milk">
                                <Avatar src={milk} alt="Milk" variant="square" />
                            </Tooltip>
                            <Tooltip title="milk Products">
                                <Avatar src={milkProduct} alt="Curd" variant="square" />
                            </Tooltip>
                            <Tooltip title="Fish">
                                <Avatar src={fish} alt="fish" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is vitamin B2 absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="After digestion of food, free form of riboflavin is absorbed by intestinal cells by sodium dependent transport system. It is mainly stored in liver and majority of it is excreted through urine." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>

                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1.5,
                        py: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens if you are deficient in vitamin B2?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                            <ListItem>
                                Cereal based diet, old age, prolonged fevers may cause riboflavin deficiency.
                            </ListItem>
                            <ListItem>
                                Angular stomatitis, glossitis, burning sensation in mouth and tongue.
                            </ListItem>
                            <ListItem>
                                Dermatitis involving skin
                            </ListItem>
                            <ListItem>
                                Photophobia, burning and fatigue of eyes
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2,
                            mb: 0.5
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="angular stomatitis">
                                <Avatar src={Angular_stomatitis} size="lg" alt="angular stomatitis" variant="square" />
                            </Tooltip>
                            <Tooltip title="glossitis">
                                <Avatar src={glossitis} alt="glossitis" variant="square" />
                            </Tooltip>
                            <Tooltip title="burning sensation in mouth">
                                <Avatar src={burning_sensation_in_mouth} alt="burning sensation in mouth" variant="square" />
                            </Tooltip>
                            <Tooltip title="dermatitis">
                                <Avatar src={dermatitis} alt="Fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="photophobia">
                                <Avatar src={photophobia} alt="Legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="fatigue of eyes">
                                <Avatar src={fatigue_of_eyes} alt="fatigue of eyes" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <RiboflavinRdaTable />
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is vitamin B2 absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="After digestion of food, free form of riboflavin is absorbed by intestinal cells by sodium dependent transport system. It is mainly stored in liver and majority of it is excreted through urine." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1.5,
                        py: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens if you are deficient in vitamin B2?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                            <ListItem>
                                Cereal based diet, old age, prolonged fevers may cause riboflavin deficiency.
                            </ListItem>
                            <ListItem>
                                Angular stomatitis, glossitis, burning sensation in mouth and tongue.
                            </ListItem>
                            <ListItem>
                                Dermatitis involving skin
                            </ListItem>
                            <ListItem>
                                Photophobia, burning and fatigue of eyes
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2,
                            mb: 0.5,
                            display: { xs: 'block', sm: 'none' }
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="angular stomatitis">
                                <Avatar src={Angular_stomatitis} size="lg" alt="angular stomatitis" variant="square" />
                            </Tooltip>
                            <Tooltip title="glossitis">
                                <Avatar src={glossitis} alt="glossitis" variant="square" />
                            </Tooltip>
                            <Tooltip title="burning sensation in mouth">
                                <Avatar src={burning_sensation_in_mouth} alt="burning sensation in mouth" variant="square" />
                            </Tooltip>
                            <Tooltip title="dermatitis">
                                <Avatar src={dermatitis} alt="Fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="photophobia">
                                <Avatar src={photophobia} alt="Legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="fatigue of eyes">
                                <Avatar src={fatigue_of_eyes} alt="fatigue of eyes" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>

            </Grid>
        </>
    );
}




