import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import IndiaMapData from "../../../maps/2022/india_state_nfhs5.json"; // India map data
import AndhraMapData from "../../../maps/2022/NFHS5_district_maps/andhra pradesh.json"; // Andhra Pradesh state map data
import ArunachalMapData from "../../../maps/2022/NFHS5_district_maps/arunachal pradesh.json"; // Arunachal Pradesh state map data
// ... import map data for all other Indian states
import HighchartsMap from "highcharts/modules/map";
HighchartsMap(Highcharts);
const IndiaMap = () => {
  const [data, setData] = useState([]);
  const chartRef = useRef(null);
  useEffect(() => {
    fetch("https://nutritionatlasapi.cyclic.app/api/data?source_id=1&indicator_id=1&area_level=2")
      .then((response) => response.json())
      .then((json) => setData(json.results));
  }, []);
  useEffect(() => {
    const chartOptions = {
      chart: {
        map: IndiaMapData,
        events: {
          drilldown: function (e) {
            if (e.point.drilldown) {
              const drilldownName = e.point.drilldown.replace(/\s+/g, "");
              const drilldownData = eval(`${drilldownName}MapData`);
              chartRef.current.showLoading();
              this.hideTooltip();

              setTimeout(() => {
                this.setTitle({ text: `${e.point.name} Map` });

                this.hideLoading();
                this.addSeriesAsDrilldown(e.point, {
                  name: e.point.name,
                  data: drilldownData,
                  joinBy: "name",
                });
              }, 500);
            }
          },
          drillup: function () {
            this.setTitle({ text: "India Map" });
          },
        },
      },
      title: {
        text: "India Map",
      },
      subtitle: {
        text: "Click on a state to see its districts",
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },
      colorAxis: {
        min: 0,
        stops: [
          [0.2, "#FFC4AA"],
          [0.4, "#FF8A66"],
          [0.6, "#FF392B"],
          [0.8, "#B71525"],
          [1, "#7A0826"],
        ],
      },
      series: [
        {
          data: data,
          mapData: IndiaMapData,
          joinBy: ["NAME1_", "area_name"],
          name: "States",
          states: {
            hover: {
              color: "#BADA55",
            },
          },
          dataLabels: {
            enabled: true,
            format: "{point.NAME1_}}",
          },
        },
      ],
      drilldown: {
        series: [
          {
            name: "Andhra Pradesh",
            data: AndhraMapData,
          },
          {
            name: "Arunachal Pradesh",
            data: ArunachalMapData,
          },
        ],
      },
    };
    const chart = Highcharts.mapChart(chartRef.current, chartOptions);
    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <div>
      <div ref={chartRef} />
    </div>
  );
};

export default IndiaMap;
