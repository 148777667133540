import * as React from "react";
import { Box, CardHeader, CardMedia, CssBaseline, Divider, Grid, ListItemIcon } from "@mui/material";
import Sheet from "@mui/joy/Sheet";
// import "./Vitamins.css";

import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Container,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { AspectRatio, AvatarGroup, CardOverflow, ListItemContent, ListItemDecorator, Tooltip } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";

// import FatIcon from "../../../../../../src/assets/images/icons/fats.png";
import FatIcon from "../../../../../../src/assets/images/fat.png";
import nutritionLabel from "../../../../../../src/assets/images/icons/Fats_nutrition_label.png";
import bodyenergy from "../../../../../../src/assets/images/icons/fats/bodyenergy.png";
import absorption from "../../../../../../src/assets/images/icons/fats/absorption.png";
import palatability from "../../../../../../src/assets/images/icons/fats/palatability.png";
import braingrowth from "../../../../../../src/assets/images/icons/fats/braingrowth.png";
import Human from "../../../../../../src/assets/FoodSources/Carbohydrate/user.png";
import cell_membranes from "../../../../../../src/assets/images/icons/fats/cell_membranes.png";
import protection from "../../../../../../src/assets/images/icons/fats/protection.png";
import body_warm from "../../../../../../src/assets/images/icons/fats/body_warm.png";
import healthyskin from "../../../../../../src/assets/images/icons/fats/healthyskin.png";
import coconut_oil from "../../../../../../src/assets/images/icons/fats/sources/coconut_oil.png";
import ghee from "../../../../../../src/assets/images/icons/fats/sources/ghee.png";
import palm_oil from "../../../../../../src/assets/images/icons/fats/sources/palm_oil.png";
import donut from "../../../../../../src/assets/images/icons/fats/sources/donut.png";
import cookies from "../../../../../../src/assets/images/icons/fats/sources/cookies.png";
import margarines from "../../../../../../src/assets/images/icons/fats/sources/margarines.png";
import fried_potatoes from "../../../../../../src/assets/images/icons/fats/sources/fried_potatoes.png";
import Dairy from "../../../../../../src/assets/FoodSources/FoodSourceIcons/Dairy.png";
import Meat from "../../../../../../src/assets/FoodSources/FoodSourceIcons/Meat.png";
import weight_loss from "../../../../../../src/assets/images/icons/fats/weight_loss.png";
import olive_oil from "../../../../../../src/assets/images/icons/fats/sources/olive_oil.png";
import sesame_oil from "../../../../../../src/assets/images/icons/fats/sources/sesame_oil.png";
import peanut_oil from "../../../../../../src/assets/images/icons/fats/sources/peanut_oil.png";
import oil from "../../../../../../src/assets/images/icons/fats/sources/oil.png";
import walnut from "../../../../../../src/assets/images/icons/fats/sources/walnut.png";
import chia from "../../../../../../src/assets/images/icons/fats/sources/chia.png";
import canola_oil from "../../../../../../src/assets/images/icons/fats/sources/canola_oil.png";
import flax_seeds from "../../../../../../src/assets/images/icons/fats/sources/flax_seeds.png";
import pumpkin_seed from "../../../../../../src/assets/images/icons/fats/sources/pumpkin_seed.png";
import corn_oil from "../../../../../../src/assets/images/icons/fats/sources/corn_oil.png";
import human from "../../../../../../src/assets/images/icons/fats/human.png";
import pancreas from "../../../../../../src/assets/images/icons/fats/pancreas.png";
import obese from "../../../../../../src/assets/images/icons/fats/obese.png";
import intestines_damage from "../../../../../../src/assets/images/icons/fats/intestines_damage.png";
import growth from "../../../../../../src/assets/images/icons/minerals/Deficiency/growth.png";
import packaged_foods from "../../../../../../src/assets/FoodSources/Minerals/Sources/packaged_foods.png";
import avocado from "../../../../../../src/assets/FoodSources/Minerals/Sources/avocado.png";
import sunflowerSeed from "../../../../../../src/assets/FoodSources/Minerals/Sources/sunflowerSeed.png";
import sunflowerOil from "../../../../../../src/assets/FoodSources/Minerals/Sources/sunflowerOil.png";
import fish from "../../../../../../src/assets/FoodSources/Minerals/Sources/fish.png";
import nuts from "../../../../../../src/assets/FoodSources/Minerals/Sources/nuts.png";
import heart_problem from "../../../../../../src/assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png";

import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FatsCookingOilDataTable from "./FatsCookingOilDataTable";
import CookingOilCompositionChart from "./CookingOilCompositionChart";

export default function Fats() {
  const theme = useTheme();
  const myRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const buttonRefat = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openfat, setOpenfat] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFat = () => {
    setOpenfat(false);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("backToTop");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div style={{ overflowX: "hidden" }} id="backToTop">
      <React.Fragment>
        <CssBaseline />
        <Container fixed m={2} fluid maxWidth="lg">
          <Typography level="h1" fontSize="2em" color="#000" fontWeight="bold" marginTop="0.5em">
            Fats
          </Typography>
          <Grid container direction="row">
            <Grid item lg={4} md={4} sm={12}>
              <Box>
                <img src={FatIcon} alt="Fats icon" width="80%" />
              </Box>
            </Grid>
            <Grid item lg={8} md={8} sm={12}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  marginTop: "20px",
                  maxHeight: { xs: "100%", md: "300px" },
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  {/* <p align="left">
                    <strong>Dietary fats : </strong>
                  </p> */}
                  <ul align="justify">
                    <li><Typography level="h6" align="justify" gutterBottom>Fats are macronutrients that are required by the body in appropriate amounts.  They are classified as healthy “good fats” and unhealthy “bad fats” for general term use.</Typography></li>
                    <li><Typography level="h6" align="justify" gutterBottom>Healthy fats are required by the body for proper functioning.</Typography></li>
                    <li><Typography level="h6" align="justify" gutterBottom>
                      Fats have high calorific value. Per gram of fat provides 9Kcal,  more energy per gram compared to protein and carbohydrates.
                    </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid md={12} mt={4} id="dropdownSection">
            <Fab
              color="primary"
              aria-label="scroll back to top"
              onClick={handleClickScroll}
              style={{ position: "fixed", bottom: theme.spacing(8), right: theme.spacing(2) }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Grid>
          <Grid md={12} mt={1} item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
              <Typography level="h5" gutterBottom m={2}>
                Dietary fats
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            </div>
          </Grid>
          <Grid container spacing={1} mb={2}>
            {/* <Grid xs={12} md={8} item>
            <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
              <Typography
                level="h5"
                sx={{ textAlign: "left", marginBottom: 1 }}
              >
                Which foods contain saturated and unsaturated fats?
              </Typography>
              <FatsClassificationFlowChart />
              </Card>
            </Grid>
            <Grid xs={12} md={4} item>
            <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  height:"100%",
                  overflow: "auto",
                  resize: "horizontal",
                  px:1
                }}
              >
              <Typography
                level="h5"
                sx={{
                  textAlign: "left", [theme.breakpoints.up('md')]: {
                    marginBottom: 5,
                  }
                }}
              >
                Which foods contain trans fats?
              </Typography>
              <FatsClassificationFlowChart2 />
              </Card>
            </Grid> */}
            <Grid xs={12} md={5} item>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    Saturated fatty acid: Unhealthy fats
                  </Typography>
                  <List sx={{ textAlign: "justify" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Saturated fats are  considered “unhealthy fats” since they increase LDL cholesterol “bad cholesterol” and increase the risk of getting heart diseases.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Found naturally in many foods, especially
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold", paddingLeft: '1.5em' }}>animal foods such as ghee, butter, meat and dairy products</ListItem>
                    <ListItem sx={{ fontweight: "bold", paddingLeft: '1.5em' }}>plant sources such as coconut oil, palm and palm kernel</ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      They are also found in deep-fried foods and packaged food items.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="ghee">
                      <Avatar src={ghee} alt="ghee" variant="square" />
                    </Tooltip>
                    <Tooltip title="Dairy">
                      <Avatar src={Dairy} alt="Dairy" variant="square" />
                    </Tooltip>
                    <Tooltip title="Meat">
                      <Avatar src={Meat} alt="skin problems" variant="square" />
                    </Tooltip>
                    <Tooltip title="coconut oil">
                      <Avatar src={coconut_oil} alt="coconut oil" variant="square" />
                    </Tooltip>
                    <Tooltip title="palm and palm kernel">
                      <Avatar src={palm_oil} alt="palm oil" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 2
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    What are Trans fat?
                  </Typography>
                  <List sx={{ textAlign: "justify" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Trans fats are formed during food processing to turn liquid oils into solid fats.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Trans fatty acids (TFA) not only increases the levels of LDL cholesterol and cholesterol which are bad for the heart but also decreases levels of good (HDL) cholesterol. Therefore it is best to avoid trans fatty acids.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>Found in fried foods, commercial baked goods (donuts, cookies, crackers), processed foods, and margarines (vanaspati).</ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>Look for the terms ‘partially hydrogenated’ or ‘shortening’ on food labels as these are the sources of trans fatty acids.</ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="fried foods">
                      <Avatar src={fried_potatoes} alt="fried foods" variant="square" />
                    </Tooltip>
                    <Tooltip title="donuts">
                      <Avatar src={donut} alt="donuts" variant="square" />
                    </Tooltip>
                    <Tooltip title="cookies and crackers">
                      <Avatar src={cookies} alt="cookies" variant="square" />
                    </Tooltip>
                    <Tooltip title="processed foods">
                      <Avatar src={packaged_foods} alt="processed foods" variant="square" />
                    </Tooltip>
                    <Tooltip title="margarines (vanaspati)">
                      <Avatar src={margarines} alt="margarines" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>
              </Card>
            </Grid>
            <Grid xs={12} md={7} item>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    Unsaturated fatty acids: Healthy fats
                  </Typography>
                  <List sx={{ textAlign: "justify", '& > li': { marginBottom: '-5px' } }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Unsaturated fats are termed as “healthy fats” as they lower the risk of heart diseases. From the total calories coming from fats, it should be primarily from unsaturated fatty acids, in it less than 10% from SFA and less than 1% from TFA.
                    </ListItem>
                    <ListItem sx={{ fontweight: "bold" }}>Sources include: Sunflower, corn, soybean, canola and flaxseed oils; walnuts, flax seeds and fish.</ListItem>
                    <ListItem>
                      <Typography variant="body2" sx={{ color: 'black' }}>
                        <span>It is further divided into: </span>
                        <span style={{ fontWeight: 'bold' }}>Monounsaturated fatty acids</span>
                        <span> and </span>
                        <span style={{ fontWeight: 'bold' }}>Polyunsaturated fatty acids</span>
                        <span>.</span>
                      </Typography>
                    </ListItem>
                  </List>
                  <Typography level="h6" fontWeight="lg" align="start" mb={-1}>
                    Monounsaturated fatty acids (MUFA):
                  </Typography>
                  <List sx={{ textAlign: "justify" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      MUFA are primarily derived from plants. Examples include olive oil,  peanut oil, sesame oil, avocados and nuts.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  mb={1}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="olive oil">
                      <Avatar src={olive_oil} alt="olive oil" variant="square" />
                    </Tooltip>
                    <Tooltip title="peanut oil">
                      <Avatar src={peanut_oil} alt="peanut oil" variant="square" />
                    </Tooltip>
                    <Tooltip title="sesame oil">
                      <Avatar src={sesame_oil} alt="sesame oil" variant="square" />
                    </Tooltip>
                    <Tooltip title="avocados">
                      <Avatar src={avocado} alt="avocados" variant="square" />
                    </Tooltip>
                    <Tooltip title="nuts">
                      <Avatar src={nuts} alt="nuts" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>
                <CardContent>
                  <Typography level="h6" fontWeight="lg" align="start" mb={-1}>
                    Polyunsaturated fatty acids (PUFA):
                  </Typography>
                  <List sx={{ textAlign: "justify" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      PUFA are called essential fatty acids. Essential fatty acids – are not synthesized in the body therefore the requirements have to be met from diet. Vegetable oil (Sunflower, corn, soyabean, canola and flaxseed), walnuts, flaxseeds and fish.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  mb={1}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="Vegetable oil (Sunflower, corn, soyabean, canola and flaxseed)">
                      <Avatar src={oil} alt="oil" variant="square" />
                    </Tooltip>
                    <Tooltip title="walnut">
                      <Avatar src={walnut} alt="walnut" variant="square" />
                    </Tooltip>
                    <Tooltip title="flaxseed">
                      <Avatar src={flax_seeds} alt="flaxseed" variant="square" />
                    </Tooltip>
                    <Tooltip title="fish">
                      <Avatar src={fish} alt="fish" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>

                <CardContent>
                  <Typography level="h6" fontWeight="lg" align="start" mb={-1}>
                    Alpha-linolenic acid (omega-3 or n-3):
                  </Typography>
                  <List sx={{ textAlign: "justify" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      The sources of omega-3 fatty acids includes fish and other seafood, particularly cold-water fatty fish like salmon, mackerel, tuna, herring, and sardines. Good sources from plant foods are  flaxseeds, walnuts, chia seeds and canola oil.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  mb={1}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="water fatty fish like salmon, mackerel, tuna, herring, and sardines">
                      <Avatar src={fish} alt="fish" variant="square" />
                    </Tooltip>
                    <Tooltip title="flax seeds">
                      <Avatar src={flax_seeds} alt="flax seeds" variant="square" />
                    </Tooltip>
                    <Tooltip title="walnuts">
                      <Avatar src={walnut} alt="walnuts" variant="square" />
                    </Tooltip>
                    <Tooltip title="chia seeds">
                      <Avatar src={chia} alt="chia seeds" variant="square" />
                    </Tooltip>
                    <Tooltip title="canola oil">
                      <Avatar src={canola_oil} alt="canola oil" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>

                <CardContent>
                  <Typography level="h6" fontWeight="lg" align="start" mb={-1}>
                    Linoleic acid (omega-6 or n-6):
                  </Typography>
                  <List sx={{ textAlign: "justify" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Good sources of omega-6 fatty acids include safflower oil, sunflower oil, corn oil, soybean oil, and sunflower seeds, walnuts, pumpkin seeds.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="safflower oil, sunflower oil">
                      <Avatar src={sunflowerOil} alt="sunflower Oil" variant="square" />
                    </Tooltip>
                    <Tooltip title="corn oil">
                      <Avatar src={corn_oil} alt="corn oil" variant="square" />
                    </Tooltip>
                    <Tooltip title="sunflower seeds">
                      <Avatar src={sunflowerSeed} alt="sunflower seeds" variant="square" />
                    </Tooltip>
                    <Tooltip title="walnuts">
                      <Avatar src={walnut} alt="walnuts" variant="square" />
                    </Tooltip>
                    <Tooltip title="pumpkin seeds">
                      <Avatar src={pumpkin_seed} alt="pumpkin seeds" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid md={12} mt={1} item mb={2}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
              {/* <Typography level="h5" gutterBottom m={2}>
                Dietary fats
              </Typography> */}

              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            </div>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item lg={6} md={7} sm={12}>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start">
                  What are the functions of fats?
                </Typography>
                <CardContent>
                  <List aria-labelledby="ellipsis-list-demo"
                    sx={{ textAlign: "justify", '--ListItemDecorator-size': '56px' }}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="bodyenergy" src={bodyenergy} />
                      </ListItemAvatar>
                      <ListItemText primary="Fats provides energy for the body." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="absorption" src={absorption} />
                      </ListItemAvatar>
                      <ListItemText primary="They are required for transportation and absorption of fat-soluble vitamins (A, D, E and K) in the body." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="palatability" src={palatability} />
                      </ListItemAvatar>
                      <ListItemText primary="They increase the palatability and satiety of the food." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="braingrowth" src={braingrowth} />
                      </ListItemAvatar>
                      <ListItemText primary="Fats are essential for the development of the brain and eyes in infants and young children." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="healthyskin" src={healthyskin} />
                      </ListItemAvatar>
                      <ListItemText primary="It is crucial for maintaining good skin in both children and adults." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="cell membranes" src={cell_membranes} />
                      </ListItemAvatar>
                      <ListItemText primary="It is an essential component of all cell membranes." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="shielding" src={protection} />
                      </ListItemAvatar>
                      <ListItemText primary="Fat tissue supports and cushions organs and nerves in your body, shielding them from harm due to impacts or shocks." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="body warm" src={body_warm} />
                      </ListItemAvatar>
                      <ListItemText primary="They provide insulation and keeps body warm." />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>

              <Box sx={{ textAlign: "justify", marginTop: 1 }}>
                <Card variant="outlined" sx={{ height: "100%", padding: 2 }} >
                  <CardContent>
                    <Typography level="h5" gutterBottom>
                      What is difference between visible and invisible fats?
                    </Typography>
                    <Typography mt={1}>
                      <Box
                        component="span"
                        sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF5722", color: "#FFFFFF", }}
                      >
                        <strong>Invisible fats:</strong>
                      </Box>{" "}  Invisible fat are fats present as integral components of food. These fats are also known as hidden fats.
                    </Typography>
                    <Typography mt={1}>
                      <Box
                        component="span"
                        sx={{ px: 0.5, py: 0.3, backgroundColor: "#FF5722", color: "#FFFFFF", }}
                      >
                        <strong>Visible fats:</strong>
                      </Box>{" "}  Visible fats are those which are used while cooking such as Vegetable oil, ghee, butter and Vanaspati.
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 1,
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    What will happen when there is too low fat intake in the diet?
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Inadequate dietary fat, along with insufficient protein and carbohydrates, can result in weight loss and hinder growth.  Deficiencies in omega-3 and omega-6 PUFAs may cause neurological issues, skin problems, and stunted growth, primarily in those severely malnourished or with conditions like cystic fibrosis, pancreatic issues, or intestinal damage, impairing fat absorption.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="weight loss">
                      <Avatar src={weight_loss} alt="weight loss" variant="square" />
                    </Tooltip>
                    <Tooltip title="hinder growth">
                      <Avatar src={growth} alt="hinder growth" variant="square" />
                    </Tooltip>
                    <Tooltip title="skin problems">
                      <Avatar src={human} alt="skin problems" variant="square" />
                    </Tooltip>
                    <Tooltip title="pancreatic issues">
                      <Avatar src={pancreas} alt="pancreatic issues" variant="square" />
                    </Tooltip>
                    <Tooltip title="intestinal damage">
                      <Avatar src={intestines_damage} alt="intestinal damage" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>

                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start" mt={2}>
                    What happens when there is overconsumption of fats?
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Consuming too much fat in the diet can increase the chance of becoming obese, developing heart problems, and certain cancers. Increased intake of  lots of saturated fats is known to raise the risk of heart disease.
                    </ListItem>
                  </List>
                </CardContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                    <Tooltip title="obesity">
                      <Avatar src={obese} alt="Obesity" variant="square" />
                    </Tooltip>
                    <Tooltip title="heart problems">
                      <Avatar src={heart_problem} alt="heart problems" variant="square" />
                    </Tooltip>
                  </AvatarGroup>
                </Box>
              </Card>



            </Grid>
            <Grid item lg={6} md={5} sm={12}>
              {/* <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: { xs: -1, sm: 1 },
                  display: { xs: 'block', sm: 'none' }
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    Digestion & absorption of fats
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem>
                      Fats are insoluble in water unlike carbohydrates and proteins. Hence, they are absorbed and transported through lymphatic system.
                    </ListItem>
                    <ListItem>
                      Bile acid, secreted from gall bladder, is required for emulsification (bread down) of fats.
                    </ListItem>
                    <ListItem>
                      Fats are digested into small intestine wherein they are converted into long chain fatty acids (LCFAs) and then to short chain fatty acids (SCFAs) and finally into digested lipid products which as ready for absorption.
                    </ListItem>
                    <ListItem>
                      Fats form complexes with proteins (lipoproteins) for transportation.
                    </ListItem>
                  </List>
                </CardContent>
              </Card> */}
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: { xs: 1, sm: 0 },
                }}
              >
                <Typography level="h5" fontWeight="lg" align="start">
                  How much fat should be included in the diet per day?
                </Typography>
                <CardContent>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem alignItems="flex-start">
                      {/* <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar> */}
                      <ListItemText primary="ICMR-NIN recommends the upper limit for visible fat intake based on individual physical activity." />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={Human} />
                      </ListItemAvatar>
                      <ListItemText primary="For adults with sedentary, moderate, and heavy exercise, the recommended dietary fat intake has been set at 25, 30, and 40 g/d for men and 20, 25, and 30 g/d for women." />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 1,
                  // display: { xs: 'none', sm: 'block' }
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    Digestion & absorption of fats
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem>
                      Fats are insoluble in water unlike carbohydrates and proteins. Hence, they are absorbed and transported through lymphatic system.
                    </ListItem>
                    <ListItem>
                      Bile acid, secreted from gall bladder, is required for the emulsification (bread down) of fats.
                    </ListItem>
                    <ListItem>
                      Fats are digested into small intestine wherein they are converted into long chain fatty acids (LCFAs) and then to short chain fatty acids (SCFAs) and finally into digested lipid products which are ready for absorption.
                    </ListItem>
                    <ListItem>
                      Fats form complexes with proteins (lipoproteins) for transportation.
                    </ListItem>
                  </List>
                </CardContent>
                <Box sx={{ textAlign: "justify", }}>
                  <Typography>
                    <Box
                      component="span"
                      sx={{ px: 0.8, py: 0.3, backgroundColor: "#FF5722", color: "#FFFFFF", }}
                    >
                      <strong>Tip:</strong>
                    </Box>{" "}  Cholesterol is found only in animal source foods- plant based oils are naturally cholesterol free! However, it is needed in smaller quantities as it plays key role in membrane integrity, hormones synthesis and  bile salts that in turn help in metabolism.
                  </Typography>
                </Box>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  //  width: 320,
                  // to make the card resizable
                  overflow: "auto",
                  resize: "horizontal",
                  mt: 1,
                }}
              >
                <CardContent>
                  <Typography level="h5" fontWeight="lg" align="start">
                    Nutrition label:
                  </Typography>
                  <List sx={{ textAlign: "left" }}>
                    <ListItem sx={{ fontweight: "bold" }}>
                      Be cautious about food items labelled as “zero-trans fat” or “trans-fat free”. Such food items are usually high in saturated fats. Check the labels properly before making any decision.
                    </ListItem>
                  </List>
                </CardContent>
                <CardMedia
                  component="img"
                  height="auto" // Height will adjust based on the image's aspect ratio
                  image={nutritionLabel}
                  alt="Nutrition label image"
                  sx={{
                    m: -2
                  }}
                />
              </Card>

            </Grid>
          </Grid>
          <Grid md={12} mt={1} item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
              <Typography level="h5" gutterBottom m={2}>
                Fatty acid composition of Indian cooking oil*
              </Typography>

              <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            </div>
          </Grid>
          <FatsCookingOilDataTable />
          <Grid item xs={12} mt={5}>
            <CookingOilCompositionChart />
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}
