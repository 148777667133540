import {
  Box,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Button,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from "@mui/material";
import React from "react";
import RdaTable from "./RdaTable";
import RdaDataTable from "./RdaDataTable";
import EarTable from "./EarTable";
import EarDataTable from "./EarDataTable";
import RdaBook from "../../../../../assets/images/RDA_thum.jpg";
import PhysiologicalGroups from "../../../../../assets/images/PhysiologicalGroups.png";
import { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { FcOk } from "react-icons/fc";
import HairLossIcon from "../../../../../../src/assets/images/icons/Protein/HairLossIcon.png";
import { Link } from "react-router-dom";

const options = [
  { label: "Full book", url: "https://www.nin.res.in/RDA_Full_Report_2024.html" },
  { label: "Short Report", url: "https://www.nin.res.in/RDA_short_Report_2024.html" },
];

function RdaAndEarHome() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openfat, setOpenfat] = React.useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (url) => {
    if (url) {
      window.open(url, "_blank"); // Opens the URL in a new tab
    }
    handleMenuClose(); // Close the menu after selection
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFat = () => {
    setOpenfat(false);
  };

  const handleEarScroll = () => {
    const element = document.getElementById("earGrid");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleRdaScroll = () => {
    const element = document.getElementById("rdaGrid");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <React.Fragment>
          <CssBaseline />
          <Container fixed m={4} fluid maxWidth="lg">
            <Typography
              variant="h4"
              style={{
                fontSize: "1.8em",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "bold",
                color: "#FF4081",
                marginTop: "1em",
              }}
            >
              NUTRIENT REQUIREMENT FOR INDIANS: RDA and EAR
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontSize: "1rem",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "bold",
                color: "#555",
                marginTop: "0.5em",
                marginBottom: "1.5em",
              }}
            >
              ICMR-NIN Expert Group Report on Nutrient Requirements for Indians
            </Typography>
            {/* <Typography
              level="h1"
              fontSize="1.5em"
              textColor="#000"
              fontWeight="bold"
              marginTop="1em"
              marginBottom="1em"
            >
              Nutrient Requirements for Indians: RDA and EAR
            </Typography> */}
            <Grid container direction="row">
              <Grid item lg={4} md={4} sm={12}>
                <Box
                  sx={{
                    mt: {
                      sx: 1,
                      md: "1.5em",
                    },
                    display: "flex",
                    flexDirection: "column", // To stack the image and button vertically
                    alignItems: "center", // To center the items horizontally
                  }}
                >
                  <img src={RdaBook} alt="RdaBook" width="60%" />
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      marginTop: "10px",
                      color: "#6b3238",
                      fontWeight: "bold",
                      borderColor: "#6b3238",
                    }}
                    onClick={handleMenuOpen}
                  >
                    Read
                  </Button>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    {options.map((option, index) => (
                      <MenuItem key={index} onClick={() => handleOptionSelect(option.url)}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={12}>
                <Box sx={{ width: "100%" }}>
                  <Typography variant="body1" align="justify" gutterBottom>
                    <Box
                      component="span"
                      sx={{ fontSize: "1.3em", fontWeight: "bold", color: "#6b3238" }}
                    >
                      Introduction:
                    </Box>{" "}
                    Over the past decade, dietary patterns have changed with economic and nutrition
                    transition at the community level. These rapid changes are relevant to the dual
                    burden of malnutrition in India. Nutrient requirements have to be reviewed with
                    respect to these changes in food intake, access and lifestyle. Humans require a
                    wide range of foods and nutrients to lead a healthy and active life. Food and
                    nutrient requirement vary considerably depending upon age, sex, physiological
                    status and lifestyle of individuals. The Expert Group of Indian Council of
                    Medical Research, National Institute of Nutrition (ICMR-NIN, 2020 Report)
                    developed the nutrient requirements for Indians, based on concepts related to
                    the distribution of nutrient requirements in normal individuals. The median/mean
                    of this (normal or normalized) requirement distribution is called the{" "}
                    <Link onClick={handleEarScroll} style={{ fontWeight: "bold" }}>
                      Estimated Average Requirement (EAR)
                    </Link>{" "}
                    and the{" "}
                    <Link onClick={handleRdaScroll} style={{ fontWeight: "bold" }}>
                      Recommended Dietary Allowances (RDA)
                    </Link>{" "}
                    is derived from the EAR. The key measurement is the EAR, which is ideally
                    directly measured experimentally. EAR is adjusted for absorption, which is
                    tightly regulated and varies between individuals depending on nutrient intakes
                    and deficiency status etc.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Typography p={1} mt={4} sx={{ backgroundColor: "#6b3238", color: "white" }}>
              The Nutrient Requirement is different for:
            </Typography>
            <Grid container alignItems="stretch">
              <Grid xs={12} md={6} p={1} mt={2} item style={{ display: "flex" }}>
                <Box>
                  <Card sx={{ height: "100%", boxShadow: "none" }}>
                    {/* <CardHeader title="The Nutrient Requirement is different for:" sx={{ backgroundColor: "#6b3238", color: "white" }} /> */}
                    <CardContent>
                      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                        <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                          <ListItemAvatar>
                            <Avatar style={{ fontSize: "5em" }}>
                              <FcOk />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Different age groups- children, adolescents, adults and elderly" />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                          <ListItemAvatar>
                            <Avatar style={{ fontSize: "48px" }}>
                              <FcOk />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Gender, male and females" />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                          <ListItemAvatar>
                            <Avatar style={{ fontSize: "48px" }}>
                              <FcOk />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Different body weights" />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                          <ListItemAvatar>
                            <Avatar style={{ fontSize: "48px" }}>
                              <FcOk />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Physiological stages- Pregnant women and lactating mother" />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              <Grid xs={12} md={6} p={1} item style={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column", // To stack the image and button vertically
                    alignItems: "center", // To center the items horizontally
                  }}
                >
                  <img src={PhysiologicalGroups} alt="RdaBook" width="60%" />
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" id="earGrid">
              <Grid item lg={6} md={6} sm={12}>
                <Box sx={{ textAlign: "left" }}>
                  <Typography
                    variant="h5"
                    align="left"
                    gutterBottom
                    mt={4}
                    color="#6b3238"
                    fontWeight="bold"
                  >
                    Estimated Average Requirements (EAR)
                  </Typography>
                  <Typography variant="body1" align="justify" gutterBottom>
                    The Estimated Average Requirement (EAR) is the average daily nutrient intake
                    level estimated to meet the requirements of half the healthy individuals in a
                    particular life stage and gender group. It serves as a reference point to assess
                    the adequacy of nutrient intake within a population. EAR is determined through
                    scientific research and is used as a basis for setting the Recommended Dietary
                    Allowances (RDAs).
                  </Typography>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textAlign: "left",
                        color: "#6b3238",
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Applications of EAR in the field of nutrition, health and public policy:
                    </Typography>
                    <ListItem alignitems="justify">
                      <ListItemAvatar>
                        <FcOk style={{ fontSize: "2.5em" }} />
                      </ListItemAvatar>
                      <ListItemText
                        align="justify"
                        primary="Research and Population Assessment: EAR is primarily used in scientific research to assess the nutritional status of populations. It helps researchers understand the average nutrient needs within specific groups based on age, gender, and life stage."
                      />
                    </ListItem>

                    <ListItem alignitems="justify">
                      <ListItemAvatar>
                        <FcOk style={{ fontSize: "2.5em" }} />
                      </ListItemAvatar>
                      <ListItemText
                        align="justify"
                        primary="Setting Dietary Reference Intakes (DRIs): EAR serves as the foundation for establishing other components of DRIs, such as Adequate Intakes (AI) and Tolerable Upper Intake Levels (UL). DRIs provide a comprehensive framework for dietary recommendations."
                      />
                    </ListItem>
                    <ListItem alignitems="justify">
                      <ListItemAvatar>
                        <FcOk style={{ fontSize: "2.5em" }} />
                      </ListItemAvatar>
                      <ListItemText
                        align="justify"
                        primary="Public Health Surveys: EAR values are utilized in national and international surveys to evaluate the adequacy of nutrient intake within populations. These surveys help identify trends and potential nutritional deficiencies."
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} mt={5}>
                <EarTable />
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <EarDataTable />
            </Grid>

            <Typography
              variant="h5"
              color="#212529"
              style={{
                fontFamily: "Baskerville",
                fontWeight: "bold",
                color: "rgb(102, 44, 144)",
                textAlign: "left",
              }}
              mt={1}
            >
              Note:
            </Typography>
            <Typography
              variant="h6"
              color="#212529"
              style={{
                fontFamily: "Baskerville",
                fontWeight: "bold",
                color: "rgb(102, 44, 144)",
                textAlign: "justify",
              }}
              ml={5}
            >
              1. EAR is more focused on research and assessing the nutritional status of
              populations.
            </Typography>
            <Typography
              variant="h6"
              color="#212529"
              style={{
                fontFamily: "Baskerville",
                fontWeight: "bold",
                color: "rgb(102, 44, 144)",
                textAlign: "justify",
              }}
              mb={2}
              ml={5}
            >
              2. RDA and EAR both play essential role in ensuring adequate nutrition to support
              optimal health to individuals and population respectively.
            </Typography>
            {/* <Box border={1} borderRadius={5} p={1.5} mt={5} backgroundColor="#faebd7a8" style={{ borderColor: '#FF4081' }}>
              <Typography variant="h6" color="#212529" style={{ fontFamily: 'Baskerville' }}>
                Note: EAR is more focused on research and assessing the nutritional status of populations.
              </Typography>
            </Box> */}

            <Grid container justifyContent="center" alignItems="center" id="rdaGrid">
              <Grid item lg={7} md={7} sm={12}>
                <Box sx={{ textAlign: "left" }}>
                  <Typography
                    variant="h5"
                    align="left"
                    gutterBottom
                    mt={2}
                    color="#6b3238"
                    fontWeight="bold"
                  >
                    2. Recommended Dietary Allowances (RDA):
                  </Typography>
                  <Typography variant="body1" align="justify" gutterBottom>
                    Recommended Dietary Allowances (RDAs) are the average daily dietary nutrient
                    intake levels sufficient to meet the nutrient requirements of nearly all
                    (97-98%) healthy individuals in a specific life stage and gender group. RDAs are
                    set based on the EAR, taking into account individual variability. RDAs are
                    designed to prevent nutrient deficiencies and maintain optimal health.
                  </Typography>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        textAlign: "left",
                        fontSize: "1.05rem",
                        fontWeight: "bold",
                        color: "#6b3238",
                      }}
                    >
                      Applications of RDA in the field of nutrition, health and public policy:
                    </Typography>
                    <ListItem alignitems="justify">
                      <ListItemAvatar>
                        <FcOk style={{ fontSize: "2.5em" }} />
                      </ListItemAvatar>
                      <ListItemText
                        align="justify"
                        primary="Individual Dietary Planning: RDA is commonly used by nutritionists and dietitians when developing personalized dietary plans for individuals. It provides a target for nutrient intake that is sufficient to meet the needs of nearly all individuals in a specific life stage and gender."
                      />
                    </ListItem>

                    <ListItem alignitems="justify">
                      <ListItemAvatar>
                        <FcOk style={{ fontSize: "2.5em" }} />
                      </ListItemAvatar>
                      <ListItemText
                        align="justify"
                        primary="Clinical Practice: Healthcare professionals use RDAs to assess and manage the nutritional needs of patients. RDAs guide interventions to prevent or address nutrient deficiencies and promote overall health."
                      />
                    </ListItem>
                    <ListItem alignitems="justify">
                      <ListItemAvatar>
                        <FcOk style={{ fontSize: "2.5em" }} />
                      </ListItemAvatar>
                      <ListItemText
                        align="justify"
                        primary="Food Labeling: RDA values are utilized in the development of nutrition labels on packaged foods. These labels help consumers understand the percentage of the recommended daily intake provided by a particular food item."
                      />
                    </ListItem>
                    <ListItem alignitems="justify">
                      <ListItemAvatar>
                        <FcOk style={{ fontSize: "2.5em" }} />
                      </ListItemAvatar>
                      <ListItemText
                        align="justify"
                        primary="Public Health Guidelines: RDAs contribute to the establishment of public health guidelines and policies related to nutrition. Government agencies use these values to inform recommendations for healthy eating habits at the population level."
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item lg={5} md={5} sm={12}>
                <RdaTable />
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <RdaDataTable />
            </Grid>

            <Typography
              variant="h5"
              color="#212529"
              style={{
                fontFamily: "Baskerville",
                fontWeight: "bold",
                color: "rgb(102, 44, 144)",
                textAlign: "left",
              }}
              mt={1}
            >
              Note:
            </Typography>
            <Typography
              variant="h6"
              color="#212529"
              style={{
                fontFamily: "Baskerville",
                fontWeight: "bold",
                color: "rgb(102, 44, 144)",
                textAlign: "justify",
              }}
              ml={5}
            >
              1. RDA is commonly applied in individual dietary planning, clinical settings and
              "supervised" nutrition counselling.
            </Typography>
            <Typography
              variant="h6"
              color="#212529"
              style={{
                fontFamily: "Baskerville",
                fontWeight: "bold",
                color: "rgb(102, 44, 144)",
                textAlign: "justify",
              }}
              mb={2}
              ml={5}
            >
              2. RDA and EAR both play essential role in ensuring adequate nutrition to support
              optimal health to individuals and population respectively.
            </Typography>

            {/* <Grid container direction="row">
              <Typography variant="body1" p={1} mt={4} sx={{ textAlign: "left", color: "#6b3238", fontWeight: "bold", fontSize: "1.3rem" }}>
                The Estimated Average Requirement (EAR) and Recommended Dietary Allowance (RDA) are both used in the fields of nutrition, health, and public policy, but they have distinct applications:
              </Typography>
                <Grid xs={12} md={5} p={1} item>

                  <Box sx={{ textAlign: "left"}}>
                      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      <Typography variant="subtitle2" sx={{ textAlign: "left", fontSize: "1.1rem", fontWeight: "bold" }}>
                        1.	Estimated Average Requirement (EAR):
                      </Typography>
                        <ListItem alignitems="justify">
                          <ListItemAvatar>
                            <FcOk style={{ fontSize: '2.5em' }} />
                          </ListItemAvatar>
                          <ListItemText
                            align="justify"
                            primary="Research and Population Assessment: EAR is primarily used in scientific research to assess the nutritional status of populations. It helps researchers understand the average nutrient needs within specific groups based on age, gender, and life stage."
                          />
                        </ListItem>

                        <ListItem alignitems="justify">
                          <ListItemAvatar>
                            <FcOk style={{ fontSize: '2.5em' }} />
                          </ListItemAvatar>
                          <ListItemText
                            align="justify"
                            primary="Setting Dietary Reference Intakes (DRIs): EAR serves as the foundation for establishing other components of DRIs, such as Adequate Intakes (AI) and Tolerable Upper Intake Levels (UL). DRIs provide a comprehensive framework for dietary recommendations."
                          />
                        </ListItem>
                        <ListItem alignitems="justify">
                          <ListItemAvatar>
                            <FcOk style={{ fontSize: '2.5em' }} />
                          </ListItemAvatar>
                          <ListItemText
                            align="justify"
                            primary="Public Health Surveys: EAR values are utilized in national and international surveys to evaluate the adequacy of nutrient intake within populations. These surveys help identify trends and potential nutritional deficiencies."
                          />
                        </ListItem>
                      </List>
                  </Box>
                </Grid>
                <Grid xs={12} md={7} p={1} item>
                  <Box sx={{ textAlign: "left"}}>
                      <List sx={{ width: "100%", bgcolor: "background.paper"}}>
                      <Typography variant="subtitle2" sx={{ textAlign: "left", fontSize: "1.1rem", fontWeight: "bold" }}>
                      2.	Recommended Dietary Allowance (RDA):
                      </Typography>
                        <ListItem alignitems="justify">
                          <ListItemAvatar>
                            <FcOk style={{ fontSize: '2.5em' }} />
                          </ListItemAvatar>
                          <ListItemText
                            align="justify"
                            primary="Individual Dietary Planning: RDA is commonly used by nutritionists and dietitians when developing personalized dietary plans for individuals. It provides a target for nutrient intake that is sufficient to meet the needs of nearly all individuals in a specific life stage and gender."
                          />
                        </ListItem>

                        <ListItem alignitems="justify">
                          <ListItemAvatar>
                            <FcOk style={{ fontSize: '2.5em' }} />
                          </ListItemAvatar>
                          <ListItemText
                            align="justify"
                            primary="Clinical Practice: Healthcare professionals use RDAs to assess and manage the nutritional needs of patients. RDAs guide interventions to prevent or address nutrient deficiencies and promote overall health."
                          />
                        </ListItem>
                        <ListItem alignitems="justify">
                          <ListItemAvatar>
                            <FcOk style={{ fontSize: '2.5em' }} />
                          </ListItemAvatar>
                          <ListItemText
                            align="justify"
                            primary="Food Labeling: RDA values are utilized in the development of nutrition labels on packaged foods. These labels help consumers understand the percentage of the recommended daily intake provided by a particular food item."
                          />
                        </ListItem>
                        <ListItem alignitems="justify">
                          <ListItemAvatar>
                            <FcOk style={{ fontSize: '2.5em' }} />
                          </ListItemAvatar>
                          <ListItemText
                            align="justify"
                            primary="Public Health Guidelines: RDAs contribute to the establishment of public health guidelines and policies related to nutrition. Government agencies use these values to inform recommendations for healthy eating habits at the population level."
                          />
                        </ListItem>
                      </List>
                  </Box>
                </Grid>
                <Typography variant="body1" sx={{ textAlign: "left" }} mb={2}>
                While EAR is more focused on research and assessing the nutritional status of populations, RDA is commonly applied in individual dietary planning, clinical settings, and the development of nutritional guidelines for public health. Both EAR and RDA play essential roles in ensuring that individuals and populations receive adequate nutrition to support optimal health.
              </Typography>
              </Grid> */}
            {/* <Grid item lg={6} md={6} sm={12}>
              <RdaTable />
            </Grid>
            <Grid sm={12}>
              <RdaDataTable />
            </Grid> */}
            {/* <Grid item lg={6} md={6} sm={12}>
              <EarTable />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <EarDataTable />
            </Grid> */}

            <Grid mb={8} lg={6} sm={12}></Grid>
          </Container>
        </React.Fragment>
      </div>
    </>
  );
}

export default RdaAndEarHome;
