import React from "react";
import "./testdietary.css";
import { CssBaseline, Grid, useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Container, ListItemText } from "@mui/material";

import Avatar from "@mui/joy/Avatar";

import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";

import Typography from "@mui/joy/Typography";

import gb from "../../../../../../assets/images/dietaryguidelines.png";

import DietaryResponsive from "./DietaryResponsive";
import pdfFile from "../../../../../../assets/images/dietary_guidelines.pdf";
import DietaryResponsiveMobile from "./DietaryResponsiveMobile";

const guidelines = [
  "1.Eat a variety of foods to ensure a balanced diet",
  "2.Ensure provision of extra food and healthcare during pregnancy and lactation",
  "3.Ensure exclusive breastfeeding for the first six months and continue breastfeeding till two years and beyond",
  "4.Start feeding homemade semi-solid complementary foods to the infant soon a fter six months of age",
  "5.Ensure adequate and appropriate diets for children and adolescents both in health and sickness",
  "6.Eat plenty of vegetables and legumes",
  "7.Use oils/fats in moderation; choose a variety of oil seeds, nuts, nutricereals and legumes to meet daily needs of fats and essential fatty acids (EFA)",
  "8.Obtain good quality proteins and essential amino acids (EAA) through appropriate combination of foods and avoid protein supplements to build muscle mass",
  "9.Adopt a healthy lifestyle to prevent abdominal obesity, overweight and overall obesity",
  "10.Be physically active and exercise regularly to maintain good health",
  "11.Restrict salt intake",
  "12.Consume safe and clean foods",
  "13.Adopt appropriate pre-cooking and cooking methods",
  "14.Drink adequate quantity of water",
  "15.Minimize the consumption of high fat, sugar, salt (HFSS) and ultra-processed foods (UPF)",
  "16.Include nutrient-rich foods in the diets of the elderly for health and wellness",
  "17.Read information on food labels to make informed and healthy food choices",
];
function TestDietary() {
  const theme = createTheme();
  const listItemHeight = 80;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (isSmallScreen) {
    // Render DietaryResponsive on small screens
    // return <DietaryResponsive />;
    return <DietaryResponsiveMobile />;
  }

  const navigateToPDFPage = (pageNumber) => {
    const url = `${pdfFile}#page=${pageNumber}`;
    window.location.href = url;
  };

  return (
    //     <div className="rectangle-container">
    //     <div className="rectangle"></div>
    //   </div>
    <div style={{ overflowX: "hidden" }} id="backToTop">
      <React.Fragment>
        <CssBaseline />
        <Container fixed m={2} maxWidth="lg" alignItems="center" justifycontent="center">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            <Typography level="h2" gutterBottom m={2}>
              Dietary Guidelines
            </Typography>

            <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
          </div>

          <Grid
            container
            spacing={2}
            mt={2}
            mb={3}
            sx={{
              backgroundImage: `url(${gb})`,
              backgroundSize: "26% 100%", // Adjust the percentage to make the image smaller
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid item xs={6} alignItems="flex-start" mt={0}>
              <List
                sx={{
                  alignItems: "start",
                  "--ListItem-paddingX": "0.6em",
                  "--ListDivider-gap": "1px",
                  "--ListItem-minHeight": `${listItemHeight}px`,
                }}
              >
                {/* Guideline 1 */}
                <ListItem
                  onClick={() => navigateToPDFPage(19)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      border: "none",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: "calc(100% - 149px)",
                      top: 0,
                      width: "150px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#ffffff",
                        fontWeight: "400",
                      }}
                      level="body1"
                    >
                      Eat a variety of foods to ensure a balanced diet
                    </Typography>
                  </ListItemText>
                </ListItem>

                {/* Guideline 3 */}
                <ListItem
                  onClick={() => navigateToPDFPage(39)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "72.5%", // Set the width as needed
                    borderLeft: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    3
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        width: "95%",
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#c76c20",
                        fontWeight: "500",
                      }}
                      level="body1"
                    >
                      Ensure exclusive breastfeeding for the first six months and continue
                      breastfeeding till two years and beyond
                    </Typography>
                  </ListItemText>
                </ListItem>

                {/* Guideline 5 */}
                <ListItem
                  onClick={() => navigateToPDFPage(55)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: "calc(100% - 149px)",
                      top: 0,
                      width: "150px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    5
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        marginRight: "0.8em",
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#ffff",
                        fontWeight: "400",
                      }}
                      level="body1"
                    >
                      Ensure adequate and appropriate diets for children and adolescents both in
                      health and sickness
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 7 */}
                <ListItem
                  onClick={() => navigateToPDFPage(69)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "72.5%", // Set the width as needed
                    borderLeft: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    7
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        width: "95%",
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#c76c20",
                        fontWeight: "500",
                      }}
                      level="body1"
                    >
                      Use oils/fats in moderation; choose a variety of oil seeds, nuts, nutricereals
                      and legumes to meet daily needs of fats and essential fatty acids (EFA)
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 9 */}
                <ListItem
                  onClick={() => navigateToPDFPage(80)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: "calc(100% - 149px)",
                      top: 0,
                      width: "150px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    9
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        marginRight: "0.8em",
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#ffffff",
                      }}
                      level="body1"
                    >
                      Adopt a healthy lifestyle to prevent abdominal obesity, overweight and overall
                      obesity
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 11 */}
                <ListItem
                  onClick={() => navigateToPDFPage(91)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "72.5%", // Set the width as needed
                    borderLeft: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    11
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        width: "95%",
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#c76c20",
                        fontWeight: "500",
                      }}
                      level="body1"
                    >
                      Restrict salt intake
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 13 */}
                <ListItem
                  onClick={() => navigateToPDFPage(99)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: "calc(100% - 149px)",
                      top: 0,
                      width: "150px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    13
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        marginRight: "0.8em",
                        textAlign: "justify",
                        color: "#ffffff",
                        fontSize: "1em",
                      }}
                      level="body1"
                    >
                      Adopt appropriate pre-cooking and cooking methods
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 15 */}
                <ListItem
                  onClick={() => navigateToPDFPage(111)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "72.5%", // Set the width as needed
                    borderLeft: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    15
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        width: "95%",
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#c76c20",
                        fontWeight: "500",
                      }}
                      level="body1"
                    >
                      Minimize the consumption of high fat, sugar, salt (HFSS) and ultra-processed
                      foods (UPF)
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 17*/}
                <ListItem
                  onClick={() => navigateToPDFPage(124)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: "calc(100% - 149px)",
                      top: 0,
                      width: "150px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    17
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        marginRight: "0.8em",
                        textAlign: "justify",
                        color: "white",
                        fontSize: "1em",
                      }}
                      level="body1"
                    >
                      Read information on food labels to make informed and healthy food choices
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={6} alignItems="flex-end" mt={0}>
              <List
                sx={{
                  alignItems: "end",
                  "--ListItem-paddingX": "0.6em",
                  "--ListDivider-gap": "1px",
                  "--ListItem-minHeight": `${listItemHeight}px`,
                  marginLeft: "-2.2em",
                }}
              >
                {/* Guideline 2 */}
                <ListItem
                  onClick={() => navigateToPDFPage(31)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "65%", // Set the width as needed
                    borderRight: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    2
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: "1em",
                        color: "#c76c20",
                        fontWeight: "500",
                      }}
                      level="body1"
                    >
                      Ensure provision of extra food and healthcare during pregnancy and lactation
                    </Typography>
                  </ListItemText>
                </ListItem>

                {/* Guideline 4 */}
                <ListItem
                  onClick={() => navigateToPDFPage(44)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight + 10}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "239px",
                      height: `${listItemHeight + 10}px`,
                      backgroundColor: "#ffffff",
                      transform: "scaleX(-1)",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      marginLeft: "0.8em",
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    4
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{ textAlign: "justify", color: "#ffffff", fontSize: "1em" }}
                      level="body1"
                    >
                      Start feeding homemade semi-solid complementary foods to the infant soon a
                      fter six months of age
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 6 */}
                <ListItem
                  onClick={() => navigateToPDFPage(65)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "65%", // Set the width as needed
                    borderRight: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    6
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#c76c20",
                      }}
                      level="body1"
                    >
                      Eat plenty of vegetables and legumes
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 8 */}
                <ListItem
                  onClick={() => navigateToPDFPage(75)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight + 15}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "239px",
                      height: `${listItemHeight + 15}px`,
                      backgroundColor: "#ffffff",
                      transform: "scaleX(-1)",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      marginLeft: "0.8em",
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    8
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{ textAlign: "justify", color: "#ffffff", fontSize: "1em" }}
                      level="body1"
                    >
                      Obtain good quality proteins and essential amino acids (EAA) through
                      appropriate combination of foods and avoid protein supplements to build muscle
                      mass
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 10 */}
                <ListItem
                  onClick={() => navigateToPDFPage(87)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "65%", // Set the width as needed
                    borderRight: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    10
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#c76c20",
                      }}
                      level="body1"
                    >
                      Be physically active and exercise regularly to maintain good health
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 12 */}
                <ListItem
                  onClick={() => navigateToPDFPage(95)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "239px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                      transform: "scaleX(-1)",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      marginLeft: "0.8em",
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    12
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{ textAlign: "justify", color: "#ffffff", fontSize: "1em" }}
                      level="body1"
                    >
                      Consume safe and clean foods
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 14 */}
                <ListItem
                  onClick={() => navigateToPDFPage(107)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "65%", // Set the width as needed
                    borderRight: "1px solid #63b0b7",
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.5em",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#c76c20",
                      color: "white",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    14
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#c76c20",
                      }}
                      level="body1"
                    >
                      Drink adequate quantity of water
                    </Typography>
                  </ListItemText>
                </ListItem>
                {/* Guideline 16*/}
                <ListItem
                  onClick={() => navigateToPDFPage(118)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "red",
                    position: "relative",
                    width: "75%", // Set the width as needed
                    "&.rectangle-container": {
                      position: "relative",
                      width: "75%",
                      backgroundColor: "#63b0b7",
                      height: `${listItemHeight}px`,
                    },
                    "&:hover": {
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increase the shadow effect
                      transition: "box-shadow 0.2s ease-in-out", // Adjust the transition duration
                      cursor: "pointer",
                      padding: "0.8em",
                    },
                  }}
                  className="rectangle-container"
                >
                  <div
                    className="rectangle"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "239px",
                      height: `${listItemHeight}px`,
                      backgroundColor: "#ffffff",
                      transform: "scaleX(-1)",
                    }}
                  ></div>
                  <Avatar
                    sx={{
                      marginLeft: "0.8em",
                      bgcolor: "white",
                      color: "#63b0b7",
                      width: 30,
                      height: 30,
                      marginRight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    16
                  </Avatar>
                  <ListItemText>
                    <Typography
                      sx={{ textAlign: "justify", color: "#ffffff", fontSize: "1em" }}
                      level="body1"
                    >
                      Include nutrient-rich foods in the diets of the elderly for health and
                      wellness
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            <Typography level="h4" gutterBottom m={2}>
              Dietary Guidelines pdf
            </Typography>

            <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
          </div>
          <iframe
            src="https://drive.google.com/file/d/1sWD0KBdgQdthedlUYKY3FCUMMVjtmYPx/preview"
            width="100%"
            height="900px"
            title="PDF Viewer"
          />
        </Container>
      </React.Fragment>
    </div>
  );
}

export default TestDietary;
