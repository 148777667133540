import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector, useDispatch } from "react-redux";
import { getCompareData, setCompareData } from "../components/dropdown/CompareDataSlice";
import { getMapKeyValue, setMapKey } from "./MapKeySlice";
import { Grid, Typography } from "@mui/material";

require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);

function Compare1(props) {
  const { selectedOption } = props;
  const [seriesData, setSeriesData] = useState([]);
 

  const compareData = useSelector(getCompareData);
  const dispatch = useDispatch();
 // console.log("CompareData for compare tab is ", compareData);
  const mapKeyValue = useSelector(getMapKeyValue);
  //console.log("mapKeyValueforcompare", mapKeyValue);
  const andhraPradeshDrilldownData = [
    { name: '1', y: 10 },
    { name: '2', y: 15 },
    // ... add more drilldown data points for Andhra Pradesh
  ];
  useEffect(() => {
    dispatch(setCompareData ());
  }, [dispatch]);
 
 
  useEffect(() => {
    if (compareData && Array.isArray(compareData.results)) {
      const allowedSourceCodes = [
        "N5", "N4","N3", "N2", "N1", "D4","D3","D2","D1", "CN",  "AHSCAB", "LW", "AHS10_11", "AHS11_12", "AHS12_13","NNMBURBAN","NNMB3","NNMBR2006" 
      ];
  
      const filteredData = compareData.results.filter(
        (d) => allowedSourceCodes.includes(d.source_code)
      );
     // console.log("Filtered Data:", filteredData);
  
      const uniqueSourceNames = Array.from(
        new Set(filteredData.map((dataPoint) => dataPoint.source_name))
      );
     // console.log("Unique Source Names:", uniqueSourceNames);
  
      const generatedSeriesData = uniqueSourceNames.map((sourceName) => {
  const filteredSourceData = filteredData.filter(
    (dataPoint) => dataPoint.source_name === sourceName
  );
  if (filteredSourceData.length <= 1) {
    return null; // Skip series with one or fewer data points
  }
// console.log("Filtered Source Data for", sourceName, ":", filteredSourceData);

  
  const seriesData = filteredSourceData
  .filter((dataPoint) => {
    if (mapKeyValue && mapKeyValue !== "india") {
      //const isIndiaAreaLevel = dataPoint.area === dataPoint.area_name && (dataPoint.area_level === 2 || dataPoint.area_level === 1)
      const isIndiaAreaLevel = dataPoint.area && dataPoint.area.toLowerCase() === mapKeyValue.toLowerCase()
     // console.log("Data Point for", sourceName, ":", dataPoint, "Is India Area Level:", isIndiaAreaLevel);
      return isIndiaAreaLevel;
    } else {
      const isMapKeyValueArea = dataPoint.area === dataPoint.area_name && (dataPoint.area_level === 2 || dataPoint.area_level === 1)
      //console.log("Data Point for", sourceName, ":", dataPoint, "Is MapKeyValue Area:", isMapKeyValueArea);
      //console.log("mapKeyValue:", mapKeyValue);
     // console.log("dataPoint.area_name:", dataPoint.area);
      return isMapKeyValueArea;
    }
  })
    .map((dataPoint) => ({
      name: dataPoint.area_name,
      y: parseFloat(dataPoint.value),
     
      rural: dataPoint.rural,
      urban: dataPoint.urban,
      title: dataPoint.title,
      indicator_name: dataPoint.indicator_name,
      subindicator_name: dataPoint.subindicator_name,
      title: dataPoint.title,
      source_footnote: dataPoint.source_footnote,
      footnote1:dataPoint.footnote1,
      footnote2:dataPoint.footnote2,
      footnote3:dataPoint.footnote3,
    }));

  //console.log("Series Data for", sourceName, ":", seriesData);

  return {
    name: sourceName,
    data: seriesData,
  };
}).filter(Boolean); ;

  
     // console.log("Generated Series Data:", generatedSeriesData);
  
      setSeriesData(generatedSeriesData);
    } else {
      //console.error("Error: compareData or compareData.results is null or undefined");
    }
  }, [compareData, mapKeyValue]);
  
  
  
  
  useEffect(() => {
    if (seriesData.length > 0) {
      const groupedSeriesData = {};
  
      // Group and merge series data based on area_name
      seriesData.forEach((series) => {
        series.data.forEach((dataPoint) => {
          const areaName = dataPoint.name;
          if (!groupedSeriesData[areaName]) {
            groupedSeriesData[areaName] = [];
          }
  
          // Populate missing fields with default values
          const dataPointWithDefaults = {
            ...dataPoint,
            title: dataPoint.title || 'NA',
            indicator_name: dataPoint.indicator_name || 'NA',
            source_footnote: dataPoint.source_footnote || 'NA',
            footnote1: dataPoint.footnote1 || 'NA',
            footnote2: dataPoint.footnote2 || 'NA',
            footnote3: dataPoint.footnote3 || 'NA',
            subindicator_name: dataPoint.subindicator_name || 'NA',
            title: dataPoint.title || 'NA',
            source_footnote: dataPoint.source_footnote || 'NA',
            footnote1:dataPoint.footnote1|| 'NA',
            footnote2:dataPoint.footnote2|| 'NA',
            footnote3:dataPoint.footnote3|| 'NA',
          };
         
          groupedSeriesData[areaName].push(dataPointWithDefaults);
        });
      });
  
      const groupedSeriesArray = Object.entries(groupedSeriesData).map(
        ([areaName, dataPoints]) => ({
          name: areaName,
          data: dataPoints,
          id: areaName,
        })
      );
  
     // console.log("groupedSeriesData", groupedSeriesArray); // Log the groupedSeriesArray
  
      // Continue with further logic or rendering using the groupedSeriesArray
    }
  }, [seriesData]);
 

const chartOptions = {
        chart: {
          type: "column",
           height:"400vh",
          
        },
        tooltip: {
          headerFormat: "",
          useHTML: true, // Enable HTML rendering
          formatter: function () {
            if (this.point) {
              const valueKey = selectedOption === "total" ? "y" : selectedOption;
              const indicatorName = this.point.indicator_name || 'N/A';
              const subindicatorName = this.point.subindicator_name || 'N/A';
              const areaName = this.point.name || 'N/A';
              const value = this.point[valueKey] !== undefined ? this.point[valueKey] : 'N/A';
        
              return (
                '<b>' + this.series.name + '</b><br><br>' +
                'indicator_name: ' + indicatorName + '<br>' +
                'subindicator_name: ' + subindicatorName + '<br>' +
                'area_name: ' + areaName + '<br>' +
                selectedOption + ': ' + value
              );
            } else {
              return 'Data not available';
            }
          },
        },
        drilldown: {
          series: [
            {
              id: 'andhraPradeshDrilldown',
              data: andhraPradeshDrilldownData,
            },
            // Add more drilldown series as needed
          ],
        },
      
        series: seriesData
        .filter((series) => {
          // Check if all data points in the series have valid values for the selectedOption
          return series.data.some((dataPoint) => {
            const valueKey = selectedOption === "total" ? "y" : selectedOption;
            return dataPoint[valueKey] !== "NA";
          });
        })
        .map((series) => ({
          ...series,
          color: getColorForSeries(series.name),
          data: series.data.map((dataPoint) => ({
            ...dataPoint,
            y:
              selectedOption === "total"
                ? parseFloat(dataPoint.y)
                : selectedOption === "rural"
                ? parseFloat(dataPoint.rural)
                : parseFloat(dataPoint.urban),
          })),
        })),
        
        title: {
            text: ''
        },
        xAxis: {
            categories: seriesData.length > 0 ? Array.from(new Set(seriesData.flatMap((item) => item.data.map((dataPoint) => dataPoint.name)))) : [],
            crosshair: false,
            labels: {
              rotation: -90,
              style: {
                fontSize: "10px",
                fontWeight: "bold",
              },
              step: 1,
            },
        },
        yAxis: {
          title: {
            text: `Prevalence of ${seriesData[0]?.data[0]?.indicator_name} in (%)`,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              
            },
          },
          labels: {
            //rotation: -90,
            style: {
              fontSize: "10px",
              fontWeight: "bold",
            },
          },
          gridLineWidth:0, // Remove grid lines
          max: 100,
          tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            floating: true,
            backgroundColor: '#FFFFFF'
        },
        plotOptions: {
          column: {
            showInLegend: true,
            dataLabels: {
              enabled: true,
              rotation: -90, // Rotate the data label text vertically
              inside:false, // Display data labels inside the column/bar
              align: "top", // Center-align the data labels
              verticalAlign: "top", // Vertically align the data labels
              style: {
                fontSize: "8px",
                fontWeight: "bold",
              },
              // You can customize the formatting of the data labels as needed
              format: "{point.y:.1f}", // Example format: display the y value with one decimal place
            },
          },
        },
       
        credits: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                "separator",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
              ],
            },
          },
        },
    };
    function getColorForSeries(seriesName) {
      if (seriesName === "NFHS-5(2019-21)") {
        //return "#E6A532";
        return "#c4000080";
      } else if (seriesName === "NFHS-4(2015-16)") {
        return "#62968c";
      }
      
      else if (seriesName === "NFHS-3(2005-06)") {
        //return "#9e9ac8";
        return "#945ECF";
        
      }
      else if (seriesName === "NFHS-2(1998-99)") {
        return "#367dc4";
      }
      else if (seriesName === "NFHS-1(1992-93)") {
        return "#ef8d5d";
      }
      else if (seriesName === "CNNS(2016-18)") {
        return "#E6A532";
      }
      else if (seriesName === "DLHS-4(2012-14)") {
        return "#b11217";
      }
      else if (seriesName === "DLHS-3(2005-06)") {
        return "#e17646";
      }
      else if (seriesName === "DLHS-2(2002-04)") {
        return "#dbbe5e";
      }
      else if (seriesName === "DLHS-1(1998-99)") {
        return "#caddab";
      }
      else if (seriesName === "AHS-CAB(2012-13)") {
        return "#fea848";
      }
      else if (seriesName === "AHS(2010-11)") {
        return "#af69a0";
      }
      else if (seriesName === "AHS-(2011-12)") {
        return "#349db4";
      }
      else if (seriesName === "AHS-(2012-13)") {
        return "#724d8c";
      }
      else if (seriesName === "LASI Wave-1(2017-18)") {
        return "#a1cbbc";
      }
      else if (seriesName.trim() === "NNMB-Urban Nutrition Report (2017)") {
        
        return "#8a5479";
      }
      else if (seriesName.trim() === "NNMB-Rural-Third repeat survey (2011-12)") {
        
        return "#887cc2";
      }
      else if (seriesName.trim() === "NNMB Rural Survey (2005-06)") {
        
        return "#bf95b6";
      }
      return "#fea848";
    }
    return (
               



        <div align="center" padding="25px">
        
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        
  </div>

      );
    }
    


export default Compare1;

