import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import { Link } from "react-router-dom";
import macronutrient from "../../../assets/images/macronutrients.jpg";
import carb from "../../../assets/images/icons/carb.png";
import protein from "../../../assets/images/icons/protein.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardContent } from "@mui/joy";
import { Button, CardActions, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default function Macronutrients() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div onClick={handleExpandClick}>
      <Box sx={{ minHeight: 350 }}>
        <Card
          variant="outlined"
          sx={(theme) => ({
            width: 300,
            gridColumn: "span 2",
            flexDirection: "row",
            flexWrap: "wrap",
            resize: "horizontal",
            overflow: "hidden",
            gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
            transition: "transform 0.3s, border 0.3s",
            "&:hover": {
              borderColor: theme.vars.palette.primary.outlinedHoverBorder,
              transform: "translateY(-2px)",
            },
            "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
          })}
        >
          <AspectRatio
            variant="soft"
            sx={{
              flexGrow: 1,
              display: "contents",
              "--AspectRatio-paddingBottom":
                "clamp(0px, (100% - 360px) * 999, min(calc(100% / (16 / 9)), 300px))",
            }}
          >
            <img src={macronutrient} loading="lazy" alt="" />
          </AspectRatio>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              maxWidth: 200,
            }}
          >
            <AspectRatio
              variant="soft"
              sx={{
                "--AspectRatio-paddingBottom": "clamp(0px, (100% - 200px) * 999, 200px)",
                pointerEvents: "none",
              }}
            >
              <img alt="" src={macronutrient} />
            </AspectRatio>

            <CardActions disableSpacing>
              <Box sx={{ display: "flex", gap: 1.5, mt: "auto" }}>
                <Typography level="h2" sx={{ fontSize: "md" }} mb={0.5}>
                  Macronutrients
                </Typography>
                {/* <div>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </div> */}
              </Box>
            </CardActions>
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Button
                component={Link}
                to={"/Carbohydrates"}
                sx={{ m: 1 }}
                variant="outlined"
                color="secondary"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                Carbohydrates
              </Button>

              <Button
                component={Link}
                to={"/Protein"}
                sx={{ m: 1 }}
                variant="outlined"
                color="secondary"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                Proteins
              </Button>
              <Button
                component={Link}
                to={"/Fats"}
                sx={{ m: 1 }}
                variant="outlined"
                color="secondary"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                Fats
              </Button>
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </div>
  );
}
