import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import nutrientToEnergy from "../../../../../assets/images/icons/vitamins/Functions/nutrientToEnergy.png";
import yeast from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B7/yeast.png";
import egg from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/egg.png";
import nuts_seeds from "../../../../../assets/FoodSources/Minerals/Sources/nuts_seeds.png";
import seeds from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B7/seeds.png";
import organMeat from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/organMeats.png";
import fish from "../../../../../assets/images/icons/vitamins/vitaminb3/fish.png";
import meat from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import milk from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milk.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import fatigue from "../../../../../assets/images/icons/vitamins/vitaminb2/fatigue.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import HairLossIcon from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/HairLossIcon.png";
import Thin_hair from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/Thin_hair.png";
import brittle_nails from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/brittle_nails.png";
import grey_hair from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/grey_hair.png";
import skin_rashes from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB7/skin_rashes.png";

export default function Vitaminb7() {
  return (
    <>
      <Grid item lg={6} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py:1
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of vitamin B7?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="nutrient to energy" src={nutrientToEnergy} />
                </ListItemAvatar>
                <ListItemText primary="Biotin is necessary for the break down of fats, carbohydrates, and protein in the body." />
              </ListItem>
            </List>

          </CardContent>

        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py:1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the sources of vitamin B7?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
              <ListItem>
                Biotin can be found in a wide range of food sources, including yeast, eggs, nuts, seeds, organ meats (liver, kidney), fish, meat and milk.
              </ListItem>
              <ListItem>
                Raw egg white (avidin) can block its absorption.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.6, sm: 1 }}}>
              <Tooltip title="yeast">
                <Avatar src={yeast} size="lg" alt="yeast" variant="square" />
              </Tooltip>
              <Tooltip title="Egg">
                <Avatar src={egg} alt="Egg" variant="square" />
              </Tooltip>
              <Tooltip title="Nuts and seeds">
                <Avatar src={nuts_seeds} alt="Nuts" variant="square" />
              </Tooltip>
              <Tooltip title="organ meats (liver, kidney)">
                <Avatar src={organMeat} alt="organ meats (liver, kidney)" variant="square" />
              </Tooltip>
              <Tooltip title="Fish ">
                <Avatar src={fish} alt="fish" variant="square" />
              </Tooltip>
              <Tooltip title="Meat ">
                <Avatar src={meat} alt="Meat" variant="square" />
              </Tooltip>
              <Tooltip title="Milk ">
                <Avatar src={milk} alt="Milk" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={6} md={6} sm={12}>
      <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 0, sm: 1 },
            py:1,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            How is vitamin B7 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Biotin is usually bound to protein in food. After digestion, free form of biotin is used by the body. An enzyme called biotinidase recycle or reuse the biotin again and again." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py:1,
            mt: { xs: 1, sm: 0 },
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What is the recommended dietary intake of biotin?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar>
                <ListItemText
                  primary="The recommended intake of biotin is  40  µg/day for adults and pregnant women, 45 mg/day for lactating women accounting for losses through breast milk. The AI for age groups  1-3, 4-10  and adolescents  are 20, 25 and 35 µg/day."
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py:1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            How is vitamin B7 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Biotin is usually bound to protein in food. After digestion, free form of biotin is used by the body. An enzyme called biotinidase recycle or reuse the biotin again and again." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py:1,
            mt:-1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
            What happens if you are deficient in vitamin B7?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
              <ListItem>
              Biotin deficiency is rare but if deficient can cause thinning of hair, greying of hair, hair loss (alopecia), brittle nails and skin rashes.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt:1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="thinning of hair">
                <Avatar src={Thin_hair} alt="thinning of hair" variant="square" />
              </Tooltip>
              <Tooltip title="greying of hair">
                <Avatar src={grey_hair} alt="greying of hair" variant="square" />
              </Tooltip>
              <Tooltip title="hair loss (alopecia)">
                <Avatar src={HairLossIcon} alt="hair loss (alopecia)" variant="square" />
              </Tooltip>
              <Tooltip title="brittle nails">
                <Avatar src={brittle_nails} alt="brittle nails" variant="square" />
              </Tooltip>
              <Tooltip title="skin rashes">
                <Avatar src={skin_rashes} alt="skin rashes" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
    </>
  );
}
