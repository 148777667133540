import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Colocasia from "../../../../../../assets/FoodSources/Protein/Roots and tubers/1_Colocasia1.jpg"
import YamWild from "../../../../../../assets/FoodSources/Protein/Roots and tubers/2_YamWild.jpg"
import YamElephant from "../../../../../../assets/FoodSources/Protein/Roots and tubers/3_YamElephant.jpg"
import YamOrdinary from "../../../../../../assets/FoodSources/Protein/Roots and tubers/4_YamOrdinary.jpg"
import Beetroot from "../../../../../../assets/FoodSources/Protein/Roots and tubers/5_Beetroot.jpg"
import LotusStem from "../../../../../../assets/FoodSources/Protein/Roots and tubers/6_LotusStem.jpg"
import PotatoRedSkin from "../../../../../../assets/FoodSources/Protein/Roots and tubers/7_PotatoRedSkin.jpg"
import PotatoBrownSkin from "../../../../../../assets/FoodSources/Protein/Roots and tubers/8_Potato.jpg"
import SweetPotatoBrownSkin from "../../../../../../assets/FoodSources/Protein/Roots and tubers/9_SweetPotatoBrownSkin.jpg"
import Radish from "../../../../../../assets/FoodSources/Protein/Roots and tubers/10_Radish.jpg"
import { Typography } from '@mui/material';

export default function RootsAndTubers() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: Colocasia,
    title: 'Colocasia',
    author: '3.31 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: YamWild,
    title: 'Yam, wild',
    author: '17.2 per 100gm',
  },
  {
    img: YamElephant,
    title: 'Yam, elephant',
    author: '14.59 per 100gm',
  },
  {
    img: YamOrdinary,
    title: 'Yam, ordinary',
    author: '13.6 per 100gm',
    cols: 2,
  },
  {
    img: Beetroot,
    title: 'Beet root',
    author: '13.5 per 100gm',
    cols: 2,
  },
  {
    img: LotusStem,
    title: 'Lotus root',
    author: '13.27 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: PotatoRedSkin,
    title: 'Potato, red skin',
    author: '13.11 per 100gm',
  },
  {
    img: PotatoBrownSkin,
    title: 'Potato, brown skin, big',
    author: '12.5 per 100gm.4',
  },
  {
    img: SweetPotatoBrownSkin,
    title: 'Sweet potato, brown skin',
    author: '11.38-10.36 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: Radish,
    title: 'Radish rat-tailed',
    author: '10.96 per 100gm',
  }
];