import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import indicatorslist from "../../../../data/newmenu.json";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ListItemIcon } from "@mui/material";
import { setSelectedOption } from "../../../home/HomeRadialComponents/selectedOptionSlice";
import { getMapKeyValue, setMapKey } from "../../charts/MapKeySlice";
import { useDispatch, useSelector } from "react-redux";
import { purple } from "@mui/material/colors";
import image1 from "../../../../assets/images/menu/infant.png";
import image2 from "../../../../assets/images/menu/schoolchildren.png";
import image3 from "../../../../assets/images/menu/adolscentboy.png";
import image4 from "../../../../assets/images/menu/adolescentgirl.png";
import image5 from "../../../../assets/images/menu/adults.png";
import image6 from "../../../../assets/images/menu/adultwomen.png";
import image7 from "../../../../assets/images/menu/pregnantwomen.png";
import image8 from "../../../../assets/images/menu/lactatingwomen.png";
import image9 from "../../../../assets/images/menu/adultmen.png";
import image10 from "../../../../assets/images/menu/elderlycouple.png";
import image11 from "../../../../assets/images/menu/adolscents.png";
import SelectedDropdownData from "./SelectedDropdownData";

function Dropdown({ onAreFieldsSelectedChange }) {
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [lifecycle, setLifecycle] = useState(null);
  const [indicator, setIndicator] = useState([]);
  const [subIndicator, setSubIndicator] = useState([]);
  const [indicatorID, setIndicatorID] = useState("");
  const [subIndicatorID, setSubIndicatorID] = useState("");
  const [source, setSource] = useState([]);
  const [sourceID, setSourceID] = useState("");
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedSourceData, setSelectedSourceData] = useState([]);
  const selectedOption = useSelector((state) => state.selectedOptionFromHome);
  const dispatch = useDispatch();
  const [submittedData, setSubmittedData] = useState(null);
  const [areFieldsSelected, setAreFieldsSelected] = useState(false);

  // const handleLifecycle = (e) => {
  //   const getLifecycle = indicatorslist.find(
  //     (indicator) => indicator.lifecycle_id === selectedOption
  //   );
  //   setLifecycle(selectedOption);
  //   setIndicator(getLifecycle.indicators);
  //   setIndicatorID("");
  //   setSubIndicator([]);
  //   setSubIndicatorID("");
  //   setSource([]);
  //   setSourceID("");
  //   setSelectedSource(null);
  //   dispatch(setSelectedOption(getLifecycle.lifecycle_id));
  //   // dispatch(setSelectedOption(e.target.value));
  // };



  const areAllFieldsSelected = () => {
    return lifecycle && indicatorID && subIndicatorID && selectedSource;
  };





  // const checkFieldsSelected = () => {
  //   // Implement your logic to check if all fields are selected
  //   const allFieldsSelected = lifecycle && indicatorID && subIndicatorID && selectedSource;
  
  //   // Set the state variable `areFieldsSelected` to the result
  //   setAreFieldsSelected(allFieldsSelected);
  
  //   // Conditionally call `onAreFieldsSelectedChange`
  //   // Only call it when all fields are selected (allFieldsSelected is true)
  //   if (allFieldsSelected) {
  //     onAreFieldsSelectedChange(true);
  //   }
  // };

  const checkFieldsSelected = () => {
    // Check each field individually
    const isLifecycleSelected = Boolean(lifecycle);
    const isIndicatorSelected = Boolean(indicatorID);
    const isSubIndicatorSelected = Boolean(subIndicatorID);
    const isSelectedSourceSelected = Boolean(selectedSource);
  
    // Set the state variable `areFieldsSelected` to the result
    const areFieldsSelected = isLifecycleSelected && isIndicatorSelected && isSubIndicatorSelected && isSelectedSourceSelected;
    setAreFieldsSelected(areFieldsSelected);
  
    // Conditionally call `onAreFieldsSelectedChange`
    // Only call it when any field is selected (areFieldsSelected is true)
    if (areFieldsSelected) {
      onAreFieldsSelectedChange(true);
    }
  };
  
  // useEffect to check fields selection whenever any of the dropdown values change
  useEffect(() => {
    checkFieldsSelected();
  }, [lifecycle, indicatorID, subIndicatorID, selectedSource]);


  useEffect(() => {
    // Enable the reset button only when the lifecycle is selected
    setIsResetDisabled(!lifecycle);
  }, [lifecycle]);
  
  const handleLifecycle = (e) => {
    const getLifecycle = indicatorslist.find(
      (indicator) => indicator.lifecycle_id === e.target.value
    );
    setLifecycle(e.target.value);
    setIndicator(getLifecycle.indicators);
    setIndicatorID("");
    setSubIndicator([]);
    setSubIndicatorID("");
    setSource([]);
    setSourceID("");
    setSelectedSource(null);
  };

  useEffect(() => {
    if (selectedOption) {
      const getLifecycle = indicatorslist.find(
        (indicator) => indicator.lifecycle_id === selectedOption
      );
      setLifecycle(selectedOption);
      setIndicator(getLifecycle.indicators);
      setIndicatorID("");
      setSubIndicator([]);
      setSubIndicatorID("");
      setSource([]);
      setSourceID("");
      setSelectedSource(null);
    }
  }, [selectedOption]);

  const handleIndicator = (e) => {
    const selectedIndicator = indicator.find(
      (indicator) => indicator.indicator_id === e.target.value
    );
    setIndicatorID(e.target.value);
    setIsResetDisabled(false);
    if (selectedIndicator) {
      setSubIndicator(selectedIndicator.indicator_subgroup || []);
      setSubIndicatorID("");
      setSource(selectedIndicator.sources || []);
      setSourceID("");
      setSelectedSource(null);
    } else {
      setSubIndicator([]);
      setSubIndicatorID("");
      setSource([]);
      setSourceID("");
      setSelectedSource(null);
    }
  };

  const handleSubIndicator = (e) => {
    const selectedSubIndicator = subIndicator.find(
      (subIndicator) => subIndicator.subgroup_id === e.target.value
    );
    setSubIndicatorID(e.target.value);
    if (selectedSubIndicator.sources) {
      setSource(selectedSubIndicator.sources || []);
      setSourceID("");
      setSelectedSource(null);
    } else {
      setSubIndicator([]);
      setSubIndicatorID("");
      setSource([]);
      setSourceID("");
      setSelectedSource(null);
    }
  };

  const handleSource = (e) => {
    const sourceId = e.target.value;
    const selectedSource = source.find((s) => s.source_id === sourceId);
    setSelectedSource(selectedSource);
    if (selectedSource) {
      e.target.value = selectedSource.source_id;

      const newData = [lifecycle, indicatorID, subIndicatorID, selectedSource?.source_code];
      setSelectedSourceData(newData);
      dispatch(setMapKey("india"));
    } else {
      setSelectedSource(null);
      e.target.value = "";

      setSelectedSourceData([]);
    }
  };
  const handleFormSubmit = () => {
    if (areAllFieldsSelected()) {
      // Store the selected data in the state
      const newData = [lifecycle, indicatorID, subIndicatorID, selectedSource?.source_code];
      setSubmittedData(newData);

      // Dispatch any actions if needed
      dispatch(setMapKey("india"));
    }
  };
  const handleFormReset = () => {
    // Clear the selected data
    setLifecycle(null);
    setIndicator([]);
    setSubIndicator([]);
    setIndicatorID("");
    setSubIndicatorID("");
    setSource([]);
    setSourceID("");
    setSelectedSource(null);
    setSelectedSourceData([]);
    dispatch(setSelectedOption(null));
    setIsResetDisabled(!isResetDisabled);
    dispatch(setMapKey("india"));
  };

  const resetFields = () => {
    setLifecycle(null);
    setIndicator([]);
    setSubIndicator([]);
    setIndicatorID("");
    setSubIndicatorID("");
    setSource([]);
    setSourceID("");
    setSelectedSource(null);
    setSelectedSourceData([]);
    dispatch(setSelectedOption(null));
    setIsResetDisabled(true);
    dispatch(setMapKey("india"));
  };


  useEffect(() => {
    if (lifecycle) {
      const getLifecycle = indicatorslist.find((indicator) => indicator.lifecycle_id === lifecycle);
      setIndicator(getLifecycle.indicators);
    }
  }, [lifecycle]);

  return (
    <>
      <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
        <Grid item xs={6} md={2}>
          <Box sx={{ m: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="simple-select-autowidth-label">Life Cycle</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                label="Select Life Cycle"
                value={lifecycle || selectedOption || ""}
                onChange={handleLifecycle}
              >
                {indicatorslist.map((indicatorlist) => {
                  let icon;
                  let rotationAngle = 'none'; 
                  switch (indicatorlist.lifecycle_id) {
                    case 1:
                      icon = image1;
                     // rotationAngle = 'rotate(-10deg)';
                      break;
                    case 2:
                      icon = image2;
                     // rotationAngle = 'rotate(-5deg)';
                      break;
                    case 3:
                      icon = image3;
                      break;
                    case 4:
                      icon = image4;
                      break;
                    case 5:
                      icon = image9;
                      break;
                    case 6:
                      icon = image6;
                      //rotationAngle = 'rotate(-5deg)';
                      break;
                    case 7:
                      icon = image7;
                      break;
                    case 8:
                      icon = image8;
                      break;
                    case 9:
                      icon = image10;
                     // rotationAngle = 'rotate(-5deg)';
                      break;
                    case 10:
                      icon = image11;
                      break;
                    case 11:
                      icon = image5;
                     // rotationAngle = 'rotate(-10deg)';
                      break;

                    default:
                      icon = null;
                  }
                  return (
                    <MenuItem key={indicatorlist.lifecycle_id} value={indicatorlist.lifecycle_id}>
                      <ListItemIcon>
                        {icon ? (
                          <img src={icon} alt="lifecycle_icon" style={{ height: 30, width: 30 , transform: rotationAngle}} />
                        ) : null}
                      </ListItemIcon>
                      {indicatorlist.lifecycle_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} md={2}>
          <Box sx={{ m: 1 }}>
            <FormControl fullWidth disabled={!lifecycle}>
              <InputLabel id="simple-select-autowidth-label">Indicator</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                label="Select Indicator"
                value={indicatorID}
                onChange={handleIndicator}
              >
                {indicator.map((ind) => (
                  <MenuItem key={ind.indicator_id} value={ind.indicator_id}>
                    <Tooltip title={ind.indicator_description || ""}>
                      <span>{ind.indicator_name}</span>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6} md={2}>
          <Box sx={{ m: 1 }}>
            <FormControl fullWidth disabled={!subIndicator.length}>
              <InputLabel id="simple-select-autowidth-label">Sub Indicator</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                label="Select Sub Indicator"
                value={subIndicatorID}
                onChange={handleSubIndicator}
              >
                {subIndicator.map((subind) => (
                  <MenuItem key={subind.subgroup_id} value={subind.subgroup_id}>
                    <Tooltip title={subind.subgroup_description || ""}>
                      <span>{subind.subgroup_name}</span>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6} md={2}>
          <Box sx={{ m: 1 }}>
            <FormControl fullWidth disabled={!source.length}>
              <InputLabel id="simple-select-autowidth-label">Source</InputLabel>
              <Select
                labelId="simple-select-autowidth-label"
                value={selectedSource ? selectedSource.source_id : ""}
                label="Select Source"
                onChange={handleSource}
              >
                {source.map((s) => (
                  <MenuItem key={s.source_id} value={s.source_id}>
                    {s.source_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={4} md={1}>
        <Box sx={{ m: 1 }}>
          <Button
            variant="contained"
            onClick={handleFormSubmit} // Use handleFormSubmit to store the selected data
            disabled={!areAllFieldsSelected()}
            aria-label="submit"
            size="small"
            color="success"
          >
            Submit
          </Button>
        </Box>
      </Grid>
      <Grid item xs={2} md={1}>
        <Box sx={{ m: 1 }}>
          <Button
            variant="contained"
            onClick={handleFormReset} // Use handleFormReset to reset the form
            disabled={isResetDisabled}// Disable if no data is submitted
            aria-label="clear"
            size="small"
            color="error"
          >
            Reset
          </Button>
        </Box>
      </Grid>
        <Grid item xs={52}>
          <SelectedDropdownData {...selectedSourceData}  areFieldsSelected={areFieldsSelected} />
          {/* <SelectedDropdownData {...(!isResetDisabled ? selectedSourceData : {})} /> */}
        </Grid>
      </Grid>
    </>
  );
}

export default Dropdown;
