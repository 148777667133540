import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  CompareData: null,
};

export const CompareDataSlice = createSlice({
  name: 'CompareData',
  initialState,
  reducers: {
    setCompareData: (state, action) => {
      state.CompareData = action.payload;
    },
  },
});

export const { setCompareData } = CompareDataSlice.actions;
export const getCompareData = (state) => state.CompareData.CompareData;

export default CompareDataSlice.reducer;
