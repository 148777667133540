import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { transformedData1 } from "../../RDA/RDAtableData"
import Avatar from '@mui/material/Avatar';
import Infants from "../../../../../../assets/images/menu/infant.png";
import Children from "../../../../../../assets/images/menu/schoolchildren.png";
import Boys from "../../../../../../assets/images/menu/adolscentboy.png";
import Girls from "../../../../../../assets/images/menu/adolescentgirl.png";
import Men from "../../../../../../assets/images/menu/adultmen.png";
import Women from "../../../../../../assets/images/menu/adultwomen.png";
import Elderly from "../../../../../../assets/images/menu/elderlycouple.png";

export default function ZincRdaTable() {
  const data = transformedData1;

  // Group the data by 'AgeGroup'
  const groupedData = {};
  Object.values(data).forEach((rowData) => {
    const { AgeGroup, CategoryOfWork } = rowData;
    if (!groupedData[AgeGroup]) {
      groupedData[AgeGroup] = [];
    }
    groupedData[AgeGroup].push(rowData);
  });

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{ cursor: 'pointer', fontWeight: "bold", fontSize: "0.9rem" }} colSpan={3}>
              RDA and EAR for Zinc for Indians - ICMR-NIN, 2020</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedData).map(([ageGroup, rows]) => (
            <AgeGroupRow key={ageGroup} ageGroup={ageGroup} rows={rows} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


function getAvatarIcon(ageGroup) {
  // Map Age Group names to corresponding Avatar icons or images
  const avatarIcons = {
    Infants: <Avatar alt="Infants" src={Infants} />,
    Children: <Avatar alt="Children" src={Children} />,
    Boys: <Avatar alt="Boys" src={Boys} />,
    Girls: <Avatar alt="Girls" src={Girls} />,
    Men: <Avatar alt="Men" src={Men} />,
    Women: <Avatar alt="Women" src={Women} />,
    Elderly: <Avatar alt="Elderly" src={Elderly} />,
  };

  // Return the corresponding Avatar icon or image for the given Age Group
  return avatarIcons[ageGroup] || <Avatar>{ageGroup.charAt(0)}</Avatar>;
}


function AgeGroupRow({ ageGroup, rows }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };


  const ageGroupColors = {
    Infants: '#662C90',
    Children: '#2C74BE',
    Boys: '#3CADF1',
    Girls: '#29A89F',
    Men: '#8CC63C',
    Women: '#FCEF0A',
    Elderly: '#F8B13F',
  };

  // Get the background color for the current Age Group
  const ageGroupColor = ageGroupColors[ageGroup] || '#000';

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: `2px solid ${ageGroupColor}` } }}>
        <TableCell
          component="th"
          scope="row"
          onClick={handleClick}
          style={{ cursor: 'pointer', borderBottom: `2px solid ${ageGroupColor}`,padding: '10px' }}
        >
          {getAvatarIcon(ageGroup)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={handleClick}
          style={{ cursor: 'pointer', color: '#42010f', fontWeight: "bold", fontSize: "1rem", borderBottom: `2px solid ${ageGroupColor}`,padding: '10px' }}
        >
          {ageGroup}
        </TableCell>
        <TableCell
          onClick={handleClick}
          style={{ borderBottom: `2px solid ${ageGroupColor}`,padding: '10px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ cursor: 'pointer', color: 'blck', fontWeight: "bold", boxShadow: `0px -2px 4px ${ageGroupColor}` }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Category Of Work</TableCell>
                    <TableCell>Reference body Wt(Kg) </TableCell>
                    <TableCell>RDA (mg/d)</TableCell>
                    <TableCell>EAR (mg/d)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((rowData) => (
                    <CategoryOfWorkRow key={rowData.CategoryOfWork} rowData={rowData} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CategoryOfWorkRow({ rowData }) {
  const { CategoryOfWork, ReferenceBodyWeight, ["Zinc (mg/d)"]: Zinc } = rowData;

  // Access the RDA and EAR values for Magnesium (mg/d)
  const RDAZincValue = Zinc ? Zinc.RDA : "-";
  const EARZincValue = Zinc ? Zinc.EAR : "-";

  return (
    <TableRow>
      <TableCell>{CategoryOfWork}</TableCell>
      <TableCell>{ReferenceBodyWeight}</TableCell>
      <TableCell>{typeof RDAZincValue === "number" ? RDAZincValue : "-"}</TableCell>
      <TableCell>{typeof EARZincValue === "number" ? EARZincValue : "-"}</TableCell>
    </TableRow>
  );
}