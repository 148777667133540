import React from 'react';
import { Grid, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Box, ListItemAvatar, Avatar, ListItemText, ListItemIcon } from '@mui/material';
import Typography from "@mui/joy/Typography";
import CookingOilCompositionChart from './CookingOilCompositionChart';
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { Card, CardContent, List, ListItem } from '@mui/joy';

const FatsCookingOilDataTable = () => {

    const formatTableCell = (text) => {
        if (typeof text === 'string') {
            const formattedText = text
                .replace('*', '<strong style="font-size: 1em; vertical-align: super;">*</strong>')
                .replace('+', '<strong style="font-size: 1em; vertical-align: super;">+</strong>')
                .replace('#', '<strong style="font-size: 1em; vertical-align: super;">#</strong>')
                .replace('$', '<strong style="font-size: 1em; vertical-align: super;">$</strong>')
            // .replace(/(\d)%/g, (_, match) => `<span style="font-size: 1.2em; vertical-align: super;">${match}</span><sup>%</sup>`);
            return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
        }
        return text;
    };

    const tableData = [
        ["Coconut oil", "90.84", "7.24", "1.90", "0.02"],
        ["Corn oil", "16.60", "33.67", "49.74", "3.00"],
        ["Cotton seed oil", "28.17", "19.66", "52.16", "1.85"],
        ["Gingelly oil", "16.45", "41.21", "42.34", "2.57"],
        ["Groundnut oil", "19.27", "53.77", "26.96", "1.40"],
        ["Mustard oil", "5.73", "66.98", "27.28", "4.76"],
        ["Palmolein oil", "44.84", "43.62", "11.54", "0.26"],
        ["Rice bran", "23.63", "43.71", "32.66", "1.38"],
        ["Safflower oil pure", "9.19", "14.04", "76.78", "8.39"],
        ["Safflower oil blended", "19.31", "36.90", "43.79", "2.27"],
        ["Soyabean oil", "15.90", "24.77", "59.33", "3.73"],
        ["Sunflower oil", "11.39", "25.92", "62.69", "5.51"],
        ["Ghee", "71.02", "26.44", "2.54", "0.04"],
        ["Vanaspathi", "61.65", "33.62", "4.73", "0.08"],
    ];

    const additionalText = [
        "TSFA – Total Saturated Fatty Acid",
        "TMUFA – Total Unsaturated Fatty Acid",
        "TPUFA –  Total Polyunsaturated Fatty Acid",
    ];
    const referenceText = "* Dorni C, Sharma P, Saikia G, Longvah T. Fatty acid profile of edible oils and fats consumed in India.Food Chem. 2018;238:9-15.";


    const renderStyledText = (text) => {
        const parts = text.split('–'); // Split the text into parts based on "–" (en dash)
        const boldText = parts[0].trim(); // Get the first part and remove leading/trailing whitespace

        // Check if the part contains "Food Chem"
        const isItalic = text.includes("Food Chem");

        return (
            <Typography key={text} component="span" level="body-xs" color="#333" style={{ textAlign: 'justify', fontSize: "0.7em" }}>
                <strong style={{ fontWeight: 'bold', fontSize: "0.8em" }}>{boldText}</strong> – {parts[1].trim()} {/* Wrap the first part in <strong> tag */}
                {isItalic ? <span style={{ fontStyle: 'italic' }}>Food Chem</span> : null}
            </Typography>
        );
    };

    return (
        <Grid container justifyContent="space-evenly" alignItems="center" spacing={3} mb={3}>
        <Grid xs={12} item>
            <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py:1
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        Fatty acid composition of Indian cooking oil*
                    </Typography>
                    <CardContent>
                        <List >
                            <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                                    <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                                </ListItemIcon>
                                <ListItemText primary="WHO suggested a fatty acid ratio of 1:1.5:1 for saturated : monounsaturated : polyunsaturated fatty acids and 1:5–10 alpha-linolenic acid (omega-3): linoleic acid (omega-6) in the food intake." />
                            </ListItem>
                            <ListItem alignItems="center" sx={{ textAlign: "justify" }}>
                                <ListItemIcon sx={{ minWidth: 32, marginRight: 1 }}>
                                    <GridCheckCircleIcon style={{ color: '#FF4081' }} />
                                </ListItemIcon>
                                <ListItemText primary="Since no one oil has the fatty acid profile in the correct ratio, it is advised to use combination of two or three oil in daily cooking or rotate the oil every three months." />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={12} mb={1}>
                {/* <Paper elevation={3} style={{ padding: '1.3em', marginTop: 20 }}> */}
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1
                    }}
                >
                    <TableContainer style={{ overflowX: 'auto', marginTop: 20 }}>
                        <Typography level="h5" style={{ textAlign: 'justify', marginBottom: 1 }}>
                            Table of fatty acid composition of Indian cooking oil*
                        </Typography>
                        <Table style={{ borderRadius: 8 }}>
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#e0e0e0' }}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Edible oil</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>TSFA</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>TMUFA</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>TPUFA</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>TPUFA/TSFA</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row, index) => (
                                    <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'inherit' }}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex} style={{ minWidth: 100, paddingBottom: "7px", paddingTop: "7px" }}>
                                                {formatTableCell(cell)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ marginTop: 10, textAlign: 'left', color: '#333' }}>
                        {additionalText.map((text) => renderStyledText(text))}
                        <Typography component="p" level="body-xs" color="#333" style={{ textAlign: 'justify', fontSize: "0.6em" }}>
                            {referenceText}
                        </Typography>
                    </div>
                </Card>
                {/* </Paper> */}
            </Grid>
        </Grid>
    );
};

export default FatsCookingOilDataTable;