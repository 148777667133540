import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopFoodSource from "../../../../../assets/FoodSources/FoodSourceIcons/TopFoodSource.png"
import Cereals from "../../../../../assets/FoodSources/FoodSourceIcons/cereal.png"
import Pulses from "../../../../../assets/FoodSources/FoodSourceIcons/pulses.png"
import OilAndNuts from "../../../../../assets/FoodSources/FoodSourceIcons/OilAndNuts.png"
import Vegetable from "../../../../../assets/FoodSources/FoodSourceIcons/vegetable.png"
import Tuber from "../../../../../assets/FoodSources/FoodSourceIcons/tuber.png"
import GreenLeafyVegetables from "../../../../../assets/FoodSources/FoodSourceIcons/GreenLeafy.png"
import FruitsImg from "../../../../../assets/FoodSources/FoodSourceIcons/fruit.png"
import Spices from "../../../../../assets/FoodSources/FoodSourceIcons/spices.png"
import DairyImg from "../../../../../assets/FoodSources/FoodSourceIcons/Dairy.png"
import FleshFoods from "../../../../../assets/FoodSources/FoodSourceIcons/fleshFood1.png"
import TopFoodSources from './Source Tables/TopFoodSources';
import CerealGrains from './Source Tables/CerealGrains';
import PulsesAndGrains from './Source Tables/PulsesAndGrains';
import NutsAndOils from './Source Tables/NutsAndOils';
import Vegetables from './Source Tables/Vegetables';
import RootsAndTubers from './Source Tables/RootsAndTubers';
import Fruits from './Source Tables/Fruits';
import SpicesAndHerbs from './Source Tables/SpicesAndHerbs';
import Dairy from './Source Tables/Dairy';

import GLVs from './Source Tables/GLVs';

export default function ControlledAccordions() {
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : '');
    };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    // sx={{ backgroundColor: '#f5f5f5' }}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={TopFoodSource} alt="Jaggery" style={{ width: '26px', height: '26px', marginRight: "4px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Top Sources</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TopFoodSources />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={Cereals} alt="Jaggery" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Cereals and millets</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CerealGrains />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={Pulses} alt="Jaggery" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Pulses and legumes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PulsesAndGrains />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={OilAndNuts} alt="Jaggery" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Oils, Fats, Nuts & oilseeds</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <NutsAndOils />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={Vegetable} alt="Jaggery" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Vegetables</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Vegetables />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={Tuber} alt="Roots and Tubers" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Roots and tubers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RootsAndTubers />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={GreenLeafyVegetables} alt="Green Leafy Vegetables" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Green leafy vegetables</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <GLVs />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={FruitsImg} alt="Fruits" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Fruits</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Fruits />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={Spices} alt="Spices and Herbs" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Spices and Herbs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SpicesAndHerbs />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'pane20'} onChange={handleChange('pane20')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                            paddingTop:"2px"
                        },
                    }}
                >
                    <img src={DairyImg} alt="Dairy" style={{ width: '26px', height: '26px', marginRight: "6px" }} />
                    <Typography sx={{ flexShrink: 0, color: "#0F5257", fontWeight: "bold" }}>Dairy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Dairy />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
