import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import SweetPotato from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/1_SweetPotato.jpg"
import WaterChestnut from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/2_WaterChestnuts.jpg"
import YemWild from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/3_YamWild.jpg"
import Colocasia from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/4_Colocasia.jpg"
import Tapioca from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/5_Tapioca.jpg"
import Potato from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/6_Potato.jpg"
import LotusStem from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/7_LotusStem.jpg"
import Carrot from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/8_Carrot.jpg"
import Radish from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/9_Radish.jpg"
import BeetRoot from "../../../../../../assets/FoodSources/Carbohydrate/Roots and tubers/10_Beetroot.jpg"
import { Typography } from '@mui/material';

export default function RootsAndTubers() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: SweetPotato,
    title: 'Sweet potato (brown & pink skin)',
    author: '24.25 – 23.93 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: WaterChestnut,
    title: 'Water Chestnut',
    author: '21.46 per 100gm',
  },
  {
    img: YemWild,
    title: 'Yam (Wild, ordinary, elephant)',
    author: '20.95 per 100gm',
  },
  {
    img: Colocasia,
    title: 'Colocasia',
    author: '17.85 per 100gm',
    cols: 2,
  },
  {
    img: Tapioca,
    title: 'Tapioca',
    author: '17.81 per 100gm',
    cols: 2,
  },
  {
    img: Potato,
    title: 'Potato (red skin & brown skin)',
    author: '15.43 – 14.89 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: LotusStem,
    title: 'Lotus root',
    author: '14.67 per 100gm',
  },
  {
    img: Carrot,
    title: 'Carrot',
    author: '6.71 per 100gm.4',
  },
  {
    img: Radish,
    title: 'Radish',
    author: '6.71 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: BeetRoot,
    title: 'Beetroot',
    author: '6.18 per 100gm',
  }
];