import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles, styled } from "@mui/material";


const ResponsiveTable = styled(TableContainer)(() => ({
  minWidth: 300,
}));

const HeadingCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
  color: '#333',
}));

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  backgroundColor: color,
  color:"#333"
}));

function GlycaemicLoadTable() {
  return (
    <ResponsiveTable>
      <Table aria-label="responsive table">
      {/* <TableRow>
            <TableCell
            style={{ cursor: 'pointer',color: '#333', fontWeight: "bold", fontSize: "1rem", textAlign:"center"}} colSpan={2}>
            What counts as high, medium and low Glycaemic load (GL)?</TableCell>
          </TableRow> */}
        <TableBody>
          <TableRow>
            <StyledTableCell color="#cfec9c" component="th" scope="row">
            Low GL<br /> (GL value of 10 or lower)
            </StyledTableCell>
            <StyledTableCell color="#cfec9c">
            Wheat roti, pulses and legumes, most fruits and vegetables.
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell color="#fff089" component="th" scope="row">
            Medium GL<br /> (GL value between 11 and 19)
            </StyledTableCell>
            <StyledTableCell color="#fff089">
            Brown rice, Dhokla, boiled potato, sweet potato, banana, papaya.
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell color="#ff89a5" component="th" scope="row">
            High GL<br /> (GL value of 20 or higher)
            </StyledTableCell>
            <StyledTableCell color="#ff89a5">
            Poori, White rice, Idli, Dosa, French fries.
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ResponsiveTable>
  );
}

export default GlycaemicLoadTable;