import React, { useMemo, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCMap from "highcharts/modules/map";
import HCDrilldown from "highcharts/modules/drilldown";
import HCExport from "highcharts/modules/exporting";
import InMap2014 from "../../../maps/2022/india1.geo.json";
import InMap2019 from "../../../maps/2022/india-highcharts.json";
import InMap2013 from "../../../maps/india2013.geo.json";
import InMap1998 from "../../../maps/india1998.geo.json";
import Compare from "./Compare";
import { Dialog, DialogActions, DialogContent, DialogContentText, MenuItem, Select } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { getMapKeyValue, setMapKey } from "./MapKeySlice";
import { getApiData } from "../components/dropdown/dropdownSlice";
//import BarChart from "./BarChart";
import { getCompareData,setCompareData} from "../components/dropdown/CompareDataSlice";
import Carddata from "./Carddata";
import DataTable from "../components/DataTable";
import { StyledEngineProvider } from "@mui/material/styles";
import { Grid, Card, CardHeader, CardContent,Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Spinner from "../../../components/spinner";
import FootNotes from "./FootNotes";
import Infocard from "./Infocard";
//import "./helpers/proj4-module";

// Load Highcharts modules
HCMap(Highcharts);
HCDrilldown(Highcharts);
HCExport(Highcharts);
 // Set the initial selected source code
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const India2014 = (props) => {
  const [seriesData, setSeriesData] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // Track the active tab index
  const [uniqueSourceNames, setUniqueSourceNames] = useState([]);
  const compareData = useSelector(getCompareData);
  const dispatch = useDispatch();
  //console.log("CompareData for carddata is ", compareData);
  const mapKeyValue = useSelector(getMapKeyValue);
 // console.log("mapKeyValueformap", mapKeyValue);
  const [selectedSourceCode, setSelectedSourceCode] = useState("");
  const [selectedOption, setSelectedOption] = useState('total');
  

  //const { selectedOption } = props;
  const [apiData, setApiData] = useState(null);
  const [allowDrilldown, setAllowDrilldown] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState("India"); 
 
  const [areaSelectedOption, setAreaSelectedOption] = useState("India");
  console.log('Initial areaSelectedOption:', areaSelectedOption);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
 
  useEffect(() => {
    dispatch(setCompareData());
  }, [dispatch]);

  const renderSelectedArea = () => {
    if (selectedArea) {
      return <div>Selected Area: {selectedArea}</div>;
    }
    return null;
  };

  const handleAreaSelection = (selectedArea) => {
    console.log('Selected area:', selectedArea);
    setSelectedArea(selectedArea);
    setAreaSelectedOption(selectedArea);
    setIsDropdownOpen(false);
    dispatch(setMapKey(selectedArea));
  };
  const handleMapAreaSelection = (selectedArea) => {
    setSelectedArea(selectedArea); // Update the selected area state
    setAreaSelectedOption(selectedArea); // Set the selected option in the dropdown
    dispatch(setMapKey(selectedArea)); // Update the map key value
  };
  useEffect(() => {
    // Set the selected area based on the mapKey value
    setSelectedArea(mapKeyValue);
    setAreaSelectedOption(mapKeyValue); // Set the selected option in the dropdown
  }, [mapKeyValue]);
  useEffect(() => {
    setAreaSelectedOption(selectedArea); // Update areaSelectedOption when selectedArea changes
  }, [selectedArea]);
  useEffect(() => {
    if (compareData && Array.isArray(compareData.results)) {
      const allowedSourceCodes = [
        "N5", "N4", "N3", "N2", "N1", "D4", "D3", "D2", "D1", "CN", "AHSCAB", "LW", 
        "AHS10_11", "AHS11_12", "AHS12_13","NNMBURBAN","NNMB3","NNMBR2006"
      ];
  
      // Filter data based on allowed source codes
      const filteredDataformap = compareData.results.filter(
        (d) => allowedSourceCodes.includes(d.source_code)
      );
    
      console.log("Filtered Data for map:", filteredDataformap);
      // Calculate uniqueSourceNames and set it
      const sourceNames = Array.from(
        new Set(filteredDataformap.map((dataPoint) => dataPoint.source_name))
      );
      setUniqueSourceNames(sourceNames);
      const uniqueSourceInfo = new Set();

    filteredDataformap.forEach((dataPoint) => {
      const { source_name, sourcearea_level,area_level } = dataPoint;
      uniqueSourceInfo.add(`${source_name}_${sourcearea_level}_${area_level}`);
    });

    const sourceInfo = Array.from(uniqueSourceInfo).map((uniqueCombination) => {
      const [source_name, sourcearea_level, area_level] = uniqueCombination.split('_');
      return { source_name, sourcearea_level, area_level };
    });
    const hasSourceAreaLevel2 = sourceInfo.some((info) => info.sourcearea_level === '2');
  setAllowDrilldown(!hasSourceAreaLevel2);
    
    console.log("allowDrilldown:", allowDrilldown);
    
      // Get the first source name
      const firstSourceName = sourceNames[0];
  
      // Find all data for the first source name
      const firstSourceData = filteredDataformap
        .filter((dataPoint) => dataPoint.source_name === firstSourceName);
  
      // Set apiData as an object with 'count' and 'results' properties
      const apiData = {
        count: firstSourceData.length,
        results: firstSourceData
      };
  
      setApiData(apiData);
  
      // Print apiData for verification
     // console.log("apiData", apiData);
  
      // Set Series Data as an empty array for now
      setSeriesData([]);
    } else {
     // console.error("Error: compareData or compareData.results is null or undefined");
    }
  }, [compareData]);
  
  const getMapSeriesData = (selectedOption) => {
    if (selectedOption === "rural") {
      // Return the data for the "rural" option
      return data.rural;
    } else if (selectedOption === "urban") {
      // Return the data for the "urban" option
      return data.urban;
    } else {
      // Return the data for the "total" option (default)
      return data.total;
    }
  };
 // console.log("getMapSeriesData", getMapSeriesData);
  

  const filteredData = useMemo(() => {
    if (apiData?.results) {
      if (
        apiData.results.some((data) => data.sourcearea_level === 2 && data.area === data.area_name)
      ) {
        // Filter based on sourcearea_level === 2 and area = area_name
        return apiData.results.filter(
          (data) => data.sourcearea_level === 2 && data.area === data.area_name
        );
      } else {
        // Filter based on sourcearea_level === 3 and area === "Assam"
        return apiData.results.filter((data) => data.sourcearea_level === 3);
      }
    } else {
      return [];
    }
  }, [
    apiData && apiData.results && apiData.results.map((item) => [item.area, item.sourcearea_level]),
  ]);
  useEffect(() => {
 
    if (
      !mapKeyValue ||
      (mapKeyValue !== "india" &&
        !filteredData.find(
          (d) => d.area_name.toLowerCase() === mapKeyValue.toLowerCase()
        ))
    ) {
      setIsErrorModalOpen(true);
      dispatch(setMapKey("india"));
    }
  }, [compareData, mapKeyValue, filteredData, dispatch, selectedArea]);
  const filteredDataByLevel2 = filteredData.filter(
    (data) => data.sourcearea_level === 2 && data.area === data.area_name
  );
  const filteredDataByLevel3 = filteredData.filter(
    (data) => data.sourcearea_level === 3 && data.area === data.area_name
  );

  if (filteredDataByLevel2.length === 0 && filteredDataByLevel3.length === 0) {
    return null;
  }
 
  const filteredStateData = JSON.parse(
    JSON.stringify(filteredDataByLevel2.length > 0 ? filteredDataByLevel2 : filteredDataByLevel3)
  );
  // parse the data back to an array of objects
  // var parsedData = data.map((element) => element.value);
  // console.log(`filtereddata ${JSON.stringify(data)}`);

   console.log("filteredData", filteredData);
 
   
  let InMap;

  if (filteredData.some((data) => data.source_code === "N5")) {
    //InMap = InMap2014;
    InMap = InMap2019;
  } 
  else if (filteredData.some((data) => ["N4", "D4", "CN", "LW","NNMBURBAN","NNMB3"].includes(data.source_code))) 
  {
    //InMap = InMap2014;
    InMap = InMap2014;

  } 
  else if (filteredData.some((data) => ["D1", "N1", "N2"].includes(data.source_code))) 
  {
    InMap = InMap1998;
   // InMap = InMap2014;

  }  
  else if (
    filteredData.some((data) =>
      ["N3", "D2", "D3", "AHSCAB", "AHS12_13", "AHS10_11",  "AHS11_12","NNMBR2006"].includes(
        data.source_code
      )
    )
  ) {
    InMap = InMap2013;
    //InMap = InMap2014;
  } else {
    InMap = "";
  }

  let sourceFootnote = "";
  let footnote1 = "";
  let footnote2 = "";
  let footnote3 = "";
  let title = "";
  let subindicator_name = "";
  let boundaries="";


  if (mapKeyValue === "india") {
    // Fetch sourceFootnote, footnote1, footnote2, footnote3, title, subindicator_name directly from filteredData
    sourceFootnote = filteredData[0]?.source_footnote;
    footnote1 = filteredData[0]?.footnote1;
    footnote2 = filteredData[0]?.footnote2;
    footnote3 = filteredData[0]?.footnote3;
    title = filteredData[0]?.title;
    subindicator_name = filteredData[0]?.subindicator_name;
    boundaries=filteredData[0]?.boundaries;
  } else {
    const filteredDataByMapKey = filteredData.filter(
      (d) => d.area && d.area.toLowerCase() === mapKeyValue.toLowerCase()
    );
    sourceFootnote = filteredDataByMapKey[0]?.source_footnote;
    footnote1 = filteredDataByMapKey[0]?.footnote1;
    footnote2 = filteredDataByMapKey[0]?.footnote2;
    footnote3 = filteredDataByMapKey[0]?.footnote3;
    title = filteredDataByMapKey[0]?.title;
    subindicator_name = filteredDataByMapKey[0]?.subindicator_name;
    boundaries=filteredDataByMapKey[0]?.boundaries;
  }

  let data = Highcharts.geojson(InMap);
  data = data.map(function (el) {
    const areaName = el.properties?.["hc-key"] || "";
    const filteredDataItem = filteredStateData.find(
      (d) => d.area && d.area.toLowerCase() === areaName.toLowerCase()
    );
    return {
      ...el,
      drilldown:areaName,
      value: filteredDataItem ? filteredDataItem.value : 0,
      name: filteredDataItem ? filteredDataItem.area_name : el.name,
      subindicator_name: filteredDataItem
        ? filteredDataItem.subindicator_name
        : el.subindicator_name,
    };
  });
  let captionText = 'Note:'+'\n'+ sourceFootnote + '\n' + footnote1 + '\n' + footnote2 + '\n' + footnote3;
  let hasDrilldownOccurred = false;
   const selectedSeries = selectedArea === "India" ? 2 : 1;
   const mapOptions = {
    chart: {
      height: "350vh",
    
    
      
      events: {
        drilldown: async function (e) {
        
          if (!e.seriesOptions) {
            var chart = this,
              mapKey = e.point.drilldown.toLowerCase(),
              // Handle error, the timeout is cleared on success
              fail = setTimeout(function () {
                if (!Highcharts.maps[mapKey]) {
                  if (chart && chart.loading) {
                    chart.showLoading('<i class="icon-frown"></i> Failed loading ' + e.point.name);
                    fail = setTimeout(function () {
                      if (chart && chart.loading) {
                        chart.hideLoading();
                      }
                    }, 1000);
                  }
                }
              }, 3000);
        
            // Show the spinner
            if (chart && chart.loading) {
              chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>'); // Font Awesome spinner
            }
        
            // Load the drilldown map
            // setMapKey(mapKey);
            // dispatch(setMapKey(mapKey));
            if (!hasDrilldownOccurred) {
            const districtData = filteredData.filter(
              (d) => d.area && d.area.toLowerCase() === mapKeyValue.toLowerCase()
            );
        
            // if (!districtData.some((data) => data.area_name.toLowerCase() === mapKeyValue.toLowerCase())) {
            //   alert("No data available for this state.");
            //   return false;
            // }
        
            if (e.point.value === null) {
              if (chart && chart.loading) {
                chart.hideLoading();
                clearTimeout(fail);
              }
              return false; // Disable the drilldown action
            }
        
            // Load the drilldown map
            // setMapKey(mapKey);
            dispatch(setMapKey(mapKey));
        
            let mapPath;
        
            if (districtData.some((data) => data.source_code === "N5")) {
              mapPath = "5";
            } else if (
              districtData.some(
                (data) =>
                  data.source_code === "N4" ||
                  data.source_code === "D4" ||
                  data.source_code === "AHSCAB" ||
                  data.source_code === "AHS10_11" ||
                  data.source_code === "AHS11_12" ||
                  data.source_code === "AHS12_13"
              )
            ) {
              mapPath = "4";
            } else if (
              districtData.every(
                (data) => data.source_code === "D4" && data.area_level !== 3 && data.value !== "NA"
              )
            ) {
              return false;
            } else {
              return false;
            }
            if (
              districtData.some(
                (data) =>
                  data.source_code === "D4" &&
                  (data.indicator_id === "L1_1" || data.indicator_id === "L1_2" || data.indicator_id === "L1_3")
              )
            ) {
              mapPath = null; // Set mapPath to null to prevent drilldown for "L1_1", "L1_2", and "L1_3" in "D4"
            }
            const map = await import(
              `../../../maps/2022/NFHS${mapPath}_district_maps/${mapKey}.json`
            );
            const mapData = map.default;
            data = Highcharts.geojson(mapData);
        
            data = data.map((i) => {
              // const district = i.properties.district || "";
              const district = i.properties.NAME1_ || "";
              const filteredDistrictDataItem = districtData.find(
                (d) => d.area_name.toLowerCase() === district.toLowerCase()
              );
              const areaName = filteredDistrictDataItem
                ? filteredDistrictDataItem.area_name
                : i.name;
        
              // Check if the source_code is "D4" and load sourcearea_name instead of area_name
              let name;
              if (
                filteredDistrictDataItem &&
                (filteredDistrictDataItem.source_code === "D4" ||
                  filteredDistrictDataItem.source_code === "AHSCAB" ||
                  filteredDistrictDataItem.source_code === "AHS10_11" ||
                  filteredDistrictDataItem.source_code === "AHS11_12" ||
                  filteredDistrictDataItem.source_code === "AHS12_13")
              ) {
                name = filteredDistrictDataItem.sourcearea_name;
                subindicator_name = filteredDistrictDataItem.subindicator_name;
              } else {
                name = areaName;
                subindicator_name = filteredDistrictDataItem
                  ? filteredDistrictDataItem.subindicator_name
                  : i.subindicator_name;
              }
              return {
                ...i,
                drilldown: district,
                value: filteredDistrictDataItem ? filteredDistrictDataItem.value : 0,
                name,
                subindicator_name: subindicator_name,
              };
            });
        
            if (chart && chart.loading) {
              chart.hideLoading();
              clearTimeout(fail);
            }
        
            // Add Map Layer
            chart.addSingleSeriesAsDrilldown(e.point, {
              name: e.point.name,
              data: data,
             // borderColor: "white",
              //borderWidth: 0.6,
              dataLabels: {
                enabled: false,
          
                allowOverlap:true,
                crop:true,
                overflow: "right",
                format: "{point.name}",
                style: {
                  fontSize: "8px",
                  verticalAlign: "middle",
                },
              },
            });
            this.setTitle(null, { text: e.point.name });
            // Add Drilldown
            chart.applyDrilldown();
            hasDrilldownOccurred = true; // Set the variable to true after drilldown
        
            // Disable click event to prevent further clicks
            chart.removeEventListener('click');
          }
        }
        
        
       
        },
       
        drillup: async function () {
          dispatch(setMapKey("india"));
          this.setTitle("India", { text: "India" });
          await new Promise((resolve) => setTimeout(resolve, 0)); // Wait for the event loop to continue
         // chart.addEventListener('click', clickHandler);
        },
        click: function () {
          // Reset the map key to "India" when clicking on the map
          dispatch(setMapKey("india"));
          this.setTitle("India", { text: "India" });
        },
      },
    },
    title: {
      align: "center",
      text: boundaries,
      style: {
        fontSize: "10px",
        fontFamily: 'Verdana, sans-serif',
        fontWeight: "bold",
      },
    },
    subtitle: {
      align: "center",
      text: '*double click on map to see district map ',
      style: {
        fontSize: "8px",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "bold",
      },
    },
  exporting: {
    enabled: true,
    sourceWidth: 800,
    sourceHeight: 300,
    scale: 1,
  },
 // caption: {
  //  text: captionText,
  //  align: 'center',
  //  verticalAlign: 'bottom',
   // useHTML: true,
   // style: {
   //   fontSize: '10px',
   //   color: '#000000',
   //   fontFamily: 'Verdana, sans-serif',
   //   fontWeight: "bold",
   // },
  //  position: {
   //   align: 'center',
   //   verticalAlign: 'bottom',
   //   y: -10,
   // },
 // },
  credits: {
    enabled: false,
    //text: "Nutrition Atlas 2.0,<br/> Powered by ICMR-National Institute of Nutrition",
    //href: "http://nutritionatlasindia.in/",
    //position: {
     // align: "right",
     // x: -10,
     // y: -15,
   // },
  },
    accessibility: {
      enabled: false,
    },
   // colorAxis: {
    //  stops: colorAxisConfig.stops
   // },
  
  
    colorAxis: {
      minColor: 'rgba(196, 0, 0, 0.1)',
      maxColor: 'rgba(196, 0, 0, 1)'
  },
    legend: {
      enabled: false,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      y: 25,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "top",
      },
    },
   
  tooltip: {
    formatter: function () {
      if (this.point && this.point.value !== null) {
        let value =
          this.point.value !== undefined
            ? this.point.subindicator_name + ": " + this.point.value
            : this.point.drilldown !== undefined
            ? this.point.subindicator_name + ": " + this.point.drilldown
            : "No data";
        return "<b>" + this.point.drilldown + "</b>";
      } else {
        return false; // Return false for null points or when this.point is undefined to disable tooltip
      }
    },
    valueSuffix: "NFHS 5",
  },

  plotOptions: {
    series: {
      allowPointSelect: true, // Enable point selection
      states: {
        hover: {
          enabled: true,
          color: "#EEDD66", // Custom hover color for non-null points
        },
        select: {
          color: "green", // Set a color for selected points
        },
      },
      // Add an event for mouseOver to handle hover for null points
      events: {
        mouseOver: function () {
          if (this.point && this.point.value === null) {
            this.setState({
              color: null, // Set the color to null to disable the hover effect for null points
            });
          }
        },
      },
    },
  },
  
  series: [
    {
      mapData: filteredData,
      data: data.map((el) => {
        const areaName = el.properties?.["hc-key"] || "";
        const filteredDataItem = filteredStateData.find(
          (d) => d.area && d.area.toLowerCase() === areaName.toLowerCase()
        );
        const isSelectedArea = areaName.toLowerCase() === selectedArea.toLowerCase();
        let areaValue = null;
        let nullColor = "grey"; // Default null color
  
        // Check if the selected area is "India"
        if (selectedArea.toLowerCase() === "india" || selectedArea === "") {
          // For India or when no area is selected, set all areas to be clickable and colored
          areaValue = filteredDataItem ? filteredDataItem.value : null;
          nullColor = "#9CAF88"; // Color for areas not explicitly styled
        } else {
          // Only highlight the selected area if it matches the selectedArea
          areaValue = isSelectedArea ? (filteredDataItem ? filteredDataItem.value : null) : null;
        }
  
        return {
          ...el,
          drilldown: areaName,
          value: areaValue,
          name: filteredDataItem ? filteredDataItem.area_name : el.name,
          subindicator_name: filteredDataItem ? filteredDataItem.subindicator_name : el.subindicator_name,
          nullColor: nullColor, // Set nullColor based on the condition
        };
      }),
      name: "India",
      borderColor: "green",
      borderWidth: 0.6,
      dataLabels: {
        enabled: false,
        allowOverlap: true,
        crop: false,
        overflow: "none",
        format: "{point.name}",
        style: {
          fontSize: "10px",
        },
      },
    },
  ],
  
  
  
    drilldown: {
      defaultParent: "India",
      breadcrumbs: {
        buttonTheme: {
          fill: "#f7f7f7",
          padding: 8,
          stroke: "#cccccc",
          "stroke-width": 1,
        },
        floating: true,
        position: {
          align: "right",
        },
        showFullPath: false,
      },
      activeDataLabelStyle: {
        enabled: false,
        color: "#FFFFFF",
        textDecoration: "none",
        textOutline: "1px #000000",
      },
      drillUpButton: {
        relativeTo: "spacingBox",
        position: {
          x: 0,
          y: 100,
        },
      },
      
    },
  };
  //const uniqueAreas = [...new Set(filteredData.filter(dataPoint => dataPoint.area_level === 1 || dataPoint.area_level === 2).map(dataPoint => dataPoint.area_name))];
  // Filter and sort area_level=1 areas alphabetically
const level1Areas = filteredData.filter(dataPoint => dataPoint.area_level === 1)
.map(dataPoint => dataPoint.area_name)
.sort();

// Filter and sort area_level=2 areas alphabetically
const level2Areas = filteredData.filter(dataPoint => dataPoint.area_level === 2)
.map(dataPoint => dataPoint.area_name)
.sort();

// Concatenate level1Areas and level2Areas
const uniqueAreas = [...level1Areas, ...level2Areas];
  return (
    <>
<Grid container spacing={1} maxWidth="xl" >
<Grid item xs={12} md={3}>
  <Grid container spacing={1}>
  <Grid item xs={12}>
  {/* Dropdown menu */}
  <Select
  value={areaSelectedOption} // Ensure that the value is set to areaSelectedOption
  onChange={(e) => handleAreaSelection(e.target.value)} // Handle area selection
  fullWidth
  displayEmpty
>
  <MenuItem value="" disabled>
    Select Area
  </MenuItem>
  {uniqueAreas.map((area, index) => (
    <MenuItem key={index} value={area.toLowerCase()}>
    {area}
  </MenuItem>
  ))}
</Select>
</Grid>
    <Grid item xs={12} >
    {renderSelectedArea()}
    <HighchartsReact
  highcharts={Highcharts}
  constructorType={"mapChart"}
  options={mapOptions}
  callback={(chart) => {
    chart.mapKeydown = function () {
      return true;
    };
    chart.mapAreaClick = function (e) {
      handleMapAreaSelection(e.point.drilldown); // Call handleMapAreaSelection when an area on the map is clicked
    };
  }}
/>
    </Grid>
    <Grid item xs={12} >
    <Infocard/>
    </Grid>
  </Grid>
</Grid>
  <Grid item xs={12} md={9} >
    {apiData === null ? (
      <Grid container justify="center" alignItems="center">
        {/* <Spinner /> */}
        <div>No data available</div>
      </Grid>
    ) : (



<Grid item xs={12} >
<Compare/>
</Grid>
     
    )}
  </Grid>
  
  {isErrorModalOpen && (
        <Dialog open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
          <DialogContent>
            <DialogContentText>
              The selected area is not present in one of the selected sources. Please select another area that is common in the selected sources.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsErrorModalOpen(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
</Grid>




    </>
  );
};

export default India2014;