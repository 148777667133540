// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import bone from '../../../../../assets/images/icons/minerals/Functions/bone.png';
import coenzyme from '../../../../../assets/images/icons/minerals/Functions/coenzyme.png';
import immunity from '../../../../../assets/images/icons/minerals/Functions/immunity.png';
import growth from '../../../../../assets/images/icons/minerals/Deficiency/growth.png';
import skeletal_abnormalities from '../../../../../assets/images/icons/minerals/Deficiency/skeletal_abnormalities.png';
import impaired_reproductive_function from '../../../../../assets/images/icons/minerals/Deficiency/impaired_reproductive_function.png';
import blood_sugar  from '../../../../../assets/images/icons/minerals/Deficiency/blood_sugar.png';
import wheat from "../../../../../assets/FoodSources/Minerals/Sources/wheat_bran.png";
import fruits from "../../../../../assets/FoodSources/Minerals/Sources/fruits.png";
import vegetables from "../../../../../assets/FoodSources/Minerals/Sources/vegetables.png";
import egg from "../../../../../assets/FoodSources/Minerals/Sources/egg.png";
import beef from "../../../../../assets/FoodSources/Minerals/Sources/beef.png";
import chicken from "../../../../../assets/FoodSources/Minerals/Sources/chicken.png";

export default function Manganese() {
    return (
        <>

            <Grid item lg={7} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        padding:"0.9em"
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                    What are the functions of manganese?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={coenzyme} />
                                </ListItemAvatar>
                                <ListItemText primary="As manganese is a cofactor for various enzymes which are involved in carbohydrate, fat and protein metabolism." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={bone} />
                                </ListItemAvatar>
                                <ListItemText primary="It is necessary for the formation and maintenance of healthy bones." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={immunity} />
                                </ListItemAvatar>
                                <ListItemText primary="It is also required for normal functioning of immune and reproductive systems." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt:1,
                        padding:"0.9em"
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                        What are the food sources of manganese?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                            Plant foods like wheat, barley, rice bran are rich in manganese. Fruits and vegetables are moderate sources and animal foods like eggs, beef and chicken contain low levels.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="Plant foods like wheat, barley, rice bran">
                                <Avatar src={wheat} size="lg" alt="wheat grains" variant="square" />
                            </Tooltip>
                            <Tooltip title="fruits">
                                <Avatar src={fruits} alt="fruits" variant="square" />
                            </Tooltip>
                            <Tooltip title="vegetables">
                                <Avatar src={vegetables} alt="vegetables" variant="square" />
                            </Tooltip>
                            <Tooltip title="eggs">
                                <Avatar src={egg} alt="eggs" variant="square" />
                            </Tooltip>
                            <Tooltip title="beef">
                                <Avatar src={beef} alt="beef" variant="square" />
                            </Tooltip>
                            <Tooltip title="chicken">
                                <Avatar src={chicken} alt="chicken" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>

            </Grid>
            <Grid item lg={5} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py:2
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                    What is the recommended dietary intake of manganese?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={Human} />
                                </ListItemAvatar>
                                <ListItemText primary="The recommended intake of manganese required for adult man and adult woman is 4 mg/day" />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>



                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens when you are deficient in Manganese?
                        </Typography>
                        <List sx={{ textAlign: "left",'& > li': { marginBottom: '-6px' }, }}>
                            <ListItem>
                                Manganese deficiency in body could lead to growth failure, skeletal abnormalities and impaired reproductive function.
                            </ListItem>
                            <ListItem>
                                Abnormal insulin metabolism and glucose intolerance can also occur as result of manganese deficiency.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        mt={1}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="Whole Grain cereals">
                                <Avatar src={growth} size="lg" alt="Whole Grains" variant="square" />
                            </Tooltip>
                            <Tooltip title="Milk and Milk Products">
                                <Avatar src={skeletal_abnormalities} alt="Milk" variant="square" />
                            </Tooltip>
                            <Tooltip title="impaired reproductive function">
                                <Avatar src={impaired_reproductive_function} alt="impaired reproductive function" variant="square" />
                            </Tooltip>
                            <Tooltip title="glucose intolerance">
                                <Avatar src={blood_sugar} alt="glucose intolerance" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>


            </Grid>
        </>
    );
}



