import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsSankey from 'highcharts/modules/sankey';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

// Initialize the required Highcharts modules
HighchartsSankey(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const SankeyChart = () => {
  useEffect(() => {
    Highcharts.chart('container', {
      title: {
        text: 'Data sources used for Nutrition atlas',
      },
      chart: {
        inverted: false,
      },
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.',
        },
      },
      credits: {
        enabled: false,
      },
      series: [{
        keys: ['from', 'to', 'weight', 'text'], 
        data: [
          ['NFHS', 'NFHS-5', 1, 'NFHS-5'],
          ['NFHS', 'NFHS-4', 1, 'NFHS-4'],
          ['NFHS', 'NFHS-3', 1, 'NFHS-3'],
          ['NFHS', 'NFHS-2', 1, 'NFHS-2'],
          ['NFHS', 'NFHS-1', 1, 'NFHS-1'],
    
          ['NFHS-5', 'Stunting', 1, 'Stunting','#e6a852'],
          ['NFHS-5', 'Wasting', 1, 'Wasting','#e6a852'],
          ['NFHS-5', 'Underweight', 1, 'Underweight','#e6a852'],
          ['NFHS-5', 'Overweight', 1, 'Overweight','#e6a852'],
          ['NFHS-5', 'Anaemia', 1, 'Anaemia','#e6a852'],
          ['NFHS-5', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation','#e6a852'],
          ['NFHS-5', 'Infant and Young Child Feeding Practices(IYCF)', 1, 'IYCF','#e6a852'],
          ['NFHS-5', 'Adequate Diet', 1, 'Adequate Diet','#e6a852'],
          ['NFHS-5', 'Mortality', 1, 'Mortality','#e6a852'],
          ['NFHS-5', 'Marriage & Pregnancy', 1, 'Marriage & Pregnancy','#e6a852'],
          ['NFHS-5', 'Body Mass Index(BMI)', 1, 'BMI','#e6a852'],
          ['NFHS-5', 'Waist-To-Hip Ratio', 1, 'Waist-To-Hip Ratio','#e6a852'],
          ['NFHS-5', 'Blood sugar', 1, 'Blood sugar','#e6a852'],
          ['NFHS-5', 'Hypertension', 1, 'Hypertension','#e6a852'],
          ['NFHS-5', 'Antenatal Care(ANC)Visits', 1, 'ANC Visits','#e6a852'],
          ['NFHS-5', 'Institutional Delivery', 1, 'Institutional Delivery','#e6a852'],
          ['NFHS-5', 'Post-Natal Care For Mothers', 1, 'Post-Natal Care For Mothers','#e6a852'],
    
          ['NFHS-4', 'Stunting', 1, 'Stunting'],
      ['NFHS-4', 'Wasting', 1,'Wasting'],
      ['NFHS-4', 'Underweight', 1,'Underweight'],
      ['NFHS-4', 'Anaemia', 1,'Anaemia'],
      ['NFHS-4', 'Vaccination & Supplementation', 1,'Vaccination & Supplementation'],
      ['NFHS-4', 'Infant and Young Child Feeding Practices(IYCF)', 1,'Infant and Young Child Feeding Practices(IYCF)'],
      ['NFHS-4', 'Adequate Diet', 1,'Adequate Diet'],
      ['NFHS-4', 'Mortality', 1,'Mortality'],
      ['NFHS-4', 'Body Mass Index(BMI)', 1,'Body Mass Index(BMI)'],
      ['NFHS-4', 'Blood sugar', 1,'Blood sugar'],
      ['NFHS-4', 'Hypertension', 1,'Hypertension'],
      ['NFHS-4', 'Antenatal Care(ANC)Visits', 1,'Antenatal Care(ANC)Visits'],
      ['NFHS-4', 'Institutional Delivery', 1,'Institutional Delivery'],
      ['NFHS-4', 'Post-Natal Care For Mothers', 1,'Post-Natal Care For Mothers'],
    
      ['NFHS-3', 'Stunting', 1,'Stunting'],
      ['NFHS-3', 'Wasting', 1,'Wasting'],
      ['NFHS-3', 'Underweight', 1,'Underweight'],
      ['NFHS-3', 'Anaemia', 1,'Anaemia'],
      ['NFHS-3', 'Vaccination & Supplementation', 1,'Vaccination & Supplementation'],
      ['NFHS-3', 'Infant and Young Child Feeding Practices(IYCF)', 1,'Infant and Young Child Feeding Practices(IYCF)'],
      ['NFHS-3', 'Mortality', 1,'Mortality'],
      ['NFHS-3', 'Body Mass Index(BMI)', 1,'Body Mass Index(BMI)'],
      ['NFHS-3', 'Antenatal Care(ANC)Visits', 1,'Antenatal Care(ANC)Visits'],
      ['NFHS-3', 'Institutional Delivery', 1,'Institutional Delivery'],
      ['NFHS-3', 'Post-Natal Care For Mothers', 1,'Post-Natal Care For Mothers'],
    
    
      ['NFHS-2', 'Stunting', 1,'Stunting'],
      ['NFHS-2', 'Wasting', 1,'Wasting'],
      ['NFHS-2', 'Underweight', 1,'Underweight'],
      ['NFHS-2', 'Low Birth Weight', 1,'Low Birth Weight'],
      ['NFHS-2', 'Anaemia', 1,'Anaemia'],
      ['NFHS-2', 'Vaccination & Supplementation', 1,'Vaccination & Supplementation'],
      ['NFHS-2', 'Mortality', 1,'Mortality'],
      ['NFHS-2', 'Institutional Delivery', 1,'Institutional Delivery'],
    
      ['NFHS-1', 'Stunting', 1,'Stunting'],
      ['NFHS-1', 'Wasting', 1,'Wasting'],
      ['NFHS-1', 'Underweight', 1,'Underweight'],
      ['NFHS-1', 'Vaccination & Supplementation', 1,'Vaccination & Supplementation'],
      ['NFHS-1', 'Infant and Young Child Feeding Practices(IYCF)', 1,'Infant and Young Child Feeding Practices(IYCF)'],
      ['NFHS-1', 'Mortality', 1,'Mortality'],
      ['NFHS-1', 'Institutional Delivery', 1,'Institutional Delivery'],
    
          ['DLHS', 'DLHS-4', 1, 'DLHS-4'],
          ['DLHS', 'DLHS-3', 1, 'DLHS-3'],
          ['DLHS', 'DLHS-2', 1, 'DLHS-2'],
          ['DLHS', 'DLHS-1', 1, 'DLHS-1'],
    
          ['DLHS-4', 'Stunting', 1, 'Stunting'],
          ['DLHS-4', 'Wasting', 1, 'Wasting'],
          ['DLHS-4', 'Underweight', 1, 'Underweight'],
          ['DLHS-4', 'Low Birth Weight', 1, 'Low Birth Weight'],
          ['DLHS-4', 'Anaemia', 1, 'Anaemia'],
          ['DLHS-4', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation'],
          ['DLHS-4', 'Blood sugar', 1, 'Blood sugar'],
          ['DLHS-4', 'Hypertension', 1, 'Hypertension'],
          ['DLHS-4', 'Antenatal Care(ANC)Visits', 1, 'ANC Visits'],
          ['DLHS-4', 'Institutional Delivery', 1, 'Institutional Delivery'],
          ['DLHS-4', 'Post-Natal Care For Mothers', 1, 'Post-Natal Care For Mothers'],
    
          ['DLHS-3', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation'],
          ['DLHS-3', 'Antenatal Care(ANC)Visits', 1, 'ANC Visits'],
          ['DLHS-3', 'Institutional Delivery', 1, 'Institutional Delivery'],
          ['DLHS-3', 'Post-Natal Care For Mothers', 1, 'Post-Natal Care For Mothers'],
    
          ['DLHS-2', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation'],
          ['DLHS-2', 'Antenatal Care(ANC)Visits', 1, 'ANC Visits'],
          ['DLHS-2', 'Institutional Delivery', 1, 'Institutional Delivery'],
          ['DLHS-2', 'Post-Natal Care For Mothers', 1, 'Post-Natal Care For Mothers'],
    
          ['DLHS-1', 'Low Birth Weight', 1, 'Low Birth Weight'],
          ['DLHS-1', 'Antenatal Care(ANC)Visits', 1, 'ANC Visits'],
          ['DLHS-1', 'Institutional Delivery', 1, 'Institutional Delivery'],
    
    
    
          ['AHS', 'AHS_CAB', 1, 'AHS_CAB'],
          ['AHS', 'AHS 12_13', 1, 'AHS 12_13'],
          ['AHS', 'AHS 11_12', 1, 'AHS 11_12'],
          ['AHS', 'AHS 10_11', 1, 'AHS 10_11'],
    
    
    
          ['AHS_CAB', 'Stunting', 1, 'Stunting'],
          ['AHS_CAB', 'Wasting', 1, 'Wasting'],
          ['AHS_CAB', 'Underweight', 1, 'Underweight'],
          ['AHS_CAB', ' Anaemia', 1, ' Anaemia'],
          ['AHS_CAB', 'Body Mass Index(BMI)', 1, 'Body Mass Index(BMI)'],
          ['AHS_CAB', 'Blood sugar', 1, 'Blood sugar'],
          ['AHS_CAB', 'Hypertension', 1, 'Hypertension'],
    
    
          ['AHS 12_13', 'Low Birth Weight', 1, 'Low Birth Weight'],
          ['AHS 12_13', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation'],
          ['AHS 12_13', 'Infant and Young Child Feeding Practices(IYCF)', 1, 'Infant and Young Child Feeding Practices(IYCF)'],
          ['AHS 12_13', 'Mortality', 1, 'Mortality'],
          ['AHS 12_13', 'Antenatal Care(ANC)Visits ', 1, 'Antenatal Care(ANC)Visits '],
          ['AHS 12_13', 'Institutional Delivery', 1, 'Institutional Delivery'],
          ['AHS 12_13', 'Post-Natal Care For Mothers', 1, 'Post-Natal Care For Mothers'],
    
    
          ['AHS 11_12', 'Low Birth Weight', 1, 'Low Birth Weight'],
          ['AHS 11_12', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation'],
          ['AHS 11_12', 'Infant and Young Child Feeding Practices(IYCF)', 1, 'Infant and Young Child Feeding Practices(IYCF)'],
          ['AHS 11_12', 'Mortality', 1, 'Mortality'],
          ['AHS 11_12', 'Antenatal Care(ANC)Visits ', 1, 'Antenatal Care(ANC)Visits '],
          ['AHS 11_12', 'Institutional Delivery', 1, 'Institutional Delivery'],
          ['AHS 11_12', 'Post-Natal Care For Mothers', 1, 'Post-Natal Care For Mothers'],
    
    
    
          ['AHS 10_11', 'Low Birth Weight', 1, 'Low Birth Weight'],
          ['AHS 10_11', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation'],
          ['AHS 10_11', 'Infant and Young Child Feeding Practices(IYCF)', 1, 'Infant and Young Child Feeding Practices(IYCF)'],
          ['AHS 10_11', 'Mortality', 1, 'Mortality'],
          ['AHS 10_11', 'Antenatal Care(ANC)Visits ', 1, 'Antenatal Care(ANC)Visits '],
          ['AHS 10_11', 'Institutional Delivery', 1, 'Institutional Delivery'],
          ['AHS 10_11', 'Post-Natal Care For Mothers', 1, 'Post-Natal Care For Mothers'],
    
         
    
         
          ['NNMB', 'NNMBURBAN2017', 1, 'NNMBURBAN2017'],
          ['NNMB', 'NNMB2012', 1, 'NNMB2012'],
          ['NNMB', 'NNMB2009', 1, 'NNMB2009'],
          ['NNMB', 'NNMB2006', 1, 'NNMB2006'],
    
    
          ['NNMBURBAN2017', 'Stunting', 1, 'Stunting'],
          ['NNMBURBAN2017', 'Underweight', 1, 'Underweight'],
          ['NNMBURBAN2017', 'Low Birth Weight', 1, 'Low Birth Weight'],
          ['NNMBURBAN2017', 'Infant and Young Child Feeding Practices(IYCF)', 1, 'Infant and Young Child Feeding Practices(IYCF)'],
       
         
          ['NNMB2012', 'Stunting', 1, 'Stunting'],
          ['NNMB2012', 'Wasting', 1, 'Wasting'],
          ['NNMB2012', 'Underweight', 1, 'Underweight'],
          ['NNMB2012', 'Low Birth Weight', 1, 'Low Birth Weight'],
          ['NNMB2012', 'Vaccination & Supplementation', 1, 'Vaccination & Supplementation'],
          ['NNMB2012', 'Infant and Young Child Feeding Practices(IYCF)', 1, 'Infant and Young Child Feeding Practices(IYCF)'],
          ['NNMB2012', 'Antenatal Care(ANC)Visits ', 1, 'Antenatal Care(ANC)Visits '],
          ['NNMB2012', 'Institutional Delivery', 1, 'Institutional Delivery'],
       
          ['NNMB2009', 'Stunting', 1, 'Stunting'],
          ['NNMB2009', 'Wasting', 1, 'Wasting'],
          ['NNMB2009', 'Underweight', 1, 'Underweight'],
          ['NNMB2009', 'Body Mass Index(BMI)', 1, 'Body Mass Index(BMI)'],
         
    
          ['NNMB2006', 'Stunting', 1, 'Stunting'],
          ['NNMB2006', 'Wasting', 1, 'Wasting'],
          ['NNMB2006', 'Underweight', 1, 'Underweight'],
         
          ['Data Sources', 'CNNS', 1, 'CNNS'],
          ['Data Sources', 'LASI WAVE 1', 1, 'LASI WAVE 1'],
    
          ['CNNS', 'Stunting', 1, 'Stunting'],
          ['CNNS', 'Wasting', 1, 'Wasting'],
          ['CNNS', 'Underweight', 1, 'Underweight'],
          ['CNNS', 'Mid-Upper Arm Circumference(MUAC)', 1, 'Mid-Upper Arm Circumference(MUAC)'],
          ['CNNS', 'Triceps Skinfold Thickness(TST)', 1, 'Triceps Skinfold Thickness(TST)'],
          ['CNNS', 'Subscapular Skinfold Thickness(SST)', 1, 'Subscapular Skinfold Thickness(SST)'],
          ['CNNS', 'Anaemia', 1, 'Anaemia'],
          ['CNNS', 'Micronutrient Deficiency', 1, 'Micronutrient Deficiency'],
          ['CNNS', 'Waist circumference', 1, 'Waist circumference'],
          ['CNNS', 'Lipid Profile', 1, 'Lipid Profile'],
          ['CNNS', 'High Blood Sugar', 1, 'High Blood Sugar'],
          ['CNNS', 'Glycosylated Haemoglobin', 1, 'Glycosylated Haemoglobin'],
          ['CNNS', 'Serum Creatinine', 1, 'Serum Creatinine'],
          ['CNNS', 'Body Mass Index(BMI)', 1, 'BMI'],
    
    
    
          ['LASI WAVE 1', ' Body Mass Index(BMI)', 1, ' Body Mass Index(BMI)'],
          ['LASI WAVE 1', 'Waist Circumference', 1, 'Waist Circumference'],
          ['LASI WAVE 1', 'Waist-Hip Ratio', 1, 'Waist-Hip Ratio'],
          ['LASI WAVE 1', 'Hypertension', 1, 'Hypertension'],
          ['LASI WAVE 1', 'Anaemia ', 1, 'Anaemia '],
          ['LASI WAVE 1', 'Cardiovascular Diseases', 1, 'Cardiovascular Diseases'],
          ['LASI WAVE 1', 'High Cholesterol', 1, 'High Cholesterol'],
          ['LASI WAVE 1', 'Diabetes Or High Blood Sugar', 1, 'Diabetes Or High Blood Sugar'],
         
        ],
        nodes: [
          { id: 'NFHS', color: '#977171' }, // Specify color for 'NFHS'
          { id: 'NFHS-5', color: '#c192e5' }, // Specify color for 'NFHS-5'
          { id: 'NFHS-4', color: '#f46666' },
          { id: 'NFHS-3', color: '#5fbd89' },
          { id: 'NFHS-2', color: '#FFD700' },
          { id: 'NFHS-1', color: '#488ce5' }, 
          { id: 'DLHS', color: '#977171' }, // Specify color for 'NFHS-4'
          // ... add more nodes with their respective colors
        ],
        nodeWidth: 100,
        nodePadding: 1,
        type: 'sankey',
        name: 'Data sources',
        //colorByPoint: false,
      }],
    });
  }, []); 

  return (
    <div id="container" style={{ height: '900px' }}></div>
  );
};

export default SankeyChart;
