import React, { useState, useEffect } from "react";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import MapChart from "./MapChart";
import mapData from "../../../maps/2022/india_state_nfhs5.json";

// Load Highcharts modules
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);
const IndiaMap = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      "https://nutritionatlasapi.cyclic.app/api/data?lifecycle=1&indicator_id=L1_1&subindicator_id=L1_1_1&source_code=N5"
    )
      .then((response) => response.json())
      .then((json) => setData(json.results));
  }, []);

  const mapOptions = {
    chart: {
      height: "320vh",
      map: mapData,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },

    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    mapNavigation: {
      enabled: false,
    },

    colorAxis: {
      min: 20,
      max: 40,
      minColor: "#086192",
      maxColor: "#e31e24",
    },
    legend: {
      enabled: false,
    },

    tooltip: {
      formatter: function () {
        let point = this.point;
        return "<b>" + point.NAME1_ + "</b><br>" + point.indicator_name + "  :" + point.value;
      },
      valueSuffix: "NFHS 5",
    },
    plotOptions: {
      map: {
        states: {
          hover: {
            color: "#EEDD66",
          },
        },
      },
    },

    series: [
      {
        showInLegend: false,
        data: data,
        joinBy: ["NAME1_", "area_name"],
        borderColor: "white",
        borderWidth: 0.6,
        name: "Population density",
      },
    ],
  };

  return (
    <div style={{ marginTop: "-550px", marginInline: "90px" }}>
      <MapChart options={mapOptions} highcharts={Highcharts} />
    </div>
  );
};
export default IndiaMap;
