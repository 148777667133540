import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import antioxidant from "../../../../../assets/images/icons/vitamins/Functions/antioxidant.png";
import bloodClot from "../../../../../assets/images/icons/vitamins/Functions/bloodClot.png";
import hormone from "../../../../../assets/images/icons/vitamins/Functions/hormone1.png";
import hormone2 from "../../../../../assets/images/icons/vitamins/Functions/hormone2.png";
import immunity from "../../../../../assets/images/icons/vitamins/Functions/immunity.png";
import cellMembrane from "../../../../../assets/images/icons/vitamins/Functions/cellMembrane.png"
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import fortifiedCereals from "../../../../../assets/images/icons/vitamins/vitaminD/fortifiedCereals.png";
import sunflowerSeed from "../../../../../assets/images/icons/vitamins/VitaminE/sunflowerSeed.png";
import hazelnutAlmond from "../../../../../assets/images/icons/vitamins/VitaminE/hazelnutAlmond.png";
import sunflowerOil from "../../../../../assets/images/icons/vitamins/VitaminE/sunflowerOil.png";
import glv from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/spinach.png";
import broccoli from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B5/broccoli.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/vitaminb2/dermatitis.png";
import fatigue from "../../../../../assets/images/icons/vitamins/vitaminb2/fatigue.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import Platelet from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/Platelet.png";
import WeakImmunity from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/WeakImmunity.png";
import anaemia from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/anemia.png";
import impairedVision from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/impairedVision.png";
import infertility from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/infertility.png";
import lossOfCoordination from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/lossOfCoordination.png";
import muscleWeakness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/muscleWeakness.png";
import numbness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/numbness.png";


export default function Vitamine() {
  return (
    <>
      <Grid item lg={6} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the Functions of Vitamin E?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="antioxidant" src={antioxidant} />
                </ListItemAvatar>
                <ListItemText primary="It is a potent  antioxidant and prevents oxidative stress" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="cell membrane" src={cellMembrane} />
                </ListItemAvatar>
                <ListItemText primary="It protects cell membrane against damage from free radicals." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="blood clot" src={bloodClot} />
                </ListItemAvatar>
                <ListItemText primary="It is involved in the formation of red blood cells and also help in preventing clot formation in blood." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="immunity" src={immunity} />
                </ListItemAvatar>
                <ListItemText primary="It keeps proper  functioning of the brain and nervous system and regulates immune system." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="hormone" src={hormone2} />
                </ListItemAvatar>
                <ListItemText primary="Hormonal properties- helps in production of estrogen and testosterone." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the sources of Vitamin E?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Good food sources of Vitamin E include nuts and seeds (such as almonds, sunflower seeds, and hazelnuts), vegetable oils (such as wheat germ oil, sunflower oil, and safflower oil), leafy green vegetables (such as spinach and broccoli), and fortified cereals.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}

          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="almonds and hazelnuts">
                <Avatar src={hazelnutAlmond} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="sunflower seeds">
                <Avatar src={sunflowerSeed} alt="Milk" variant="square" />
              </Tooltip>
              <Tooltip title="wheat germ oil, sunflower oil, and safflower oil">
                <Avatar src={sunflowerOil} alt="sunflower oil" variant="square" />
              </Tooltip>
              <Tooltip title="spinach">
                <Avatar src={glv} alt="spinach" variant="square" />
              </Tooltip>
              <Tooltip title="broccoli">
                <Avatar src={broccoli} alt="broccoli" variant="square" />
              </Tooltip>
              <Tooltip title="fortified cereals ">
                <Avatar src={fortifiedCereals} alt="fortified cereals" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={6} md={5} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: -1, sm: 1 },
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is Vitamin E absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Vitamin E, in its free form is absorbed in the small intestine, the absorption is enhanced in presence of fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 1, sm: 0 },
            py: 1
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What is the RDA for Vitamin E?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar>
                <ListItemText primary="The RDA  for vitamin E for adults is 7.5 – 10 mg tocopherol per day." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is Vitamin E absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Vitamin E, in its free form is absorbed in the small intestine, the absorption is enhanced in presence of fats.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens when you are deficient in Vitamin E?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem sx={{ fontweight: "bold" }}>
                Vitamin E deficiency can manifest in various ways. Common symptoms include muscle weakness, loss of coordination, impaired vision, peripheral neuropathy (numbness or tingling in the hands and feet), and an increased susceptibility to infections.
              </ListItem>
              <ListItem>
                Accelerated destruction of red blood cells: hemolytic anaemia , platelet and lymphocyte malfunction.
              </ListItem>
              <ListItem>
                It’s deficiency can lead to impaired fertility in both sexes.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
              <Tooltip title="muscle weakness">
                <Avatar src={muscleWeakness} size="lg" alt="muscle weakness" variant="square" />
              </Tooltip>
              <Tooltip title="loss of coordination">
                <Avatar src={lossOfCoordination} alt="loss of coordination" variant="square" />
              </Tooltip>
              <Tooltip title="impaired vision">
                <Avatar src={impairedVision} alt="impaired vision" variant="square" />
              </Tooltip>
              <Tooltip title="numbness or tingling in the hands">
                <Avatar src={numbness} alt="numbness or tingling in the hands" variant="square" />
              </Tooltip>
              <Tooltip title="increased susceptibility to infections">
                <Avatar src={WeakImmunity} alt="increased susceptibility to infections" variant="square" />
              </Tooltip>
              <Tooltip title="hemolytic anaemia ">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={anaemia} alt="hemolytic anaemia" variant="square" />
              </Tooltip>
              <Tooltip title="platelet malfunction">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={Platelet} alt="platelet malfunction" variant="square" />
              </Tooltip>
              <Tooltip title="impaired fertility in both sexes ">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={infertility} alt="infertility" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
             
              {/* <Tooltip title="increased susceptibility to infections">
                <Avatar src={WeakImmunity} alt="increased susceptibility to infections" variant="square" />
              </Tooltip> */}
              <Tooltip title="hemolytic anaemia ">
                <Avatar src={anaemia} alt="hemolytic anaemia" variant="square" />
              </Tooltip>
              <Tooltip title="platelet malfunction">
                <Avatar src={Platelet} alt="platelet malfunction" variant="square" />
              </Tooltip>
              <Tooltip title="impaired fertility in both sexes ">
                <Avatar src={infertility} alt="infertility" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: -1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the Interactions of vitamin E with other nutrients?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' } }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Vitamin E works synergistically with vitamin C, B3, selenium and glutathione to reduce the inflammation in the body." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>

    </>
  );
}
