import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { transformedData1 } from "./RDAtableData";
import "./RdaDataTable.css";
import { TableCell, TableFooter, TableHead, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
    boxShadow: "5px 2px 5px grey",
    borderRight: "2px solid black",
  },
});
const EarDataTable = () => {
  const rowsWithIds = Object.keys(transformedData1).map((id) => {
    const rowData = transformedData1[id];
    console.log("rowData", rowData)
    return { id, ...rowData };
  });
  console.log("rowData")

  // Extract the nutrient names dynamically from the first row of data
  const nutrientNames = Object.keys(transformedData1["1.11"]).filter(
    (key) => key !== "AgeGroup" && key !== "CategoryOfWork" && key !== "ReferenceBodyWeight"
  );

  // Create columns dynamically based on nutrient names
  const columns = [
    { field: "AgeGroup", headerName: "Age Group", flex: 1, minWidth: 85 },
    { field: "CategoryOfWork", headerName: "Category of Work", flex: 1, minWidth: 110 },
    { field: "ReferenceBodyWeight", headerName: "Reference body weight", flex: 1, minWidth: 85 },
  ];

  const nutrientMeasuringUnits = {};

  // const getTooltipContent = (nutrient, rowData) => {
  //   const nutrientData = rowData[nutrient] || { RDA: "-", EAR: "-" };
  //   return nutrientData.RDA === "-" ? "-" : `${nutrientData.EAR}`;
  // };
  const getTooltipContent = (nutrient, rowData) => {
    const nutrientData = rowData[nutrient];

    if (typeof nutrientData === 'object') {
      // For nutrients with nested structure (RDA and EAR)
      return nutrientData.EAR !== undefined ? nutrientData.EAR : "-";
    } else {
      // For "Energy (Kcal/Kg/d)" and other nutrients without nested structure
      return nutrientData !== undefined ? nutrientData : "-";
    }
  };


  // Add default values for missing nutrients
  for (const nutrient of nutrientNames) {
    console.log("Nutrient:", nutrient);
    columns.push({
      field: nutrient,
      headerName: (
        <div style={{ whiteSpace: "pre-line", textAlign: "center", lineHeight: "1.2" }}>
          {`${nutrient}\n${nutrientMeasuringUnits[nutrient] || ""}`}
        </div>
      ),
      flex: 17,
      minWidth: 85,
      align: "center",
      renderCell: (params) => {
        const rowData = params.row;
        const tooltipContent = getTooltipContent(nutrient, rowData);
        return (
          <Tooltip title={tooltipContent} arrow>
            <div style={{ overflowWrap: "anywhere", wordBreak: "break-all" }}>{tooltipContent}</div>
          </Tooltip>
        );
      },
    });
  }
  const getRowClassName = (params) => {
    const ageGroup = params.row.AgeGroup;

    // Use a switch or if-else to apply different CSS classes based on the "Age Group"
    switch (ageGroup) {
      case "Infants":
        return "age-group-infants";
      case "Children":
        return "age-group-children";
      case "Boys":
        return "age-group-boys";
      case "Girls":
        return "age-group-girls";
      case "Men":
        return "age-group-men";
      case "Women":
        return "age-group-women";
      case "Elderly":
        return "age-group-elderly";
      // Add more cases for other age groups
      default:
        return "";
    }
  };

  const getRowHeight = (params) => 35;

  return (
    <div style={{ height: 930, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "text.disabled",
            color: "black",
          },
        }}
        rows={rowsWithIds}
        columns={columns}
        isRowSelectable={() => false}
        disableRowSelection
        density="standard"
        components={{
          Toolbar: GridToolbar,
          Header: (props) => (
            <TableHead>
              <TableRow>
                {/* Adjust the number and content of cells based on your columns */}
                <TableCell colSpan={columns.length} style={{ color:"#6b3238", textAlign: "center", fontSize: '1.3em', fontWeight: 'bold' }}>
                  Summary of Estimated Average Requirements (EAR) of Nutrients for Indians
                </TableCell>
                {/* Add more cells for other columns */}
              </TableRow>
            </TableHead>
          ),
          // Footer: (props) => (
          //   <TableFooter>
          //     <TableCell colSpan={columns.length} style={{ textAlign: "left" }}>
          //       <div>Note: The energy required indicated for different age groups is for specific body weight, gender and physical activity.</div>
          //       <div>The energy required for an individual may be varying depending on his/her body weight and physical activity. Refer Tables 2 and 3.</div>
          //       <div>* Adequate Intake (AI); ** There is no RDA for energy, the EAR for energy is equivalent to the Estimated Energy Requirement (EER).</div>
          //       <div>For adequate intake of Biotin and Pantothenic acid, refer to the text on summary of recommendations.</div>
          //       <div>#Apart from the daily requirement of Energy per kg body weight, a pregnant women should take additional energy allowance of 350Kcals/day, while lactating women require an additional energy allowance of 600 kcals and 520 kcals respectively.</div>
          //       <div># Protein requirement: additional 7.6g and 17.6g during 2nd and 3rd trimester for pregnant women, and 13.6g & 10.6g for lactating women respectively.</div>
          //       <div>Additional requirement of protein is for 10kg gestational weight gain (GWG).</div>
          //       <div>Protein recommendation is for quality protein.</div>
          //       {/* Add more lines as needed */}  
          //     </TableCell>
          //   </TableFooter>
          // ),
        }}
        componentsProps={{
          toolbar: {
            printOptions: {
              disableToolbarButton: true,
            },
            csvOptions: {
              disableToolbarButton: true,
            },
          },
        }}
        pageSize={22}
        rowsPerPageOptions={[5]}
        getRowClassName={getRowClassName}
        getRowHeight={getRowHeight}
      />
    </div>
  );
};

export default EarDataTable;
