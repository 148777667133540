import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import bloodCells from "../../../../../assets/images/icons/vitamins/Functions/bloodCells.png";
import heartHealth from "../../../../../assets/images/icons/vitamins/Functions/heartHealth.png";
import nutrientToEnergy from "../../../../../assets/images/icons/vitamins/Functions/nutrientToEnergy.png";
import pregnancyBrain from "../../../../../assets/images/icons/vitamins/Functions/pregnancyBrain.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import fish from "../../../../../assets/images/icons/vitamins/vitaminb3/fish.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import meat from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import avocado from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B5/avocado.png";
import banana from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B6/bananas.png";
import poultry from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B6/poultry.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import nuts from "../../../../../assets/images/icons/vitamins/vitaminb2/nuts.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/vitaminb2/dermatitis.png";
import fatigue from "../../../../../assets/images/icons/vitamins/vitaminb2/fatigue.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import VitB6RdaTable from "./RdaAndEarTables/VitB6RdaTable";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";

export default function Vitaminb6() {
  return (
    <>
      <Grid item lg={7} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of vitamin B6 (Pyridoxine)?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="nutrient to energy" src={nutrientToEnergy} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin B6 is necessary for the breakdown of carbohydrates, proteins , and fats." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="blood cells" src={bloodCells} />
                </ListItemAvatar>
                <ListItemText primary="It is needed for the formation of red blood cells and genetic material (DNA and RNA)" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="pregnancy Brain" src={pregnancyBrain} />
                </ListItemAvatar>
                <ListItemText primary="It supports brain development during pregnancy & infancy" />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="heart health" src={heartHealth} />
                </ListItemAvatar>
                <ListItemText primary="It balances levels of homocysteine (as high levels can cause heart problems) in the blood" />
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the food sources of pyridoxine ?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Good sources of vitamin B6 are fish, poultry, meat, bananas, avocados, nuts, whole
                grains and legumes.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
              mb: 1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
              <Tooltip title="Fish">
                <Avatar src={fish} size="lg" alt="Fish" variant="square" />
              </Tooltip>
              <Tooltip title="Poultry">
                <Avatar src={poultry} size="lg" alt="poultry" variant="square" />
              </Tooltip>
              <Tooltip title="Meat">
                <Avatar src={meat} alt="meat" variant="square" />
              </Tooltip>
              <Tooltip title="Bananas">
                <Avatar src={banana} alt="Bananas" variant="square" />
              </Tooltip>
              <Tooltip title="Avocados">
                <Avatar src={avocado} alt="avocados" variant="square" />
              </Tooltip>
              <Tooltip title="Nuts">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={nuts} alt="Nuts" variant="square" />
              </Tooltip>
              <Tooltip title="Whole Grains">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="legumes">
                <Avatar sx={{
                  display: { xs: 'none', sm: 'block' },
                }} src={legumes} alt="legumes" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 }}}>
              <Tooltip title="Nuts">
                <Avatar src={nuts} alt="Nuts" variant="square" />
              </Tooltip>
              <Tooltip title="Whole Grains">
                <Avatar src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="legumes">
                <Avatar src={legumes} alt="legumes" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin B6 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                It is readily absorbed in the stomach and small intestine and a limited amount is stored in kidney, liver and brain. A chain of reactions are involved in activation of vitamin B6 in liver and skeletal muscles.
              </ListItem>
            </List>
          </CardContent>
        </Card>

      </Grid>
      <Grid item lg={5} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <VitB6RdaTable />
        </Card>

        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens if you are deficient in vitamin B6?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Deficiencies are rare and are usually related to overall B-complex vitamins deficiencies.
              </ListItem>
            </List>
          </CardContent>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="Whole Grain cereals">
                <Avatar src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="Milk and Milk Products">
                <Avatar src={milk} alt="Milk" variant="square" />
              </Tooltip>
              <Tooltip title="Meat">
                <Avatar src={pork} alt="meat" variant="square" />
              </Tooltip>
              <Tooltip title="Meat">
                <Avatar src={nuts} alt="meat" variant="square" />
              </Tooltip>
              <Tooltip title="Green leafy vegetables (spinach, fenugreek leaves and gogu (gongura)">
                <Avatar src={glv} alt="Green vegetables" variant="square" />
              </Tooltip>
              <Tooltip title="Butter ">
                <Avatar src={butter} alt="Butter" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box> */}
        </Card>

      </Grid>
    </>
  );
}
