import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector, useDispatch } from "react-redux";
import { getCompareData, setCompareData } from "../components/dropdown/CompareDataSlice";
import { getMapKeyValue, setMapKey } from "./MapKeySlice";
import India2014 from "./India2014";
import Carddata from './Carddata';
import Compare1 from "./Compare1";
import { Grid, Typography } from "@mui/material";
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);

function Compare(props) {
 
  const [seriesData, setSeriesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("total");
  const [groupedSeriesData, setGroupedSeriesData] = useState([]);
  const compareData = useSelector(getCompareData);
  const dispatch = useDispatch();
 //console.log("CompareData for compare tab is ", compareData);
  const mapKeyValue = useSelector(getMapKeyValue);
 //console.log("mapKeyValueforcompare", mapKeyValue);
 const [filteredData, setFilteredData] = useState([]);
 useEffect(() => {
  dispatch(setCompareData());
}, [dispatch]);

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  useEffect(() => {
    //console.log("Map Key Value for compare chart :", mapKeyValue);
    // Rest of your code...
  }, [mapKeyValue]);
  useEffect(() => {
    if (compareData && Array.isArray(compareData.results)) {
      const allowedSourceCodes = [
        "N5", "N4", "N3", "N2", "N1", "D4", "D3", "D2", "D1", "CN", "AHSCAB", "LW", 
        "AHS10_11", "AHS11_12", "AHS12_13","NNMBURBAN","NNMB3","NNMBR2006"
      ];
  
      const filteredData = compareData.results.filter(
        (d) => allowedSourceCodes.includes(d.source_code)
      );
      setFilteredData(filteredData);
      const uniqueSourceNames = Array.from(
        new Set(filteredData.map((dataPoint) => dataPoint.source_name))
      );
  
      const generatedSeriesData = uniqueSourceNames.map((sourceName) => {
        const filteredSourceData = filteredData.filter(
          (dataPoint) => dataPoint.source_name === sourceName
        );
        if (filteredSourceData.length <= 1) {
          return null; // Skip series with one or fewer data points
        }
  
        const seriesData = filteredSourceData
          .filter((dataPoint) => {
            if (mapKeyValue) {
              const isMapKeyValueArea = dataPoint.area === dataPoint.area_name && (dataPoint.area_level === 2 || dataPoint.area_level === 1);
              return isMapKeyValueArea && dataPoint.area.toLowerCase() === mapKeyValue.toLowerCase();
            } else {
              return true; // Include all data if mapKeyValue is not set
            }
          })
          .map((dataPoint) => ({
            name: dataPoint.source_name,
            y: parseFloat(dataPoint.value),
            rural: dataPoint.rural,
            urban: dataPoint.urban,
            area: dataPoint.area,
            title: dataPoint.title,
            indicator_name: dataPoint.indicator_name,
            subindicator_name: dataPoint.subindicator_name,
            source_footnote: dataPoint.source_footnote,
            footnote1: dataPoint.footnote1,
            footnote2: dataPoint.footnote2,
            footnote3: dataPoint.footnote3,
            source_name: dataPoint.source_name,
            source_family: dataPoint.source_family,
            
          }));
  
        return {
          name: sourceName,
          data: seriesData,
        };
      }).filter(Boolean);
  
      setSeriesData(generatedSeriesData);
    } else {
      //console.error("Error: compareData or compareData.results is null or undefined");
    }
  }, [compareData, mapKeyValue]);
  
  
  
  useEffect(() => {
    if (seriesData.length > 0) {
      const groupedSeriesData = {};
  
      // Group and merge series data based on area_name
      seriesData.forEach((series) => {
        series.data.forEach((dataPoint) => {
          const sourceName = dataPoint.name;
          if (!groupedSeriesData[sourceName]) {
            groupedSeriesData[sourceName] = [];
          }
  
          // Populate missing fields with default values
          const dataPointWithDefaults = {
            ...dataPoint,
            area:dataPoint.area|| 'NA',
            title: dataPoint.title || 'NA',
            indicator_name: dataPoint.indicator_name || 'NA',
            source_footnote: dataPoint.source_footnote || 'NA',
            footnote1: dataPoint.footnote1 || 'NA',
            footnote2: dataPoint.footnote2 || 'NA',
            footnote3: dataPoint.footnote3 || 'NA',
            subindicator_name: dataPoint.subindicator_name || 'NA',
            title: dataPoint.title || 'NA',
            source_footnote: dataPoint.source_footnote || 'NA',
            footnote1:dataPoint.footnote1|| 'NA',
            footnote2:dataPoint.footnote2|| 'NA',
            footnote3:dataPoint.footnote3|| 'NA',
            source_name: dataPoint.source_name|| 'NA',
            source_family: dataPoint.source_family|| 'NA',
          };
          groupedSeriesData[sourceName].push(dataPointWithDefaults);
        });
      });
  
      const groupedSeriesArray = Object.entries(groupedSeriesData).map(
        ([sourceName, dataPoints]) => ({
          name: sourceName,
          data: dataPoints,
          id: sourceName,
         
        })
      );
  
      //console.log("groupedSeriesData", groupedSeriesArray); // Log the groupedSeriesArray
  
      // Continue with further logic or rendering using the groupedSeriesArray
      setGroupedSeriesData(groupedSeriesArray);
    }
  }, [seriesData]);
 
  const desiredOrder = [ "NFHS-1(1992-93)","NFHS-2(1998-99)","NFHS-3(2005-06)","NFHS-4(2015-16)","CNNS(2016-18)","NFHS-5(2019-21)"];

  // Sort the seriesData array based on the desired order
  const sortedSeriesData = seriesData.sort((a, b) => {
    return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
  });
  const uniqueCategories = Array.from(new Set(sortedSeriesData.flatMap(series => series.data.map(dataPoint => dataPoint.name))));
  const sameSourceFamily = () => {
    if (seriesData.length > 0) {
        const firstSourceFamily = seriesData[0].data[0]?.source_family;
        //console.log("First source_family:", firstSourceFamily);
        const allSameSourceFamily = seriesData.every(series => {
            const seriesName = series.name;
           // console.log(`Source family for ${seriesName}:`);
            const allSame = series.data.every(dataPoint => {
                const dataPointSourceFamily = dataPoint.source_family;
               // console.log(dataPointSourceFamily);
                return dataPointSourceFamily === firstSourceFamily;
            });
            //console.log(`All data points for series ${seriesName} have the same source_family: ${allSame}`);
            return allSame;
        });
       // console.log("All series data have same source_family:", allSameSourceFamily);
        return allSameSourceFamily;
    }
    return false;
};
  let splineSeries = null;
if (sameSourceFamily()) {
  splineSeries = {
    name: "",
    type: "spline",
    lineWidth: 3.5,
    color: "blue", // Adjust the color as needed
    data: uniqueCategories.map(category => {
      // Find the data point for the category in the column series
      const dataPointColumn = sortedSeriesData.flatMap(series => series.data).find(data => data.name === category);

      // If data point is found, create the spline data
      return dataPointColumn ? {
        name: dataPointColumn.name,
        y: selectedOption === "total"
          ? parseFloat(dataPointColumn.y)
          : selectedOption === "rural"
          ? parseFloat(dataPointColumn.rural)
          : parseFloat(dataPointColumn.urban)
      } : null;
    }).filter(data => data !== null), // Filter out null values
  };
}
const chartOptions = {
        chart: {
          type: sameSourceFamily() ? "column" : "line",
           height:"400vh",
          
        },
        tooltip: {
          headerFormat: "",
          useHTML: true,
          formatter: function () {
            if (this.point) {
              //console.log("Data Point:", this.point);
        
              // Use the name of the point as indicator_name
             // const indicatorName = this.point.indicator_name  || 'N/A';
              //const subindicatorName = this.point.subindicator_name || 'N/A';
              const source_name = this.point.name || 'N/A';
             // const area_name = this.point.area_name || 'N/A';
              const value = this.point.y !== undefined ? this.point.y : 'N/A';
        
              return (
                '<b>' + this.series.name + '</b><br><br>' +
                //'indicator_name: ' + indicatorName + '<br>' +
               // 'subindicator_name: ' + subindicatorName + '<br>' +
                'source_name: ' + source_name + '<br>' +
                //'area_name: ' + area_name + '<br>' +
                'Value: ' + value
              );
            } else {
              return 'Data not available';
            }
          },
        },
        
      
        
      
        
      
        series: [
          // Column series
          ...sortedSeriesData.map((series) => ({
            name: series.name,
            type: "column",
            color: getColorForSeries(series.name),
            data: series.data.map((dataPoint) => ({
              name: dataPoint.name,
              y:
                selectedOption === "total"
                  ? parseFloat(dataPoint.y)
                  : selectedOption === "rural"
                  ? parseFloat(dataPoint.rural)
                  : parseFloat(dataPoint.urban),
            })),
          })),
          
          ...(sameSourceFamily() ? [splineSeries] : []),
        ],
        title: {
          text: seriesData.length > 0 ? Array.from(new Set(seriesData.flatMap((item) => item.data.map((dataPoint) => dataPoint.area_name)))).join(', ') : '',
        },
        xAxis: {
          type: 'category',
            crosshair: false,
            labels: {
              //rotation: -180,
              style: {
                fontSize: "12px",
                fontWeight: "bold",
              },
              step: 1,
            },
        },
        yAxis: {
          title: {
            text: `Prevalence of ${seriesData[0]?.data[0]?.indicator_name} in (%)`,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              
            },
          },
          labels: {
            rotation: -90,
            style: {
              fontSize: "10px",
              fontWeight: "bold",
            },
          },
          gridLineWidth:0, // Remove grid lines
          max: 100,
          tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        },
          legend: {
            layout: 'vertical',
            align: 'center',
            verticalAlign: 'center',
            floating: true,
            backgroundColor: '#FFFFFF'
        },
        plotOptions: {
          column: {
            showInLegend: true,
            dataLabels: {
              enabled: true,
              rotation: -90, // Rotate the data label text vertically
              inside:false, // Display data labels inside the column/bar
              align: "top", // Center-align the data labels
              verticalAlign: "top", // Vertically align the data labels
              style: {
                fontSize: "8px",
                fontWeight: "bold",
              },
              // You can customize the formatting of the data labels as needed
              format: "{point.y:.1f}", // Example format: display the y value with one decimal place
            },
          },
        },
       
        credits: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                "separator",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
              ],
            },
          },
        },
    };
    function getColorForSeries(seriesName) {
      if (seriesName === "NFHS-5(2019-21)") {
        //return "#E6A532";
        return "#c4000080";
      } else if (seriesName === "NFHS-4(2015-16)") {
        return "#62968c";
      }
      
      else if (seriesName === "NFHS-3(2005-06)") {
        //return "#9e9ac8";
        return "#945ECF";
        
      }
      else if (seriesName === "NFHS-2(1998-99)") {
        return "#367dc4";
      }
      else if (seriesName === "NFHS-1(1992-93)") {
        return "#ef8d5d";
      }
      else if (seriesName === "CNNS(2016-18)") {
        return "#E6A532";
      }
      else if (seriesName === "DLHS-4(2012-14)") {
        return "#b11217";
      }
      else if (seriesName === "DLHS-3(2005-06)") {
        return "#e17646";
      }
      else if (seriesName === "DLHS-2(2002-04)") {
        return "#dbbe5e";
      }
      else if (seriesName === "DLHS-1(1998-99)") {
        return "#caddab";
      }
      else if (seriesName === "AHS-CAB(2012-13)") {
        return "#fea848";
      }
      else if (seriesName === "AHS(2010-11)") {
        return "#af69a0";
      }
      else if (seriesName === "AHS-(2011-12)") {
        return "#349db4";
      }
      else if (seriesName === "AHS-(2012-13)") {
        return "#724d8c";
      }
      else if (seriesName === "LASI Wave-1(2017-18)") {
        return "#a1cbbc";
      }
      else if (seriesName.trim() === "NNMB-Urban Nutrition Report (2017)") {
        
        return "#8a5479";
      }
      else if (seriesName.trim() === "NNMB-Rural-Third repeat survey (2011-12)") {
        
        return "#887cc2";
      }
      else if (seriesName.trim() === "NNMB Rural Survey (2005-06)") {
        
        return "#bf95b6";
      }
      
      return "#fea848";
    }
   // const { indicator_id } = props;
    //console.log("Props:", props);

    if (filteredData.length > 0 && filteredData[0]?.indicator_id === "L1_13") {
      // Render only Carddata with full width when indicator_id is "L1_13"
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <div align="center" style={{ padding: "25px" }}>
            <div id="custom">
              <input
                type="radio"
                name="dataOption"
                value="total"
                checked={selectedOption === "total"}
                onChange={handleRadioChange}
              />
              <label htmlFor="total"><b>Total</b></label>
              <input
                type="radio"
                name="dataOption"
                value="rural"
                checked={selectedOption === "rural"}
                onChange={handleRadioChange}
              />
              <label htmlFor="rural"><b>Rural</b></label>
              <input
                type="radio"
                name="dataOption"
                value="urban"
                checked={selectedOption === "urban"}
                onChange={handleRadioChange}
              />
              <label htmlFor="urban"><b>Urban</b></label>
            </div>
            </div>
            <Carddata selectedOption={selectedOption} />
          </Grid>
          
        </Grid>
      
      );
    }
    
    return (
      <Grid container spacing={3}> 
       
        <Grid item xs={6}> {/* First Half - Compare Component */}
          <div align="center" style={{ padding: "25px" }}>
            <div id="custom">
              <input
                type="radio"
                name="dataOption"
                value="total"
                checked={selectedOption === "total"}
                onChange={handleRadioChange}
              />
              <label htmlFor="total"><b>Total</b></label>
              <input
                type="radio"
                name="dataOption"
                value="rural"
                checked={selectedOption === "rural"}
                onChange={handleRadioChange}
              />
              <label htmlFor="rural"><b>Rural</b></label>
              <input
                type="radio"
                name="dataOption"
                value="urban"
                checked={selectedOption === "urban"}
                onChange={handleRadioChange}
              />
              <label htmlFor="urban"><b>Urban</b></label>
            </div>
            {groupedSeriesData.length > 0 ? (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    ) : (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px' }}>
      {seriesData.some(series => series.name.trim() === 'NNMB-Urban Nutrition Report (2017)') ? (
        selectedOption === 'urban' ? (
          <p>
            Please select one of the states from the map.
          </p>
        ) : (
          <p>
            <span style={{ color: '#8a5479', fontWeight: 'bold' }}>
              {seriesData.find(series => series.name.trim() === 'NNMB-Urban Nutrition Report (2017)').name.trim()}
            </span>
            {' is comparable only with urban values. Please select the "Urban" option above.'}
          </p>
        )
      ) : seriesData.some(series => series.name.trim() === 'NNMB-Rural-Third repeat survey (2011-12)') ? (
        selectedOption === 'rural' ? (
          <p>
            Please select one of the states from the map.
          </p>
        ) : (
          <p>
            <span style={{ color: '#887cc2', fontWeight: 'bold' }}>
              {seriesData.find(series => series.name.trim() === 'NNMB-Rural-Third repeat survey (2011-12)').name.trim()}
            </span>
            {' is comparable only with Rural values. Please select the "Rural" option above.'}
          </p>
        )
      ) : seriesData.some(series => series.name.trim() === 'NNMB Rural Survey (2005-06)') ? (
        selectedOption === 'rural' ? (
          <p>
            Please select one of the states from the map.
          </p>
        ) : (
          <p>
            <span style={{ color: '#bf95b6', fontWeight: 'bold' }}>
              {seriesData.find(series => series.name.trim() === 'NNMB Rural Survey (2005-06)').name.trim()}
            </span>
            {' is comparable only with Rural values. Please select the "Rural" option above.'}
          </p>
        )
      ) : (
        <p>
          Please select one of the states from the map.
        </p>
      )}
    </div>
    
    
    )}
    
   
  </div>
        </Grid>
        <Grid item xs={6}>
          <Carddata selectedOption={selectedOption} />
        </Grid>
        <Grid item xs={12}>
          <Compare1 selectedOption={selectedOption} />
        </Grid>
      </Grid>
    );
    
                } 


export default Compare;
