import React from "react";
import { AvatarGroup, Badge, Box, Chip, IconButton, Tooltip, badgeClasses } from "@mui/joy";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import {
  ArrowRight,
  ArrowRightOutlined,
  ArrowRightSharp,
  Check,
  CircleRounded,
  FavoriteBorder,
} from "@mui/icons-material";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import bloodCells from "../../../../../assets/images/icons/vitamins/Functions/bloodCells.png"
import energy2 from "../../../../../assets/images/icons/vitamins/vitaminb1/energy2.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import fish from "../../../../../assets/images/icons/vitamins/vitaminb3/fish.png";
import meat from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import poultry from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B6/poultry.png";
import egg from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/egg.png";
import milk from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milk.png";
import milkProduct from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milkProduct.png";
import fortifiedCereals from "../../../../../assets/images/icons/vitamins/vitaminD/fortifiedCereals.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import yeast from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B7/yeast.png";
import anxiety from "../../../../../assets/images/icons/vitamins/vitaminb2/anxiety.png";
import conjunctivitis from "../../../../../assets/images/icons/vitamins/vitaminb2/conjunctivitis.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/vitaminb2/dermatitis.png";
import mouthsores from "../../../../../assets/images/icons/vitamins/vitaminb2/mouthsores.png";
import VitB12RdaTable from "./RdaAndEarTables/VitB12RdaTable";

import fatigue from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png";
import anemia from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB12/anemia.png";
import dizziness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB12/dizziness.png";
import pale_skin from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/pale_skin.png";
import numbness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/numbness.png";
import nerve_damage from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/nerve_damage.png";


export default function Vitaminb12() {
  return (
    <>
      <Grid item lg={6} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            py: 1.5
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of vitamin B12?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="blood cells" src={bloodCells} />
                </ListItemAvatar>
                <ListItemText primary="Vitamin B12 is required for the formation of red blood cells and DNA synthesis." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={brain} />
                </ListItemAvatar>
                <ListItemText primary="It is necessary for the function and development of brain and nerve cells." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the sources of vitamin B12?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Good sources of vitamin B12 include fish, meat, poultry, eggs, and dairy products.
                In addition, fortified breakfast cereals and fortified nutritional yeasts are rich sources of vitamin B12 that have high bioavailability.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.5, sm: 1 }}}>
              <Tooltip title="Fish">
                <Avatar src={fish} size="lg" alt="Fish" variant="square" />
              </Tooltip>
              <Tooltip title="meat">
                <Avatar src={meat} alt="meat" variant="square" />
              </Tooltip>
              <Tooltip title="Poultry">
                <Avatar src={poultry} alt="poultry" variant="square" />
              </Tooltip>
              {/* <Tooltip title="Eggs">
                <Avatar src={egg} alt="Eggs" variant="square" />
              </Tooltip> */}
              <Tooltip title="Milk">
                <Avatar src={milk} alt="Milk" variant="square" />
              </Tooltip>
              <Tooltip title="Milk Product ">
                <Avatar src={milkProduct} alt="Curd" variant="square" />
              </Tooltip>
              <Tooltip title="fortified cereal ">
                <Avatar src={fortifiedCereals} alt="cereal" variant="square" />
              </Tooltip>
              <Tooltip title="fortified nutritional yeasts">
                <Avatar src={yeast} alt="yeast" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens if you are deficient in vitamin B12?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Symptoms of B12 deficiency include fatigue, weakness, dizziness, pale skin, and tingling sensations in the hands and feet. Long-term deficiency can lead to serious complications like anemia and neurological problems.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="fatigue">
                <Avatar src={fatigue} size="lg" alt="fatigue" variant="square" />
              </Tooltip>
              <Tooltip title="dizziness">
                <Avatar src={dizziness} alt="dizziness" variant="square" />
              </Tooltip>
              <Tooltip title="pale skin">
                <Avatar src={pale_skin} alt="pale skin" variant="square" />
              </Tooltip>
              <Tooltip title="tingling sensations in the hands and feet">
                <Avatar src={numbness} alt="tingling sensations in the hands and feet" variant="square" />
              </Tooltip>
              <Tooltip title="anemia">
                <Avatar src={anemia} alt="anemia" variant="square" />
              </Tooltip>
              <Tooltip title="neurological problem">
                <Avatar src={nerve_damage} alt="neurological problem" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item lg={6} md={5} sm={12}>
      <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: -1, sm: 1 },
            py: 1,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin B12 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                It is absorbed primarily in the ileum mediated by the intrinsic factor secreted from parietal cells in the stomach. Excess vitamin b12 is stored in the liver within vitamin B12 protein complex.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 1, sm: 0 },
          }}
        >
          <VitB12RdaTable />
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin B12 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                It is absorbed primarily in the ileum mediated by the intrinsic factor secreted from parietal cells in the stomach. Excess vitamin b12 is stored in the liver within vitamin B12 protein complex.
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens if you are deficient in vitamin B12?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Symptoms of B12 deficiency include fatigue, weakness, dizziness, pale skin, and tingling sensations in the hands and feet. Long-term deficiency can lead to serious complications like anemia and neurological problems.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="fatigue">
                <Avatar src={fatigue} size="lg" alt="fatigue" variant="square" />
              </Tooltip>
              <Tooltip title="dizziness">
                <Avatar src={dizziness} alt="dizziness" variant="square" />
              </Tooltip>
              <Tooltip title="pale skin">
                <Avatar src={pale_skin} alt="pale skin" variant="square" />
              </Tooltip>
              <Tooltip title="tingling sensations in the hands and feet">
                <Avatar src={numbness} alt="tingling sensations in the hands and feet" variant="square" />
              </Tooltip>
              <Tooltip title="anemia">
                <Avatar src={anemia} alt="anemia" variant="square" />
              </Tooltip>
              <Tooltip title="neurological problem">
                <Avatar src={nerve_damage} alt="neurological problem" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>

    </>
  );
}