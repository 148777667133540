// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..

import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import { Avatar, Grid, ListItemAvatar, ListItemText } from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import DnaRna from "../../../../../assets/images/icons/vitamins/Functions/DnaRna.png";
import nervousSystem from "../../../../../assets/images/icons/vitamins/Functions/nervousSystem.png";
import nutrientToEnergy from "../../../../../assets/images/icons/vitamins/Functions/nutrientToEnergy.png";
import hormone from "../../../../../assets/images/icons/vitamins/Functions/hormone1.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import meat from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import fish from "../../../../../assets/images/icons/vitamins/vitaminb3/fish.png";
import fortifiedCereals from "../../../../../assets/images/icons/vitamins/vitaminD/fortifiedCereals.png";
import nuts from "../../../../../assets/images/icons/vitamins/vitaminb3/nuts.png";
import dementia from "../../../../../assets/images/icons/vitamins/vitaminb3/dementia.png";
import diarrhea from "../../../../../assets/images/icons/vitamins/vitaminb3/diarrhea.png";
import dermatitis from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB2/Dermatitis.png";

import NiacinRdaTable from "./RdaAndEarTables/NiacinRdaTable";

export default function Vitaminb3() {
    return (
        <>
            <Grid item lg={7} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        py: 1
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of vitamin B3?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="nutrient to energy" src={nutrientToEnergy} />
                                </ListItemAvatar>
                                <ListItemText primary="Niacin aids in the conversion of nutrients into energy by assisting in the breakdown of carbohydrates, proteins, fats." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="DNA RNA" src={DnaRna} />
                                </ListItemAvatar>
                                <ListItemText primary="It helps in synthesis of protein, fats and five-carbon sugars needed for the formation of DNA and RNA" />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={nervousSystem} />
                                </ListItemAvatar>
                                <ListItemText primary="It is necessary for proper nervous system function and maintenance of healthy skin" />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="hormoneb" src={hormone} />
                                </ListItemAvatar>
                                <ListItemText primary="It is involved in production of certain hormones." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>

                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the sources of vitamin B3?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Foods rich in niacin include meat (such as poultry, beef, and pork), fish, legumes, whole grains, nuts, and fortified cereals.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="Meat (such as poultry, beef and pork)">
                                <Avatar src={meat} alt="meat" variant="square" />
                            </Tooltip>
                            <Tooltip title="Fish">
                                <Avatar src={fish} alt="Fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="Legumes">
                                <Avatar src={legumes} alt="Legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="Whole Grains">
                                <Avatar src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
                            </Tooltip>
                            <Tooltip title="Butter ">
                                <Avatar src={nuts} alt="Butter" variant="square" />
                            </Tooltip>
                            <Tooltip title="Green leafy vegetables (spinach, fenugreek leaves and gogu (gongura)">
                                <Avatar src={fortifiedCereals} alt="Green vegetables" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens if you are deficient in vitamin B3?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                            <ListItem>
                                Niacin deficiency can lead to pellagra.
                            </ListItem>
                            <ListItem>
                                Pellagra is characterized by symptoms like dermatitis (skin inflammation), diarrhea, dementia, and in severe cases, death.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                            mb: 1
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="dermatitis">
                                <Avatar src={dermatitis} size="lg" alt="dermatitis" variant="square" />
                            </Tooltip>
                            <Tooltip title="diarrhea">
                                <Avatar src={diarrhea} size="lg" alt="diarrhea" variant="square" />
                            </Tooltip>
                            <Tooltip title="dementia">
                                <Avatar src={dementia} alt="dementia" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
            <Grid item lg={5} md={6} sm={12}>
            <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 0, sm: 1.5 },
                        py: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is vitamin B3 absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="It is readily absorbed in the stomach and small intestine and a limited amount is stored in kidney, liver and brain. Excess niacin is excreted in urine." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 1, sm: 0 },
                    }}
                >
                    <NiacinRdaTable />
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1.5,
                        py: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is vitamin B3 absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="It is readily absorbed in the stomach and small intestine and a limited amount is stored in kidney, liver and brain. Excess niacin is excreted in urine." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        py: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens if you are deficient in vitamin B3?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
                            <ListItem>
                                Niacin deficiency can lead to pellagra.
                            </ListItem>
                            <ListItem>
                                Pellagra is characterized by symptoms like dermatitis (skin inflammation), diarrhea, dementia, and in severe cases, death.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                            mb: 1
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="dermatitis">
                                <Avatar src={dermatitis} size="lg" alt="dermatitis" variant="square" />
                            </Tooltip>
                            <Tooltip title="diarrhea">
                                <Avatar src={diarrhea} size="lg" alt="diarrhea" variant="square" />
                            </Tooltip>
                            <Tooltip title="dementia">
                                <Avatar src={dementia} alt="dementia" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>

            </Grid>
        </>
    );
}
