import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from "@mui/material";

const AlertModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Compare component is best viewed on larger screens.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;

