
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Grid, TableHead } from '@mui/material';

const getBrightness = (color) => {
  if (!color) return 0;

  const hex = color.replace(/^#/, '');
  const num = parseInt(hex, 16);
  const r = (num >> 16) & 0xff;
  const g = (num >> 8) & 0xff;
  const b = num & 0xff;

  return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
};

const getContrastColor = (backgroundColor) => {
  const brightnessThreshold = 0.7;

  if (!backgroundColor) return 'black';

  const brightness = getBrightness(backgroundColor);

  return brightness > brightnessThreshold ? 'black' : 'white';
};

const MyPlateDataTable = ({ data, backgroundColor }) => {

  const attributeLabels = {
    name: 'Food intake of Vegetables* greeen Leafy vegetable (GLV) of 2000Kcal',
    consumedFoods: 'Foods to be consumed raw weight (g/day)',
    energy: '% of Energy from each food group/day',
    totalEnergy: 'Total Energy from each food group/day (Kcal)',
    totalProtein: 'Total Protein from each food group/day (g)',
    totalFat: 'Total Fat from each food group/day (g)',
    totalCabs: 'Total Carbs from each food group/day (g)',
  };

  const chartData = data;
  const rowHeight = '3.9rem';

  // Exclude the first row with the key 'name'
  const filteredChartData = Object.fromEntries(
    Object.entries(chartData).filter(([key]) => key !== 'name')
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ height: rowHeight, backgroundColor: backgroundColor }}>
              <TableCell colSpan={2} style={{ textAlign: 'center', padding: '0.7rem', fontWeight: 'bold',fontSize:'0.95em', color: data.name === "Nuts & Seeds" ? '#333' : getContrastColor(backgroundColor) }}>
                {data && `Intake of ${data.name} for 2000Kcal/day diet`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredChartData &&
              Object.entries(filteredChartData).map(([attribute, value], index) => (
                <TableRow key={attribute} style={{ height: rowHeight }}>
                  <TableCell style={{ padding: '0.7rem', borderBottom: `2px solid ${backgroundColor}`, borderLeft: `1px solid ${backgroundColor}`, fontWeight: 'bold' }}>
                    {attributeLabels[attribute]}
                  </TableCell>
                  <TableCell style={{ padding: '0.7rem', borderBottom: `2px solid ${backgroundColor}`, borderRight: `1px solid ${backgroundColor}`, fontWeight: 'bold' }}>
                    {value}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyPlateDataTable;