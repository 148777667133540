import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import WholeWheat from "../../../../../../assets/FoodSources/Protein/Combined food groups/1_Wheat,whole.jpg"
import LotusSeed from "../../../../../../assets/FoodSources/Protein/Combined food groups/2_LotusSeed.jpg"
import AmaranthSeedBlack from "../../../../../../assets/FoodSources/Protein/Combined food groups/3_AmaranthSeed.jpg"
import Oatmeal from "../../../../../../assets/FoodSources/Protein/Combined food groups/4_oatmeal.jpg"
import RiceBran from "../../../../../../assets/FoodSources/Protein/Combined food groups/5_RiceBran.jpg"
import AmaranthSeedWhite from "../../../../../../assets/FoodSources/Protein/Combined food groups/6_amaranthSeeds.jpg"
import Quinoa from "../../../../../../assets/FoodSources/Protein/Combined food groups/7_quinoa-real.jpg"
import Panivaragu from "../../../../../../assets/FoodSources/Protein/Combined food groups/8_Panivaragu.jpg"
import WheatAndWheatProducts from "../../../../../../assets/FoodSources/Protein/Combined food groups/9_WheatAndWheatProducts.jpg"
import Bajra from "../../../../../../assets/FoodSources/Protein/Combined food groups/10_Bajra.jpg"
import { Typography } from '@mui/material';

export default function TopFoodSources() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: WholeWheat,
    title: 'Wheat, germ',
    author: '29.2 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: LotusSeed,
    title: 'Lotus seeds',
    author: '17.2 per 100gm',
  },
  {
    img: AmaranthSeedBlack,
    title: 'Amaranth seeds, black',
    author: '14.59 per 100gm',
  },
  {
    img: Oatmeal,
    title: 'Oatmeal',
    author: '13.6 per 100gm',
    cols: 2,
  },
  {
    img: RiceBran,
    title: 'Rice, bran',
    author: '13.5 per 100gm',
    cols: 2,
  },
  {
    img: AmaranthSeedWhite,
    title: 'Amaranth seed, pale brown',
    author: '13.27 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Quinoa,
    title: 'Quinoa',
    author: '13.11 per 100gm',
  },
  {
    img: Panivaragu,
    title: 'Panivaragu',
    author: '12.5 per 100gm',
  },
  {
    img: WheatAndWheatProducts,
    title: 'Wheat (semolina, bulgur (broken wheat), whole wheat, wheat flour, vermicelli (roasted), refined flour)',
    author: '11.38-10.36 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: Bajra,
    title: 'Bajra',
    author: '10.96 per 100gm',
  }
];