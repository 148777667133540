// import React from "react";
// import { useNavigate } from "react-router-dom";
// import constructionImage from "../../../../assets/images/"; // Replace with the path to your construction image

// const UnderConstructionPage = () => {
//     const navigate = useNavigate(); // Update the hook

//     const handleNavigateHome = () => {
//       navigate("/"); // Use navigate instead of history.push
//     };

//   return (
//     <div style={{ textAlign: "center" }}>
//       <img
//         src={constructionImage}
//         alt="Under Construction"
//         style={{ width: "100%", maxWidth: "600px", margin: "20px auto" }}
//       />
//       <p>This page is under construction. Please check back later!</p>
//       <button onClick={handleNavigateHome}>Go to Home</button>
//     </div>
//   );
// };

// export default UnderConstructionPage;

import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import constructionImage from "../../../../assets/images/UnderConstructionImage.jpg"; // Replace with the path to your construction image

const UnderConstruction = () => {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };

  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={constructionImage}
        alt="Under Construction"
        style={{ width: "100%", maxWidth: "800px", margin: "20px auto" }} // Adjust the width
      />
      <Typography variant="body1" style={{ color: "#5b698f", marginBottom: "20px" }}>
        This page is under construction. Please check back later!
      </Typography>
      <Button
        variant="contained"
        onClick={handleNavigateHome}
        style={{
          backgroundColor: "#ff794c",
          color: "#feddc1",
          fontWeight: "bold",
          marginTop: "10px",
        }}
      >
        Go to Home
      </Button>
    </div>
  );
};

export default UnderConstruction;