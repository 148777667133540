// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment 
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..




import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import {
    Avatar,
    Grid,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import MagnesiumRdaTable from "./RdaAndEarTables/MagnesiumRdaTable";
import fatigue from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminA/fatigue.png';
import loss_appetite from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/loss_appetite.png';
import numbness from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminE/numbness.png';
import vomit from '../../../../../assets/images/icons/vitamins/vitaminb5/vomit.png';
import seizure from '../../../../../assets/images/icons/minerals/Deficiency/seizure.png';
import muscle_cramps from '../../../../../assets/images/icons/minerals/Deficiency/muscle_cramps.png';
import rapid_heartbeat from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png';
import bone from '../../../../../assets/images/icons/minerals/Functions/bone.png';
import coenzyme from '../../../../../assets/images/icons/minerals/Functions/coenzyme.png';
import nerve_impulse from '../../../../../assets/images/icons/minerals/Functions/nerve_impulse.png';
import muscle_contraction from '../../../../../assets/images/icons/minerals/Functions/muscle_contraction.png';
import spinach from "../../../../../assets/FoodSources/Minerals/Sources/spinach.png";
import nuts from "../../../../../assets/FoodSources/Minerals/Sources/nuts.png";
import seeds from "../../../../../assets/FoodSources/Minerals/Sources/seeds.png";
import legumes from "../../../../../assets/FoodSources/Minerals/Sources/legumes.png";
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import poultry from "../../../../../assets/FoodSources/Minerals/Sources/poultry.png";
import beef from "../../../../../assets/FoodSources/Minerals/Sources/beef.png";
import avocado from "../../../../../assets/FoodSources/Minerals/Sources/avocado.png";
import dark_chocolate from "../../../../../assets/FoodSources/Minerals/Sources/dark_chocolate.png";




export default function Magnesium() {
    return (
        <>
            <Grid item lg={7} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                    }}
                >
                    <Typography level="h5" fontWeight="lg" align="start">
                        What are the functions of magnesium?
                    </Typography>
                    <CardContent>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={muscle_contraction} />
                                </ListItemAvatar>
                                <ListItemText primary="Magnesium along with sodium helps in regulating muscle contraction and relaxation." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={nerve_impulse} />
                                </ListItemAvatar>
                                <ListItemText primary="It is also required for the maintenance of electrical impulse between nerves and muscle membranes." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={coenzyme} />
                                </ListItemAvatar>
                                <ListItemText primary="It is essential for the functioning of many enzymes." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={bone} />
                                </ListItemAvatar>
                                <ListItemText primary="Magnesium is also an important mineral involved in bone formation." />
                            </ListItem>
                        </List>

                    </CardContent>

                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 1, sm:2 }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the sources of magnesium?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Rich food sources of magnesium include green leafy vegetables such as spinach, nuts and seeds such as almonds, cashews, pumpkin seeds, legumes, whole grains, fish, poultry, beef, avocados, dark chocolate.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="spinach">
                                <Avatar src={spinach} size="lg" alt="spinach" variant="square" />
                            </Tooltip>
                            <Tooltip title="nuts">
                                <Avatar src={nuts} alt="nuts" variant="square" />
                            </Tooltip>
                            <Tooltip title="seeds">
                                <Avatar src={seeds} alt="seeds" variant="square" />
                            </Tooltip>
                            <Tooltip title="legumes">
                                <Avatar src={legumes} alt="legumes" variant="square" />
                            </Tooltip>
                            <Tooltip title="whole grain cereals">
                                <Avatar src={wholegrains} alt="whole grain cereals" variant="square" />
                            </Tooltip>
                            <Tooltip title="fish ">
                                <Avatar src={fish} alt="fish" variant="square" />
                            </Tooltip>
                            <Tooltip title="poultry ">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={poultry} alt="poultry" variant="square" />
                            </Tooltip>
                            <Tooltip title="beef">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={beef} alt="beef" variant="square" />
                            </Tooltip>
                            <Tooltip title="avocado">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={avocado} alt="avocado" variant="square" />
                            </Tooltip>
                            <Tooltip title="dark_chocolate">
                                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={dark_chocolate} alt="dark_chocolate" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>

                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
                            <Tooltip title="poultry ">
                                <Avatar src={poultry} alt="poultry" variant="square" />
                            </Tooltip>
                            <Tooltip title="beef">
                                <Avatar src={beef} alt="beef" variant="square" />
                            </Tooltip>
                            <Tooltip title="avocado">
                                <Avatar src={avocado} alt="avocado" variant="square" />
                            </Tooltip>
                            <Tooltip title="dark_chocolate">
                                <Avatar src={dark_chocolate} alt="dark_chocolate" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>


                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        display: { xs: 'block', sm: 'none' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is magnesium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Magnesium is absorbed in the small intestine by passive diffusion." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        display: { xs: 'block', sm: 'none' },
                        mt: { xs: 1, sm:0 }
                    }}
                >
                    <MagnesiumRdaTable />
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 1, sm:2 }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What happens when you are deficient in magnesium?
                        </Typography>
                        <List sx={{ textAlign: "left" }}>
                            <ListItem>
                                Deficiency of magnesium occurs from a prolonged low magnesium diet, malabsorption, alcohol abuse or the usage of certain drugs such as diuretics, proton pump inhibitors, and antibiotics.
                            </ListItem>
                            <ListItem>
                                Signs of magnesium deficiency are fatigue, weakness, poor appetite, nausea, vomiting, tingling or numbness in skin, muscle cramps, seizures, and an irregular heart rate.
                            </ListItem>
                        </List>
                    </CardContent>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1
                        }}
                    >
                        <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.5, sm: 1 }}}>
                            <Tooltip title="fatigue">
                                <Avatar src={fatigue} size="lg" alt="fatigue" variant="square" />
                            </Tooltip>
                            <Tooltip title="poor appetite">
                                <Avatar src={loss_appetite} alt="poor appetite" variant="square" />
                            </Tooltip>
                            <Tooltip title="vomiting">
                                <Avatar src={vomit} alt="vomiting" variant="square" />
                            </Tooltip>
                            <Tooltip title="tingling or numbness in skin">
                                <Avatar src={numbness} alt="tingling or numbness in skin" variant="square" />
                            </Tooltip>
                            <Tooltip title="muscle cramps ">
                                <Avatar src={muscle_cramps} alt="muscle cramps" variant="square" />
                            </Tooltip>
                            <Tooltip title="seizures">
                                <Avatar  src={seizure} alt="seizures" variant="square" />
                            </Tooltip>
                            <Tooltip title="irregular heart rate">
                                <Avatar  src={rapid_heartbeat} alt="irregular heart rate" variant="square" />
                            </Tooltip>
                        </AvatarGroup>
                    </Box>
                </Card>
            </Grid>
            <Grid item lg={5} md={6} sm={12}>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <MagnesiumRdaTable />
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: 1,
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            How is magnesium absorbed in the body?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Magnesium is absorbed in the small intestine by passive diffusion." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        //  width: 320,
                        // to make the card resizable
                        overflow: "auto",
                        resize: "horizontal",
                        mt: { xs: 0, sm:1 }
                    }}
                >
                    <CardContent>
                        <Typography level="h5" fontWeight="lg" align="start">
                            What are the interactions of magnesium with other nutrients?
                        </Typography>
                        <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Magnesium interacts with calcium to hinder its absorption." />
                            </ListItem>

                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}



