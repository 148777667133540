import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import CatFishEggs from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/1_CatFishEggs.jpg";
import Tuna from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/2_Tuna.jpg";
import Stingray from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/3_Stingray.jpg";
import Korka from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/4_Korka.jpg";
import MilkFish from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/5_MilkFish.jpg";
import SharkHammerHead from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/6_SharkHammerHead.JPG";
import KiteFish from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/7_KiteFish.jpg";
import Singhi from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/8_Singhi.jpg";
import RedSnapper from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/9_RedSnapper.jpg";
import Silan from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/10_Silan.jpg";
import { Typography } from "@mui/material";

export default function FishesAndOtherSeeFoods() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))!important",
        border: "1px solid #ccc",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "0.em", whiteSpace: "break-spaces", fontWeight: "bold" }}
              >
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: CatFishEggs,
    title: "Eggs, cat fish",
    author: "24.68 per 100gm",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Tuna,
    title: "Tuna",
    author: "24.5 per 100gm",
  },
  {
    img: Stingray,
    title: "Stingray",
    author: "23.98 per 100gm",
  },
  {
    img: Korka,
    title: "Korka",
    author: "23.72 per 100gm",
    cols: 2,
  },
  {
    img: MilkFish,
    title: "Milk fish",
    author: "23.66 per 100gm",
    cols: 2,
  },
  {
    img: SharkHammerHead,
    title: "Shark, hammer head",
    author: "23.4 per 100gm",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: KiteFish,
    title: "Kite fish",
    author: "23.07 per 100gm",
  },
  {
    img: Singhi,
    title: "Singhi",
    author: "22.8 per 100gm",
  },
  {
    img: RedSnapper,
    title: "Red snapper",
    author: "22.79 per 100gm",
    rows: 2,
    cols: 2,
  },
  {
    img: Silan,
    title: "Silan",
    author: "22.72 per 100gm",
  },
];
