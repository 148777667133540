import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import WaterChestnuts from "../../../../../../assets/FoodSources/Protein/Vegetables/1_WaterChestnuts.jpg"
import DoubleBeans from "../../../../../../assets/FoodSources/Protein/Vegetables/2_doubleBeans.jpg"
import Sundakkai from "../../../../../../assets/FoodSources/Protein/Vegetables/3_Sundakkai.png"
import RedgramTender from "../../../../../../assets/FoodSources/Protein/Vegetables/4_RedgramTender.jpg"
import BeansScarletRunner from "../../../../../../assets/FoodSources/Protein/Vegetables/5_beansScarletRunner.png"
import Peas from "../../../../../../assets/FoodSources/Protein/Vegetables/6_Peas.jpg"
import JackSeeds from "../../../../../../assets/FoodSources/Protein/Vegetables/7_jackSeeds.jpg"
import LotusStem from "../../../../../../assets/FoodSources/Protein/Vegetables/8_LotusStem.jpg"
import BroadBean from "../../../../../../assets/FoodSources/Protein/Vegetables/9_BroadBean.jpg"
import FieldbeanTender from "../../../../../../assets/FoodSources/Protein/Vegetables/10_FieldbeanTender.jpg"
import { Typography } from '@mui/material';

export default function Vegetables() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: WaterChestnuts,
    title: 'water chestnut, dry',
    author: '13.4 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: DoubleBeans,
    title: 'Double beans',
    author: '8.3 per 100gm',
  },
  {
    img: Sundakkai,
    title: 'Sundakai, dry',
    author: '8.3 per 100gm',
  },
  {
    img: RedgramTender,
    title: 'Red gram, tender, fresh',
    author: '8.09 per 100gm',
    cols: 2,
  },
  {
    img: BeansScarletRunner,
    title: 'Beans, scarlet runner',
    author: '7.4 per 100gm',
    cols: 2,
  },
  {
    img: Peas,
    title: 'Peas, fresh',
    author: '7.25 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: JackSeeds,
    title: 'Jack, fruit seed',
    author: '6.6 per 100gm',
  },
  {
    img: LotusStem,
    title: 'Lotus stem, dry',
    author: '4.1 per 100gm.4',
  },
  {
    img: BroadBean,
    title: 'Broad beans',
    author: '3.85 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: FieldbeanTender,
    title: 'field beans, tender, lean',
    author: '3.71 per 100gm',
  }
];