import React, { useMemo, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCMap from "highcharts/modules/map";
import HCDrilldown from "highcharts/modules/drilldown";
import HCExport from "highcharts/modules/exporting";
import InMap2014 from "../../../maps/2022/india1.geo.json";
import InMap2019 from "../../../maps/2022/india-highcharts.json";
import InMap2013 from "../../../maps/india2013.geo.json";
import InMap1998 from "../../../maps/india1998.geo.json";
import pptxgen from "pptxgenjs";
import html2canvas from "html2canvas"; // Import html2canvas

import { useSelector, useDispatch } from "react-redux";
import { getMapKeyValue, setMapKey } from "./MapKeySlice";
import { getApiData } from "../components/dropdown/dropdownSlice";
import BarChart from "./BarChart";
import DataTable from "../components/DataTable";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FootNotes from "./FootNotes";

//import "./helpers/proj4-module";

// Load Highcharts modules
HCMap(Highcharts);
HCDrilldown(Highcharts);
HCExport(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/export-data"); // Importing the export-data module
require("highcharts/modules/offline-exporting");
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const India2014 = () => {
  const dispatch = useDispatch();
  const apiData = useSelector(getApiData);
  const mapKeyValue = useSelector(getMapKeyValue);
  console.log("mapKeyValue", mapKeyValue);

  const filteredData = useMemo(() => {
    if (apiData?.results) {
      if (
        apiData.results.some((data) => data.sourcearea_level === 2 && data.area === data.area_name)
      ) {
        // Filter based on sourcearea_level === 2 and area = area_name
        return apiData.results.filter(
          (data) => data.sourcearea_level === 2 && data.area === data.area_name
        );
      } else {
        // Filter based on sourcearea_level === 3 and area === "Assam"
        return apiData.results.filter((data) => data.sourcearea_level === 3);
      }
    } else {
      return [];
    }
  }, [
    apiData && apiData.results && apiData.results.map((item) => [item.area, item.sourcearea_level]),
  ]);

  const filteredDataByLevel2 = filteredData.filter(
    (data) => data.sourcearea_level === 2 && data.area === data.area_name
  );
  const filteredDataByLevel3 = filteredData.filter(
    (data) => data.sourcearea_level === 3 && data.area === data.area_name
  );

  if (filteredDataByLevel2.length === 0 && filteredDataByLevel3.length === 0) {
    return null;
  }

  const filteredStateData = JSON.parse(
    JSON.stringify(filteredDataByLevel2.length > 0 ? filteredDataByLevel2 : filteredDataByLevel3)
  );
  // parse the data back to an array of objects
  // var parsedData = data.map((element) => element.value);
  // console.log(`filtereddata ${JSON.stringify(data)}`);

  // console.log("filteredData", filteredData);

  if (
    !mapKeyValue ||
    (mapKeyValue !== "india" &&
      !filteredData.find((d) => d.area_name.toLowerCase() === mapKeyValue))
  ) {
    // return <Spinner />;
    return <div>No data available</div>;
  }

  let InMap;

  if (filteredData.some((data) => data.source_code === "N5")) {
    InMap = InMap2019;
    // InMap = InMapNFHS5;
  } else if (
    filteredData.some((data) => ["N4", "D4", "CN", "LW", "NNMBURBAN"].includes(data.source_code))
  ) {
    InMap = InMap2014;
  } else if (filteredData.some((data) => ["D1", "N1", "N2"].includes(data.source_code))) {
    InMap = InMap1998;
  } else if (
    filteredData.some((data) =>
      [
        "N3",
        "D2",
        "D3",
        "AHSCAB",
        "AHS12_13",
        "AHS10_11",
        "AHS11_12",
        "NNMB3",
        "NNMBT",
        "NNMBR2006",
      ].includes(data.source_code)
    )
  ) {
    InMap = InMap2013;
  } else {
    InMap = "";
  }

  let sourceFootnote = "";
  let footnote1 = "";
  let footnote2 = "";
  let footnote3 = "";
  let title = "";
  let subindicator_name = "";
  let boundaries = "";
  let description = "";
  let image = "";
  let alt_name = "";

  if (mapKeyValue === "india") {
    // Fetch sourceFootnote, footnote1, footnote2, footnote3, title, subindicator_name directly from filteredData
    sourceFootnote = filteredData[0]?.source_footnote;
    footnote1 = filteredData[0]?.footnote1;
    footnote2 = filteredData[0]?.footnote2;
    footnote3 = filteredData[0]?.footnote3;
    title = filteredData[0]?.title;
    subindicator_name = filteredData[0]?.subindicator_name;
    boundaries = filteredData[0]?.boundaries;
    description = filteredData[0]?.indicator_description;
    image = filteredData[0]?.indicator_icon;
    alt_name = filteredData[0]?.indicator_name;
  } else {
    const filteredDataByMapKey = filteredData.filter(
      (d) => d.area && d.area.toLowerCase() === mapKeyValue.toLowerCase()
    );
    sourceFootnote = filteredDataByMapKey[0]?.source_footnote;
    footnote1 = filteredDataByMapKey[0]?.footnote1;
    footnote2 = filteredDataByMapKey[0]?.footnote2;
    footnote3 = filteredDataByMapKey[0]?.footnote3;
    title = filteredDataByMapKey[0]?.title;
    subindicator_name = filteredDataByMapKey[0]?.subindicator_name;
    boundaries = filteredDataByMapKey[0]?.boundaries;
    description = filteredData[0]?.indicator_description;
    image = filteredData[0]?.indicator_icon;
    alt_name = filteredData[0]?.indicator_name;
  }

  let data = Highcharts.geojson(InMap);
  data = data.map(function (el) {
    const areaName = el.properties?.["hc-key"] || "";
    const filteredDataItem = filteredStateData.find(
      (d) => d.area && d.area.toLowerCase() === areaName.toLowerCase()
    );
    return {
      ...el,
      drilldown: areaName,
      value: filteredDataItem ? filteredDataItem.value : 0,
      name: filteredDataItem ? filteredDataItem.area_name : el.name,
      subindicator_name: filteredDataItem
        ? filteredDataItem.subindicator_name
        : el.subindicator_name,
    };
  });
  let captionText =
    "Note:" + "\n" + sourceFootnote + "\n" + footnote1 + "\n" + footnote2 + "\n" + footnote3;
  let hasDrilldownOccurred = false;
  const mapOptions = {
    chart: {
      height: "600vh",

      
      events: {
        drilldown: async function (e) {
        
          if (!e.seriesOptions) {
            var chart = this,
              mapKey = e.point.drilldown.toLowerCase(),
              // Handle error, the timeout is cleared on success
              fail = setTimeout(function () {
                if (!Highcharts.maps[mapKey]) {
                  if (chart && chart.loading) {
                    chart.showLoading('<i class="icon-frown"></i> Failed loading ' + e.point.name);
                    fail = setTimeout(function () {
                      if (chart && chart.loading) {
                        chart.hideLoading();
                      }
                    }, 1000);
                  }
                }
              }, 3000);
        
            // Show the spinner
            if (chart && chart.loading) {
              chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>'); // Font Awesome spinner
            }
        
            // Load the drilldown map
            // setMapKey(mapKey);
            // dispatch(setMapKey(mapKey));
            if (!hasDrilldownOccurred) {
            const districtData = filteredData.filter(
              (d) => d.area && d.area.toLowerCase() === mapKeyValue.toLowerCase()
            );
        
            // if (!districtData.some((data) => data.area_name.toLowerCase() === mapKeyValue.toLowerCase())) {
            //   alert("No data available for this state.");
            //   return false;
            // }
        
            if (e.point.value === null) {
              if (chart && chart.loading) {
                chart.hideLoading();
                clearTimeout(fail);
              }
              return false; // Disable the drilldown action
            }
        
            // Load the drilldown map
            // setMapKey(mapKey);
            dispatch(setMapKey(mapKey));
        
            let mapPath;
        
            if (districtData.some((data) => data.source_code === "N5")) {
              mapPath = "5";
            } else if (
              districtData.some(
                (data) =>
                  data.source_code === "N4" ||
                  data.source_code === "D4" ||
                  data.source_code === "AHSCAB" ||
                  data.source_code === "AHS10_11" ||
                  data.source_code === "AHS11_12" ||
                  data.source_code === "AHS12_13"
              )
            ) {
              mapPath = "4";
            } else if (
              districtData.every(
                (data) => data.source_code === "D4" && data.area_level !== 3 && data.value !== "NA"
              )
            ) {
              return false;
            } else {
              return false;
            }
            if (
              districtData.some(
                (data) =>
                  data.source_code === "D4" &&
                  (data.indicator_id === "L1_1" || data.indicator_id === "L1_2" || data.indicator_id === "L1_3")
              )
            ) {
              mapPath = null; // Set mapPath to null to prevent drilldown for "L1_1", "L1_2", and "L1_3" in "D4"
            }
            const map = await import(
              `../../../maps/2022/NFHS${mapPath}_district_maps/${mapKey}.json`
            );
            const mapData = map.default;
            data = Highcharts.geojson(mapData);
        
            data = data.map((i) => {
              // const district = i.properties.district || "";
              const district = i.properties.NAME1_ || "";
              const filteredDistrictDataItem = districtData.find(
                (d) => d.area_name.toLowerCase() === district.toLowerCase()
              );
              const areaName = filteredDistrictDataItem
                ? filteredDistrictDataItem.area_name
                : i.name;
        
              // Check if the source_code is "D4" and load sourcearea_name instead of area_name
              let name;
              if (
                filteredDistrictDataItem &&
                (filteredDistrictDataItem.source_code === "D4" ||
                  filteredDistrictDataItem.source_code === "AHSCAB" ||
                  filteredDistrictDataItem.source_code === "AHS10_11" ||
                  filteredDistrictDataItem.source_code === "AHS11_12" ||
                  filteredDistrictDataItem.source_code === "AHS12_13")
              ) {
                name = filteredDistrictDataItem.sourcearea_name;
                subindicator_name = filteredDistrictDataItem.subindicator_name;
              } else {
                name = areaName;
                subindicator_name = filteredDistrictDataItem
                  ? filteredDistrictDataItem.subindicator_name
                  : i.subindicator_name;
              }
              return {
                ...i,
                drilldown: district,
                value: filteredDistrictDataItem ? filteredDistrictDataItem.value : 0,
                name,
                subindicator_name: subindicator_name,
              };
            });
        
            if (chart && chart.loading) {
              chart.hideLoading();
              clearTimeout(fail);
            }
        
            // Add Map Layer
            chart.addSingleSeriesAsDrilldown(e.point, {
              name: e.point.name,
              data: data,
             // borderColor: "white",
              //borderWidth: 0.6,
              dataLabels: {
                enabled: true,
          
                allowOverlap:true,
                crop:true,
                overflow: "right",
                format: "{point.name}",
                style: {
                  fontSize: "10px",
                  verticalAlign: "middle",
                },
              },
            });
            this.setTitle(null, { text: e.point.name });
            // Add Drilldown
            chart.applyDrilldown();
            hasDrilldownOccurred = true; // Set the variable to true after drilldown
        
            // Disable click event to prevent further clicks
            chart.removeEventListener('click');
          }
        }
        
        
       
        },
       
        drillup: async function () {
          dispatch(setMapKey("india"));
          this.setTitle("India", { text: "India" });
          await new Promise((resolve) => setTimeout(resolve, 0)); // Wait for the event loop to continue
         // chart.addEventListener('click', clickHandler);
        },
        click: function () {
          // Reset the map key to "India" when clicking on the map
          dispatch(setMapKey("india"));
          this.setTitle("India", { text: "India" });
        },
      },
    },
    title: {
      align: "center",
      text: title,
      style: {
        fontSize: "16px",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "normal",
        
      },
    },
    subtitle: {
      align: "center",
      text: boundaries,
      style: {
        fontSize: "8px",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "bold",
      },
    },
    exporting: {
      fallbackToExportServer: false,
      width: 800,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            // "separator",
            // {
            //   text: "Download PPTX",
            //   onclick: async function () {
            //     const pptx = new pptxgen();
            //     const slide = pptx.addSlide();

            //     // Create a temporary div to render the chart
            //     const tempDiv = document.createElement("div");
            //     tempDiv.style.width = "800px"; // Adjust dimensions as needed
            //     tempDiv.style.height = "500px";

            //     // Render the chart inside the temporary div
            //     const chart = Highcharts.chart(tempDiv, mapOptions);

            //     // Get the SVG content of the chart
            //     const chartSVG = chart.getSVG();

            //     // Convert the SVG content to a data URL
            //     const chartDataURL = `data:image/svg+xml;base64,${btoa(
            //       unescape(encodeURIComponent(chartSVG))
            //     )}`;

            //     // Add the image to the slide
            //     slide.addImage({
            //       data: chartDataURL,
            //       x: 1,
            //       y: 0,
            //       w: 8,
            //       h: 5,
            //     });

            //     // Save the PowerPoint file

            //     pptx.writeFile("Nutritionatlas2.0");
            //   },
            // },
          ],
        },
      },
    },
    caption: {
      text: captionText,
      align: "center",
      verticalAlign: "bottom",
      useHTML: true,
      style: {
        fontSize: "10px",
        color: "#000000",
        fontFamily: "Verdana, sans-serif",
        fontWeight: "normal",
      },
      position: {
        align: "center",
        verticalAlign: "bottom",
        y: -10,
      },
    },
    credits: {
      text: "Nutrition Atlas 2.0,<br/> Powered by ICMR-National Institute of Nutrition",
      href: "http://nutritionatlasindia.in/",
      position: {
        align: "right",
        x: -10,
        y: -15,
      },
    },
    accessibility: {
      enabled: true,
    },
    colorAxis: {
      reversed: false,
      stops: [
        [0, "#f4cf65"], // First color at 0% position
        [0.33, "#f48e32"], // Second color at 33% position
        [0.66, "#9e3a26"], // Third color at 66% position
        [1, "#3f1020"], // Third color at 100% position
      ],
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      y: 25,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "top",
      },
    },

    tooltip: {
      formatter: function () {
        if (this.point && this.point.value !== null) {
          let value =
            this.point.value !== undefined
              ? this.point.subindicator_name + ": " + this.point.value
              : this.point.drilldown !== undefined
              ? this.point.subindicator_name + ": " + this.point.drilldown
              : "No data";
          return "<b>" + this.point.drilldown + "</b><br>" + value;
        } else {
          return false; // Return false for null points or when this.point is undefined to disable tooltip
        }
      },
      valueSuffix: "NFHS 5",
    },

    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            color: "#EEDD66", // Custom hover color for non-null points
          },
        },
        // Add an event for mouseOver to handle hover for null points
        events: {
          mouseOver: function () {
            if (this.point && this.point.value === null) {
              this.setState({
                color: null, // Set the color to null to disable the hover effect for null points
              });
            }
          },
        },
      },
    },
    series: [
      {
        mapData: filteredData,
        data: data,
        joinBy: ["area_name", "name"],
        name: "India",
        borderColor: "white",
        borderWidth: 0.6,
        dataLabels: {
          enabled: true,

          allowOverlap: true,
          crop: false,
          overflow: "none",
          format: "{point.name}",
          style: {
            fontSize: "10px",
          },
        },
        nullColor: "grey",
      },
    ],
    drilldown: {
      breadcrumbs: {
        buttonTheme: {
          fill: "#f7f7f7",
          padding: 8,
          stroke: "#cccccc",
          "stroke-width": 1,
        },
        floating: true,
        position: {
          align: "right",
        },
        showFullPath: false,
      },
      activeDataLabelStyle: {
        color: "#FFFFFF",
        textDecoration: "none",
        textOutline: "1px #000000",
      },
      drillUpButton: {
        relativeTo: "spacingBox",
        position: {
          x: 0,
          y: 100,
        },
      },
    },
  };

  return (
    <>
      {filteredData && filteredData.length > 0 && filteredData[0].indicator_id === "L1_13" ? (
        // Render only the DataTable component for indicator_id 'L8_1'
        <Grid item xs={12} md={6}>
          <Grid m={1} mb={8}>
            <Item>
              <StyledEngineProvider injectFirst>
                <DataTable mapKey={mapKeyValue} filteredData={filteredData} />
              </StyledEngineProvider>
            </Item>
          </Grid>
        </Grid>
      ) : (
        // Render all other components for other indicator IDs
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Grid>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"mapChart"}
                  options={mapOptions}
                />
              </Grid>
              <Grid container direction="row" style={{ padding: '0.5em', marginTop:"3em" }}>
                  {/* Image Grid */}
                  <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        mx: 'auto',
                        maxWidth: '150px', // Adjust as needed
                      }}
                    >
                      <img src={require(`../../../assets/images/indicatoricons/${image}`)} alt={alt_name} width="100%" style={{ display: 'block', margin: '0 auto' }} />
                    </Box>
                  </Grid>

                  {/* Text Grid */}
                  <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start' }}>
                    <Box
                      sx={{
                        textAlign: 'center',
                        width: '100%', // Adjust as needed
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="h3" style={{ fontSize: '1.5rem', fontFamily: 'Open Sans, sans-serif', fontWeight: 'bold', color: '#FF4081' }}>
                        {alt_name}
                      </Typography>
                      <Typography variant="subtitle2" style={{ fontSize: '1rem', fontFamily: 'Open Sans, sans-serif', fontWeight: 'bold', color: '#555', marginTop: '0.5em' }}>
                        {description}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
            </Grid>
            {apiData === null ? (
              <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                {/* <Spinner /> */}
                <div>No data available</div>
              </Grid>
            ) : (
              <Grid item xs={12} md={6} >
                <Grid m={1}>
                
                    {mapKeyValue && <BarChart mapKey={mapKeyValue} filteredData={filteredData} />}
                  
                </Grid>
                <Grid m={1} mb={8}>
                 
                    <StyledEngineProvider injectFirst>
                      {mapKeyValue && (
                        <DataTable mapKey={mapKeyValue} filteredData={filteredData} />
                      )}
                    </StyledEngineProvider>
                 
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}

      {apiData === null && (
        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
          <div>No data available</div>
        </Grid>
      )}
    </>
  );
};

export default India2014;
