import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import TamarindLeave from "../../../../../../assets/FoodSources/Carbohydrate/GLV/1_TamarindLeaves.jpg"
import Parsley from "../../../../../../assets/FoodSources/Carbohydrate/GLV/2_Parsley.jpg"
import BetelLeave from "../../../../../../assets/FoodSources/Carbohydrate/GLV/3_BetelLeaves.jpg"
import KnolKnol from "../../../../../../assets/FoodSources/Carbohydrate/GLV/4_KnolKnol.jpg"
import DrumstickLeaves from "../../../../../../assets/FoodSources/Carbohydrate/GLV/5_DrumstickLeaves.jpg"
import AgathiLeaves from "../../../../../../assets/FoodSources/Carbohydrate/GLV/6_AgathiLeaves.jpg"
import PonnanganniLeaves from "../../../../../../assets/FoodSources/Carbohydrate/GLV/7_PonnanganniLeaves.jpg"
import BrusselSprouts from "../../../../../../assets/FoodSources/Carbohydrate/GLV/8_BrusselSprouts.jpg"
import PumpkinLeave from "../../../../../../assets/FoodSources/Carbohydrate/GLV/9_PumpkinLeaves.jpg"
import GardenCress from "../../../../../../assets/FoodSources/Carbohydrate/GLV/10_GardenCress.jpg"
import { Typography } from '@mui/material';

export default function GLVs() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: TamarindLeave,
    title: 'Tamarind leaves, tender',
    author: '10.04 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Parsley,
    title: 'Parsley',
    author: '9.43 per 100gm',
  },
  {
    img: BetelLeave,
    title: 'Betel leaves',
    author: '7.37 – 6.16 per 100gm',
  },
  {
    img: KnolKnol,
    title: 'Knol-khol, leaves',
    author: '6.16 per 100gm',
    cols: 2,
  },
  {
    img: DrumstickLeaves,
    title: 'Drumstick leaves',
    author: '5.62 per 100gm',
    cols: 2,
  },
  {
    img: AgathiLeaves,
    title: 'Agathi leaves',
    author: '5.21 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: PonnanganniLeaves,
    title: 'Ponganni',
    author: '5.17 per 100gm',
  },
  {
    img: BrusselSprouts,
    title: 'Brussels sprouts',
    author: '5.09 per 100gm.4',
  },
  {
    img: PumpkinLeave,
    title: 'Pumpkin leaves, tender',
    author: '4.75 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: GardenCress,
    title: 'Garden cress',
    author: '4.48 per 100gm',
  }
];