import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import Paper from "@mui/material/Paper";

import { FcAreaChart, FcHome, FcReading } from "react-icons/fc";

import { Link } from "react-router-dom";

import PositionedMenu from "./PositionedMenu";

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);

  return (
    <Box sx={{ pb: 7 }} ref={ref} style={{ zIndex: 3 }}>
      <CssBaseline />

      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            component={Link}
            to={"/"}
            value={"/"}
            label="Home"
            icon={<FcHome />}
          />
          <BottomNavigationAction
            component={Link}
            to={"/Dashboard"}
            value={"/Dashboard"}
            label="Dashboard"
            icon={<FcAreaChart />}
          />

          <BottomNavigationAction
            component={Link}
            to={"/Nutrieducation"}
            value={"/Nutrieducation"}
            label="NutriEducation"
            icon={<FcReading />}
          />
          <PositionedMenu />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
