// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..

import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import { Avatar, Grid, ListItemAvatar, ListItemText } from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import growth from "../../../../../assets/images/icons/vitamins/Functions/growth.png";
import nerveImpulse from "../../../../../assets/images/icons/vitamins/Functions/nerveImpulse.png";
import nervousSystem from "../../../../../assets/images/icons/vitamins/Functions/nervousSystem.png";
import nutrientToEnergy from "../../../../../assets/images/icons/vitamins/Functions/nutrientToEnergy.png";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milk.png";
import milkProduct from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/milkProduct.png";
import egg from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/egg.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/spinach.png";
import legumes from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/legumes.png";
import organMeat from "../../../../../assets/FoodSources/Vitamins/Watersoluble vitamins/B1/organMeats.png";
import beriberi from "../../../../../assets/images/icons/vitamins/vitaminb1/Beriberi.png";
import muscleweakness from "../../../../../assets/images/icons/vitamins/vitaminb1/muscleweakness.png";
import abnormalheart from "../../../../../assets/images/icons/vitamins/vitaminb1/abnormal_10636080.png";
import damagednervous from "../../../../../assets/images/icons/vitamins/vitaminb1/damagednervous.png";
import ThiamineRdaTable from "./RdaAndEarTables/ThiamineRdaTable";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import confusion from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/confusion.png";
import heart_pain from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/heart_pain.png";
import loss_appetite from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/loss_appetite.png";
import muscleWeakness from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/muscleWeakness.png";
import nerve_damage from "../../../../../assets/images/icons/vitamins/Deficiency/VitaminB1/nerve_damage.png";

export default function Vitaminb1() {
  return (
    <>
      <Grid item lg={7} md={7} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of vitamin B1?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="growth" src={growth} />
                </ListItemAvatar>
                <ListItemText primary="Body requires thiamine for growth, development, and cellular function." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="nutrient to energy" src={nutrientToEnergy} />
                </ListItemAvatar>
                <ListItemText primary="The coenzyme form of thiamine is necessary for the conversion of nutrient into energy." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={nerveImpulse} />
                </ListItemAvatar>
                <ListItemText primary="Thiamine helps in production of acetylcholine, a neurotransmitter that is involved in the transmission of nerve impulses." />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="nervous system" src={nervousSystem} />
                </ListItemAvatar>
                <ListItemText primary="It is necessary for proper brain and nervous system function." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1.5,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the sources of vitamin B1?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Thiamine rich foods include whole grain cereals , organ meats, pork, egg, legumes, green leafy vegetables (spinach, fenugreek leaves and  gogu (gongura) and milk and milk products.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}
            mb={1}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 0.5, sm: 1 }}}>
              <Tooltip title="Whole Grain cereals">
                <Avatar src={wholegrains} size="lg" alt="Whole Grains" variant="square" />
              </Tooltip>
              <Tooltip title="Organ meat">
                <Avatar src={organMeat} alt="organ meats" variant="square" />
              </Tooltip>
              <Tooltip title="Egg">
                <Avatar src={egg} alt="egg" variant="square" />
              </Tooltip>
              <Tooltip title="Legumes">
                <Avatar src={legumes} alt="Legumes" variant="square" />
              </Tooltip>
              <Tooltip title="Green leafy vegetables (spinach, fenugreek leaves and gogu (gongura)">
                <Avatar src={glv} alt="Green vegetables" variant="square" />
              </Tooltip>
              <Tooltip title="Milk">
                <Avatar src={milk} alt="Milk" variant="square" />
              </Tooltip>
              <Tooltip title="Milk Products">
                <Avatar src={milkProduct} alt="milkProduct" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1.5,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is vitamin B1 absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                Thiamine is absorbed by active transport in small intestine. Processing such as milling and polishing of grains; and cooking can lead to vitamin loss (40-50%) in raw foods. Tannic acid contained in foods like tea, coffee, nuts and legumes can inhibit thiamine absorption.
              </ListItem>
              <ListItem>
                Tannic acid contained in foods like tea, coffee, nuts and legumes can inhibit thiamine absorption.
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={5} md={5} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <ThiamineRdaTable />
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py:1
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens if you are deficient in vitamin B1?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem>
                A deficiency of thiamine could lead to a condition called beriberi, characterized by muscle weakness, heart problems, nerve damage, loss of appetite, anorexia, decreased muscle tone, confusion and neurologic changes.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb:1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="beriberi">
                <Avatar src={beriberi} size="lg" alt="beriberi" variant="square" />
              </Tooltip>
              <Tooltip title="muscle weakness">
                <Avatar src={muscleWeakness} size="lg" alt="muscle weakness" variant="square" />
              </Tooltip>
              <Tooltip title="heart problems">
                <Avatar src={heart_pain} alt="heart problems" variant="square" />
              </Tooltip>
              <Tooltip title="nerve damage">
                <Avatar src={nerve_damage} alt="nerve damage" variant="square" />
              </Tooltip>
              <Tooltip title="loss of appetite">
                <Avatar src={loss_appetite} alt="loss of appetite" variant="square" />
              </Tooltip>
              <Tooltip title="confusion">
                <Avatar src={confusion} alt="confusion" variant="square" />
              </Tooltip>
              
            </AvatarGroup>
          </Box>
        </Card>
      </Grid>

    </>
  );
}
