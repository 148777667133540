import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import FenugreekSeeds from "../../../../../../assets/FoodSources/Protein/Condiments and spices/1_FenegreekSeeds.jpg"
import PoppySeeds from "../../../../../../assets/FoodSources/Protein/Condiments and spices/2_PoppySeeds.jpg"
import Omum from "../../../../../../assets/FoodSources/Protein/Condiments and spices/3_Omum.jpg"
import CuminSeeds from "../../../../../../assets/FoodSources/Protein/Condiments and spices/4_CuminSeeds.jpg"
import ChilliesRed from "../../../../../../assets/FoodSources/Protein/Condiments and spices/5_ChilliesRed.jpg"
import CorianderSeeds from "../../../../../../assets/FoodSources/Protein/Condiments and spices/6_CorianderSeeds.jpg"
import Pippali from "../../../../../../assets/FoodSources/Protein/Condiments and spices/7_Pippali.jpg"
import Pepper from "../../../../../../assets/FoodSources/Protein/Condiments and spices/8_Pepper1.jpg"
import CardamomGreen from "../../../../../../assets/FoodSources/Protein/Condiments and spices/9_CardamomGreen_1.jpg"
import TurmericPowder from "../../../../../../assets/FoodSources/Protein/Condiments and spices/10_TurmericPowder.jpg"
import { Typography } from '@mui/material';

export default function SpicesAndHerbs() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: FenugreekSeeds,
    title: 'Fenugreek seeds',
    author: '25.41 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: PoppySeeds,
    title: 'Poppy seeds',
    author: '20.31 per 100gm',
  },
  {
    img: Omum,
    title: 'Omum',
    author: '15.89 per 100gm',
  },
  {
    img: CuminSeeds,
    title: 'Cumin seeds',
    author: '13.91 per 100gm',
    cols: 2,
  },
  {
    img: ChilliesRed,
    title: 'Chillies, red',
    author: '12.69 per 100gm',
    cols: 2,
  },
  {
    img: CorianderSeeds,
    title: 'Coriander seeds',
    author: '10.66 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Pippali,
    title: 'Pippali',
    author: '10.53 per 100gm',
  },
  {
    img: Pepper,
    title: 'Pepper, black',
    author: '10.12 per 100gm.4',
  },
  {
    img: CardamomGreen,
    title: 'Cardamom, green',
    author: '8.1 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: TurmericPowder,
    title: 'Turmeric powder',
    author: '7.66 per 100gm',
  }
];