import React, { useState, useEffect } from "react";

import {
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Dropdown from "./components/dropdown/Dropdown";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { getApiData } from "../dashboard/components/dropdown/dropdownSlice";
import India2014 from "./charts/India2014";
import SelectedDropdownData from "./components/dropdown/SelectedDropdownData";
import Spinner1 from "../../components/spinner1";
import AlertModal from "./AlertModal";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
const AppContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start", // Align to the left
  alignItems: "stretch", // Take up the full height
  padding:2,
 margin: 0,
}));

const Disclaimer = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFAFA", 
  padding: theme.spacing(2),
  textAlign: "center",
  fontWeight: "bold", // Set text to bold
  color: theme.palette.text.primary,
  fontSize: "16px",
  margin: `${theme.spacing(2)}px auto`, // Center the disclaimer horizontally
}));





const VerticalGridContainer = styled(Grid)(({ theme }) => ({
  //backgroundColor: "#F6F1F1", // Set the background color
  //backgroundColor: "#F6F1F1",
  border: "1px  #F6F1F1",
 padding: `${theme.spacing(2)}px 0`, // Add top and bottom padding
  borderRadius: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%", // Set a fixed width for the vertical menu bar
}));
const mediaQuery = window.matchMedia("(max-width: 920px)");
const Compare= () => {
  const [filteredData, setFilteredData] = useState([]);
  const apiData = useSelector(getApiData);
  const [isLoading, setIsLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 920px)").matches
  );
  const [showAlert, setShowAlert] = useState(isSmallScreen);
  useEffect(() => {
    // Simulate API data loading
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    const mediaQuery = window.matchMedia("(max-width: 920px)");

    const handleMediaQueryChange = (event) => {
      setIsSmallScreen(event.matches);
      if (event.matches) {
        setShowAlert(true);
      }
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
<React.Fragment>
  <CssBaseline />
  <AppContainer maxWidth="xxl">
 
  <Grid container>
    {/* First Grid */}
    <Grid item xs={12} md={2}>
      <VerticalGridContainer>
        <Dropdown />
      </VerticalGridContainer>
    </Grid>
    
    {/* Second Grid */}
    <Grid item xs={12} md={10}>
    <Disclaimer>
    Comparisons may be interpreted cautiously because there is variation in sample size and data collection for each survey. Footnotes have been provided for each graph.
            </Disclaimer>     
    {showAlert ? (
          <AlertModal open={showAlert} onClose={closeAlert} />
        ) : (
          <>
            {isLoading || apiData === null ? (
              <Spinner1 />
            ) : (
              <India2014 />
            )}
          </>
        )}
        {/* Render the disclaimer in the middle of the screen */}
        
    </Grid>
   
  </Grid>
 
</AppContainer>
</React.Fragment>


  );
};
export default Compare;
