import React from 'react'

function FootNotes(props) {
    const { Footnote,footnote1,footnote2,footnote3 } = props;
  return (
    <div>
      <b>FootNotes:</b>
      {Footnote && <span>{Footnote}</span>}
      {footnote1 && <span>{footnote1}</span>}
      {footnote2 && <span>{footnote2}</span>}
      {footnote3 && <span>{footnote3}</span>}
    </div>
  )
}

export default FootNotes