import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import AgathiLev from "../../../../../../assets/FoodSources/Protein/GLV/1_AgathiLeaves.jpg";
import NeemLev from "../../../../../../assets/FoodSources/Protein/GLV/2_neemLeaves.jpg";
import ColocasiaLev from "../../../../../../assets/FoodSources/Protein/GLV/3_ColocasiaLeaves.jpg";
import KuppameniLev from "../../../../../../assets/FoodSources/Protein/GLV/4_kuppameniFromGoogle.png";
import DrumstickLev from "../../../../../../assets/FoodSources/Protein/GLV/5_DrumstickLeaves.jpg";
import SoyaLev from "../../../../../../assets/FoodSources/Protein/GLV/6_SoyaLeaves.jpg";
import AmaranthusLev from "../../../../../../assets/FoodSources/Protein/GLV/7_Amaranthus.JPG";
import ManathakkaliLev from "../../../../../../assets/FoodSources/Protein/GLV/8_ManathakkaliFromGoogle.jpg";
import TamarindLev from "../../../../../../assets/FoodSources/Protein/GLV/9_TamarindLeaves.jpg";
import ParsleyLev from "../../../../../../assets/FoodSources/Protein/GLV/10_ParsleyLeaves.jpg";
import { Typography } from "@mui/material";

export default function GLVs() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))!important",
        border: "1px solid #ccc",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "0.em", whiteSpace: "break-spaces", fontWeight: "bold" }}
              >
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: AgathiLev,
    title: "Agathi leaves",
    author: "8.01 per 100gm",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: NeemLev,
    title: "Neem leaves mature",
    author: "7.1 per 100gm",
  },
  {
    img: ColocasiaLev,
    title: "Colocasia leaves (black variety)",
    author: "6.8 per 100gm",
  },
  {
    img: KuppameniLev,
    title: "kuppameni",
    author: "6.7 per 100gm",
    cols: 2,
  },
  {
    img: DrumstickLev,
    title: "Drumstick leaves",
    author: "6.41 per 100gm",
    cols: 2,
  },
  {
    img: SoyaLev,
    title: "Soya leaves",
    author: "6 per 100gm",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: AmaranthusLev,
    title: "Amaranth, paniculatus",
    author: "5.9 per 100gm",
  },
  {
    img: ManathakkaliLev,
    title: "menathakkali leaves",
    author: "5.9 per 100gm.4",
  },
  {
    img: TamarindLev,
    title: "Tamarind leaves, tender",
    author: "5.84 per 100gm",
    rows: 2,
    cols: 2,
  },
  {
    img: ParsleyLev,
    title: "parsley",
    author: "5.55 per 100gm",
  },
];
