import React from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCMap from "highcharts/modules/map";
import HCDrilldown from "highcharts/modules/drilldown";
import HCExport from "highcharts/modules/exporting";
import InMap from "../../../maps/india2013.geo.json";
import cordinateData from "./StateMapsSourceList";
import offlineExporting from "highcharts/modules/offline-exporting";
offlineExporting(Highcharts);
//import "./helpers/proj4-module";

// Load Highcharts modules
HCMap(Highcharts);
HCDrilldown(Highcharts);
HCExport(Highcharts);

cordinateData.forEach((d) => {
  d.z = d.population;
});

let data = Highcharts.geojson(InMap);
// Set drilldown pointers
data.forEach(function (el, i) {
  el.drilldown = el.properties["hc-key"];
  el.value = i;
});

const mapOptions = {
  chart: {
    events: {
      drilldown: function (e) {
        if (!e.seriesOptions) {
          var chart = this,
            mapKey = e.point.name,
            // Handle error, the timeout is cleared on success
            fail = setTimeout(function () {
              if (!Highcharts.maps[mapKey]) {
                chart.showLoading('<i class="icon-frown"></i> Failed loading ' + e.point.name);
                fail = setTimeout(function () {
                  chart.hideLoading();
                }, 1000);
              }
            }, 3000);

          // Show the spinner
          chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>'); // Font Awesome spinner
          // Load the drilldown map
          import("../../../maps/2022/" + mapKey + ".json").then((map) => {
            map = map.default;
            data = Highcharts.geojson(map);

            data.forEach((i) => {
              this.value = i;
            });

            chart.hideLoading();
            clearTimeout(fail);

            // Add Map Layer
            chart.addSingleSeriesAsDrilldown(e.point, {
              name: e.point.name,
              mapData: map,
              dataLabels: {
                enabled: true,
                format: "{point.name}",
              },
            });

            // Add Lat Long points Layer
            chart.addSingleSeriesAsDrilldown(e.point, {
              type: "mappoint",
              name: e.point.name,
              color: "red",
              data: cordinateData.filter((i) => i.parentState === e.point.name),
            });
            // Add Drilldown
            chart.applyDrilldown();
          });
        }

        this.setTitle(null, { text: e.point.name });
      },
      drillup: function () {
        this.setTitle("India", { text: "India" });
      },
    },
  },
  title: {
    text: "India Heat Map",
  },
  subtitle: {
    text: "India",
    floating: true,
    align: "right",
    y: 50,
    style: {
      fontSize: "16px",
    },
  },
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  colorAxis: {
    min: 0,
    minColor: "#E6E7E8",
    maxColor: "#005645",
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: "top",
    },
  },
  tooltip: {
    formatter: function () {
      let point = this.point;
      return (
        "<b>" + point.properties.ST_NM + "</b><br>" + point.indicator_name + "  :" + point.value
      );
    },
    valueSuffix: "NFHS 5",
  },
  plotOptions: {
    map: {
      states: {
        hover: {
          color: "#EEDD66",
        },
      },
    },
  },
  series: [
    {
      mapData: InMap,
      data: data,
      joinBy: ["hc-key", "drilldown"],
      name: "India",
      borderColor: "white",
      borderWidth: 0.6,
      dataLabels: {
        enabled: true,
        format: "{point.properties.name}",
      },
      nullColor: "grey",
    },
  ],
  drilldown: {
    activeDataLabelStyle: {
      color: "#FFFFFF",
      textDecoration: "none",
      textOutline: "1px #000000",
    },
    drillUpButton: {
      relativeTo: "spacingBox",
      position: {
        x: 60,
        y: 100,
      },
    },
  },
};

// Render app with demo chart
class India2013 extends React.Component {
  render() {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"mapChart"}
          options={mapOptions}
        />
      </div>
    );
  }
}
export default India2013;
