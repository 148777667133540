import React from "react";
import { Grid } from "@mui/material";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import Avatar from "@mui/joy/Avatar";
import List from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";

export const Team = () => {
  return (
    <div>
      <Grid mt={4}>
        {/* <Typography level="h1">Meet the Team</Typography> */}
        <Grid md={12} mt={1} item>
          <div style={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
            <Typography
              variant="h6"
              gutterBottom
              m={2}
              sx={{ color: "black", fontSize: "1.8rem", fontWeight: "bold" }}
            >
              Meet the Team
            </Typography>

            <div style={{ flex: 1, backgroundColor: "#FF4081", height: "5px" }} />
          </div>
        </Grid>
        <Typography level="h4">Scientific Team</Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item lg={4}>
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: 380,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <AspectRatio ratio="1" sx={{ width: 90 }}>
                <img
                  src="https://www.nin.res.in/images/director-sm-cp.png"
                  srcSet="https://www.nin.res.in/images/director-sm-cp.png 2x"
                  loading="lazy"
                  alt=""
                />
              </AspectRatio>
              <CardContent>
                <Typography level="h2" fontSize="lg" sx={{ mb: 1 }}>
                  Dr. Hemalatha R
                </Typography>
                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                  <Link
                    overlay
                    underline="none"
                    href="https://www.nin.res.in/The_Director.html"
                    sx={{ color: "text.tertiary" }}
                  >
                    Director, ICMR-NIN
                  </Link>
                </Typography>
                <Chip variant="soft" color="primary" sx={{ mx: 2 }}>
                  Project Coordinator
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: 380,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <AspectRatio ratio="1" sx={{ width: 90 }}>
                <img
                  src="https://bl-i.thgim.com/public/migration_catalog/6zcjb-mendhu/alternates/FREE_1200/mendhu"
                  srcSet="https://bl-i.thgim.com/public/migration_catalog/6zcjb-mendhu/alternates/FREE_1200/mendhu 2x"
                  loading="lazy"
                  alt=""
                />
              </AspectRatio>
              <CardContent>
                <Typography level="h2" fontSize="lg" sx={{ mb: 1 }}>
                  Dr. M. Vishnu Vardhan Rao
                </Typography>
                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                  <Link
                    overlay
                    underline="none"
                    href="#interactive-card"
                    sx={{ color: "text.tertiary" }}
                  >
                    Former Director, ICMR-NIMS
                  </Link>
                </Typography>
                <Chip variant="soft" color="primary" sx={{ mx: 2 }}>
                  Project Coordinator
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: 380,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <AspectRatio ratio="1" sx={{ width: 90 }}>
                <img
                  src="https://www.nin.res.in/images/scientistprofiles/SRao.jpg"
                  srcSet="https://www.nin.res.in/images/scientistprofiles/SRao.jpg 2x"
                  loading="lazy"
                  alt=""
                />
              </AspectRatio>
              <CardContent>
                <Typography level="h2" fontSize="lg" sx={{ mb: 1 }}>
                  Dr. SubbaRao M Gavaravarapu
                </Typography>
                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                  <Link
                    overlay
                    underline="none"
                    href="https://www.nin.res.in/scientistprofiles/DR.G.MARUTHI_SUBBA_RAO.html"
                    sx={{ color: "text.tertiary" }}
                  >
                    Scientist - F, ICMR-NIN
                  </Link>
                </Typography>

                <Chip variant="soft" color="primary" sx={{ mx: 2 }}>
                  Principal investigator
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3}>
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: 280,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="md" sx={{ mb: 1 }}>
                  Dr. Samarasimha Reddy N
                </Typography>
                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                  <Link
                    overlay
                    underline="none"
                    href="#interactive-card"
                    sx={{ color: "text.tertiary" }}
                  >
                    Scientist - E, ICMR-NIN
                  </Link>
                </Typography>

                <Chip variant="soft" color="info" sx={{ mx: 2 }}>
                  Co-Investigator
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3}>
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: 280,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="md" sx={{ mb: 1 }}>
                  Dr. Raja Sriswan Mamidi
                </Typography>
                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                  <Link
                    overlay
                    underline="none"
                    href="#interactive-card"
                    sx={{ color: "text.tertiary" }}
                  >
                    Scientist - D, ICMR-NIN
                  </Link>
                </Typography>

                <Chip variant="soft" color="info" sx={{ mx: 2 }}>
                  Co-Investigator
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3}>
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: 280,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="md" sx={{ mb: 1 }}>
                  Mr. Raju Naik Vankudavath
                </Typography>
                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                  <Link
                    overlay
                    underline="none"
                    href="#interactive-card"
                    sx={{ color: "text.tertiary" }}
                  >
                    Scientist - D, ICMR-NIN
                  </Link>
                </Typography>

                <Chip variant="soft" color="info" sx={{ mx: 2 }}>
                  Co-Investigator
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3}>
            <Card
              variant="outlined"
              orientation="horizontal"
              sx={{
                width: 280,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="md" sx={{ mb: 1 }}>
                  Dr. P. Raghavendra
                </Typography>
                <Typography level="body-sm" aria-describedby="card-description" mb={1}>
                  <Link
                    overlay
                    underline="none"
                    href="#interactive-card"
                    sx={{ color: "text.tertiary" }}
                  >
                    Scientist - C, ICMR-NIN
                  </Link>
                </Typography>

                <Chip variant="soft" color="info" sx={{ mx: 2 }}>
                  Co-Investigator
                </Chip>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1} my={4} display="flex" alignItems="center" flexDirection="row">
          <Grid item lg={12}>
            <Typography level="h4">Development Team (project staff)</Typography>
          </Grid>
          <Grid item mt={4} lg={4}>
            <Card
              variant="outlined"
              sx={{
                width: 360,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="xl" sx={{ mb: 1 }}>
                  Mr. Naresh Pittla
                </Typography>
                <Chip variant="soft" color="warning" sx={{ mx: 2 }}>
                  Senior Consultant Developer
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item mt={4} lg={4}>
            <Card
              variant="outlined"
              sx={{
                width: 360,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="lg" sx={{ mb: 1 }}>
                  {" "}
                  Dr. M. Thirupathi Reddy{" "}
                </Typography>
                <Chip variant="soft" color="warning" sx={{ mx: 2 }}>
                  Scientist - B (Statistics)
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item mt={4} lg={4}>
            <Card
              variant="outlined"
              sx={{
                width: 360,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="lg" sx={{ mb: 1 }}>
                  Dr. S. Priyadarshini
                </Typography>
                <Chip variant="soft" color="warning" sx={{ mx: 2 }}>
                  Scientist - B (Nutrition)
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item mt={4} lg={4}>
            <Card
              variant="outlined"
              sx={{
                width: 360,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="lg" sx={{ mb: 1 }}>
                  Ms. Rajeswari Bolla
                </Typography>
                <Chip variant="soft" color="warning" sx={{ mx: 2 }}>
                  Junior Consultant (FullStack Developer)
                </Chip>
              </CardContent>
            </Card>
          </Grid>
          <Grid item mt={4} lg={4}>
            <Card
              variant="outlined"
              sx={{
                width: 360,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
              }}
            >
              <CardContent>
                <Typography level="h2" fontSize="lg" sx={{ mb: 1 }}>
                  Ms. Bhavani Panda
                </Typography>
                <Chip variant="soft" color="warning" sx={{ mx: 2 }}>
                  Computer Programmer
                </Chip>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
