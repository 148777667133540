// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { transformedDataRDA } from "./RDAtableData"
// import './RdaDataTable.css';

// const RdaDataTable = () => {
//   const rowsWithIds = transformedDataRDA.map((row, index) => ({ ...row, id: index + 1 }));

//   const columns = [
//     { field: 'AgeGroup', headerName: 'Age Group', flex: 18 },
//     { field: 'CategoryOfWork', headerName: 'Category of Work', flex: 8 },
//     { field: 'ReferenceBodyWeight', headerName: 'Reference body weight', flex: 8 },
//     { field: 'Energy', headerName: 'Energy (Kcal/Kg/d)', flex: 8  },
//     { field: 'Protein', headerName: 'Protein', flex: 8 },
//     { field: 'Calcium', headerName: 'Calcium', flex: 8 },
//     { field: 'Magnesium', headerName: 'Magnesium', flex: 8 },
//     { field: 'Iron', headerName: 'Iron', flex: 8 },
//     { field: 'Zinc', headerName: 'Zinc', flex: 8 },
//     { field: 'Iodine', headerName: 'Iodine', flex: 8 },
//     { field: 'Thiamine', headerName: 'Thiamine', flex: 8 },
//     { field: 'Riboflavin', headerName: 'Riboflavin', flex: 8 },
//     { field: 'Niacin', headerName: 'Niacin', flex: 8 },
//     { field: 'VitB6', headerName: 'Vitamin B6', flex: 8 },
//     { field: 'Folate', headerName: 'Folate', flex: 8 },
//     { field: 'VitB12', headerName: 'Vitamin B12', flex: 8 },
//     { field: 'VitC', headerName: 'Vitamin C', flex: 8 },
//     { field: 'VitA', headerName: 'Vitamin A', flex: 8 },
//     { field: 'VitD', headerName: 'Vitamin D', flex: 8 },
//   ];
//   const getRowClassName = (params) => {
//     const ageGroup = params.row.AgeGroup;

//     // Use a switch or if-else to apply different CSS classes based on the "Age Group"
//     switch (ageGroup) {
//       case 'Infants':
//         return 'age-group-infants';
//       case 'Children':
//         return 'age-group-children';
//       case 'Boys':
//         return 'age-group-boys';
//       case 'Girls':
//         return 'age-group-girls';
//       case 'Men':
//         return 'age-group-men';
//       case 'Women':
//         return 'age-group-women';
//       case 'Elderly':
//         return 'age-group-elderly';
//       // Add more cases for other age groups
//       default:
//         return '';
//     }
//   };
//   const getRowHeight = (params) => 35;

//   return (
//     <div style={{ height: 885, width: '100%' }}>
//       <DataGrid
//         sx={{
//           // boxShadow: 2,
//           // border: 2,
//           // borderColor: 'primary.light',
//           '& .MuiDataGrid-row:hover': {
//             backgroundColor: 'text.disabled',
//           },
//         }}
//         rows={rowsWithIds}
//         columns={columns}
//         pageSize={22}
//         rowsPerPageOptions={[10]}
//         getRowClassName={getRowClassName}
//         getRowHeight={getRowHeight} // Apply the custom row height

//       />
//     </div>
//   );
// };

// export default RdaDataTable;


import * as React from 'react';
import { DataGrid, GridPagination, GridToolbar } from "@mui/x-data-grid";
import { transformedData1 } from "./RDAtableData"
import './RdaDataTable.css';
import { TableCell, TableFooter, TableHead, TableRow, Tooltip } from '@mui/material';

const RdaDataTable = () => {
  // Extract the data from the transformedDataRDA object and create an array of rows
  const rowsWithIds = Object.keys(transformedData1).map((id) => {
    const rowData = transformedData1[id];
    return { id, ...rowData };
  });

  // Extract the nutrient names dynamically from the first row of data
  const nutrientNames = Object.keys(transformedData1['1.11']).filter(key => key !== 'AgeGroup' && key !== 'CategoryOfWork' && key !== 'ReferenceBodyWeight');

  
  // Create columns dynamically based on nutrient names
  const columns = [
    { field: 'AgeGroup', headerName: 'Age Group', flex: 1, minWidth: 85, sticky: 'left'},
    { field: 'CategoryOfWork', headerName: 'Category of Work', flex: 1, minWidth: 110, sticky: 'left' },
    {
      field: 'ReferenceBodyWeight', headerName: (
        <div style={{ whiteSpace: 'pre-line', textAlign: 'center', lineHeight: '1.2' }}>
          Reference body weight
          <div style={{ fontSize: '0.8rem' }}>(Kg)</div>
        </div>
      ), flex: 1, minWidth: 110, sticky: 'left'
    },
  ];

  const nutrientMeasuringUnits = {};

  const getTooltipContent = (nutrient, rowData) => {
    const nutrientData = rowData[nutrient] || { RDA: '-', EAR: '-' };
    return nutrientData.RDA === '-' ? '-' : `${nutrientData.RDA}`;
  };



  // Add default values for missing nutrients
  for (const nutrient of nutrientNames) {
    columns.push({
      field: nutrient,
      headerName: (
        <div style={{ whiteSpace: 'pre-line', textAlign: 'center', lineHeight: '1.2' }}>
          {`${nutrient}\n${nutrientMeasuringUnits[nutrient] || ''}`}
        </div>
      ),
      flex: 17,
      align: 'center',
      minWidth: 85,
      renderCell: (params) => {
        const rowData = params.row;
        const tooltipContent = getTooltipContent(nutrient, rowData);
        return (
          <Tooltip title={tooltipContent} arrow>
            <div style={{ overflowWrap: 'anywhere', wordBreak: 'break-all' }}>
              {tooltipContent}
            </div>
          </Tooltip>
        );
      },
    });
  }

  
  const getRowClassName = (params) => {
    const ageGroup = params.row.AgeGroup;

    // Use a switch or if-else to apply different CSS classes based on the "Age Group"
    switch (ageGroup) {
      case 'Infants':
        return 'age-group-infants';
      case 'Children':
        return 'age-group-children';
      case 'Boys':
        return 'age-group-boys';
      case 'Girls':
        return 'age-group-girls';
      case 'Men':
        return 'age-group-men';
      case 'Women':
        return 'age-group-women';
      case 'Elderly':
        return 'age-group-elderly';
      // Add more cases for other age groups
      default:
        return '';
    }
  };

  const getRowHeight = (params) => 35;

  

  return (
    <div style={{ height: 930, width: '100%' }}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'text.disabled',
            color:"black"
          },
        }}
        isRowSelectable={() => false}
        rows={rowsWithIds}
        columns={columns}
        density="standard"
        components={{
        Toolbar: GridToolbar,
        Header: (props) => (
            <TableHead>
              <TableRow>
                {/* Adjust the number and content of cells based on your columns */}
                <TableCell colSpan={columns.length} style={{ color:"#6b3238", textAlign: "center", fontSize: '1.3em', fontWeight: 'bold' }}>
                  Summary of Recommended Dietary Allowances (RDA) of Nutrients for Indians
                </TableCell>
                {/* Add more cells for other columns */}
              </TableRow>
            </TableHead>
          ),
          // Footer: (props) => (
          //   <TableFooter>
          //     <TableCell colSpan={columns.length} style={{ textAlign: "left" }}>
          //       <div>* Adequate Intake (AI)</div>
          //       <div>Note: For adequate intake of Biotin and Pantothenic acid, refer to the text on summary of recommendations.</div>
          //       <div>Protein requirement: additional 9.5g and 22.0g during 2nd and 3rd trimester for pregnant women, and 17.0g & 13.0g for lactating women respectively.</div>
          //       <div>** Additional requirement of protein is for 10kg gestational weight gain (GWG).</div>
          //       <div>Protein recommendation is for quality protein.</div>
          //       {/* Add more lines as needed */}
          //     </TableCell>
          //   </TableFooter>
          // )
      }}
      componentsProps={{
        toolbar: {
          printOptions: {
            disableToolbarButton: true,
          },
          csvOptions: {
            disableToolbarButton: true,
          },
        },
        
      }}
        pageSize={22}
        rowsPerPageOptions={[5]}
        getRowClassName={getRowClassName}
        getRowHeight={getRowHeight}
      />
    </div>
  );
};

export default RdaDataTable;