import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Asafoetida from "../../../../../../assets/FoodSources/Carbohydrate/Spices/1_Asafoetida.jpg"
import Peppali from "../../../../../../assets/FoodSources/Carbohydrate/Spices/2_Peppali.jpg"
import MangoPowder from "../../../../../../assets/FoodSources/Carbohydrate/Spices/3_MangoPowder.jpg"
import CardamonBlack from "../../../../../../assets/FoodSources/Carbohydrate/Spices/4_CardamonBlack.jpg"
import Turmeric from "../../../../../../assets/FoodSources/Carbohydrate/Spices/5_Turmeric.jpg"
import CardamonGreen from "../../../../../../assets/FoodSources/Carbohydrate/Spices/6_CardamomGreen_1.jpg"
import PepperBlack from "../../../../../../assets/FoodSources/Carbohydrate/Spices/7_PepperBlack.jpg"
import Chillies from "../../../../../../assets/FoodSources/Carbohydrate/Spices/8_ChilliesRed.jpg"
import Nutmeg from "../../../../../../assets/FoodSources/Carbohydrate/Spices/9_Nutmeg.jpg"
import Mace from "../../../../../../assets/FoodSources/Carbohydrate/Spices/10_Mace.jpg"
import { Typography } from '@mui/material';

export default function SpicesAndHerbs() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: Asafoetida,
    title: 'Asafoetida',
    author: '71.95 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Peppali,
    title: 'Peppali',
    author: '65.8 per 100gm',
  },
  {
    img: MangoPowder,
    title: 'Mango powder',
    author: '64 per 100gm',
  },
  {
    img: CardamonBlack,
    title: 'Cardamon,black',
    author: '52.53 per 100gm',
    cols: 2,
  },
  {
    img: Turmeric,
    title: 'Turmeric powder',
    author: '49.22 per 100gm',
    cols: 2,
  },
  {
    img: CardamonGreen,
    title: 'Cardamom, green',
    author: '47.76 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: PepperBlack,
    title: 'pepper, black',
    author: '36.22 per 100gm',
  },
  {
    img: Chillies,
    title: 'Chillies, red',
    author: '29.46 per 100gm.4',
  },
  {
    img: Nutmeg,
    title: 'Nutmeg',
    author: '27.64 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: Mace,
    title: 'Mace',
    author: '26.51 per 100gm',
  }
];