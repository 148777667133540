import { configureStore } from "@reduxjs/toolkit";
import selectedDropdownDataReducer from '../layouts/dashboard/components/dropdown/dropdownSlice';
//import groupbyDropdownDataReducer from '../layouts/Groupby/components/dropdown/GroupbyDropdownDataSlice';
import selectedOptionReducer from "../layouts/home/HomeRadialComponents/selectedOptionSlice";
import mapKeyReducer from "../layouts/dashboard/charts/MapKeySlice";
import CompareDataReducer from '../layouts/compare/components/dropdown/CompareDataSlice';

export const store = configureStore({
    reducer: {
        // Ensure that these imports are correct and point to the reducer files
        selectedDropdownData: selectedDropdownDataReducer,
        selectedOptionFromHome: selectedOptionReducer,
        mapKeyValue: mapKeyReducer,
        CompareData: CompareDataReducer,
       // groupbyDropdownData:groupbyDropdownDataReducer,
    }
})

export default store;
