import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCompareData, setCompareData } from "../components/dropdown/CompareDataSlice";
import { getMapKeyValue, setMapKey } from "./MapKeySlice";
import { Card, CardHeader, CardContent, Typography, Avatar } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import stunting from "../../../assets/images/indicatoricons/stunting.png";

function Infocard() {
    const [seriesData, setSeriesData] = useState([]);
    const [activeTab, setActiveTab] = useState(0); // Track the active tab index
    const [uniqueSourceNames, setUniqueSourceNames] = useState([]);
    const compareData = useSelector(getCompareData);
    const dispatch = useDispatch();
   // console.log("CompareData for carddata is ", compareData);
    const mapKeyValue = useSelector(getMapKeyValue);
   // console.log("mapKeyValueforcarddata", mapKeyValue);

  useEffect(() => {
    dispatch(setCompareData());
  }, [dispatch]);

  useEffect(() => {
    if (compareData && Array.isArray(compareData.results)) {
      const allowedSourceCodes = [
        "N5", "N4", "N3", "N2", "N1", "D4", "D3", "D2", "D1", "CN", "AHSCAB", "LW", "AHS10_11", "AHS11_12", "AHS12_13","NNMBURBAN","NNMB3","NNMBR2006"
      ];

      const filteredData = compareData.results.filter(
        (d) => allowedSourceCodes.includes(d.source_code)
      );
     // console.log("Filtered Data:", filteredData);

      // Calculate uniqueSourceNames and set it
      const sourceNames = Array.from(
        new Set(filteredData.map((dataPoint) => dataPoint.source_name))
      );
      setUniqueSourceNames(sourceNames);

      const generatedSeriesData = sourceNames.map((sourceName) => {
        const filteredSourceData = filteredData.filter(
          (dataPoint) => dataPoint.source_name === sourceName
        );
        if (filteredSourceData.length <= 1) {
          return null; // Skip series with one or fewer data points
        }
       // console.log("Filtered Source Data for", sourceName, ":", filteredSourceData);

        const seriesData = filteredSourceData
          .filter((dataPoint) => {
            if (mapKeyValue && mapKeyValue !== "india") {
              const isIndiaAreaLevel = dataPoint.area && dataPoint.area.toLowerCase() === mapKeyValue.toLowerCase();
              return isIndiaAreaLevel;
            } else {
              const isMapKeyValueArea = dataPoint.area === dataPoint.area_name && (dataPoint.area_level === 2 || dataPoint.area_level === 1);

              return isMapKeyValueArea;
            }
          })
          .map((dataPoint) => ({
            name: dataPoint.area_name,
            y: parseFloat(dataPoint.value),
            rural: dataPoint.rural,
            urban: dataPoint.urban,
            title: dataPoint.title,
            indicator_name: dataPoint.indicator_name,
            indicator_description: dataPoint.indicator_description,
            indicator_icon:dataPoint.indicator_icon,
            subindicator_name: dataPoint.subindicator_name,
            title: dataPoint.title,
            source_footnote: dataPoint.source_footnote,
            footnote1: dataPoint.footnote1,
            footnote2: dataPoint.footnote2,
            footnote3: dataPoint.footnote3,
            area_level: dataPoint.area_level,
            sourcearea_level: dataPoint.sourcearea_level,
          }));

        //console.log("Series Data for infocard", sourceName, ":", seriesData);

        return {
          name: sourceName,
          data: seriesData,
        };
      }).filter(Boolean);

      setSeriesData(generatedSeriesData);
      console.log("infocarddata",generatedSeriesData)
    } else {
     // console.error("Error: compareData or compareData.results is null or undefined");
    }
  }, [compareData, mapKeyValue]);

  const cardHeaderStyle = {
    background: `linear-gradient(to bottom, rgba(196, 0, 0, 0.1), rgba(196, 0, 0, 1))`,
    borderBottom: '2px solid rgba(196, 0, 0, 1)',
    borderTop: 'none',       // No top border
    borderLeft: 'none',      // No left border
    borderRight: 'none',     // No right border
  };
  const seriesNames = seriesData.map((series) => series.name);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  function getColorForSeries(seriesName) {
    if (seriesName === "NFHS-5(2019-21)") {
      //return "#E6A532";
      return "#c4000080";
    } else if (seriesName === "NFHS-4(2015-16)") {
      return "#62968c";
    }
    
    else if (seriesName === "NFHS-3(2005-06)") {
      //return "#9e9ac8";
      return "#945ECF";
      
    }
    else if (seriesName === "NFHS-2(1998-99)") {
      return "#367dc4";
    }
    else if (seriesName === "NFHS-1(1992-93)") {
      return "#ef8d5d";
    }
    else if (seriesName === "CNNS(2016-18)") {
      return "#E6A532";
    }
    else if (seriesName === "DLHS-4(2012-14)") {
      return "#b11217";
    }
    else if (seriesName === "DLHS-3(2005-06)") {
      return "#e17646";
    }
    else if (seriesName === "DLHS-2(2002-04)") {
      return "#dbbe5e";
    }
    else if (seriesName === "DLHS-1(1998-99)") {
      return "#caddab";
    }
    else if (seriesName === "AHS-CAB(2012-13)") {
      return "#fea848";
    }
    else if (seriesName === "AHS(2010-11)") {
      return "#af69a0";
    }
    else if (seriesName === "AHS-(2011-12)") {
      return "#349db4";
    }
    else if (seriesName === "AHS-(2012-13)") {
      return "#724d8c";
    }
    else if (seriesName === "LASI Wave-1(2017-18)") {
      return "#a1cbbc";
    }
    else if (seriesName.trim() === "NNMB-Urban Nutrition Report (2017)") {
        
      return "#8a5479";
    }
    else if (seriesName.trim() === "NNMB-Rural-Third repeat survey (2011-12)") {
        
      return "#887cc2";
    }
    else if (seriesName.trim() === "NNMB Rural Survey (2005-06)") {
      
      return "#bf95b6";
    }
    // Add more conditions for other series names and colors if needed
    // If none of the conditions match, return a default color
    return "#fea848";
  }
  const cardTitle = seriesData.length > 0 ?seriesData[0].data[0]?.indicator_name || 'N/A' : 'N/A';
  const cardSubheader = seriesData.length > 0 ? seriesData[0].data[0]?.indicator_description || 'N/A' : 'N/A';
  const indicatorIconPath = seriesData.length > 0
  ? require(`../../../assets/images/indicatoricons/${seriesData[0].data[0]?.indicator_icon}`)
  : '';

  return (
    <Card>
    <CardHeader
  title={
    <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'center' }}>
      {cardTitle}
    </Typography>
  }
  subheader={cardSubheader}
  style={{
    fontWeight: 'bold',
    backgroundColor: 'transparent',  // Set background to transparent
    borderBottom: '2px solid rgba(196, 0, 0, 1)',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  }}
  avatar={
    <img
      src={indicatorIconPath}
      alt="Indicator Icon"
      style={{ width: '100px', height: '100px' }}
    />
  }
/>
    <CardContent>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {seriesNames.map((name, index) => (
          <Tab
            key={index}
            label={name}
            style={{
                color: getColorForSeries(name),
                fontWeight: activeTab === index ? "bold" : "normal",
              }}
          />
        ))}
      </Tabs>
      {seriesData.map((series, index) => (
          <div
            key={index}
            role="tabpanel"
            hidden={activeTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
          >
            {/* Content for each tab, based on series data */}
            {series.data[0] ? ( // Check if series.data[0] is defined
              <>
            <Typography
  variant="h6"
  style={{
    color: getColorForSeries(series.name),
    fontWeight: 'bold',
  }}
>
  
</Typography>
                <Typography>{series.data[0].source_footnote || 'NA'}</Typography>
              </>
            ) : (
              <Typography>No data available for this series</Typography>
            )}
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
export default Infocard;
