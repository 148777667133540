import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
//import * as d3 from "d3/dist/d3.min";

import myPlate from "../../../assets/images/myplateicon.png";
import { useNavigate } from "react-router-dom";

function ArcDesignSmall() {
  const navigate = useNavigate();
  const svgRef = useRef();

  const buttonRadius = Math.min(window.innerWidth + 1000, window.innerHeight + 1000) * 0.4;
  const anglePerButton = (2.2 * Math.PI) / 7.5;
  const outerRadius = buttonRadius * 0.85;
  const innerRadius = buttonRadius * 0.6;

  const handleArcClick = (event, data) => {
    if (!data.label) {
      return;
    }
    // navigate(`/${data.label}`);
    window.location.href = data.label;
  };

  useEffect(() => {
    var screenWidth = window.innerWidth;

    var margin = { left: 20, top: 20, right: 20, bottom: 20 },
      // width = Math.min(screenWidth, 700) - margin.left - margin.right,
      // height = Math.min(screenWidth, 700) - margin.top - margin.bottom;
      width = 2 * buttonRadius,
      height = 2 * buttonRadius;

    var svg = d3
      .select(svgRef.current)
      .append("svg")
      // .attr("width", (width + margin.left + margin.right))
      // .attr("height", (height + margin.top + margin.bottom))
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("class", "wrapper")
      // .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (height / 2 + margin.top) + ")");
      .attr("transform", `translate(${buttonRadius - 50}, ${buttonRadius}) rotate(-10)`);

    var arcData = [
      { name: "Nutrients & their sources", value: 18, label: "Nutrieducation" },
      { name: "RDA/EAR for each Nutrient", value: 19, label: "rdaAndEar" },
      { name: "Dietary Guidelines for Indians", value: 21, label: "dietaryGuidelines" },
      {
        name: "Nutrition Games",
        value: 12,
        label: "https://letsfixourfood-nutritiongames.vercel.app/",
      },
      { name: "", value: 18 },
    ];
    // var colors = ["#922590", "#EF5A2A", "#F8B13F", "#FCEF0A", "white"];
    var colors = ["#662C90", "#2C74BE", "#3CADF1", "#29A89F", "white"];
    var colorScale = d3
      .scaleOrdinal()
      .domain(arcData.map((d) => d.name))
      .range(colors);

    //Create an arc function
    var arc = d3
      .arc()
      // .innerRadius(width * 0.55 / 2)
      // .outerRadius(width * 0.75 / 2 + 30);
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    //Turn the pie chart 90 degrees counter clockwise, so it starts at the left
    var pie = d3
      .pie()
      .startAngle((-75 * Math.PI) / 180)
      .endAngle((-90 * Math.PI) / 180 + 2 * Math.PI)
      .value(function (d) {
        return d.value;
      })
      .padAngle(0.01)
      .sort(null);
    //Create the donut slices and also the invisible arcs for the text
    svg
      .selectAll(".smallArcs")
      .data(pie(arcData))
      .enter()
      .append("path")
      .attr("class", "smallArcs")
      .attr("d", arc)
      .style("fill", function (d, i) {
        if (i === 4) return "none"; // Set fill color to none for the arc at index 4
        else return colorScale(i);
      })
      .style("fill-opacity", function (d, i) {
        if (i === 4) return 0; // Set fill opacity to 0 for the arc at index 4
        else return 1;
      })
      .on("click", function (event, d) {
        if (!d.data.label) {
          return;
        }
        // navigate(`/${d.data.label}`);
        window.location.href = d.data.label;
      })
      .on("mouseenter", function (event, d) {
        if (d.data.name !== "") {
          var originalColor = colorScale(d.data.name);
          d3.select(this).style("fill", d3.color(originalColor).brighter(0.5));
          d3.select(this).transition().duration(100).style("opacity", 0.3);
        }
      })
      .on("mouseleave", function (event, d) {
        if (d.data.name !== "") {
          var originalColor = colorScale(d.data.name);
          d3.select(this).style("fill", originalColor);
          d3.select(this).transition().duration(100).style("opacity", 1);
        }
      })
      .style("cursor", "pointer")
      .each(function (d, i) {
        //Search pattern for everything between the start and the first capital L
        var firstArcSection = /(^.+?)L/;

        //Grab everything up to the first Line statement
        var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
        //Replace all the comma's so that IE can handle it
        newArc = newArc.replace(/,/g, " ");

        //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
        //flip the end and start position
        if (d.endAngle > (90 * Math.PI) / 180) {
          var startLoc = /M(.*?)A/, //Everything between the first capital M and first capital A
            middleLoc = /A(.*?)0 0 1/, //Everything between the first capital A and 0 0 1
            endLoc = /0 0 1 (.*?)$/; //Everything between the first 0 0 1 and the end of the string (denoted by $)
          //Flip the direction of the arc by switching the start en end point (and sweep flag)
          //of those elements that are below the horizontal line
          var newStart = endLoc.exec(newArc)[1];
          var newEnd = startLoc.exec(newArc)[1];
          var middleSec = middleLoc.exec(newArc)[1];

          //Build up the new arc notation, set the sweep-flag to 0
          newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
        } //if

        //Create a new invisible arc that the text can flow along
        svg
          .append("path")
          .attr("class", "hiddensmallArcs")
          .attr("id", "smallArc" + i)
          .attr("d", newArc)
          .style("fill", "none");
      });

    //Append the label names on the outside
    var fontSize = buttonRadius / 15;
    svg
      .selectAll(".arcText")
      .data(pie(arcData))
      .enter()
      .append("text")
      .attr("class", "arcText")
      //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
      .attr("dy", function (d, i) {
        return d.endAngle > (90 * Math.PI) / 180 ? -buttonRadius / 9 : buttonRadius / 7;
      })
      .append("textPath")
      .attr("startOffset", "50%")
      .style("text-anchor", "middle")
      .style("font-family", '"roboto", sans-serif')
      .style("font-size", fontSize)
      .attr("fill", "white")
      .style("letter-spacing", "0.1em")
      .style("cursor", "pointer")
      .attr("xlink:href", function (d, i) {
        return "#smallArc" + i;
      })
      .text(function (d) {
        return d.data.name;
      })
      .on("click", function (event, d) {
        handleArcClick(event, d.data);
      });
    svg
      .append("image")
      .attr("href", myPlate)
      .attr("width", buttonRadius + 50)
      .attr("height", buttonRadius + 50)
      .attr("x", -buttonRadius / 1.9)
      .attr("y", -buttonRadius / 1.95);
  }, []);

  return (
    <svg
      className="readial-component"
      viewBox={`0 0 ${buttonRadius * 2} ${buttonRadius * 2}`}
      ref={svgRef}
    ></svg>
  );
}

export default ArcDesignSmall;
