import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Jurmata from "../../../../../../assets/FoodSources/Protein/Fruits/1_jurmata.jpg"
import ManilaTamarind from "../../../../../../assets/FoodSources/Protein/Fruits/2_ManilaTamarind.jpg"
import ApricotDried from "../../../../../../assets/FoodSources/Protein/Fruits/3_ApricotDried.jpg"
import WoodApple from "../../../../../../assets/FoodSources/Protein/Fruits/4_WoodApple.jpg"
import Avocado from "../../../../../../assets/FoodSources/Protein/Fruits/5_avocado.jpg"
import TamarindPulp from "../../../../../../assets/FoodSources/Protein/Fruits/6_TamarindPulpy.jpg"
import DurianFruit from "../../../../../../assets/FoodSources/Protein/Fruits/7_durianFruit.jpg"
import Raisins from "../../../../../../assets/FoodSources/Protein/Fruits/8_Raisins.jpg"
import JackFruit from "../../../../../../assets/FoodSources/Protein/Fruits/9_JackFruit.jpg"
import Bael from "../../../../../../assets/FoodSources/Protein/Fruits/10_Bael.jpg"
import { Typography } from '@mui/material';

export default function Fruits() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: Jurmata,
    title: 'Black Jamun',
    author: '4.8 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: ManilaTamarind,
    title: 'Manila tamarind',
    author: '3.56 per 100gm',
  },
  {
    img: ApricotDried,
    title: 'Apricot, dried',
    author: '3.17 per 100gm',
  },
  {
    img: WoodApple,
    title: 'Wood apple',
    author: '13.14 per 100gm',
    cols: 2,
  },
  {
    img: Avocado,
    title: 'Avocado, fruit',
    author: '2.95 per 100gm',
    cols: 2,
  },
  {
    img: TamarindPulp,
    title: 'Tamarind, pulp',
    author: '2.92 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: DurianFruit,
    title: 'Durian',
    author: '2.8 per 100gm',
  },
  {
    img: Raisins,
    title: 'Raisins, dried, golden',
    author: '2.76 per 100gm.4',
  },
  {
    img: JackFruit,
    title: 'Jack fruit, ripe',
    author: '2.74 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: Bael,
    title: 'Bael fruit',
    author: '2.63 per 100gm',
  }
];