// Icons need to be changed as per the context, and set aligment of text and also if possible check the alignment
//   for RDA chart is it having some margin-left, we have to remove it, and check for any other changes if needed..

import React from "react";
import { AvatarGroup, Box, Tooltip } from "@mui/joy";
import { Avatar, Grid, ListItemAvatar, ListItemText } from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import energetic from "../../../../../assets/images/icons/vitamins/vitaminb1/energetic.png";
import brain from "../../../../../assets/images/icons/vitamins/vitaminb1/brain.png";
import cellularfunction from "../../../../../assets/images/icons/vitamins/vitaminb1/cellularfunction.png";
import nervoussytem from "../../../../../assets/images/icons/vitamins/vitaminb1/nervoussystem.png";
import milk from "../../../../../assets/images/icons/vitamins/vitaminb1/milk.png";
import pork from "../../../../../assets/images/icons/vitamins/vitaminb1/pork.png";
import wholegrains from "../../../../../assets/images/icons/vitamins/vitaminb1/wholegrains.png";
import glv from "../../../../../assets/images/icons/vitamins/vitaminb1/glv.png";
import legumes from "../../../../../assets/images/icons/vitamins/vitaminb1/legumes.png";
import butter from "../../../../../assets/images/icons/vitamins/vitaminb1/butter.png";
import Human from "../../../../../assets/FoodSources/Carbohydrate/user.png";
import nerve_impulse from '../../../../../assets/images/icons/minerals/Functions/nerve_impulse.png';
import fluid_balance from '../../../../../assets/images/icons/minerals/Functions/fluid_balance.png';
import vomit from '../../../../../assets/images/icons/vitamins/vitaminb5/vomit.png';
import diarrhea from '../../../../../assets/images/icons/vitamins/vitaminb3/diarrhea.png';
import sweating from '../../../../../assets/images/icons/minerals/Deficiency/sweating.png';
import muscle_cramps from '../../../../../assets/images/icons/minerals/Deficiency/muscle_cramps.png';
import weakness from '../../../../../assets/images/icons/minerals/Deficiency/weakness.png';
import irregular_heart_rate from '../../../../../assets/images/icons/vitamins/Deficiency/VitaminB9/arrhythmia.png';
import salt from "../../../../../assets/FoodSources/Minerals/Sources/salt.png";
import cheese from "../../../../../assets/FoodSources/Minerals/Sources/cheese.png";
import soy_sauce from "../../../../../assets/FoodSources/Minerals/Sources/soy_sauce.png";
import ketchep from "../../../../../assets/FoodSources/Minerals/Sources/ketchep.png";
import packaged_foods from "../../../../../assets/FoodSources/Minerals/Sources/packaged_foods.png";
import fish from "../../../../../assets/FoodSources/Minerals/Sources/fish.png";
import sea_weed from "../../../../../assets/FoodSources/Minerals/Sources/sea_weed.png";
import beef from "../../../../../assets/FoodSources/Minerals/Sources/beef.png";

export default function Chloride() {
  return (
    <>
      <Grid item lg={7} md={6} sm={12}>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What are the functions of chloride?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={fluid_balance} />
                </ListItemAvatar>
                <ListItemText primary="It along with sodium and potassium helps in the maintenance of osmotic and acid-base balance within the body." />
              </ListItem>
            </List>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={nerve_impulse} />
                </ListItemAvatar>
                <ListItemText
                  primary="It is crucial for muscular and nervous activity through mediating transmission of nerve impulses."
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the food sources of chloride?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-5px' }, }}>
              <ListItem>
                The main source of chloride in the diet is table salt (sodium chloride) and as an additive and preservative in processed foods such as cheese, potato chips, soy sauce and ketchup.
              </ListItem>
              <ListItem>
                Chloride is also naturally found in small amounts in meat, seafood, and seaweed.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="salt">
                <Avatar src={salt} size="lg" alt="salt" variant="square" />
              </Tooltip>
              <Tooltip title="cheese">
                <Avatar src={cheese} alt="cheese" variant="square" />
              </Tooltip>
              <Tooltip title="potato chips">
                <Avatar src={packaged_foods} alt="potato chips" variant="square" />
              </Tooltip>
              <Tooltip title="soy sauce">
                <Avatar src={soy_sauce} alt="soy sauce" variant="square" />
              </Tooltip>
              <Tooltip title="ketchup">
                <Avatar src={ketchep} alt="ketchup" variant="square" />
              </Tooltip>
              <Tooltip title="meat">
                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={beef} alt="meat" variant="square" />
              </Tooltip>
              <Tooltip title="sea food">
                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={fish} alt="fish" variant="square" />
              </Tooltip>
              <Tooltip title="seaweed">
                <Avatar sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }} src={sea_weed} alt="seaweed" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>

          <Box
            sx={{
                            display: { xs: 'block', sm: 'none' },
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                        }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: { xs: 1.5, sm: 1 } }}>
              <Tooltip title="meat">
                <Avatar src={beef} alt="meat" variant="square" />
              </Tooltip>
              <Tooltip title="sea food">
                <Avatar src={fish} alt="fish" variant="square" />
              </Tooltip>
              <Tooltip title="seaweed">
                <Avatar src={sea_weed} alt="seaweed" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>

        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the interactions of chloride with other nutrients?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Chloride interacts with sodium and potassium to maintain electrolyte balance. There is no hindrance reported." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={5} md={6} sm={12}>
      <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 0, sm:1 },
            py: 0.5,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is chloride absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Chloride is absorbed in the small intestine by passive diffusion." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: { xs: 1, sm:0 }
          }}
        >
          <Typography level="h5" fontWeight="lg" align="start">
            What is the recommended dietary intake of chloride?
          </Typography>
          <CardContent>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={Human} />
                </ListItemAvatar>
                <ListItemText primary="The recommended intake of chloride required for adult man and woman is 1000 mg/d." />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            py: 0.5,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              How is chloride absorbed in the body?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Chloride is absorbed in the small intestine by passive diffusion." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What happens when you are deficient in chloride?
            </Typography>
            <List sx={{ textAlign: "left", '& > li': { marginBottom: '-8px' }, }}>
              <ListItem>
                Chloride deficiency also known hypochloremia is caused by excessive fluid loss in conditions like vomiting, diarrhea, or excessive sweating.
              </ListItem>
              <ListItem>
                The symptoms of chloride deficiency includes muscle cramps, weakness, nausea, lethargy, and irregular heart rhythms.
              </ListItem>
            </List>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <AvatarGroup size="lg" sx={{ "--Avatar-size": "28px", display: "flex", gap: 1 }}>
              <Tooltip title="vomiting">
                <Avatar src={vomit} size="lg" alt="vomiting" variant="square" />
              </Tooltip>
              <Tooltip title="diarrhea">
                <Avatar src={diarrhea} alt="diarrhea" variant="square" />
              </Tooltip>
              <Tooltip title="excessive sweating">
                <Avatar src={sweating} alt="excessive sweating" variant="square" />
              </Tooltip>
              <Tooltip title="muscle cramps">
                <Avatar src={muscle_cramps} alt="muscle cramps" variant="square" />
              </Tooltip>
              <Tooltip title="weakness">
                <Avatar src={weakness} alt="weakness" variant="square" />
              </Tooltip>
              <Tooltip title="irregular heart rhythms ">
                <Avatar src={irregular_heart_rate} alt="irregular heart rhythms" variant="square" />
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Card>
        <Card
          variant="outlined"
          sx={{
            //  width: 320,
            // to make the card resizable
            overflow: "auto",
            resize: "horizontal",
            mt: 1,
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <CardContent>
            <Typography level="h5" fontWeight="lg" align="start">
              What are the interactions of chloride with other nutrients?
            </Typography>
            <List sx={{ textAlign: "left" }}>
              <ListItem alignItems="flex-start">
                <ListItemText primary="Chloride interacts with sodium and potassium to maintain electrolyte balance. There is no hindrance reported." />
              </ListItem>

            </List>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
