import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import SkimmedMilkPowder from "../../../../../../assets/FoodSources/Protein/Dairy/1_SkimmedMilkPowder.jpg"
import WholeMilkPowder from "../../../../../../assets/FoodSources/Protein/Dairy/2_WholeMilkPowder.png"
import Cheese from "../../../../../../assets/FoodSources/Protein/Dairy/3_Cheese.jpg"
import Paneer from "../../../../../../assets/FoodSources/Protein/Dairy/4_Paneer.jpg"
import ChannaCow from "../../../../../../assets/FoodSources/Protein/Dairy/5_Channa.png"
import Khoa from "../../../../../../assets/FoodSources/Protein/Dairy/6_Khoa.jpg"
import ChannaBuffalo from "../../../../../../assets/FoodSources/Protein/Dairy/7_Channa.png"
import BuffaloMilk from "../../../../../../assets/FoodSources/Protein/Dairy/8_BuffaloMilk.jpg"
import GoatMilk from "../../../../../../assets/FoodSources/Protein/Dairy/9_goatMilk.jpg"
import CowMilk from "../../../../../../assets/FoodSources/Protein/Dairy/10_Cowmilk.jpg"
import Silan from "../../../../../../assets/FoodSources/Protein/Fishes and other see foods/10_Silan.jpg"
import { Typography } from '@mui/material';

export default function Dairy() {
  return (
    <ImageList
      sx={{
        gridTemplateColumns:
          'repeat(auto-fill, minmax(190px, 1fr))!important',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2
            x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            // title={item.title}
            title={
              <Typography variant="subtitle2" sx={{ fontSize: '0.em', whiteSpace: 'break-spaces', fontWeight:'bold'}}>
                {item.title}
              </Typography>
            }
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                {/* <InfoIcon /> */}
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: SkimmedMilkPowder,
    title: 'Skimmed milk powder (cow milk)',
    author: '38 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: WholeMilkPowder,
    title: 'whole milk powder (cow milk)',
    author: '25.8 per 100gm',
  },
  {
    img: Cheese,
    title: 'Cheese',
    author: '24.1 per 100gm',
  },
  {
    img: Paneer,
    title: 'Paneer (cow milk)',
    author: '18.86 per 100gm',
    cols: 2,
  },
  {
    img: ChannaCow,
    title: 'channa (cow milk)',
    author: '18.3 per 100gm',
    cols: 2,
  },
  {
    img: Khoa,
    title: 'Khoa',
    author: '16.34 per 100gm',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: ChannaBuffalo,
    title: 'channa (buffalo milk)',
    author: '13.4 per 100gm',
  },
  {
    img: BuffaloMilk,
    title: 'milk, buffalo',
    author: '3.68 per 100gm.4',
  },
  {
    img: GoatMilk,
    title: 'milk, goat',
    author: '3.3 per 100gm',
    rows: 2,
    cols: 2,
  },
  {
    img: CowMilk,
    title: 'Milk, cow',
    author: '3.26 per 100gm',
  }
];