import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getCompareData, setCompareData } from "../components/dropdown/CompareDataSlice";
import { getMapKeyValue, setMapKey } from "./MapKeySlice";

import {Card, CardHeader, CardContent, Typography, Avatar, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails, } from "@mui/material";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//import image1 from "../../../../assets/images/menu/infant.png";
import image1 from "../../../assets/images/menu/infant.png";
import image2 from "../../../assets/images/menu/schoolchildren.png";
import image3 from "../../../assets/images/menu/adolscentboy.png";
import image4 from "../../../assets/images/menu/adolescentgirl.png";
import image5 from "../../../assets/images/menu/adults.png";
import image6 from "../../../assets/images/menu/adultwomen.png";
import image7 from "../../../assets/images/menu/pregnantwomen.png";
import image8 from "../../../assets/images/menu/lactatingwomen.png";
import image9 from "../../../assets/images/menu/adultmen.png";
import image10 from "../../../assets/images/menu/elderlycouple.png";
import image11 from "../../../assets/images/menu/adolscents.png";
import Compare from "./Compare";

function Carddata(props) {
  const { selectedOption } = props;
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const [seriesData, setSeriesData] = useState([]);
  
  const compareData = useSelector(getCompareData);
  const dispatch = useDispatch();
 // console.log("CompareData for carddata is ", compareData);
  const mapKeyValue = useSelector(getMapKeyValue);
  //console.log("mapKeyValueforcarddata", mapKeyValue);

  // Declare uniqueSourceNames outside of useEffect
  const [uniqueSourceNames, setUniqueSourceNames] = useState([]);

  useEffect(() => {
    dispatch(setCompareData());
  }, [dispatch]);

  useEffect(() => {
    if (compareData && Array.isArray(compareData.results)) {
      const allowedSourceCodes = [
        "N5", "N4", "N3", "N2", "N1", "D4", "D3", "D2", "D1", "CN", "AHSCAB", "LW", "AHS10_11", "AHS11_12", "AHS12_13","NNMBURBAN","NNMB3","NNMBR2006"
      ];

      const filteredData = compareData.results.filter(
        (d) => allowedSourceCodes.includes(d.source_code)
      );
      setFilteredData(filteredData);
      //console.log("Filtered Data:", filteredData);

      // Calculate uniqueSourceNames and set it
      const sourceNames = Array.from(
        new Set(filteredData.map((dataPoint) => dataPoint.source_name))
      );
      setUniqueSourceNames(sourceNames);

      const generatedSeriesData = sourceNames.map((sourceName) => {
        const filteredSourceData = filteredData.filter(
          (dataPoint) => dataPoint.source_name === sourceName
        );
        if (filteredSourceData.length <= 1) {
          return null; // Skip series with one or fewer data points
        }
       // console.log("Filtered Source Data for", sourceName, ":", filteredSourceData);

        const seriesData = filteredSourceData
          .filter((dataPoint) => {
            if (mapKeyValue && mapKeyValue !== "india") {
              const isIndiaAreaLevel = dataPoint.area && dataPoint.area.toLowerCase() === mapKeyValue.toLowerCase();
              return isIndiaAreaLevel;
            } else {
              const isMapKeyValueArea = dataPoint.area === dataPoint.area_name && (dataPoint.area_level === 2 || dataPoint.area_level === 1);

              return isMapKeyValueArea;
            }
          })
          .map((dataPoint) => ({
            name: dataPoint.area_name,
            y: parseFloat(dataPoint.value),
            rural: dataPoint.rural,
            urban: dataPoint.urban,
            title: dataPoint.title,
            indicator_name: dataPoint.indicator_name,
            subindicator_name: dataPoint.subindicator_name,
            title: dataPoint.title,
            source_footnote: dataPoint.source_footnote,
            footnote1: dataPoint.footnote1,
            footnote2: dataPoint.footnote2,
            footnote3: dataPoint.footnote3,
            area_level: dataPoint.area_level,
            sourcearea_level: dataPoint.sourcearea_level
          }));

       // console.log("Series Data for", sourceName, ":", seriesData);

        return {
          name: sourceName,
          data: seriesData,
        };
      }).filter(Boolean);

      setSeriesData(generatedSeriesData);
    } else {
     // console.error("Error: compareData or compareData.results is null or undefined");
    }
  }, [compareData, mapKeyValue]);

  const cardHeaderStyle = {
    background: `linear-gradient(to bottom, rgba(196, 0, 0, 0.1), rgba(196, 0, 0, 1))`,
    borderBottom: '2px solid rgba(196, 0, 0, 1)',
  };
  const imageForSelectedLifecycle = (selectedLifecycle) => {
    switch (selectedLifecycle) {
      case 1:
        return image1;
      case 2:
        return image2;
      case 3:
        return image3;
      case 4:
        return image4;
      case 5:
        return image9;
      case 6:
        return image6;
      case 7:
        return image7;
      case 8:
        return image8;
      case 9:
        return image10;
      case 10:
        return image11;
      case 11:
        return image5;
      default:
        return null;
    }
  };
  function getValueForSelectedOption(rowData, selectedOption) {
    switch (selectedOption) {
      case "total":
        // Check if rowData.y is a valid number, otherwise return a default value
        return isNaN(rowData.y) ? "NA" : rowData.y;
      case "rural":
        return rowData.rural;
      case "urban":
        return rowData.urban;
      default:
        return "NA";
    }
  }
  function getColorForSeries(seriesName) {
    if (seriesName === "NFHS-5(2019-21)") {
      //return "#E6A532";
      return "#c4000080";
    } else if (seriesName === "NFHS-4(2015-16)") {
      return "#62968c";
    }
    
    else if (seriesName === "NFHS-3(2005-06)") {
      //return "#9e9ac8";
      return "#945ECF";
      
    }
    else if (seriesName === "NFHS-2(1998-99)") {
      return "#367dc4";
    }
    else if (seriesName === "NFHS-1(1992-93)") {
      return "#ef8d5d";
    }
    else if (seriesName === "CNNS(2016-18)") {
      return "#E6A532";
    }
    else if (seriesName === "DLHS-4(2012-14)") {
      return "#b11217";
    }
    else if (seriesName === "DLHS-3(2005-06)") {
      return "#e17646";
    }
    else if (seriesName === "DLHS-2(2002-04)") {
      return "#dbbe5e";
    }
    else if (seriesName === "DLHS-1(1998-99)") {
      return "#caddab";
    }
    else if (seriesName === "AHS-CAB(2012-13)") {
      return "#fea848";
    }
    else if (seriesName === "AHS(2010-11)") {
      return "#af69a0";
    }
    else if (seriesName === "AHS-(2011-12)") {
      return "#349db4";
    }
    else if (seriesName === "AHS-(2012-13)") {
      return "#724d8c";
    }
    else if (seriesName === "LASI Wave-1(2017-18)") {
      return "#a1cbbc";
    }
    else if (seriesName.trim() === "NNMB-Urban Nutrition Report (2017)") {
        
      return "#8a5479";
    }
    else if (seriesName.trim() === "NNMB-Rural-Third repeat survey (2011-12)") {
        
      return "#887cc2";
    }
    else if (seriesName.trim() === "NNMB Rural Survey (2005-06)") {
      
      return "#bf95b6";
    }
    
    // Add more conditions for other series names and colors if needed
    // If none of the conditions match, return a default color
    return "#fea848";
  }
  return (
    <Card style={filteredData && filteredData.length > 0 && filteredData[0].indicator_id === "L1_13" ? { width: '100%', height: '65vh' } : { height: 420 }}>
      <CardHeader
        style={{ ...cardHeaderStyle, color: 'white', position: 'sticky', top: 0, zIndex: 1 }}
        avatar={
          compareData && compareData.results ? (
            <img
              src={imageForSelectedLifecycle(compareData.results[0].id)}
              alt={`Image for ID ${compareData.results[0].id}`}
              style={{ height: 80, width: 80 }}
            />
          ) : null
        }
        title={
          <Typography variant="h6" style={{ fontWeight: 'bold', color: 'white' }}>
            {mapKeyValue ? mapKeyValue.toUpperCase() : 'NA'}
          </Typography>
        }
        subheader={
          compareData && compareData.results && uniqueSourceNames && uniqueSourceNames.length > 0 ? (
            <div>
              <Typography style={{ color: 'white' }}>
                {compareData.results[0].indicator_name} - {compareData.results[0].subindicator_name}
              </Typography>
              <Typography style={{ color: 'white' }}>
                {uniqueSourceNames.length === 1
                  ? `Source: ${uniqueSourceNames[0]}`
                  : `Selected Sources: ${uniqueSourceNames[0]} compared to ${uniqueSourceNames.slice(1).join(', ')}`
                }
              </Typography>
            </div>
          ) : (
            <Typography style={{ color: 'white' }}>
              No sources to compare
            </Typography>
          )
        }
        
        style={cardHeaderStyle} // Apply the cardHeaderStyle here
      />

<CardContent style={{ overflowY: 'auto', height: filteredData && filteredData.length > 0 && filteredData[0].indicator_id === "L1_13" ? 'calc(80vh - 64px)' : 'auto' }}>
<div style={{ overflowX: 'auto', maxWidth: '100%', height: '100%' }}>
    <div style={{ overflowY: 'auto', maxHeight: 350, width: '100%', height: '100%' }}>
      <TableContainer component={Paper} style={{ height: '100%', margin: 0, padding: 0 }}>
          <Table >
          <TableHead>
          <TableRow style={{ height: "8px" }}>
  <TableCell style={{ height: "8px" }}>{/* Placeholder cell */}</TableCell>
  {seriesData.map((data) => (
    <TableCell
      key={data.name}
      style={{
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
        height: "8px",
        position: "sticky",
        top: 0,
        zIndex: 1,
        background: "white",
      }}
    >
      {selectedOption === "total" ? "Total" : selectedOption.toUpperCase()}
    </TableCell>
  ))}
</TableRow>
<TableRow style={{ height: "8px" }}>
  <TableCell style={{ height: '8px' }}>Area</TableCell>
  {seriesData.map((data) => (
    <TableCell
      key={data.name}
      style={{
        height: '8px',
        color: getColorForSeries(data.name),
        fontWeight: 'bold',
      }}
    >
      {data.name}
    </TableCell>
  ))}
</TableRow>
</TableHead>

<TableBody>
  {seriesData[0]?.data
    .sort((a, b) => a.area_level - b.area_level)
    .map((rowData) => (
      <TableRow key={rowData.name} style={{ height: "8px" }}>
        <TableCell>{rowData.name}</TableCell>
        {seriesData.map((data) => {
          const series = data.data.find((series) => series.name === rowData.name);
          const value = series ? getValueForSelectedOption(series, selectedOption) : "NA";
          return <TableCell key={`${data.name}-${rowData.name}`} style={{ height: "8px" }}>{value}</TableCell>;
        })}
      </TableRow>
    ))}
</TableBody>

</Table>


          </TableContainer>
        </div>
        </div>
      </CardContent>
    </Card>
  );
}
export default Carddata;
