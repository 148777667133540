import { Grid } from "@mui/material";
import React from "react";
import Tsparticles from "./Tsparticles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IndiaMap from "./HomeMap/IndiaMap";
import myPlate from "../../assets/images/myplateicon.png";
import "./Home.css";
import ArcDesignLarge from "./HomeRadialComponents/ArcDesignLarge";
import ArcDesignSmall from "./HomeRadialComponents/ArcDesignSmall";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",

  color: theme.palette.text.secondary,
}));
const Home = () => {
  return (
    <div>
      <Tsparticles />
      <Box display="flex" flexDirection="column" mt={4} alignContent={"center"}>
        <Grid container justifyContent="center">
          <Grid md={5} xs={12}>
            <ArcDesignLarge />

            {/* <Item sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <IndiaMap style={{ zIndex: "2" }} />
            </Item> */}
          </Grid>

          <Grid md={4} xs={12} mb={8}>
            <ArcDesignSmall />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Home;
