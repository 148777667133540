import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);


const referenceText = "* Dorni C, Sharma P, Saikia G, Longvah T. Fatty acid profile of edible oils and fats consumed in India.Food Chem. 2018;238:9-15.";

function CookingOilCompositionChart() {

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 550,
            inverted: true,
        },
        title: {
            text: 'Fatty Acid Composition of Edible Oil'
        },
        xAxis: {
            categories: ['Vanaspathi', 'Ghee', 'Sunflower oil','Soyabean oil','Safflower oil blended','Safflower oil pure', 'Ricebran oil', 'Palmolein','Mustard oil','Groundnut oil','Gingelly oil','Cotton seed oil','Corn oil','Coconut oil']
        },
        yAxis: {
            max: 100,
            title: {
                text: 'Values in percentage'
            },
            tickInterval: 5 // Set the interval between ticks to 20
        },
        caption: {
            text: referenceText,
            align: "center",
            verticalAlign: "bottom",
            useHTML: true,
            style: {
              fontSize: "10px",
              color: "#000000",
              fontFamily: "Verdana, sans-serif",
              fontWeight: "normal",
            },
            position: {
              align: "center",
              verticalAlign: "bottom",
              y: -10,
            },
          },
        legend: {
            reversed: true
        },
        credits: {
            text: "Nutrtion Atlas 2.0,<br/> Powered by ICMR-National Institute of Nutrition",
            href: "http://nutritionatlasindia.in/",
            position: {
              align: "right",
              x: -10,
              y: -15,
            },
          },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            name: 'TSFA – Total Saturated Fatty Acid',
            data: [61.65, 71.02, 11.39, 15.90, 19.91,9.19,23.63,44.84,5.73,19.27,16.45,28.17,16.60,90.84],
            color: '#FFCDFF'
        },
        {
            name: 'TMUFA – Total Unsaturated Fatty Acid',
            data: [33.62,26.44,25.92,24.77,36.90,14.04,43.71,43.62,66.98,53.77,41.21,19.66,33.67,7.24],
            color: '#99CDFF'
        }, 
        {
            name: 'TPUFA – Total Polyunsaturated Fatty Acid',
            data: [4.73,2.54,62.69,59.33,43.79,76.78,32.66,11.54,27.28,26.96,42.34,52.16,49.74,1.90],
            color: '#FFFF99'
        },
        {
            name: 'TPUFA/TSFA',
            data: [0.08,0.04,5.51,3.73,2.27,8.39,1.38,0.26,4.76,1.40,2.57,1.85,3.00,0.02],
            color: '#99FF99'
        }]
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
   
}

export default CookingOilCompositionChart;

