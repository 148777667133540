export const data1 = [
  {
    id: "0.0",
    parent: "",
    name: "RDA",
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.1",
    parent: "0.0",
    name: "Infants",
    value: 2 / 22,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.2",
    parent: "0.0",
    name: "Children",
    value: 3 / 22,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.3",
    parent: "0.0",
    name: "Boys",
    value: 3 / 22,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.4",
    parent: "0.0",
    name: "Girls",
    value: 3 / 22,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.5",
    parent: "0.0",
    name: "Men",
    value: 3 / 22,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.6",
    parent: "0.0",
    name: "Women",
    value: 6 / 22,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.7",
    parent: "0.0",
    name: "Elderly",
    value: 2 / 22,
    rdavalue: "",
    earvalue: "",
  },

  {
    id: "1.11",
    parent: "1.1",
    name: "0-6 Months* <br> (Reference body Wt 5.8 Kg)",
    value: 17.0,
    referenceBodyWeight: 5.8,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.111",
    parent: "1.11",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 90,
  },
  {
    id: "1.112",
    parent: "1.11",
    name: "Dietary Fibre (g/d)",
    measuringunit: "(g/d)",
    value: 1,
    rdavalue: "-",
    earvalue: "-",
  },
  {
    id: "1.113",
    parent: "1.11",
    name: "Protein (g/Kg/d)",
    measuringunit: "(g/Kg/d)",
    value: 1,
    rdavalue: 1.4,
    earvalue: 1.16,
  },
  {
    id: "1.114",
    parent: "1.11",
    name: "VitaminA (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 350,
    earvalue: "-",
  },
  {
    id: "1.115",
    parent: "1.11",
    name: "Thiamin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.2,
    earvalue: "-",
  },
  {
    id: "1.116",
    parent: "1.11",
    name: "Riboflavin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.4,
    earvalue: "-",
  },
  {
    id: "1.117",
    parent: "1.11",
    name: "Niacin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 2,
    earvalue: "-",
  },
  {
    id: "1.118",
    parent: "1.11",
    name: "VitaminC (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 20,
    earvalue: "-",
  },
  {
    id: "1.119",
    parent: "1.11",
    name: "Vitamin-B6 (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.1,
    earvalue: "-",
  },
  {
    id: "1.1110",
    parent: "1.11",
    name: "Folate (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 25,
    earvalue: "-",
  },
  {
    id: "1.1111",
    parent: "1.11",
    name: "Vitamin-B12 (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 1.2,
    earvalue: "-",
  },
  {
    id: "1.1112",
    parent: "1.11",
    name: "VitaminD (IU/d)",
    measuringunit: "(Iu/d)",
    value: 1,
    rdavalue: 400,
    earvalue: "-",
  },
  {
    id: "1.1113",
    parent: "1.11",
    name: "Calcium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 300,
    earvalue: "-",
  },
  {
    id: "1.1114",
    parent: "1.11",
    name: "Magnesium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 30,
    earvalue: "-",
  },
  {
    id: "1.1115",
    parent: "1.11",
    name: "Iron (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: "-",
  },
  {
    id: "1.1116",
    parent: "1.11",
    name: "Zinc (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: "-",
  },
  {
    id: "1.1117",
    parent: "1.11",
    name: "Iodine (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: "100",
    earvalue: "-",
  },

  {
    id: "1.12",
    parent: "1.1",
    name: "6-12 Months <br> (Reference body Wt 8.5 Kg)",
    value: 17.0,
    referenceBodyWeight: 8.5,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.121",
    parent: "1.12",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 80,
  },
  {
    id: "1.122",
    parent: "1.12",
    name: "Dietary Fibre (g/d)",
    measuringunit: "(g/d)",
    value: 1,
    rdavalue: "-",
    earvalue: "-",
  },
  {
    id: "1.123",
    parent: "1.12",
    name: "Protein (g/Kg/d)",
    measuringunit: "(g/Kg/d)",
    value: 1,
    rdavalue: 1.23,
    earvalue: 1.04,
  },
  {
    id: "1.124",
    parent: "1.12",
    name: "VitaminA (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 350,
    earvalue: 170,
  },
  {
    id: "1.125",
    parent: "1.12",
    name: "Thiamin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.4,
    earvalue: "-",
  },
  {
    id: "1.126",
    parent: "1.12",
    name: "Riboflavin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.6,
    earvalue: "-",
  },
  {
    id: "1.127",
    parent: "1.12",
    name: "Niacin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 5,
    earvalue: "-",
  },
  {
    id: "1.128",
    parent: "1.12",
    name: "VitaminC (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 30,
    earvalue: "-",
  },
  {
    id: "1.129",
    parent: "1.12",
    name: "Vitamin-B6 (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.6,
    earvalue: 0.5,
  },
  {
    id: "1.1210",
    parent: "1.12",
    name: "Folate (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 85,
    earvalue: 71,
  },
  {
    id: "1.1211",
    parent: "1.12",
    name: "Vitamin-B12 (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 1.2,
    earvalue: 1,
  },
  {
    id: "1.1212",
    parent: "1.12",
    name: "VitaminD (IU/d)",
    measuringunit: "(IU/d)",
    value: 1,
    rdavalue: 400,
    earvalue: "-",
  },
  {
    id: "1.1213",
    parent: "1.12",
    name: "Calcium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 300,
    earvalue: "-",
  },
  {
    id: "1.1214",
    parent: "1.12",
    name: "Magnesium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 75,
    earvalue: "-",
  },
  {
    id: "1.1215",
    parent: "1.12",
    name: "Iron (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 3,
    earvalue: 2,
  },
  {
    id: "1.1216",
    parent: "1.12",
    name: "Zinc (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 2.5,
    earvalue: 2.1,
  },
  {
    id: "1.1217",
    parent: "1.12",
    name: "Iodine (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 130,
    earvalue: "-",
  },

  // Children

  {
    id: "1.21",
    parent: "1.2",
    name: "1-3 Years <br> (Reference body Wt 12.9 Kg)",
    value: 17.0,
    referenceBodyWeight: 12.9,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.211",
    parent: "1.21",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 83,
  },
  {
    id: "1.212",
    parent: "1.21",
    name: "Dietary Fibre (g/d)",
    measuringunit: "(g/d)",
    value: 1,
    rdavalue: 15,
    earvalue: "-",
  },
  {
    id: "1.213",
    parent: "1.21",
    name: "Protein (g/Kg/d)",
    measuringunit: "(g/kg/d)",
    value: 1,
    rdavalue: 0.97,
    earvalue: 0.79,
  },
  {
    id: "1.214",
    parent: "1.21",
    name: "VitaminA (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 390,
    earvalue: 180,
  },
  {
    id: "1.215",
    parent: "1.21",
    name: "Thiamin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.7,
    earvalue: 0.6,
  },
  {
    id: "1.216",
    parent: "1.21",
    name: "Riboflavin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 1.1,
    earvalue: 0.8,
  },
  {
    id: "1.217",
    parent: "1.21",
    name: "Niacin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 7,
    earvalue: 6,
  },
  {
    id: "1.218",
    parent: "1.21",
    name: "VitaminC (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 30,
    earvalue: 24,
  },
  {
    id: "1.219",
    parent: "1.21",
    name: "Vitamin-B6 (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.9,
    earvalue: 0.8,
  },
  {
    id: "1.2110",
    parent: "1.21",
    name: "Folate (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 120,
    earvalue: 97,
  },
  {
    id: "1.2111",
    parent: "1.21",
    name: "Vitamin-B12 (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 1.2,
    earvalue: 1,
  },
  {
    id: "1.2112",
    parent: "1.21",
    name: "VitaminD (IU/d)",
    measuringunit: "(IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.2113",
    parent: "1.21",
    name: "Calcium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 500,
    earvalue: 400,
  },
  {
    id: "1.2114",
    parent: "1.21",
    name: "Magnesium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 90,
    earvalue: 73,
  },
  {
    id: "1.2115",
    parent: "1.21",
    name: "Iron (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 8,
    earvalue: 6,
  },
  {
    id: "1.2116",
    parent: "1.21",
    name: "Zinc (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 3.3,
    earvalue: 2.8,
  },
  {
    id: "1.2117",
    parent: "1.21",
    name: "Iodine (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 90,
    earvalue: 65,
  },

  {
    id: "1.22",
    parent: "1.2",
    name: "4-6 Years <br> (Reference body Wt 18.3 Kg)",
    value: 17.0,
    referenceBodyWeight: 18.3,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.221",
    parent: "1.22",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 74,
  },
  {
    id: "1.222",
    parent: "1.22",
    name: "Dietary Fibre (g/d)",
    measuringunit: "(g/d)",
    value: 1,
    rdavalue: 20,
    earvalue: "-",
  },
  {
    id: "1.223",
    parent: "1.22",
    name: "Protein (g/Kg/d)",
    measuringunit: "(g/kg/d)",
    value: 1,
    rdavalue: 0.87,
    earvalue: 0.7,
  },
  {
    id: "1.224",
    parent: "1.22",
    name: "VitaminA (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 510,
    earvalue: 240,
  },
  {
    id: "1.225",
    parent: "1.22",
    name: "Thiamin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 0.9,
    earvalue: 0.8,
  },
  {
    id: "1.226",
    parent: "1.22",
    name: "Riboflavin (mg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 1.3,
    earvalue: 1.1,
  },
  {
    id: "1.227",
    parent: "1.22",
    name: "Niacin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 9,
    earvalue: 8,
  },
  {
    id: "1.228",
    parent: "1.22",
    name: "VitaminC (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 35,
    earvalue: 27,
  },
  {
    id: "1.229",
    parent: "1.22",
    name: "Vitamin-B6 (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 1.2,
    earvalue: 1,
  },
  {
    id: "1.2210",
    parent: "1.22",
    name: "Folate (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 135,
    earvalue: 111,
  },
  {
    id: "1.2211",
    parent: "1.22",
    name: "Vitamin-B12 (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.2212",
    parent: "1.22",
    name: "VitaminD (IU/d)",
    measuringunit: "(IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.2213",
    parent: "1.22",
    name: "Calcium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 550,
    earvalue: 450,
  },
  {
    id: "1.2214",
    parent: "1.22",
    name: "Magnesium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 125,
    earvalue: 104,
  },
  {
    id: "1.2215",
    parent: "1.22",
    name: "Iron (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 11,
    earvalue: 8,
  },
  {
    id: "1.2216",
    parent: "1.22",
    name: "Zinc (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 4.5,
    earvalue: 3.7,
  },
  {
    id: "1.2217",
    parent: "1.22",
    name: "Iodine (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 90,
    earvalue: 65,
  },

  {
    id: "1.23",
    parent: "1.2",
    name: "7-9 Years <br> (Reference body Wt 25.3 Kg)",
    value: 17.0,
    referenceBodyWeight: 25.3,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.231",
    parent: "1.23",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 67,
  },
  {
    id: "1.232",
    parent: "1.23",
    name: "Dietary Fibre (g/d)",
    measuringunit: "(g/d)",
    value: 1,
    rdavalue: 26,
    earvalue: "-",
  },
  {
    id: "1.233",
    parent: "1.23",
    name: "Protein (g/Kg/d)",
    measuringunit: "(g/kg/d)",
    value: 1,
    rdavalue: 0.92,
    earvalue: 0.75,
  },
  {
    id: "1.234",
    parent: "1.23",
    name: "VitaminA (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 630,
    earvalue: 290,
  },
  {
    id: "1.235",
    parent: "1.23",
    name: "Thiamin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 1.1,
    earvalue: 1,
  },
  {
    id: "1.236",
    parent: "1.23",
    name: "Riboflavin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 1.6,
    earvalue: 1.3,
  },
  {
    id: "1.237",
    parent: "1.23",
    name: "Niacin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 11,
    earvalue: 10,
  },
  {
    id: "1.238",
    parent: "1.23",
    name: "VitaminC (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 45,
    earvalue: 36,
  },
  {
    id: "1.239",
    parent: "1.23",
    name: "Vitamin-B6 (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 1.5,
    earvalue: 1.3,
  },
  {
    id: "1.2310",
    parent: "1.23",
    name: "Folate (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 170,
    earvalue: 142,
  },
  {
    id: "1.2311",
    parent: "1.23",
    name: "Vitamin-B12 (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.2312",
    parent: "1.23",
    name: "VitaminD (IU/d)",
    measuringunit: "(IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.2313",
    parent: "1.23",
    name: "Calcium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 650,
    earvalue: 500,
  },
  {
    id: "1.2314",
    parent: "1.23",
    name: "Magnesium (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 175,
    earvalue: 144,
  },
  {
    id: "1.2315",
    parent: "1.23",
    name: "Iron (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 15,
    earvalue: 10,
  },
  {
    id: "1.2316",
    parent: "1.23",
    name: "Zinc (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 5.9,
    earvalue: 4.9,
  },
  {
    id: "1.2317",
    parent: "1.23",
    name: "Iodine (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 90,
    earvalue: 65,
  },

  // Boys

  {
    id: "1.31",
    parent: "1.3",
    name: "10-12 Years <br> (Reference body Wt 34.9 Kg)",
    value: 17.0,
    referenceBodyWeight: 34.9,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.311",
    parent: "1.31",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 64,
  },
  {
    id: "1.312",
    parent: "1.31",
    name: "Dietary Fibre (g/d)",
    measuringunit: "(g/d)",
    value: 1,
    rdavalue: 33,
    earvalue: "-",
  },
  {
    id: "1.313",
    parent: "1.31",
    name: "Protein (g/Kg/d)",
    measuringunit: "(g/kg/d)",
    value: 1,
    rdavalue: 0.91,
    earvalue: 0.75,
  },
  {
    id: "1.314",
    parent: "1.31",
    name: "VitaminA (μg/d)",
    measuringunit: "(μg/d)",
    value: 1,
    rdavalue: 770,
    earvalue: 360,
  },
  {
    id: "1.315",
    parent: "1.31",
    name: "Thiamin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 1.5,
    earvalue: 1.3,
  },
  {
    id: "1.316",
    parent: "1.31",
    name: "Riboflavin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 2.5,
    earvalue: 1.7,
  },
  {
    id: "1.317",
    parent: "1.31",
    name: "Niacin (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 15,
    earvalue: 12,
  },
  {
    id: "1.318",
    parent: "1.31",
    name: "VitaminC (mg/d)",
    measuringunit: "(mg/d)",
    value: 1,
    rdavalue: 55,
    earvalue: 45,
  },
  {
    id: "1.319",
    parent: "1.31",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2,
    earvalue: 1.7,
  },
  {
    id: "1.3110",
    parent: "1.31",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 220,
    earvalue: 180,
  },
  {
    id: "1.3111",
    parent: "1.31",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.3112",
    parent: "1.31",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.3113",
    parent: "1.31",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 850,
    earvalue: 650,
  },
  {
    id: "1.3114",
    parent: "1.31",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 240,
    earvalue: 199,
  },
  {
    id: "1.3115",
    parent: "1.31",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 16,
    earvalue: 12,
  },
  {
    id: "1.3116",
    parent: "1.31",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 8.5,
    earvalue: 7,
  },
  {
    id: "1.3117",
    parent: "1.31",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 100,
    earvalue: 70,
  },

  {
    id: "1.32",
    parent: "1.3",
    name: "13-15 Years <br> (Reference body Wt 50.5 Kg)",
    value: 17.0,
    referenceBodyWeight: 50.5,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.321",
    parent: "1.32",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 57,
  },
  {
    id: "1.322",
    parent: "1.32",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 43,
    earvalue: "-",
  },
  {
    id: "1.323",
    parent: "1.32",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.89,
    earvalue: 0.72,
  },
  {
    id: "1.324",
    parent: "1.32",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 930,
    earvalue: 430,
  },
  {
    id: "1.325",
    parent: "1.32",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.326",
    parent: "1.32",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2.7,
    earvalue: 2.2,
  },
  {
    id: "1.327",
    parent: "1.32",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 19,
    earvalue: 16,
  },
  {
    id: "1.328",
    parent: "1.32",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 70,
    earvalue: 60,
  },
  {
    id: "1.329",
    parent: "1.32",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.6,
    earvalue: 2.2,
  },
  {
    id: "1.3210",
    parent: "1.32",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 285,
    earvalue: 238,
  },
  {
    id: "1.3211",
    parent: "1.32",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.3212",
    parent: "1.32",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.3213",
    parent: "1.32",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.3214",
    parent: "1.32",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 345,
    earvalue: 287,
  },
  {
    id: "1.3215",
    parent: "1.32",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 22,
    earvalue: 15,
  },
  {
    id: "1.3216",
    parent: "1.32",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 14.3,
    earvalue: 11.9,
  },
  {
    id: "1.3217",
    parent: "1.32",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 100,
  },

  {
    id: "1.33",
    parent: "1.3",
    name: "16-18 Years <br> (Reference body Wt 64.4 Kg)",
    value: 17.0,
    referenceBodyWeight: 64.4,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.331",
    parent: "1.33",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 52,
  },
  {
    id: "1.332",
    parent: "1.33",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 50,
    earvalue: "-",
  },
  {
    id: "1.333",
    parent: "1.33",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.86,
    earvalue: 0.7,
  },
  {
    id: "1.334",
    parent: "1.33",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 480,
  },
  {
    id: "1.335",
    parent: "1.33",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 1.9,
  },
  {
    id: "1.336",
    parent: "1.33",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 3.1,
    earvalue: 2.1,
  },
  {
    id: "1.337",
    parent: "1.33",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 22,
    earvalue: 19,
  },
  {
    id: "1.338",
    parent: "1.33",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 85,
    earvalue: 70,
  },
  {
    id: "1.339",
    parent: "1.33",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 3,
    earvalue: 2.5,
  },
  {
    id: "1.3310",
    parent: "1.33",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 340,
    earvalue: 286,
  },
  {
    id: "1.3311",
    parent: "1.33",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.3312",
    parent: "1.33",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.3313",
    parent: "1.33",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1050,
    earvalue: 850,
  },
  {
    id: "1.3314",
    parent: "1.33",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 440,
    earvalue: 367,
  },
  {
    id: "1.3315",
    parent: "1.33",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 26,
    earvalue: 18,
  },
  {
    id: "1.3316",
    parent: "1.33",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 17.6,
    earvalue: 14.7,
  },
  {
    id: "1.3317",
    parent: "1.33",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 100,
  },

  // Girls

  {
    id: "1.41",
    parent: "1.4",
    name: "10-12 Years <br> (Reference body Wt 36.4 Kg)",
    value: 17,
    referenceBodyWeight: 36.4,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.411",
    parent: "1.41",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 57,
  },
  {
    id: "1.412",
    parent: "1.41",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 30,
    earvalue: "-",
  },
  {
    id: "1.413",
    parent: "1.41",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.9,
    earvalue: 0.73,
  },
  {
    id: "1.414",
    parent: "1.41",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 790,
    earvalue: 370,
  },
  {
    id: "1.415",
    parent: "1.41",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.4,
    earvalue: 1.2,
  },
  {
    id: "1.416",
    parent: "1.41",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.417",
    parent: "1.41",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 14,
    earvalue: 12,
  },
  {
    id: "1.418",
    parent: "1.41",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 50,
    earvalue: 44,
  },
  {
    id: "1.419",
    parent: "1.41",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.4110",
    parent: "1.41",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 225,
    earvalue: 186,
  },
  {
    id: "1.4111",
    parent: "1.41",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.4112",
    parent: "1.41",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.4113",
    parent: "1.41",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 850,
    earvalue: 650,
  },
  {
    id: "1.4114",
    parent: "1.41",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 250,
    earvalue: 207,
  },
  {
    id: "1.4115",
    parent: "1.41",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 28,
    earvalue: 16,
  },
  {
    id: "1.4116",
    parent: "1.41",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 8.5,
    earvalue: 7.1,
  },
  {
    id: "1.4117",
    parent: "1.41",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 100,
    earvalue: 70,
  },

  {
    id: "1.42",
    parent: "1.4",
    name: "13-15 Years <br> (Reference body Wt 49.6 Kg)",
    value: 17.0,
    referenceBodyWeight: 49.6,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.421",
    parent: "1.42",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 49,
  },
  {
    id: "1.422",
    parent: "1.42",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 36,
    earvalue: "-",
  },
  {
    id: "1.423",
    parent: "1.42",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.87,
    earvalue: 0.7,
  },
  {
    id: "1.424",
    parent: "1.42",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 890,
    earvalue: 420,
  },
  {
    id: "1.425",
    parent: "1.42",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.6,
    earvalue: 1.3,
  },
  {
    id: "1.426",
    parent: "1.42",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 1.9,
  },
  {
    id: "1.427",
    parent: "1.42",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 16,
    earvalue: 13,
  },
  {
    id: "1.428",
    parent: "1.42",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 65,
    earvalue: 55,
  },
  {
    id: "1.429",
    parent: "1.42",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 1.8,
  },
  {
    id: "1.4210",
    parent: "1.42",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 245,
    earvalue: 204,
  },
  {
    id: "1.4211",
    parent: "1.42",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.4212",
    parent: "1.42",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.4213",
    parent: "1.42",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.4214",
    parent: "1.42",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 340,
    earvalue: 282,
  },
  {
    id: "1.4215",
    parent: "1.42",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 30,
    earvalue: 17,
  },
  {
    id: "1.4216",
    parent: "1.42",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 12.8,
    earvalue: 10.7,
  },
  {
    id: "1.4217",
    parent: "1.42",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 100,
  },

  {
    id: "1.43",
    parent: "1.4",
    name: "16-18 Years <br> (Reference body Wt 55.7 Kg)",
    value: 17.0,
    referenceBodyWeight: 55.7,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.431",
    parent: "1.43",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 45,
  },
  {
    id: "1.432",
    parent: "1.43",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 38,
    earvalue: "-",
  },
  {
    id: "1.433",
    parent: "1.43",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.83,
    earvalue: 0.67,
  },
  {
    id: "1.434",
    parent: "1.43",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 860,
    earvalue: 400,
  },
  {
    id: "1.435",
    parent: "1.43",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.7,
    earvalue: 1.4,
  },
  {
    id: "1.436",
    parent: "1.43",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2.3,
    earvalue: 1.9,
  },
  {
    id: "1.437",
    parent: "1.43",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 17,
    earvalue: 14,
  },
  {
    id: "1.438",
    parent: "1.43",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 70,
    earvalue: 57,
  },
  {
    id: "1.439",
    parent: "1.43",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.3,
    earvalue: 1.9,
  },
  {
    id: "1.4310",
    parent: "1.43",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 270,
    earvalue: 223,
  },
  {
    id: "1.4311",
    parent: "1.43",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.4312",
    parent: "1.43",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.4313",
    parent: "1.43",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1050,
    earvalue: 850,
  },
  {
    id: "1.4314",
    parent: "1.43",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 380,
    earvalue: 317,
  },
  {
    id: "1.4315",
    parent: "1.43",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 32,
    earvalue: 18,
  },
  {
    id: "1.4316",
    parent: "1.43",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 14.2,
    earvalue: 11.8,
  },
  {
    id: "1.4317",
    parent: "1.43",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 100,
  },

  //Men

  {
    id: "1.51",
    parent: "1.5",
    name: "Sedentary <br> (Reference body Wt 65 Kg)",
    value: 17.0,
    referenceBodyWeight: 65,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.511",
    parent: "1.51",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 32,
  },
  {
    id: "1.512",
    parent: "1.51",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 30,
    earvalue: "-",
  },
  {
    id: "1.513",
    parent: "1.51",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.83,
    earvalue: 0.66,
  },
  {
    id: "1.514",
    parent: "1.51",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 460,
  },
  {
    id: "1.515",
    parent: "1.51",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.4,
    earvalue: 1.2,
  },
  {
    id: "1.516",
    parent: "1.51",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2,
    earvalue: 1.6,
  },
  {
    id: "1.517",
    parent: "1.51",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 14,
    earvalue: 12,
  },
  {
    id: "1.518",
    parent: "1.51",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 80,
    earvalue: 65,
  },
  {
    id: "1.519",
    parent: "1.51",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.5110",
    parent: "1.51",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 300,
    earvalue: 250,
  },
  {
    id: "1.5111",
    parent: "1.51",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.5112",
    parent: "1.51",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.5113",
    parent: "1.51",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.5114",
    parent: "1.51",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 440,
    earvalue: 370,
  },
  {
    id: "1.5115",
    parent: "1.51",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 19,
    earvalue: 11,
  },
  {
    id: "1.5116",
    parent: "1.51",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 17,
    earvalue: 14.1,
  },
  {
    id: "1.5117",
    parent: "1.51",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },

  {
    id: "1.52",
    parent: "1.5",
    name: "Moderate <br> (Reference body Wt 65 Kg)",
    value: 17.0,
    referenceBodyWeight: 65,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.521",
    parent: "1.52",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 42,
  },
  {
    id: "1.522",
    parent: "1.52",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 40,
    earvalue: "-",
  },
  {
    id: "1.523",
    parent: "1.52",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.83,
    earvalue: 0.66,
  },
  {
    id: "1.524",
    parent: "1.52",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 460,
  },
  {
    id: "1.525",
    parent: "1.52",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.8,
    earvalue: 1.5,
  },
  {
    id: "1.526",
    parent: "1.52",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2.5,
    earvalue: 2.1,
  },
  {
    id: "1.527",
    parent: "1.52",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 18,
    earvalue: 15,
  },
  {
    id: "1.528",
    parent: "1.52",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 80,
    earvalue: 65,
  },
  {
    id: "1.529",
    parent: "1.52",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.4,
    earvalue: 2.1,
  },
  {
    id: "1.5210",
    parent: "1.52",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 300,
    earvalue: 250,
  },
  {
    id: "1.5211",
    parent: "1.52",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.5212",
    parent: "1.52",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.5213",
    parent: "1.52",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.5214",
    parent: "1.52",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 440,
    earvalue: 370,
  },
  {
    id: "1.5215",
    parent: "1.52",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 19,
    earvalue: 11,
  },
  {
    id: "1.5216",
    parent: "1.52",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 17,
    earvalue: 14.1,
  },
  {
    id: "1.5217",
    parent: "1.52",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },

  {
    id: "1.53",
    parent: "1.5",
    name: "Heavy <br> (Reference body Wt 65 Kg)",
    value: 17.0,
    referenceBodyWeight: 65,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.531",
    parent: "1.53",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 53,
  },
  {
    id: "1.532",
    parent: "1.53",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 50,
    earvalue: "-",
  },
  {
    id: "1.533",
    parent: "1.53",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.83,
    earvalue: 0.66,
  },
  {
    id: "1.534",
    parent: "1.53",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 460,
  },
  {
    id: "1.535",
    parent: "1.53",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 2.3,
    earvalue: 1.9,
  },
  {
    id: "1.536",
    parent: "1.53",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 3.2,
    earvalue: 2.7,
  },
  {
    id: "1.537",
    parent: "1.53",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 23,
    earvalue: 19,
  },
  {
    id: "1.538",
    parent: "1.53",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 80,
    earvalue: 65,
  },
  {
    id: "1.539",
    parent: "1.53",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 3.1,
    earvalue: 2.6,
  },
  {
    id: "1.5310",
    parent: "1.53",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 300,
    earvalue: 250,
  },
  {
    id: "1.5311",
    parent: "1.53",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.5312",
    parent: "1.53",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.5313",
    parent: "1.53",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.5314",
    parent: "1.53",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 440,
    earvalue: 370,
  },
  {
    id: "1.5315",
    parent: "1.53",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 19,
    earvalue: 11,
  },
  {
    id: "1.5316",
    parent: "1.53",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 17,
    earvalue: 14.1,
  },
  {
    id: "1.5317",
    parent: "1.53",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },

  //Women

  {
    id: "1.61",
    parent: "1.6",
    name: "Sedentary <br> (Reference body Wt 55 Kg)",
    value: 17,
    referenceBodyWeight: 55,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.611",
    parent: "1.61",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 30,
  },
  {
    id: "1.612",
    parent: "1.61",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 25,
    earvalue: "-",
  },
  {
    id: "1.613",
    parent: "1.61",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.83,
    earvalue: 0.66,
  },
  {
    id: "1.614",
    parent: "1.61",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 840,
    earvalue: 390,
  },
  {
    id: "1.615",
    parent: "1.61",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.4,
    earvalue: 1.1,
  },
  {
    id: "1.616",
    parent: "1.61",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.617",
    parent: "1.61",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 11,
    earvalue: 9,
  },
  {
    id: "1.618",
    parent: "1.61",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 65,
    earvalue: 55,
  },
  {
    id: "1.619",
    parent: "1.61",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.6110",
    parent: "1.61",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 220,
    earvalue: 180,
  },
  {
    id: "1.6111",
    parent: "1.61",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.6112",
    parent: "1.61",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.6113",
    parent: "1.61",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.6114",
    parent: "1.61",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 370,
    earvalue: 310,
  },
  {
    id: "1.6215",
    parent: "1.61",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 29,
    earvalue: 15,
  },
  {
    id: "1.6216",
    parent: "1.61",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 13.2,
    earvalue: 11,
  },
  {
    id: "1.6217",
    parent: "1.61",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },

  {
    id: "1.62",
    parent: "1.6",
    name: "Moderate <br> (Reference body Wt 55 Kg)",
    value: 17.0,
    referenceBodyWeight: 55,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.621",
    parent: "1.62",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 39,
  },
  {
    id: "1.622",
    parent: "1.62",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 30,
    earvalue: "-",
  },
  {
    id: "1.623",
    parent: "1.62",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.83,
    earvalue: 0.66,
  },
  {
    id: "1.624",
    parent: "1.62",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 840,
    earvalue: 390,
  },
  {
    id: "1.625",
    parent: "1.62",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.7,
    earvalue: 1.4,
  },
  {
    id: "1.626",
    parent: "1.62",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2.4,
    earvalue: 2,
  },
  {
    id: "1.627",
    parent: "1.62",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 14,
    earvalue: 12,
  },
  {
    id: "1.628",
    parent: "1.62",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 65,
    earvalue: 55,
  },
  {
    id: "1.629",
    parent: "1.62",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.6210",
    parent: "1.62",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 220,
    earvalue: 180,
  },
  {
    id: "1.6211",
    parent: "1.62",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.6212",
    parent: "1.62",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.6213",
    parent: "1.62",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.6214",
    parent: "1.62",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 370,
    earvalue: 310,
  },
  {
    id: "1.6215",
    parent: "1.62",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 29,
    earvalue: 15,
  },
  {
    id: "1.6216",
    parent: "1.62",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 13.2,
    earvalue: 11,
  },
  {
    id: "1.6217",
    parent: "1.62",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },

  {
    id: "1.63",
    parent: "1.6",
    name: "Heavy <br> (Reference body Wt 55 Kg)",
    value: 17.0,
    referenceBodyWeight: 55,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.631",
    parent: "1.63",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 49,
  },
  {
    id: "1.632",
    parent: "1.63",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 40,
    earvalue: "-",
  },
  {
    id: "1.633",
    parent: "1.63",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 0.83,
    earvalue: 0.66,
  },
  {
    id: "1.634",
    parent: "1.63",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 840,
    earvalue: 390,
  },
  {
    id: "1.635",
    parent: "1.63",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 1.8,
  },
  {
    id: "1.636",
    parent: "1.63",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 3.1,
    earvalue: 2.6,
  },
  {
    id: "1.637",
    parent: "1.63",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 18,
    earvalue: 15,
  },
  {
    id: "1.638",
    parent: "1.63",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 65,
    earvalue: 55,
  },
  {
    id: "1.639",
    parent: "1.63",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.4,
    earvalue: 2.1,
  },
  {
    id: "1.6310",
    parent: "1.63",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 220,
    earvalue: 180,
  },
  {
    id: "1.6311",
    parent: "1.63",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.6312",
    parent: "1.63",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.6313",
    parent: "1.63",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.6314",
    parent: "1.63",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 370,
    earvalue: 310,
  },
  {
    id: "1.6315",
    parent: "1.63",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 29,
    earvalue: 15,
  },
  {
    id: "1.6316",
    parent: "1.63",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 13.2,
    earvalue: 11,
  },
  {
    id: "1.6317",
    parent: "1.63",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },

  {
    id: "1.64",
    parent: "1.6",
    name: "Pregnant Women  <br> (Reference body Wt 55 + 10 Kg)",
    value: 17,
    referenceBodyWeight: 55 + 10,
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.641",
    parent: "1.64",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 30,
  },
  {
    id: "1.642",
    parent: "1.64",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: "-",
    earvalue: "-",
  },
  {
    id: "1.643",
    parent: "1.64",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: "0.83 + 9.5 (2nd trimester), 0.83 + 22 (3rd trimester)",
    earvalue: "0.66 + 7.6 (2nd trimester), 0.66 + 17.6 (3rd trimester)",
  },
  {
    id: "1.644",
    parent: "1.64",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 900,
    earvalue: 406,
  },
  {
    id: "1.645",
    parent: "1.64",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 2,
    earvalue: 1.6,
  },
  {
    id: "1.646",
    parent: "1.64",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2.7,
    earvalue: 2.3,
  },
  {
    id: "1.647",
    parent: "1.64",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 13,
    earvalue: 11,
  },
  {
    id: "1.648",
    parent: "1.64",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 80,
    earvalue: 65,
  },
  {
    id: "1.649",
    parent: "1.64",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.3,
    earvalue: 1.9,
  },
  {
    id: "1.6410",
    parent: "1.64",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 570,
    earvalue: 480,
  },
  {
    id: "1.6411",
    parent: "1.64",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.45,
    earvalue: 2.2,
  },
  {
    id: "1.6412",
    parent: "1.64",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.6413",
    parent: "1.64",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 800,
  },
  {
    id: "1.6414",
    parent: "1.64",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 440,
    earvalue: 370,
  },
  {
    id: "1.6415",
    parent: "1.64",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 27,
    earvalue: 21,
  },
  {
    id: "1.6416",
    parent: "1.64",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 14.5,
    earvalue: 12,
  },
  {
    id: "1.6417",
    parent: "1.64",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 220,
    earvalue: 160,
  },

  {
    id: "1.65",
    parent: "1.6",
    name: "Lactation(0-6m)",
    value: 17.0,
    referenceBodyWeight: "",
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.651",
    parent: "1.65",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 39,
  },
  {
    id: "1.652",
    parent: "1.65",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: "-",
    earvalue: "-",
  },
  {
    id: "1.653",
    parent: "1.65",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: "0.83 + 17 (2nd trimester)",
    earvalue: "0.66 + 13.6 (2nd trimester)",
  },
  {
    id: "1.654",
    parent: "1.65",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 950,
    earvalue: 720,
  },
  {
    id: "1.655",
    parent: "1.65",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 2.1,
    earvalue: 1.7,
  },
  {
    id: "1.656",
    parent: "1.65",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 3,
    earvalue: 2.5,
  },
  {
    id: "1.657",
    parent: "1.65",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 16,
    earvalue: 13,
  },
  {
    id: "1.658",
    parent: "1.65",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 115,
    earvalue: 95,
  },
  {
    id: "1.659",
    parent: "1.65",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.16,
    earvalue: 1.82,
  },
  {
    id: "1.6510",
    parent: "1.65",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 330,
    earvalue: 280,
  },
  {
    id: "1.6511",
    parent: "1.65",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 3.2,
    earvalue: 2.8,
  },
  {
    id: "1.6512",
    parent: "1.65",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.6513",
    parent: "1.65",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1200,
    earvalue: 1000,
  },
  {
    id: "1.6514",
    parent: "1.65",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 400,
    earvalue: 335,
  },
  {
    id: "1.6515",
    parent: "1.65",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 23,
    earvalue: 16,
  },
  {
    id: "1.6516",
    parent: "1.65",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 14.1,
    earvalue: 11.8,
  },
  {
    id: "1.6517",
    parent: "1.65",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 280,
    earvalue: 200,
  },

  {
    id: "1.66",
    parent: "1.6",
    name: "Lactation(7-12m)",
    value: 17.0,
    referenceBodyWeight: "",
    rdavalue: "",
    earvalue: "",
  },
  {
    id: "1.661",
    parent: "1.66",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 39,
  },
  {
    id: "1.662",
    parent: "1.66",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: "-",
    earvalue: "-",
  },
  {
    id: "1.663",
    parent: "1.66",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: "0.83 + 13 (3rd trimister)",
    earvalue: "0.66 + 10.6 (3rd trimister)",
  },
  {
    id: "1.664",
    parent: "1.66",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 950,
    earvalue: 720,
  },
  {
    id: "1.665",
    parent: "1.66",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 2.1,
    earvalue: 1.7,
  },
  {
    id: "1.666",
    parent: "1.66",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2.9,
    earvalue: 2.4,
  },
  {
    id: "1.667",
    parent: "1.66",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 16,
    earvalue: 13,
  },
  {
    id: "1.668",
    parent: "1.66",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 115,
    earvalue: 95,
  },
  {
    id: "1.669",
    parent: "1.66",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 2.07,
    earvalue: 1.76,
  },
  {
    id: "1.6610",
    parent: "1.66",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 330,
    earvalue: 280,
  },
  {
    id: "1.6611",
    parent: "1.66",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 3.2,
    earvalue: 2.8,
  },
  {
    id: "1.6612",
    parent: "1.66",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 600,
    earvalue: 400,
  },
  {
    id: "1.6613",
    parent: "1.66",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1200,
    earvalue: 1000,
  },
  {
    id: "1.6614",
    parent: "1.66",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 400,
    earvalue: 335,
  },
  {
    id: "1.6615",
    parent: "1.66",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 23,
    earvalue: 16,
  },
  {
    id: "1.6616",
    parent: "1.66",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 14.1,
    earvalue: 11.8,
  },
  {
    id: "1.6617",
    parent: "1.66",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 280,
    earvalue: 200,
  },

  // Elderly

  {
    id: "1.71",
    parent: "1.7",
    name: "Men >= 60 Years",
    value: 17,
    rdavalue: "",
    earvalue: "",
  },

  {
    id: "1.711",
    parent: "1.71",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 1700,
  },
  {
    id: "1.712",
    parent: "1.71",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 30,
    earvalue: "-",
  },
  {
    id: "1.713",
    parent: "1.71",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 54,
    earvalue: 43,
  },
  {
    id: "1.714",
    parent: "1.71",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 1000,
    earvalue: 460,
  },
  {
    id: "1.715",
    parent: "1.71",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.4,
    earvalue: 1.2,
  },
  {
    id: "1.716",
    parent: "1.71",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 2,
    earvalue: 1.6,
  },
  {
    id: "1.717",
    parent: "1.71",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 14,
    earvalue: 12,
  },
  {
    id: "1.718",
    parent: "1.71",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 80,
    earvalue: 65,
  },
  {
    id: "1.719",
    parent: "1.71",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.7110",
    parent: "1.71",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 300,
    earvalue: 250,
  },
  {
    id: "1.7111",
    parent: "1.71",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.7112",
    parent: "1.71",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 800,
    earvalue: 400,
  },
  {
    id: "1.7113",
    parent: "1.71",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1200,
    earvalue: 1000,
  },
  {
    id: "1.7114",
    parent: "1.71",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 440,
    earvalue: 370,
  },
  {
    id: "1.7215",
    parent: "1.71",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 19,
    earvalue: 11,
  },
  {
    id: "1.7216",
    parent: "1.71",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 17,
    earvalue: 14,
  },
  {
    id: "1.7217",
    parent: "1.71",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },

  {
    id: "1.72",
    parent: "1.7",
    name: "Women >= 60 Years",
    value: 17,
    rdavalue: "",
    earvalue: "",
  },

  {
    id: "1.721",
    parent: "1.72",
    name: "Energy (Kcal/Kg/d)",
    measuringunit: "(Kcal/Kg/d)",
    value: 1,
    rdavalue: "-",
    earvalue: 1500,
  },
  {
    id: "1.722",
    parent: "1.72",
    name: "Dietary Fibre (g/d)",
    value: 1,
    rdavalue: 25,
    earvalue: "-",
  },
  {
    id: "1.723",
    parent: "1.72",
    name: "Protein (g/Kg/d)",
    value: 1,
    rdavalue: 46,
    earvalue: 36.3,
  },
  {
    id: "1.724",
    parent: "1.72",
    name: "VitaminA (μg/d)",
    value: 1,
    rdavalue: 840,
    earvalue: 390,
  },
  {
    id: "1.725",
    parent: "1.72",
    name: "Thiamin (mg/d)",
    value: 1,
    rdavalue: 1.4,
    earvalue: 1.1,
  },
  {
    id: "1.726",
    parent: "1.72",
    name: "Riboflavin (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.727",
    parent: "1.72",
    name: "Niacin (mg/d)",
    value: 1,
    rdavalue: 11,
    earvalue: 9,
  },
  {
    id: "1.728",
    parent: "1.72",
    name: "VitaminC (mg/d)",
    value: 1,
    rdavalue: 65,
    earvalue: 55,
  },
  {
    id: "1.729",
    parent: "1.72",
    name: "Vitamin-B6 (mg/d)",
    value: 1,
    rdavalue: 1.9,
    earvalue: 1.6,
  },
  {
    id: "1.7210",
    parent: "1.72",
    name: "Folate (μg/d)",
    value: 1,
    rdavalue: 200,
    earvalue: 180,
  },
  {
    id: "1.7211",
    parent: "1.72",
    name: "Vitamin-B12 (μg/d)",
    value: 1,
    rdavalue: 2.2,
    earvalue: 2,
  },
  {
    id: "1.7212",
    parent: "1.72",
    name: "VitaminD (IU/d)",
    value: 1,
    rdavalue: 800,
    earvalue: 400,
  },
  {
    id: "1.7213",
    parent: "1.72",
    name: "Calcium (mg/d)",
    value: 1,
    rdavalue: 1200,
    earvalue: 1000,
  },
  {
    id: "1.7214",
    parent: "1.72",
    name: "Magnesium (mg/d)",
    value: 1,
    rdavalue: 370,
    earvalue: 310,
  },
  {
    id: "1.7215",
    parent: "1.72",
    name: "Iron (mg/d)",
    value: 1,
    rdavalue: 19,
    earvalue: 11,
  },
  {
    id: "1.7216",
    parent: "1.72",
    name: "Zinc (mg/d)",
    value: 1,
    rdavalue: 13.2,
    earvalue: 11,
  },
  {
    id: "1.7217",
    parent: "1.72",
    name: "Iodine (μg/d)",
    value: 1,
    rdavalue: 140,
    earvalue: 95,
  },
];

// export const transformData = (data, tableType) => {
//   // Helper function to recursively find children of a given parent
//   const findChildren = (parentId) =>
//     data.filter((item) => item.parent === parentId);

//   // Helper function to calculate the total values for each header category
//   const calculateTotal = (parentId, header) => {
//     const nutrientData = data.find(
//       (item) => item.parent === parentId && item.name.includes(header)
//     );
//     return nutrientData ? (tableType === "RDA" ? nutrientData.rdavalue : nutrientData.earvalue) : 0;
//   };

//   // Create a new array to hold the transformed data
//   const transformedData = [];

//   // Find the top-level categories (Age Groups)
//   const ageGroups = findChildren('0.0');

//   // Loop through each Age Group
//   for (const ageGroup of ageGroups) {
//     // Find the corresponding children (Categories) for each Age Group
//     const categories = findChildren(ageGroup.id);

//     // Loop through each Category
//     for (const category of categories) {
//       // Exclude the objects with empty CategoryOfWork
//       const categoryOfWork = category.name.split('<br>')[0].trim();
//       // Find the corresponding children (headers) for each Category
//       const headers = findChildren(category.id);

//       // Find the reference body weight for the category
//       const referenceBodyWeightData = data.find(
//         (item) => item.id === category.id
//       )?.referenceBodyWeight;
//       const referenceBodyWeight = referenceBodyWeightData || '';

//       // Create a new object to hold the transformed data for the Category
//       const categoryData = {
//         AgeGroup: ageGroup.name,
//         CategoryOfWork: categoryOfWork,
//         ReferenceBodyWeight: referenceBodyWeight,
//         Energy: calculateTotal(category.id, 'Energy (Kcal/Kg/d)'),
//         DietaryFiber: calculateTotal(category.id, 'Dietary Fibre (g/d)'),
//         Protein: calculateTotal(category.id, 'Protein (g/Kg/d)'),
//         Calcium: calculateTotal(category.id, 'Calcium (mg/d)'),
//         Magnesium: calculateTotal(category.id, 'Magnesium (mg/d)'),
//         Iron: calculateTotal(category.id, 'Iron (mg/d)'),
//         Zinc: calculateTotal(category.id, 'Zinc (mg/d)'),
//         Iodine: calculateTotal(category.id, 'Iodine (μg/d)'),
//         Thiamine: calculateTotal(category.id, 'Thiamin (mg/d)'),
//         Riboflavin: calculateTotal(category.id, 'Riboflavin (mg/d)'),
//         Niacin: calculateTotal(category.id, 'Niacin (mg/d)'),
//         VitB6: calculateTotal(category.id, 'Vitamin-B6 (mg/d)'),
//         Folate: calculateTotal(category.id, 'Folate (μg/d)'),
//         VitB12: calculateTotal(category.id, 'Vitamin-B12 (μg/d)'),
//         VitC: calculateTotal(category.id, 'VitaminC (mg/d)'),
//         VitA: calculateTotal(category.id, 'VitaminA (μg/d)'),
//         VitD: calculateTotal(category.id, 'VitaminD (Iu/d)'),
//       };

//       // Add the Category data to the transformed data array
//       transformedData.push(categoryData);
//     }
//   }

//   return transformedData;
// };

// // Usage for RDA table
// export const transformedDataRDA = transformData(data1, "RDA");

// // Usage for EAR table
// export const transformedDataEAR = transformData(data1, "EAR");

// console.log(transformData(data1, "EAR"))

export const transformData1 = (data) => {
  // Helper function to recursively find children of a given parent
  const findChildren = (parentId) => data.filter((item) => item.parent === parentId);

  // Create a new object to hold the transformed data
  const transformedData1 = {};

  // Find the top-level categories (Age Groups)
  const ageGroups = findChildren("0.0");

  // Loop through each Age Group
  for (const ageGroup of ageGroups) {
    // Find the corresponding children (Categories) for each Age Group
    const categories = findChildren(ageGroup.id);

    // Loop through each Category
    for (const category of categories) {
      // Exclude the objects with empty CategoryOfWork
      const categoryOfWork = category.name.split("<br>")[0].trim();
      // Find the corresponding children (headers) for each Category
      const nutrients = findChildren(category.id);

      // Find the reference body weight for the category
      const referenceBodyWeightData = data.find(
        (item) => item.id === category.id
      )?.referenceBodyWeight;
      const referenceBodyWeight = referenceBodyWeightData || "";

      // Create a new object to hold the transformed data for the Category
      const categoryData = {
        AgeGroup: ageGroup.name,
        CategoryOfWork: categoryOfWork,
        ReferenceBodyWeight: referenceBodyWeight,
      };

      // Loop through each nutrient and add RDA and EAR values to the category
      for (const nutrient of nutrients) {
        const nutrientName = nutrient.name.replace(/\sRDA|\sEAR$/, "");
        //console.log("Nutrient:", nutrient); // Add this line for debugging
        
        categoryData[nutrientName] = {
          RDA: nutrient.rdavalue || null,
          EAR: nutrient.earvalue || null,
        };
        //console.log(nutrientName,categoryData[nutrientName])
      }

      // Add the Category data to the transformed data object
      transformedData1[category.id] = categoryData;
    }
  }

  return transformedData1;
};

export const transformedData1 = transformData1(data1);

//console.log(transformedData1);